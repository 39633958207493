import { useAuth } from "@with-nx/auth";
import { useCallback, useEffect, useState } from "react";

import useService from "./useService";

export type TradeDiscount = {
  id: string;
  approved: boolean;
  association: string;
  membershipNumber: string;
  percentage: number;
  expires: string;
};

export async function getAllAvailableDiscounts(
  token: string
): Promise<TradeDiscount[]> {
  const service = useService("microservice");

  const request = await service(
    "GET",
    "/ecommerce/discounts/trade-organization",
    {
      "pagination[page]": "1",
      "pagination[pageSize]": "50",
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );

  return request?.["result"] as TradeDiscount[];
}

export async function applyForDiscount(
  token: string,
  code: string,
  association: string
): Promise<void> {
  const service = useService("microservice");

  await service(
    "POST",
    "/ecommerce/discounts/trade-organization",
    {
      association,
      membershipNumber: code,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export const useDiscounts = () => {
  const [data, _data] = useState<TradeDiscount[]>([]);
  const auth = useAuth();
  const [loading, _loading] = useState<boolean>(true);

  const fetch = useCallback(async () => {
    if (auth.authState.status !== "authenticated") {
      return;
    }

    const d = await getAllAvailableDiscounts(
      auth.authState.session?.token || ""
    );

    _data(d);
    _loading(false);
  }, [auth.authState.status, data, loading]);

  useEffect(() => {
    fetch();
  }, [auth.authState.status]);

  const helpers = {
    reload: fetch,
    apply: async (code: string, association: string) => {
      if (auth.authState.status !== "authenticated") {
        return;
      }

      await applyForDiscount(
        auth.authState.session?.token || "",
        code,
        association
      );

      fetch();
    },
  };

  return { data, helpers, loading };
};

export default useDiscounts;
