import { useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { Box } from "simple-effing-primitive-layout";

import ReusableSlantedContainer from "../reusable-slanted-container/reusable-slanted-container";

export const ReusableInstagramCallToAction = ({
  slanted,
  lighthouse,
}: {
  slanted?: boolean;
  lighthouse?: boolean;
}) => {
  const mobile = useMobile();

  return (
    <ReusableSlantedContainer
      direction="115deg"
      bottom={0}
      gradient={[
        "#8F7BEA",
        "#B069E8",
        "#DB54E4",
        "#E273B5",
        "#ECA172",
        "#EC8863",
        "#D1628B",
        "#DE7E79",
        "#EDD8A1",
      ]}
      still={slanted ? false : true}
    >
      <div
        className="container"
        style={{
          marginTop: "132px",
        }}
      >
        <Row gutter={[38, 18]} align="bottom" justify="space-between">
          <Col xs={24} sm={24} md={12} lg={13}>
            <Rule parse="!hl mb:12 d:block mt:64" size={32} tag="h6">
              See Our Products in Action
            </Rule>
            <Rule parse="!ps mb:32 d:block" size={14}>
              Explore our Instagram to see productions featuring Broadway Media
              products. Join our vibrant community and witness our technology
              tools help make stages come to life!
            </Rule>
            <DesignedButton
              label="Go to our Instagram"
              properties={{
                element: "a",
                native: {
                  href: "https://www.instagram.com/broadwaymedia/",
                  target: "_blank",
                },
                bottom: mobile ? 32 : 128,
              }}
              theme="white"
              size="large"
              type={{
                style: {
                  whiteSpace: "nowrap",
                  background:
                    "linear-gradient(269.2deg, #771ADE -35.15%, #8F38C6 4.63%, #CF4E9A 49.99%, #E37869 78.46%, #E78240 103.87%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                },
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={10} lg={7}>
            {lighthouse ? undefined : (
              <Box
                parse={`h:${
                  mobile ? "auto" : 0
                } w:100% oa:visible d:flex a:flex-end j:center`}
              >
                <a
                  rel="nofollow"
                  href="https://www.instagram.com/broadwaymedia/"
                  target="_blank"
                  aria-label="Go to our Instagram"
                >
                  <img
                    loading="lazy"
                    alt="Broadway Media's Instagram"
                    src="/assets/libs/simple-ui/reusable-instagram-call-to-action/assets/iphone.webp"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "auto",
                      pointerEvents: "none",
                    }}
                  />
                </a>
              </Box>
            )}
          </Col>
        </Row>
      </div>
    </ReusableSlantedContainer>
  );
};

export default ReusableInstagramCallToAction;
