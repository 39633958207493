import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../../shared";

const CuePopupInput = ({
  label,
  value,
  change,
  input,
  blur,
  properties,
}: {
  label?: string;
  value: string | number;
  change: (value: string | number) => void;
  blur?: () => void;
  input?: {
    [key: string]: unknown;
  };
  properties?: {
    [key: string]: unknown;
  };
}) => {
  const [storedValue, setStoredValue] = useState(value);

  useEffect(() => {
    setStoredValue(value);
  }, [value]);

  return (
    <>
      <Box parse="mb:-5" {...properties}>
        <Text rule="Label Tiny" size={10} color={Colors.font4}>
          {label}
        </Text>
        <Box>
          <input
            className="--small"
            value={storedValue}
            onBlur={() => {
              if (blur) {
                blur();
              }
            }}
            onChange={(event) => {
              setStoredValue(event.target.value);
              change(event.target.value);
            }}
            {...input}
          />
        </Box>
      </Box>
    </>
  );
};

export default CuePopupInput;
