import { ShowData, VideoSet } from "libs/editor-ui/types";

export interface EditorCueWrapperProps {
  data: ShowData;
  cue: VideoSet;
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  render: (props: EditorCueWrapperProps) => React.ReactNode;
  enabled: boolean;
  onCueMove: (
    actIndex: number,
    sceneIndex: number,
    oldCueIndex: number,
    newCueIndex: number
  ) => void;
  onCueDuplicate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onCueDisable: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onCueTeleport?: (
    teleportMode: string,
    oldActIndex: number,
    oldSceneIndex: number,
    oldCueIndex: number,
    newActIndex: number,
    newSceneIndex: number,
    newCueIndex: number
  ) => void;
  onCueUpdate?: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  onCueEnable: (actIndex: number, sceneIndex: number, cueIndex: number) => void;
  actEnabled?: boolean;
  sceneEnabled?: boolean;
  actCount?: number;
  sceneCount?: number;
  cueCount?: number;
}

const EditorCueWrapper = (props: EditorCueWrapperProps) => {
  const { render } = props;

  return render(props) as JSX.Element;
};

export default EditorCueWrapper;
