import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Props {
  render: (
    onCueMoveCallback: (dragIndex: number, hoverIndex: number) => unknown
  ) => React.ReactNode;
  mutate: (
    act: number,
    scene: number,
    dragIndex: number,
    hoverIndex: number
  ) => unknown;
  act: number;
  scene: number;
}

const CueMoverWrapper = ({ render, mutate, act, scene }: Props) => {
  const onCueMoveCallback = useCallback(
    (dragIndex: number, hoverIndex: number) =>
      mutate(act, scene, dragIndex, hoverIndex),
    [act, scene, mutate]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {render(onCueMoveCallback)}
    </DndProvider>
  );
};

export default CueMoverWrapper;
