import {
  AutoScroller,
  GridStepScroller,
  MobileViewSwitcher,
  ReusableDoubleGradientCard,
} from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { useRouter } from "next/router";
import { Box } from "simple-effing-primitive-layout";

import ReusableSection from "../reusable-section/reusable-section";
import Rule from "../rule/rule";

const solutions = [
  {
    title: "School Theatre",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/school-theatre",
    image:
      "/assets/solution/500x500/resources-for-school-theatre-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-school-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "College Theatre",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/college-theatre",
    image:
      "/assets/solution/500x500/resources-for-college-theatre-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-college-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Community Theatre",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/community-theatre",
    image:
      "/assets/solution/500x500/resources-for-community-theatre-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-community-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Professional Theatre",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/professional-theatre",
    image:
      "/assets/solution/500x500/resources-for-professional-theatre-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-professional-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Junior Theatre",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/junior-theatre",
    image:
      "/assets/solution/500x500/resources-for-junior-theatre-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-junior-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Choral and Music",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/choral-and-music",
    image:
      "/assets/solution/500x500/resources-for-choral-and-music-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-choral-and-music-provided-by-broadway-media.webp",
  },
  {
    title: "Dance and Ballet",
    gradients: ["#240BA3", "#6E1EB8"],
    url: "/solutions/dance",
    image:
      "/assets/solution/500x500/resources-for-dance-and-ballet-provided-by-broadway-media.png",
    webp: "/assets/solution/500x500/resources-for-dance-and-ballet-provided-by-broadway-media.webp",
  },
];

export const ReusableSolutionsContainer = ({
  home,
  plain,
  top,
  lighthouse,
  itemTitleTag,
}: {
  home?: boolean;
  plain?: boolean;
  top?: number;
  lighthouse?: boolean;
  itemTitleTag?: string;
}) => {
  const router = useRouter();

  return (
    <MobileViewSwitcher
      render={(mobile) => (
        <ReusableSection top={plain ? 0 : top || 96} bottom={plain ? 0 : 96}>
          {!home ? (
            <div className={mobile ? "container" : "container container-left"}>
              <Row
                gutter={[36, 18]}
                style={mobile ? undefined : { marginRight: 0, paddingRight: 0 }}
                align="middle"
              >
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <Rule parse="!hl d:block mb:16">Our Community</Rule>
                  <Rule parse="!ls d:block c:#E1E1E1 mb:24">
                    Our commitment goes beyond products - through partnerships,
                    we are dedicated to breaking barriers, and ensuring that
                    everyone, everywhere, can experience the joy of the
                    performing arts.
                  </Rule>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={16}
                  xl={18}
                  style={mobile ? undefined : { paddingRight: 0 }}
                >
                  {mobile ? (
                    <>
                      <GridStepScroller
                        xs={24}
                        items={solutions.map((item, i) => (
                          <ReusableDoubleGradientCard
                            key={i}
                            title={item.title}
                            titleTag={itemTitleTag}
                            gradients={item.gradients as [string, string]}
                            press={() => {
                              router.push(item.url);
                            }}
                            image={lighthouse ? undefined : item.image}
                            webp={lighthouse ? undefined : item.webp}
                          />
                        ))}
                      />
                    </>
                  ) : (
                    <Box parse="p:relative oy:visible">
                      <Box
                        parse="p:absolute t:0 l:0 h:100% w:50 i:4 oy:visible"
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, var(--background), transparent)",
                        }}
                      />
                      <AutoScroller>
                        {solutions.map((item, i) => (
                          <ReusableDoubleGradientCard
                            key={i}
                            title={item.title}
                            titleTag={itemTitleTag}
                            gradients={item.gradients as [string, string]}
                            press={() => {
                              router.push(item.url);
                            }}
                            properties={{
                              width: 300,
                              right: 32,
                              style: { minWidth: 300 },
                            }}
                            image={lighthouse ? undefined : item.image}
                            webp={lighthouse ? undefined : item.webp}
                          />
                        ))}
                      </AutoScroller>
                    </Box>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div className="container">
              {plain ? undefined : (
                <Rule parse="!hl d:block mb:24 ta:center">Our Community</Rule>
              )}
              <GridStepScroller
                xs={24}
                sm={24}
                md={12}
                lg={6}
                items={solutions.map((item, i) => (
                  <ReusableDoubleGradientCard
                    key={i}
                    title={item.title}
                    titleTag={itemTitleTag}
                    gradients={item.gradients as [string, string]}
                    press={() => {
                      router.push(item.url);
                    }}
                    image={lighthouse ? undefined : item.image}
                  />
                ))}
              />
            </div>
          )}
        </ReusableSection>
      )}
    />
  );
};

export default ReusableSolutionsContainer;
