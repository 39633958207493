import { FC } from "react";

interface SpotlightsProps {
  width?: string;
  height?: string;
  top?: number;
  right?: number;
}

export const Spotlights: FC<SpotlightsProps> = ({
  width = "429",
  height = "429",
  top = -72,
  right = -72,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 429 492"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ position: "absolute", top, right }}
    >
      <rect
        width="409.104"
        height="396.987"
        fill="url(#pattern0)"
        fillOpacity="0.4"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_2024_165921"
            transform="matrix(0.001716 0 0 0.00176838 -0.0594172 0)"
          />
        </pattern>
        <image
          id="image0_2024_165921"
          width="652"
          height="643"
          xlinkHref="/assets/section/spotlights.webp"
        />
      </defs>
    </svg>
  );
};
