import { Box } from "simple-effing-primitive-layout";

interface ReusableGlossBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  mode?:
    | "default"
    | "top-left-grey"
    | "top-left-green"
    | "top-left-yellow"
    | "top-left-purple"
    | "top-left-blue";
  [key: string]: unknown;
}

export const ReusableGlossBox = (props: ReusableGlossBoxProps) => {
  const DefaultGlossImageSource =
    "/assets/libs/simple-ui/reusable-gloss-box/assets/gloss.webp";
  const TopLeftGreyGlossImageSource =
    "/assets/libs/simple-ui/reusable-gloss-box/assets/top-left-grey.webp";
  const TopLeftGreenGlossImageSource =
    "/assets/libs/simple-ui/reusable-gloss-box/assets/top-left-green.webp";
  const TopLeftPurpleGlossImageSource =
    "/assets/libs/simple-ui/reusable-gloss-box/assets/top-left-purple.jpg";
  const TopLeftYellowGlossImageSource =
    "/assets/libs/simple-ui/reusable-gloss-box/assets/top-left-yellow.webp";
  const TopLeftBlueGlossImageSource =
    "/assets/libs/simple-ui/reusable-gloss-box/assets/top-left-blue.webp";

  const _image = (() => {
    switch (props["mode"]) {
      case "top-left-grey":
        return TopLeftGreyGlossImageSource;
      case "top-left-green":
        return TopLeftGreenGlossImageSource;
      case "top-left-purple":
        return TopLeftPurpleGlossImageSource;
      case "top-left-yellow":
        return TopLeftYellowGlossImageSource;
      case "top-left-blue":
        return TopLeftBlueGlossImageSource;

      default:
        return DefaultGlossImageSource;
    }
  })();

  const _style = props["style"] || {};

  return (
    <Box
      {...props}
      style={{
        backgroundColor: "#171B20",
        backgroundImage: `url(${_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        ..._style,
      }}
    >
      {props.children}
    </Box>
  );
};

export default ReusableGlossBox;
