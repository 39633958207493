import { UseCMSTestimonial, useCustomMobile } from "@with-nx/hooks-n-helpers";
import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { FC, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface TestimonialQuotesProps {
  testimonials: UseCMSTestimonial[];
  size?: "large" | "small";
}

export const TestimonialQuotes: FC<TestimonialQuotesProps> = ({
  testimonials,
  size = "large",
}) => {
  const mobile = useCustomMobile();
  const tablet = useCustomMobile(1024) && !mobile;

  const [current, _current] = useState(0);
  const [visible, _visible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      _visible(false);

      setTimeout(() => {
        _current((prev) => (prev + 1) % testimonials.length);
        _visible(true);
      }, 800);
    }, 6000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const smaller = mobile || size === "small";
  const icon = smaller ? 32 : 60;

  return (
    <Box parse="w:100% d:flex j:center">
      <Box
        parse={`d:flex fd:column a:center`}
        style={{
          maxWidth: !mobile ? "1116px" : "",
        }}
      >
        <Box
          parse={`p:relative br:10 pa:${
            smaller ? "16" : "20"
          } c:transparent d:flex a:center`}
          style={{
            opacity: visible ? 1 : 0,
            transition: visible
              ? "opacity 2s ease-in-out"
              : "opacity 0.8s ease-in-out",
          }}
        >
          <Box>
            <Box
              parse={`d:inline-block p:absolute l:${
                mobile ? -1 : tablet ? 1 : 16
              } t:${mobile ? -12 : 3}`}
            >
              <img
                width={icon}
                height="auto"
                loading="lazy"
                src="/assets/home/left-quote.svg"
              />
            </Box>

            <Box>
              <Rule
                parse={`d:block !${smaller ? "bxl" : "hxl"} ta:center 
                  ml:${mobile ? 0 : tablet ? icon : icon + 20} mr:${
                  mobile ? 0 : tablet ? icon : icon + 16
                } `}
                weight="500"
                tag="p"
              >
                {testimonials[current]?.description}
              </Rule>
              <Rule parse={`!ls d:block ml:${icon} mt:12`} color="var(--font4)">
                {testimonials[current]?.title}
              </Rule>

              {testimonials[current]?.show &&
              testimonials[current]?.location ? (
                <Box
                  parse={`d:flex j:center a:center ${
                    smaller ? "fd:column mt:30" : "mt:45"
                  }`}
                >
                  <Box
                    parse="d:flex a:center pt:5 pb:5 pl:20 pr:20 br:50 mr:15 "
                    border="1.5px solid var(--gold-120)"
                  >
                    <DesignedIcon
                      name="show_mask"
                      size={18}
                      color="var(--gold-120)"
                      properties={{
                        right: 10,
                      }}
                    />
                    <Rule parse="!_bm c:var(--gold-120) ml:10">
                      {testimonials[current].show}
                    </Rule>
                  </Box>

                  <Box
                    parse={`d:flex a:center pt:5 pb:5 pl:20 pr:20 br:50 mr:15 ${
                      smaller ? "mb:15 " : ""
                    }`}
                    style={{
                      order: smaller ? -1 : 1,
                    }}
                    border="1.5px solid #FFF"
                  >
                    <Rule parse="!_bm c:#FFF">
                      {testimonials[current].location}
                    </Rule>
                  </Box>
                </Box>
              ) : undefined}
            </Box>

            <Box
              style={{
                height: "100%",
              }}
            >
              <Box
                parse={`d:inline-block p:absolute r:${
                  smaller ? "0" : "-16"
                }  r:${mobile ? "0" : "-16"} ${
                  mobile ? "b:10" : tablet ? "b:140" : "t:3"
                }`}
              >
                <img
                  width={icon}
                  height="auto"
                  loading="lazy"
                  src="/assets/home/right-quote.svg"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
