import { Box } from "simple-effing-primitive-layout";

import { Colors } from "../shared";

const Mover = (props: {
  vertical?: boolean;
  opacity?: number;
  [key: string]: unknown;
}) => {
  return (
    <Box
      parse="w:16 h:16 d:inline-flex a:center j:center fd:column"
      style={{
        transform: props?.vertical ? "rotate(-90deg)" : "rotate(0deg)",
        transition: "transform 0.2s ease-in-out",
        opacity: Number(props?.opacity) === 0 ? 0 : undefined,
      }}
      {...props}
    >
      <Box parse="w:16 h:2 br:999 mb:3" color={Colors.entity} />
      <Box parse="w:16 h:2 br:999" color={Colors.entity} />
    </Box>
  );
};

export default Mover;
