interface RequestServiceError {
  name: string;
  status: string;
  code: string;
  statusText: string;
  hint?: string;
  details?: unknown;
}

export default class ServiceError extends Error {
  override name: string;
  status: string;
  statusText: string;
  code: string;
  hint?: string;
  details?: unknown;

  constructor(
    message: string,
    { name, status, code, statusText, hint, details }: RequestServiceError
  ) {
    super(message);

    this.status = status;
    this.code = code;
    this.name = name;
    this.statusText = statusText;
    this.hint = hint;
    this.details = details;
  }
}
