import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import ShoppingCartMethods from "../cart/ShoppingCartMethods";
import { Ecommerce } from "../types/types";

const initial: Ecommerce.Cart.State = {
  populated: false,
  status: "loading",
  step: 0,
  productions: [],
  control: {
    same: false,
    correct: false,
  },
  stageGear: {
    shippingCountries: [],
  },
};

export const fetch = createAsyncThunk(
  "cart/fetch",
  async ({ token }: { token: string }) => {
    return await ShoppingCartMethods.getCurrent({ token });
  }
);

export const slice = createSlice({
  name: "cart",
  initialState: initial,
  reducers: {
    set: (
      state,
      action: PayloadAction<Ecommerce.Schemas.ShoppingCartSchemaType>
    ) => {
      state.data = action.payload;
    },
    step: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    populate: (state, action: PayloadAction<boolean>) => {
      state.populated = action.payload;
    },
    clear: (state) => {
      state = initial;
      return state;
    },
    address: (
      state,
      action: PayloadAction<{
        type: "shipping" | "billing";
        address: Ecommerce.Payload.Address.CreateAddress;
      }>
    ) => {
      switch (action.payload.type) {
        case "shipping":
          state.addresses = {
            ...state.addresses,
            shipping: action.payload.address,
          };
          break;
        case "billing":
          state.addresses = {
            ...state.addresses,
            billing: action.payload.address,
          };
          break;
      }
    },
    production: (
      state,
      action: PayloadAction<{
        type: "licensed" | "non-licensed" | "all";
        production: Ecommerce.Payload.Production.CreateProduction;
        meta?: {
          organization?: string;
        };
      }>
    ) => {
      switch (action.payload.type) {
        case "all":
          state.productions = [
            ...(state?.data?.licensedItems?.map((item) => {
              return {
                ...action.payload.production,
                line: item.id || 0,
                organizationName:
                  action.payload.meta?.organization ||
                  action?.payload?.production?.organizationName ||
                  "",
              };
            }) || []),
            ...(state?.data?.nonLicensedItems?.map((item) => {
              return {
                ...action.payload.production,
                line: item.id || 0,
                organizationName:
                  action.payload.meta?.organization ||
                  action?.payload?.production?.organizationName ||
                  "",
              };
            }) || []),
          ];
          break;
        default:
          state.productions = [
            ...(state?.productions?.filter(
              (item) => item.line !== action.payload.production.line
            ) || []),
            action.payload.production,
          ];
          break;
      }

      return state;
    },
    control: (
      state,
      action: PayloadAction<{
        type: "same" | "correct";
        value: boolean;
      }>
    ) => {
      switch (action.payload.type) {
        case "same":
          state.control = {
            ...state.control,
            same: action.payload.value,
          };
          break;
        case "correct":
          state.control = {
            ...state.control,
            correct: action.payload.value,
          };
          break;
      }
    },
    stageGear: (
      state,
      action: PayloadAction<{
        shippingCountries: string[];
      }>
    ) => {
      const { shippingCountries } = action.payload;

      state.stageGear = {
        ...state.stageGear,
        shippingCountries,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.status = "failed";
        console.log("Shopping Cart Error", action.error.message);
        state.error = action.error.message;

        if (action.error.code === "sg:common:database:03") {
          state.data = undefined;
          state = initial;
        }
      });
  },
});

export const {
  set,
  clear,
  step,
  address,
  production,
  populate,
  control,
  stageGear,
} = slice.actions;

export default slice.reducer;
