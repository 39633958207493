import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { FC, ReactNode, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ActionDropdownOption {
  label?: ReactNode;
  icon?: ReactNode;
  onClick?: VoidFunction;
}

interface ActionDropdownProps {
  mobile?: boolean;
  label: string;
  options: ActionDropdownOption[];
}

export const ActionDropdown: FC<ActionDropdownProps> = ({
  mobile,
  label,
  options,
}) => {
  const [open, setOpen] = useState(mobile);

  const handleClick = () => (mobile ? setOpen(!open) : undefined);
  const handleMouseEnter = () => (mobile ? undefined : setOpen(true));
  const handleMouseLeave = () => (mobile ? undefined : setOpen(false));

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box css="p:relative">
        <Box
          parse="d:inline-flex d:inline-flex a:center br:5"
          color="transparent"
        >
          <Rule
            parse="!ls d:block"
            color="white"
            display="block"
            style={{ whiteSpace: "nowrap" }}
          >
            {label}
          </Rule>

          <Box parse="ml:10 w:16 h:16 d:inline-flex a:center j:center">
            {open ? (
              <DesignedIcon size={16} color="#AFAFAF" name="up/regular" />
            ) : (
              <DesignedIcon size={16} color="#AFAFAF" name="down/regular" />
            )}
          </Box>
        </Box>
        {open && (
          <Box
            parse={
              mobile
                ? "p:relative d:flex fd:column"
                : "p:absolute l:0 b:unset r:unset t:100% pt:10 z:98 i:98"
            }
          >
            {!mobile && (
              <Box parse="p:absolute r:unset b:unset t:1 l:48">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00047 0.5L13.9287 9.5H0.0722656L7.00047 0.5Z"
                    fill="#1E242D"
                  />
                </svg>
              </Box>
            )}

            <Box
              parse="d:flex"
              style={{
                flexDirection: "column",
              }}
            >
              <Box
                parse="d:flex a:flex-start pa:8 br:10 c:?foreground"
                direction="column"
              >
                {options.map((option, index) => (
                  <Box
                    key={`${option?.label}-${index}`}
                    parse="d:flex pa:4 pt:0 w:100% a:center"
                    style={{
                      columnGap: "4px",
                    }}
                  >
                    {option.icon}

                    {option.label && (
                      <Rule
                        parse="!lt d:block"
                        color="var(--font3)"
                        display="block"
                        press={option.onClick}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {option.label}
                      </Rule>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ActionDropdownProps;
