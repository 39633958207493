import {
  Formatter,
  useCMSGatedContentPromotion,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  DesignedButton,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { VisibleContainer } from "@with-nx/simple-ui/molecules";
import { Col, Row } from "antd";
import sample from "lodash/sample";
import { Box } from "simple-effing-primitive-layout";

type PageTags =
  | "Scenic Projections Landing Page"
  | "Digital Backdrops Landing Page"
  | "Choreography Guides Landing Page"
  | "Homepage";

interface PromotionSectionProps {
  page?: PageTags;
}

export const PromotionSection = (props: PromotionSectionProps) => {
  const region = useServerRegion();

  const promotions = useCMSGatedContentPromotion(
    {
      filter: {
        regionTags: String(region.id),
        pageTags: props.page,
      },
    },
    true
  );

  const promotion = sample(promotions.promotions);

  if (!promotion) {
    return null;
  }

  return (
    <VisibleContainer>
      <Box
        css="w:100%"
        color={promotion?.backgroundColor || "var(--foreground)"}
        style={{
          minHeight: 256,
          backgroundImage: promotion?.featureImage
            ? `url(${Formatter.compressed(promotion?.featureImage, 512, true)})`
            : undefined,
          backgroundSize: "contain",
          backgroundPosition: "center right",
        }}
      >
        <ReusableSection
          properties={{
            style: {
              backgroundImage: `linear-gradient(to right, ${
                promotion?.backgroundColor || "var(--foreground)"
              }, transparent)`,
            },
          }}
        >
          <div className="container">
            <Row gutter={[36, 18]}>
              <Col xs={24} sm={24} md={12}>
                <Rule
                  parse="!hl d:block mb:24"
                  color={promotion?.textColor || "var(--font1)"}
                >
                  {promotion?.title}
                </Rule>

                <Rule
                  parse="!lm d:block mb:24"
                  color={promotion?.textColor || "var(--font1)"}
                  opacity={0.75}
                >
                  {promotion?.description}
                </Rule>

                <DesignedButton
                  background={
                    promotion?.buttonBackgroundColor || "var(--primary)"
                  }
                  color={promotion?.buttonTextColor || "var(--background)"}
                  href={
                    promotion?.gatedContentPage
                      ? `/g/${promotion?.gatedContentPage}`
                      : promotion?.customURL
                  }
                  label={promotion?.button}
                />
              </Col>
            </Row>
          </div>
        </ReusableSection>
      </Box>
    </VisibleContainer>
  );
};

export default PromotionSection;
