import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";
import Toast from "react-hot-toast";

import useService from "./useService";
import { useServerRegion } from "@with-nx/region";

type UseBusinessType = {
  businessType: number;
  name: string;
};

type UseBusinessCustomer = {
  businessType: number;
  [key: string]: unknown;
};

export const useBusiness = () => {
  const auth = useAuth();
  const authenticated = auth.authState.status === "authenticated";
  const makeRequestToMicroservice = useService("microservice", {
    cache: 10_000,
  });

  const [types, _types] = useState<UseBusinessType[]>([]);
  const [ready, _ready] = useState(false);
  const [type, _type] = useState<UseBusinessType | undefined>(undefined);
  const [customer] = useState<UseBusinessCustomer | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const types = (await makeRequestToMicroservice(
        "GET",
        "/backstage/business-types"
      )) as UseBusinessType[];
      _types(types);

      if (!authenticated) {
        _ready(true);
        return;
      }

      const customer = (await makeRequestToMicroservice(
        "GET",
        "/backstage/customer",
        {},
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      )) as UseBusinessCustomer;

      const type = types.find(
        (type) => type.businessType === customer.businessType
      );
      _type(type);
      _ready(true);
    })();
  }, [authenticated]);

  const options = {
    canUploadPurchaseOrder: authenticated
      ? [
          "Elementary School",
          "Middle School",
          "High School",
          "University",
        ].includes(type?.name || "")
      : false,
  };

  return { name: type?.name, options, type, types, customer, ready };
};

export const useBusinessTypeName = () => {
  const types = useBusinessTypes();

  const search = (id?: string | number) => {
    return (
      types?.types?.find((type) => String(type.businessType) === String(id))
        ?.name || "Unknown"
    );
  };

  return { search };
};

export const useBusinessTypes = () => {
  const makeRequestToMicroservice = useService("microservice", {
    cache: 1_800_000,
  });

  const [types, _types] = useState<UseBusinessType[]>([]);

  useEffect(() => {
    (async () => {
      const types = (await makeRequestToMicroservice(
        "GET",
        "/backstage/business-types"
      )) as UseBusinessType[];
      _types(types);
    })();
  }, []);

  return { types };
};

export type UseCustomerCustomer = {
  id: string;
  type: string;
  email: string;
  firstName: string;
  lastName: string;
  organization?: string | null;
  businessType: number;
  businessTypeName: string;
  phones?: {
    number: string;
  }[];
};

export type UseCustomerOptions = {
  canUploadPurchaseOrder: boolean;
  needsUpdating: boolean;
};

export const useCustomer = (settings?: {
  load?: (customer: UseCustomerCustomer, options: UseCustomerOptions) => void;
}) => {
  const auth = useAuth();
  const region = useServerRegion();
  const authenticated = auth.authState.status === "authenticated";
  const makeRequestToMicroservice = useService("microservice");

  const [types, _types] = useState<UseBusinessType[]>([]);
  const [customer, _customer] = useState<UseCustomerCustomer | undefined>(
    undefined
  );

  const methods = {
    update: async ({
      name,
      email,
      organization,
      phone_number,
      business_type,
    }: {
      name?: string;
      email?: string;
      organization?: string;
      phone_number?: string;
      business_type?: number;
    }) => {
      const data: {
        name?: string;
        email?: string;
        organization?: string;
        phone_number?: string;
        business_type?: number;
      } = {};

      if (
        name &&
        name.length > 0 &&
        name !== customer?.firstName + " " + customer?.lastName
      ) {
        data.name = name;
      }

      if (email && email.length > 0 && email !== customer?.email) {
        data.email = email;
      }

      if (
        organization &&
        organization.length > 0 &&
        organization !== customer?.organization
      ) {
        data.organization = organization;
      }

      if (
        phone_number &&
        phone_number.length > 0 &&
        phone_number !== customer?.phones?.[0]?.number
      ) {
        data.phone_number = phone_number;
      }

      if (business_type && business_type !== customer?.businessType) {
        data.business_type = business_type;
      }

      const makeRequestToBackstage = useService("accounts", {
        bypass: true,
      });

      const response = await makeRequestToBackstage(
        "PATCH",
        "/api/v2/account",
        {
          account: {
            ...data,
          },
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response?.["errors"]) {
        Toast.error(
          response?.["errors"]?.[0]?.details ||
            "There was an error updating your account. Please try again later."
        );
        return false;
      }

      return true;
    },
    patch: async ({
      organization,
      business_type,
    }: {
      organization: string;
      business_type: number;
    }) => {
      const makeRequestToBackstage = useService("accounts", {
        bypass: true,
      });

      const response = await makeRequestToBackstage(
        "PATCH",
        "/api/v2/account",
        {
          account: {
            organization,
            business_type,
          },
        },
        {
          Authorization: `Bearer ${auth.authState.session?.token}`,
        }
      );

      if (response?.["errors"] || response?.["error"]) {
        Toast.error(
          response?.["error"] ||
            response?.["errors"]?.[0]?.details ||
            "There was an error updating your account. Please try again later."
        );
        return false;
      }

      await refresh();

      return true;
    },
  };

  const options = (customer?: UseCustomerCustomer): UseCustomerOptions => {
    return {
      canUploadPurchaseOrder: authenticated
        ? [
            "Elementary School",
            "Middle School",
            "High School",
            "University",
          ].includes(customer?.businessTypeName || "") && region.id === 1
        : false,
      needsUpdating:
        !customer?.organization?.length ||
        customer?.businessType === null ||
        isNaN(customer?.businessType),
    };
  };

  const refresh = async (
    cb?: (c: UseCustomerCustomer, o: UseCustomerOptions) => void
  ) => {
    const ts = types?.length
      ? types
      : ((await makeRequestToMicroservice(
          "GET",
          "/backstage/business-types"
        )) as UseBusinessType[]);
    _types(ts);

    const customer = (await makeRequestToMicroservice(
      "GET",
      "/backstage/customer",
      {},
      {
        Authorization: `Bearer ${auth.authState.session?.token}`,
      }
    )) as UseCustomerCustomer;

    const type =
      ts.find((type) => type.businessType === (customer.businessType as number))
        ?.name || "Other";
    _customer({ ...customer, businessTypeName: type as string });
    cb?.(customer, options(customer));
  };

  useEffect(() => {
    (async () => {
      if (!authenticated) {
        return;
      }

      refresh(settings?.load);
    })();
  }, [authenticated]);

  return {
    ...customer,
    loading: !customer,
    methods,
    options: options(customer),
    types,
  };
};

export default useBusiness;
