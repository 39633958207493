import { DesignedIcon } from "@with-nx/simple-ui/atoms";
import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ModalFooterProps {
  direction: "left" | "right";
  onClick?: () => void;
  mobile?: boolean;
  disabled?: boolean;
}

export const ModalNavigationButton: FC<ModalFooterProps> = ({
  direction,
  onClick,
  mobile = false,
  disabled = false,
}) => (
  <div
    style={{
      height: "48px",
      width: "48px",
      position: "absolute",
      ...(!mobile
        ? {
            top: "50%",
            [direction]: "calc(calc(48px + 32px) * -1)",
            transform: "translate(0, -50%)",
          }
        : {
            bottom: "1rem",
            [direction]: "1rem",
          }),
      cursor: disabled ? "not-allowed" : "pointer",
    }}
    onClick={() => {
      if (!disabled) {
        onClick?.();
      }
    }}
  >
    <Box
      parse="d:flex a:center j:center h:100% br:10 c:var(--foreground)"
      border="2px solid var(--blue)"
    >
      <DesignedIcon
        name={direction}
        size={20}
        color={`var(--${disabled ? "font4" : "white"})`}
      />
    </Box>
  </div>
);
