import React from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon, {
  AvailableIconName,
} from "../designed-icon/designed-icon";
import Rule from "../rule/rule";

function invert(hex: string) {
  const pad = (string: string, length = 2) => {
    length = length || 2;
    const zeros = new Array(length).join("0");
    return (zeros + string).slice(-length);
  };

  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return "#000000";
  }
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  return "#" + pad(r) + pad(g) + pad(b);
}

function rgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

type DesignedTagSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    rule?: string;
    icon?: number;
  };
};

export interface DesignedTagProps {
  icon?: AvailableIconName;
  label?: string | null | undefined;
  press?: () => void | null | undefined;
  close?: () => void | null | undefined;
  properties?: {
    [key: string]: unknown;
  };
  size?: string;
  color?: string;
  background?: string;
  adaptive?: boolean;
}

const SIZES: DesignedTagSizes = {
  large: {
    height: 32,
    padding: 10,
    rule: "ls",
    icon: 20,
  },
  medium: {
    height: 28,
    padding: 8,
    rule: "ls",
    icon: 18,
  },
  small: {
    height: 24,
    padding: 6,
    rule: "lt",
    icon: 16,
  },
  tiny: {
    height: 18,
    padding: 5,
    rule: "lt",
    icon: 14,
  },
};

export const DesignedTag = ({
  icon,
  label = "",
  press,
  close,
  properties,
  size,
  color = Colors.font2,
  background = Colors.foreground,
  adaptive,
}: DesignedTagProps) => {
  const _size = size ? SIZES[size] || SIZES["medium"] : SIZES["medium"];
  const _adaptive = rgb(invert(color));
  const _rgba = `rgba(${_adaptive?.r}, ${_adaptive?.g}, ${_adaptive?.b}, 0.5)`;

  return (
    <Box
      parse={`h:${_size.height} pr:${_size.padding} pl:${_size.padding} br:5 d:inline-flex a:center j:flex-start`}
      color={adaptive ? _rgba : background}
      press={press}
      style={adaptive ? { backdropFilter: "blur(3px)" } : {}}
      {...properties}
    >
      {icon ? (
        <DesignedIcon
          color={Colors.entity}
          size={_size.icon || 20}
          name={icon}
        />
      ) : undefined}
      <Rule
        style={{ whiteSpace: "nowrap" }}
        left={icon ? _size.padding : 0}
        right={close ? _size.padding : 0}
        rule={_size.rule}
        color={color}
      >
        {label}
      </Rule>
      {close ? (
        <DesignedIcon
          press={close}
          color={Colors.entity}
          size={12}
          name="multiply/bold"
        />
      ) : undefined}
    </Box>
  );
};

export default DesignedTag;
