import { motion } from "framer-motion";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import { createRef, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon from "../designed-icon/designed-icon";
import DesignedSelect from "../designed-select/designed-select";
import Rule from "../rule/rule";

interface DesignedPaginationProps {
  size: number;
  value: number;
  mode?: "lines" | "dots" | "select";
  press?: (value: number) => void;
  label?: (value: number) => string | number;
  properties?: {
    [key: string]: unknown;
  };
}

export const DesignedPagination = (props: DesignedPaginationProps) => {
  const { mode = "lines", value, press, properties, label } = props;

  const size =
    isNaN(props.size) || isNull(props.size) || isUndefined(props.size)
      ? 0
      : Number(props.size);

  const [data, _data] = useState<number>(value);
  useEffect(() => {
    _data(value);
  }, [value]);

  const ref = createRef<HTMLSelectElement | undefined>();

  switch (mode) {
    case "lines":
      return (
        <Box parse="d:inline-flex a:center" {...properties}>
          {[...Array(size < 0 ? 0 : size)].map((item, i) => (
            <motion.div
              transition={{
                type: "spring",
                damping: 10,
                stiffness: 100,
              }}
              key={i}
              onClick={() => {
                if (press) {
                  press(i);
                }
                _data(i);
              }}
              animate={{
                cursor: "pointer",
                display: "inline-flex",
                height: 6,
                width: data !== i ? 12 : 24,
                minWidth: data !== i ? 12 : 24,
                maxWidth: data !== i ? 12 : 24,
                borderRadius: 2,
                backgroundColor: data !== i ? Colors.accent : Colors.entity,
                marginRight: 6,
              }}
            ></motion.div>
          ))}
        </Box>
      );

    case "dots":
      return (
        <Box parse="d:inline-flex a:center" {...properties}>
          {[...Array(size < 0 ? 0 : size)].map((item, i) => (
            <motion.div
              transition={{
                type: "spring",
                damping: 10,
                stiffness: 100,
              }}
              key={i}
              onClick={() => {
                if (press) {
                  press(i);
                }
                _data(i);
              }}
              animate={{
                cursor: "pointer",
                display: "inline-flex",
                height: data !== i ? 8 : 12,
                width: data !== i ? 8 : 12,
                minWidth: data !== i ? 8 : 12,
                maxWidth: data !== i ? 8 : 12,
                borderRadius: 999,
                backgroundColor: data !== i ? Colors.accent : Colors.entity,
                marginRight: 2.5,
                marginLeft: 2.5,
              }}
            ></motion.div>
          ))}
        </Box>
      );

    case "select":
      return (
        <Box parse="d:inline-flex a:center" {...properties}>
          <Box
            parse="d:inline-flex a:center mr:20"
            press={() => {
              if (data > 0 && press) {
                press(data - 1);
              }
              _data(data - 1);
            }}
          >
            <DesignedIcon name="left/filled" size={12} color={Colors.entity} />
            <Rule left={5} rule="ls" color={Colors.font3}>
              Prev
            </Rule>
          </Box>

          <DesignedSelect
            native={{ ref }}
            change={(item) => {
              if (press) {
                press(parseInt(item));
              }
              _data(parseInt(item));
            }}
            size="small"
            value={data.toString()}
            options={(() => {
              const options: [string, string][] = [];

              for (let index = 0; index < size; index++) {
                options.push([
                  index.toString(),
                  label ? label(index).toString() : index.toString(),
                ]);
              }

              return options;
            })()}
            bottom={0}
          />

          <Rule left={10} rule="ls" color={Colors.font4}>
            of {size}
          </Rule>

          <Box
            parse="d:inline-flex a:center ml:20"
            press={() => {
              if (data < size - 1 && press) {
                press(data + 1);
              }
              _data(data + 1);
            }}
          >
            <Rule right={5} rule="ls" color={Colors.font3}>
              Next
            </Rule>
            <DesignedIcon name="right/filled" size={12} color={Colors.entity} />
          </Box>
        </Box>
      );

    default:
      return null;
  }
};

export default DesignedPagination;
