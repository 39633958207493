import { DesignedCard } from "@with-nx/simple-ui/atoms";
import { useCallback, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import be2b629ff161 from "./assets/0e09d723-bbe1-41ae-899d-be2b629ff161.svg";
const be2b629ff161s = be2b629ff161 as unknown as { src: string };

interface TeamCardProps {
  image?: string;
  name?: string;
  surname?: string;
  title?: string;
  service?: boolean;
}

export const TeamCard = ({
  image,
  name,
  surname,
  title,
  service,
}: TeamCardProps) => {
  const [fa7812ca0c19, _fa7812ca0c19] = useState(0);
  const [d578cff94fa5, _d578cff94fa5] = useState(false);

  const e9fd36af1071 = () => {
    _d578cff94fa5(true);
    _fa7812ca0c19(0);
    setTimeout(() => _d578cff94fa5(false), 4000);
  };
  useEffect(() => {
    if (fa7812ca0c19 >= 13) {
      e9fd36af1071();
    }
  }, [e9fd36af1071, fa7812ca0c19]);

  const a5fcda690a9e = useCallback(() => {
    if (name === "Julian" && surname === "Gumenita") {
      _fa7812ca0c19(fa7812ca0c19 + 1);
    }
  }, [fa7812ca0c19]);

  return (
    <Box>
      <DesignedCard
        image={image}
        outside={{
          title: `${name} ${surname}`,
          subtitle: `${title}`,
        }}
        inside={{
          tags: service ? ["Customer Service Team"] : undefined,
        }}
        properties={{
          native: {
            onMouseEnter: () => a5fcda690a9e(),
          },
          style: {
            position: "relative",
            zIndex: 2,
          },
        }}
      />
      {name === "Julian" && surname === "Gumenita" ? (
        <img
          loading="lazy"
          height="40"
          width="40"
          src={be2b629ff161s.src}
          style={{
            position: "absolute",
            top: d578cff94fa5 ? -20 : "50%",
            right: d578cff94fa5 ? -5 : "50%",
            transform: "rotate(26deg)",
          }}
          className="--smoother"
        />
      ) : undefined}
    </Box>
  );
};

export default TeamCard;
