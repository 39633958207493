import { Fuzzy } from "libs/hooks-n-helpers/src/Fuzzy";
import shows from "../../../apps/catalog/data/shows.json";
import featuredEvents from "../../../apps/catalog/data/featuredEvents.json";

export class ShowDataStatic {
  static isShowsEmpty() {
    return shows.length === 0;
  }

  static getAllShows() {
    return shows;
  }

  static getShowBySlug = (slug: string) => {
    return shows?.find((show) => show.slug === slug);
  };

  static search(term?: string) {
    const similarities: any[] = [];

    if (!term || term?.length === 0) {
      return similarities;
    }

    const shows = this.getAllShows();

    shows.forEach((show) => {
      if (show.title === term || show.title.includes(term)) {
        return similarities.push({ ...show, similarity: 1 });
      }

      if (Fuzzy.simplify(show.title)?.startsWith(Fuzzy.simplify(term))) {
        return similarities.push({ ...show, similarity: 0.9 });
      }

      if (
        Fuzzy.slugify(show.title) === Fuzzy.slugify(term) ||
        Fuzzy.slugify(show.title).includes(Fuzzy.slugify(term))
      ) {
        return similarities.push({ ...show, similarity: 0.8 });
      }

      const similarity = Fuzzy.similarity(term, show.title);

      if (similarity > 0.4) {
        return similarities.push({ ...show, similarity });
      }

      return false;
    });

    return similarities
      .sort((a, b) => (b?.similarity || 0) - (a?.similarity || 0))
      .filter((show) => !!show.title);
  }

  static getFeaturedEventsTags() {
    return featuredEvents;
  }
}
