import { useMobile } from "@with-nx/hooks-n-helpers";
import { Box } from "simple-effing-primitive-layout";

export const ReusableSection = ({
  children,
  background = "transparent",
  properties,
  top = 48,
  bottom = 48,
  override,
}: {
  children: unknown;
  background?: string;
  properties?: {
    [key: string]: unknown;
  };
  top?: number | string;
  bottom?: number | string;
  override?: boolean;
}) => {
  const mobile = useMobile();

  return (
    <Box
      parse={`pt:${mobile ? 32 : top} pb:${
        mobile && !override ? 32 : bottom
      } w:auto c:${background} p:relative i:1 z:1`}
      {...properties}
    >
      {children}
    </Box>
  );
};

export default ReusableSection;
