import { Tooltip } from "@with-nx/editor-ui";
import { Formatter } from "@with-nx/hooks-n-helpers";
import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface DevelopmentInterface {
  children: JSX.Element;
  reason: string;
  bypass?: boolean;
}

export const Development = ({
  bypass = false,
  children,
  reason,
}: DevelopmentInterface) => {
  const [show, _show] = useState(bypass);

  useEffect(() => {
    if (!window) {
      return;
    }

    const debug = Formatter.gup("debug");
    const local = window?.origin?.includes("localhost");
    const staging = window?.origin?.includes("staging");

    if (debug || local || staging) {
      _show(true);
    }
  }, []);

  return show ? (
    <Box
      parse="pa:5 br:5 p:relative oa:visible"
      border="1px dashed rgba(255,255,255,0.2)"
    >
      <Box parse="t:-24 l:0 p:absolute pa:3 br:5 c:#F4B400 d:inline-flex a:center j:center h:16">
        <Rule
          style={{
            fontSize: 10,
            lineHeight: "10px",
            color: "var(--background)",
            fontWeight: "600",
            marginRight: 4,
          }}
        >
          IN DEVELOPMENT
        </Rule>
        <Tooltip
          position="top-center"
          label={`This component is in development and will not be present in production. Reason: ${reason}`}
        >
          <Box parse="h:14 w:14 p:relative b:2">
            <DesignedIcon
              name="info/regular"
              size={14}
              color="var(--background)"
            />
          </Box>
        </Tooltip>
      </Box>

      {children}
    </Box>
  ) : null;
};

export default Development;
