import { FC } from "react";

interface LoadingIconProps {
  color?: string;
  width?: string;
  height?: string;
}
export const LoadingIcon: FC<LoadingIconProps> = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16px"}
      height={height || "16px"}
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid"
      className="-simple-ui-designed-button-transition"
    >
      <circle
        cx="8"
        cy="8"
        fill="none"
        stroke={color || "var(--background)"}
        strokeWidth="2"
        r="6"
        strokeDasharray="28.274333882308138 11.42477796076938"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 8 8;360 8 8"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};
