/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { Ecommerce } from "../types/types";
import reducer from "./slice";

export interface RootState {
  cart: Ecommerce.Cart.State;
}

const root = combineReducers({
  cart: reducer,
});

const persisted = persistReducer(
  {
    key: "store@cart",
    storage,
  },
  root
);

const middleware = () => (next: any) => (action: any) => {
  return next(action);
};

export const store = configureStore({
  reducer: persisted,
  devTools: process.env["NODE_ENV"] !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(middleware)
      .concat(thunk),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;
