import { array, boolean, number, object, string } from "yup";

import { AttachmentSchema } from "./attachmentSchema";
import { ProductLicensorSchema } from "./licensorSchema";
import { ProductChoreoGuideSchema } from "./productChoreoGuideSchema";
import { ProductDigitalDropSchema } from "./productDigitalDropSchema";
import { ProductScenicProjectionSchema } from "./productScenicProjectionSchema";
import { ShowBrandSchema } from "./showBrandSchema";

export const ProductSchema = object({
  id: number(),
  adHocName: string().nullable().optional(),
  type: string(),
  variation: string().nullable().optional(),
  digitalDrop: ProductDigitalDropSchema.optional(),
  choreoGuide: ProductChoreoGuideSchema.optional(),
  scenicProjection: ProductScenicProjectionSchema.optional(),
  licensors: array().of(ProductLicensorSchema).nullable().optional(),
  showBrand: ShowBrandSchema.optional(),
  logoColor: string().nullable().optional(),
  attachments: array().of(AttachmentSchema).nullable().optional(),
  gearName: string().nullable().optional(),
  gearSku: string().nullable().optional(),
  gearResource: object({
    id: string(),
    name: string(),
    isPurchasable: boolean().nullable().optional(),
    category: object({
      name: string(),
      slug: string(),
    }).optional(),
    attachments: array().of(AttachmentSchema).nullable().optional(),
  }),
  gearBooqableProductGroupId: string().nullable().optional(),
  gearBooqableProductId: string().nullable().optional(),
  adHocMetadata: object().nullable().optional(),
});
