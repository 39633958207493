/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-async-promise-executor */
import * as Sentry from "@sentry/nextjs";

import Database from "./Database";
import ServiceError from "./ServiceError";

const hash = (s: string) => {
  let h = 0;

  for (let i = 0; i < s.length; i++) {
    h = (31 * h + s.charCodeAt(i)) | 0;
  }

  return h;
};

const slugify = (s: string) =>
  s
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const invalidateCache = (url: string, path: string) => {
  Database.remove(`${url}${slugify(path)}`);
};

export const isBrowserEnvironment = () => {
  try {
    return typeof window !== "undefined";
  } catch (e) {
    return false;
  }
};

export const useService = (
  service: "accounts" | "microservice" | "cms" | "custom",
  options?: {
    cache?: number;
    revalidate?: number;
    bypass?: boolean;
    debug?: boolean;
    origin?: string;
    text?: boolean;
    timeout?: number;
    retry?: number;
    url?: string;
  }
) => {
  if (options?.debug && options?.origin) {
    console.log("🪵", "Origin", options.origin);
  }

  const url =
    {
      accounts: process.env["NEXT_PUBLIC_API_ORIGIN"],
      microservice:
        process.env["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"] ||
        "http://localhost:3000",
      cms: process.env["NEXT_PUBLIC_CMS_API_ORIGIN"] || "http://localhost:3001",
      custom: options?.url,
    }[service] || process.env["NEXT_PUBLIC_API_ORIGIN"];

  const request = async (
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE",
    path: string,
    params: { [key: string]: unknown } = {},
    headers: { [key: string]: unknown } = {}
  ) => {
    const account = String(
      headers?.["authorization"] || headers?.["Authorization"]
    );

    const process = async (
      tries?: number
    ): Promise<{ [key: string]: any } | string | null> => {
      if (options?.debug && options?.origin) {
        console.log("🪵", "Origin Request", options.origin);
      }

      const _url = new URL(`${url}${path}`);

      const _previous: { [key: string]: unknown } = {};
      const _params = _url.searchParams;

      _params.forEach((value, key) => {
        _previous[key] = value;
      });

      _url.search = new URLSearchParams(
        method === "GET" && (params || _previous)
          ? ({ ...params, ..._previous } as {
              [key: string]: string;
            })
          : {}
      ).toString();

      if (method === "GET") {
        if (options?.cache && isBrowserEnvironment()) {
          const storage = await Database.get(
            `${url}${slugify(path)}@${hash(JSON.stringify(params || {}))}`,
            account,
            options?.debug || false
          );
          if (storage) {
            return storage;
          }
        }

        const response = await fetch(_url.toString(), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          ...(options?.revalidate
            ? {
                next: {
                  revalidate: options.revalidate,
                },
                cache: "force-cache",
              }
            : {}),
        });

        if (response.status === 204) {
          return null;
        }

        if (options?.text) {
          return await response.text();
        }

        const json = await response.json();

        if (!response.ok && !options?.bypass) {
          Sentry.captureException(new Error(json.error), {
            extra: {
              method,
              url: _url.toString(),
              params,
              headers,
            },
            tags: {
              service,
            },
          });

          if (tries && tries < (options?.retry || 1)) {
            return await process(tries + 1);
          } else {
            if (json?.code) {
              throw new ServiceError(json?.error || json?.message, {
                ...json,
              });
            } else {
              throw new Error(json.error || json?.message);
            }
          }
        }

        if (options?.cache && isBrowserEnvironment()) {
          Database.set(
            `${url}${slugify(path)}@${hash(JSON.stringify(params || {}))}`,
            json,
            options.cache,
            account
          );
        }

        return json;
      } else {
        if (options?.cache && isBrowserEnvironment()) {
          const storage = await Database.get(
            `${url}${slugify(path)}@${hash(JSON.stringify(params || {}))}`,
            account,
            options?.debug || false
          );
          if (storage) {
            return storage;
          }
        }

        const response = await fetch(_url.toString(), {
          method: method,
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(params || {}),
        });

        const json = await response.json();

        if (response.status === 204) {
          return null;
        }

        if (options?.text) {
          return await response.text();
        }

        if (!response.ok && !options?.bypass) {
          const error = json.error || json.errors || "";

          console.log("🪵", error);
          Sentry.captureException(new Error(error), {
            extra: {
              method,
              url: _url.toString(),
              params,
              headers,
            },
            tags: {
              service,
            },
          });

          if (tries && tries < (options?.retry || 1)) {
            return await process(tries + 1);
          } else {
            throw new Error(error);
          }
        }

        if (options?.cache && isBrowserEnvironment()) {
          Database.set(
            `${url}${slugify(path)}@${hash(JSON.stringify(params || {}))}`,
            json,
            options.cache,
            account
          );
        }

        return json;
      }
    };

    return new Promise(async (resolve, reject) => {
      try {
        if (options?.timeout) {
          const timeout = setTimeout(() => {
            reject(new Error("Request Timeout"));
          }, options?.timeout || 10000);

          const response = await process();

          clearTimeout(timeout);

          resolve(response);
        } else {
          const response = await process();
          resolve(response);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return request as (
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE",
    path: string,
    params?: { [key: string]: unknown },
    headers?: { [key: string]: unknown }
  ) => Promise<{ [key: string]: any }>;
};

export const useServiceForm = (
  service: "accounts" | "microservice" | "cms"
) => {
  const url =
    {
      accounts: process.env["NEXT_PUBLIC_API_ORIGIN"],
      microservice:
        process.env["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"] ||
        "http://localhost:3000",
      cms: process.env["NEXT_PUBLIC_CMS_API_ORIGIN"] || "http://localhost:3001",
    }[service] || process.env["NEXT_PUBLIC_API_ORIGIN"];

  const form = async (
    method: "POST" | "PATCH",
    path: string,
    data?: FormData,
    headers?: { [key: string]: unknown }
  ): Promise<{ [key: string]: any }> => {
    const _url = new URL(`${url}${path}`);

    const response = await fetch(_url.toString(), {
      method,
      headers: headers as HeadersInit,
      body: data,
    });

    const json = await response.json();

    return json;
  };

  return form;
};

export const useURL = (
  service: "accounts" | "microservice" | "cms" | "admin"
) => {
  const url =
    {
      accounts: process.env["NEXT_PUBLIC_API_ORIGIN"],
      microservice:
        process.env["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"] ||
        "http://localhost:3000",
      cms: process.env["NEXT_PUBLIC_CMS_API_ORIGIN"] || "http://localhost:3001",
      admin:
        process.env["NODE_ENV"] === "production"
          ? "https://backstage.broadwaymedia.com"
          : "https://staging.backstage.broadwaymedia.com",
    }[service] || process.env["NEXT_PUBLIC_API_ORIGIN"];

  return url;
};

export default useService;
