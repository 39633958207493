import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../../shared";

const CuePopupSelect = ({
  label,
  options,
  value,
  change,
}: {
  label?: string;
  options: Array<Array<string | number | undefined>>;
  value: string | number | undefined;
  change: (value: number | string) => void;
}) => {
  return (
    <Box>
      <Text rule="Label Tiny" size={10} color={Colors.font4}>
        {label}
      </Text>
      <Box>
        <select
          className="--select"
          disabled={options.length === 1}
          value={value}
          onChange={(event) => change(event.target.value)}
        >
          {options.map(([label, index]: Array<string | number | undefined>) => (
            <option key={index} value={index}>
              {label}
            </option>
          ))}
        </select>
      </Box>
    </Box>
  );
};

export default CuePopupSelect;
