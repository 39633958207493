/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "simple-effing-primitive-layout";

import GenericContentHolder from "../generic-content-holder/generic-content-holder";

export interface ReusableMockupProps {
  image?: string;
  video?: string;
  reference?: (reference: any) => unknown;
  autoplay?: boolean;
  loop?: boolean;
}

export const ReusableMockup = (props: ReusableMockupProps) => {
  const { image, video, reference, autoplay = true, loop } = props;

  return (
    <Box css="p:relative w:auto" parse="pt:60.84%">
      <img
        loading="lazy"
        src="/assets/libs/simple-ui/reusable-mockup/assets/computer.webp"
        width="668"
        height="421"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        alt="Computer Mockup"
      />

      <GenericContentHolder
        fit="contain"
        autoplay={autoplay}
        reference={reference}
        image={image}
        video={video}
        loop={loop}
        parse="p:absolute w:76.64% h:85.29% l:11.67% t:3.7% r:unset b:unset oa:hidden"
        style={{ borderTopLeftRadius: "2.5%", borderTopRightRadius: "2.5%" }}
        alt="Computer Mockup Image"
      />
    </Box>
  );
};

export default ReusableMockup;
