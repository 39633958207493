/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";
import Recaptcha from "react-google-invisible-recaptcha";

interface CaptchaProps {
  verify?: () => unknown;
  expire?: () => unknown;
  error?: () => unknown;
  load?: () => unknown;
}
export const Captcha = ({ verify, error, expire, load }: CaptchaProps) => {
  const reference = useRef<any>();

  useEffect(() => {
    try {
      reference?.current?.execute?.();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <Recaptcha
      ref={reference}
      badge="bottomright"
      sitekey={process.env["NEXT_PUBLIC_CAPTCHA_KEY"]}
      onResolved={verify}
      onError={error}
      onExpired={() => {
        expire?.();
        reference?.current?.reset?.();
      }}
      onLoad={load}
      style={{ display: "none" }}
    />
  );
};

export default Captcha;
