import { hotjar } from "react-hotjar";

const HOTJAR_ID = 3506718;
const HOTJAR_TRACKING_CODE = 6;

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

class CustomEventTracker {
  static getReactPixelModule() {
    return import("react-facebook-pixel").then((module) => module.default);
  }

  static trackGoogleTagManager(event: string, customData?: unknown): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      customData,
    });
  }

  static initialize(): void {
    try {
      this.getReactPixelModule().then((ReactPixel) =>
        ReactPixel.init(process.env["NEXT_PUBLIC_FB_PIXEL_ID"] || "")
      );
      hotjar.initialize(HOTJAR_ID, HOTJAR_TRACKING_CODE);
    } catch (error) {
      console.error("Error initializing tracking libraries:", error);
    }
  }

  static track(event: string, customData?: unknown): void {
    try {
      if (!hotjar.initialized()) {
        this.initialize();
      }

      this.getReactPixelModule().then((ReactPixel) =>
        ReactPixel.trackCustom(event)
      );
      this.trackGoogleTagManager(event, customData);
      hotjar.event(event);
    } catch (error) {
      console.error("Error Tracking Event:", error);
    }
  }
}

export { CustomEventTracker };
