/* eslint-disable @typescript-eslint/no-explicit-any */
import { useServerRegion } from "@with-nx/region";
import filter from "lodash/filter";
import sample from "lodash/sample";
import { useEffect, useState } from "react";

import useService from "./useService";

type UseShowVariantsOptions = {
  showVariantId?: number | string;
  showVariantSlug?: string;
  includeScenicProjections?: boolean;
  includeChoreoGuides?: boolean;
  includeDrops?: boolean;
  includeDropsData?: boolean;
};
type UseShowVariantDrops = {
  id: number | string;
  product: number | string;
  title: string;
  thumbnail: string;
  video: string;
  data: any;
};
type UseShowVariantShowVariant = {
  id: number | string;
  title: string;
  slug: string;
  thumbnail: string;
  dropIds: string[];
  choreoGuideIds: string[];
  scenicProjectionsIds: string[];
  brand: number | string;
  drops: UseShowVariantDrops[];
  marketingDescription?: string;
  logoBackground?: string;
  logo?: string;
};

export const useShowVariant = (options?: UseShowVariantsOptions) => {
  const makeRequestToAccounts = useService("accounts", {
    cache: 1_800_000,
  });
  const region = useServerRegion();
  const makeRequestToMicroservice = useService("microservice", {
    cache: 1_800_000,
  });
  const [data, setData] = useState<UseShowVariantShowVariant | undefined>(
    undefined
  );

  useEffect(() => {
    if (!options?.showVariantId && !options?.showVariantSlug) {
      return;
    }

    (async () => {
      const includes = ["show-brand", "image", "logo"];
      if (options.includeScenicProjections) {
        includes.push("packages");
      }
      if (options.includeChoreoGuides) {
        includes.push("choreo-guide");
      }

      const fetchShowVariantList = await makeRequestToAccounts(
        "GET",
        `/api/v2/shows`,
        {
          include: includes.join(","),
          ...(options.showVariantId !== undefined
            ? { "filter[id]": options.showVariantId.toString() }
            : {
                "filter[slug]": options?.showVariantSlug?.toString() || "",
              }),
        }
      );
      const fetchShowVariant = fetchShowVariantList["data"][0] || undefined;

      if (!fetchShowVariant) {
        return;
      }

      const drops: UseShowVariantDrops[] = [];
      if (options.includeDrops) {
        try {
          const fetchDrops = (await makeRequestToMicroservice(
            "GET",
            `/ecommerce/products/search/${region?.id}/digital-drops`,
            {
              "pagination[page]": "1",
              "pagination[pageSize]": "50",
              "filter[searchText]": fetchShowVariant.attributes.title,
            }
          )) as any;

          if (fetchDrops.result) {
            fetchDrops.result.map((item: any) => {
              drops.push({
                id: item.id,
                product: item?.product?.id,
                title: item.name,
                thumbnail: item?.attachments?.find(
                  (i: any) => i.name === "preview_still"
                )?.blob?.signedUrl,
                video: item?.attachments?.find(
                  (i: any) => i.name === "preview_video"
                )?.blob?.signedUrl,
                data: item,
              });
            });
          }
        } catch (error) {
          console.error(error);
        }
      }

      const includedScenicProjections = filter(
        fetchShowVariantList["included"],
        (item) => item.type === "packages"
      );
      const includedChoreoGuides = filter(
        fetchShowVariantList["included"],
        (item) => item.type === "choreo-guides"
      );
      const includedAttachments = filter(
        fetchShowVariantList["included"],
        (item) => item.type === "attachments"
      );

      const temporary: UseShowVariantShowVariant = {
        id: fetchShowVariant["id"],
        title: fetchShowVariant.attributes.title,
        slug: fetchShowVariant.attributes.slug,
        thumbnail: drops.length > 0 ? (sample(drops)?.thumbnail as string) : "",
        dropIds: drops.map((item: any) => item.id),
        choreoGuideIds: includedChoreoGuides.map((item) => item.id),
        scenicProjectionsIds: includedScenicProjections.map((item) => item.id),
        brand: fetchShowVariant.relationships["show-brand"].data.id,
        drops,
        marketingDescription:
          fetchShowVariant?.attributes?.["marketing-description"],
        logoBackground: includedAttachments.find(
          (item) =>
            item.id === fetchShowVariant?.relationships?.["image"]?.data?.id
        )?.attributes?.url,
        logo: includedAttachments.find(
          (item) =>
            item.id === fetchShowVariant?.relationships?.["logo"]?.data?.id
        )?.attributes?.url,
      };

      setData(temporary);
    })();
  }, [options?.showVariantId, options?.showVariantSlug]);

  return data;
};

type UseShowBrandOptions = {
  showBrandId?: number | string;
};
type UseShowBrandDrops = {
  id: number | string;
  title: string;
  thumbnail: string;
  video: string;
};
type UseShowBrandShowVariant = {
  id: number | string;
  title: string;
  slug: string;
  logo: string;
  thumbnail: string;
  dropIds: string[];
  choreoGuideIds: string[];
  scenicProjectionsIds: string[];
};
type UseShowBrandShowBrand = {
  id: number;
  title: string;
  marketingDescription?: string;
  thumbnail: string;
  drops: UseShowBrandDrops[];
  showVariants: UseShowBrandShowVariant[];
};

export const useShowBrand = (options?: UseShowBrandOptions) => {
  const makeRequestToAccounts = useService("accounts", {
    cache: 1_800_000,
  });
  const [data, setData] = useState<UseShowBrandShowBrand | undefined>(
    undefined
  );

  useEffect(() => {
    if (!options?.showBrandId) {
      return;
    }

    (async () => {
      try {
        const fetchShowBrand = await makeRequestToAccounts(
          "GET",
          `/api/v2/show-brands/${options.showBrandId}`,
          {
            include:
              "shows.packages.show,shows.choreo-guide.show,shows.drops,cover-drop,shows.logo",
          }
        );

        const includedShowVariants = filter(
          fetchShowBrand["included"],
          (item) => item.type === "shows"
        );
        const includedDrops = filter(
          fetchShowBrand["included"],
          (item) => item.type === "drops"
        );
        const includedScenicProjections = filter(
          fetchShowBrand["included"],
          (item) => item.type === "packages"
        );
        const includedChoreoGuides = filter(
          fetchShowBrand["included"],
          (item) => item.type === "choreo-guides"
        );
        const includedLogos = filter(
          fetchShowBrand["included"],
          (item) => item.type === "attachments"
        );

        const showBrand: UseShowBrandShowBrand = {
          id: fetchShowBrand["data"].id,
          title: fetchShowBrand["data"].attributes.name,
          marketingDescription:
            fetchShowBrand["data"].attributes?.["marketing-description"],
          thumbnail:
            includedDrops.length > 0
              ? filter(
                  includedDrops,
                  (item) =>
                    item.id ===
                    fetchShowBrand?.["data"]?.relationships?.["cover-drop"]
                      ?.data?.id
                )?.[0]?.attributes?.thumbnail
              : "",
          showVariants: ((): UseShowBrandShowVariant[] => {
            return (
              includedShowVariants.map((showVariantInclude) => {
                const temporary: UseShowBrandShowVariant = {
                  id: showVariantInclude.id,
                  title: showVariantInclude.attributes.title,
                  slug: showVariantInclude.attributes.slug,
                  logo:
                    filter(
                      includedLogos,
                      (item) =>
                        item.id ===
                        showVariantInclude?.relationships?.logo?.data?.id
                    )?.[0]?.attributes?.url || "",
                  thumbnail:
                    includedDrops.length > 0
                      ? sample(includedDrops)?.attributes?.thumbnail
                      : "",
                  dropIds: showVariantInclude?.relationships?.drops?.data?.map(
                    (item: any) => item.id
                  ),
                  choreoGuideIds: filter(
                    includedChoreoGuides,
                    (item) =>
                      item?.relationships?.show?.data?.id ===
                      showVariantInclude.id
                  ).map((item) => item.id),
                  scenicProjectionsIds: filter(
                    includedScenicProjections,
                    (item) =>
                      item?.relationships?.show?.data?.id ===
                      showVariantInclude.id
                  ).map((item) => item.id),
                };

                return temporary;
              }) || []
            );
          })(),
          drops: includedDrops.map((item) => {
            return {
              id: item.id,
              title: item.attributes.name,
              thumbnail: item.attributes["preview-still"],
              video: item.attributes["preview-video"],
            };
          }) as unknown as UseShowBrandDrops[],
        };

        setData(showBrand);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [options?.showBrandId]);

  return data;
};

type UseScenicProjectionOptions = {
  scenicProjectionId?: number | string;
};
type UseScenicProjectionScene = {
  id: number | string;
  title: string;
  video: string;
};
type UseScenicProjectionScenicProjection = {
  id: number | string;
  title?: string;
  name?: string;
  warmer?: string;
  commercial?: string;
  marketingDescription?: string;
  thumbnail?: string;
  slug?: string;
  scenes: UseScenicProjectionScene[];
  show?: number | string;
};
export const useScenicProjection = (options?: UseScenicProjectionOptions) => {
  const makeRequestToAccounts = useService("accounts", {
    cache: 1_800_000,
  });
  const [data, setData] = useState<
    UseScenicProjectionScenicProjection | undefined
  >(undefined);

  useEffect(() => {
    if (!options?.scenicProjectionId) {
      return;
    }

    (async () => {
      const fetchScenicProjection = await makeRequestToAccounts(
        "GET",
        `/api/v2/packages/${options.scenicProjectionId}`,
        {
          include: "scenes,show",
        }
      );

      const includedScenes = filter(
        fetchScenicProjection["included"],
        (item) => item.type === "scenes"
      );

      const includedShows = filter(
        fetchScenicProjection["included"],
        (item) => item.type === "shows"
      );

      const temporary: UseScenicProjectionScenicProjection = {
        id: fetchScenicProjection["data"].id,
        name: fetchScenicProjection["data"].attributes.name,
        thumbnail: fetchScenicProjection["data"].attributes.thumbnail,
        warmer:
          fetchScenicProjection["data"].attributes["curtain-warmer-preview"],
        commercial:
          fetchScenicProjection["data"].attributes["commercial-video-url"],
        marketingDescription:
          fetchScenicProjection["data"].attributes["marketing-description"],
        scenes: includedScenes.map((item) => {
          return {
            id: item.id,
            title: item.attributes.name,
            video: item.attributes["preview-video"],
          };
        }),
        show: fetchScenicProjection?.["data"]?.relationships?.show?.data?.id,
        slug: includedShows?.find(
          (i) =>
            i.id ===
            fetchScenicProjection?.["data"]?.relationships?.show?.data?.id
        )?.attributes?.slug,
        title: includedShows?.find(
          (i) =>
            i.id ===
            fetchScenicProjection?.["data"]?.relationships?.show?.data?.id
        )?.attributes?.title,
      };

      setData(temporary);
    })();
  }, [options?.scenicProjectionId]);

  return data;
};

type UseChoreoGuideOptions = {
  choreoGuideId?: number | string;
};
type UseChoreoGuideSong = {
  id: number | string;
  title: string;
  video: string;
  thumbnail: string;
};
type UseChoreoGuideChoreoGuide = {
  id: number | string;
  title: string;
  slug: string;
  licensor: string;
  producer: string;
  credits: string;
  choreographer: string;
  commercial?: string;
  marketingDescription?: string;
  songs: UseChoreoGuideSong[];
  logo?: string;
};
export const useChoreoGuide = (options?: UseChoreoGuideOptions) => {
  const makeRequestToAccounts = useService("accounts", {
    cache: 1_800_000,
  });
  const [data, setData] = useState<UseChoreoGuideChoreoGuide | undefined>(
    undefined
  );

  useEffect(() => {
    if (!options?.choreoGuideId) {
      return;
    }

    (async () => {
      const fetchChoreoGuide = await makeRequestToAccounts(
        "GET",
        `/api/v2/choreo-guides/${options.choreoGuideId}`
      );

      const includedShowBrands = filter(
        fetchChoreoGuide["included"],
        (item) => item.type === "show-brands"
      );

      const temporary: UseChoreoGuideChoreoGuide = {
        id: fetchChoreoGuide["data"].id,
        title: "Choreography Guides",
        slug: fetchChoreoGuide["data"].attributes.slug,
        licensor: fetchChoreoGuide["data"].attributes.licensor,
        producer: fetchChoreoGuide["data"].attributes.producer,
        credits: fetchChoreoGuide["data"].attributes["other-credits"],
        choreographer: fetchChoreoGuide["data"].attributes.choreographer,
        commercial: fetchChoreoGuide["data"].attributes["commercial-video-url"],
        marketingDescription:
          fetchChoreoGuide["data"].attributes["marketing-description"],
        logo: includedShowBrands?.[0]?.links?.logo,
        songs: fetchChoreoGuide["data"].attributes.songs?.map((item: any) => {
          return {
            id: item.id,
            title: item.name,
            video: item["preview_video"],
            thumbnail: item["preview_thumbnail"],
          };
        }),
      };

      setData(temporary);
    })();
  }, [options?.choreoGuideId]);

  return data;
};
