import { VideoSet } from "libs/editor-ui/types";
import { Box } from "simple-effing-primitive-layout";

import { Colors } from "../../../shared";
import CuePopupTextarea from "../CuePopupTextarea";

const PageOptions = ({
  actIndex,
  sceneIndex,
  cueIndex,
  shouldPopupDisplayOnTop,
  setPageOptionsAreOpen,
  pageOptionsAreOpen,
  onCueUpdate,
  cue,
}: {
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  shouldPopupDisplayOnTop: boolean;
  setPageOptionsAreOpen: (is: boolean) => unknown;
  pageOptionsAreOpen: boolean;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  cue: VideoSet;
}) => {
  return (
    <div onMouseLeave={() => setPageOptionsAreOpen(false)}>
      <Box
        display={pageOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          <CuePopupTextarea
            label="Page Number"
            value={cue?.cuePage || ""}
            change={(cuePage) => {
              setPageOptionsAreOpen(false);
              onCueUpdate(actIndex, sceneIndex, cueIndex, {
                ...cue,
                cuePage: cuePage as string,
              });
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default PageOptions;
