export const staticProjectorRentalData = [
  {
    product: 295,
    slug: "projector-economy-lite",
    name: "Economy Lite",
    short_description:
      "Introducing Stage Projector LITE, an affordable weekly rental bundle. For the low cost of $1,195 per week, you receive an ultra-short throw projector, common projector mount, premium quality 50 ft gold-plated HDMI cable and 50ft extension cord - with free shipping!",
    meta: {
      lumens: 5000,
      throw_ratio: ".24 Throw",
      resolution: "1280x800 (HD)",
      max_width: 42,
      light_source: "Bulb",
      total_price: 62140,
      cost_per_week: 1195,
      discounted_price: 1095,
    },
    image: "/assets/products/hardware/stage-projector-lite-and-economy.png",
    type: "PROJECTOR",
    variation: "ECONOMY_LITE",
    accessories: [
      "extension-cord-50-ft",
      "extension-cord-200-ft",
      "common-mount",
    ],
    features: [
      'Presenting the "Economy Lite" Projector - the epitome of a perfect blend between cost-effectiveness and high-performance. Dive deep into a world where every visual detail stands out, all without putting a dent in your wallet. Boasting a sleek and compact design, the Economy Lite effortlessly integrates into any room, whether it\'s a professional boardroom for presentations, a cozy living room for movie marathons, or a dedicated gaming den for those intense sessions.',

      "Engineered with the latest energy-efficient technology, it promises extended lamp longevity, ensuring that you enjoy uninterrupted visuals for longer periods. The adjustable focus combined with keystone correction guarantees a picture-perfect, sharp image, regardless of the projection distance. With a user-friendly interface and intuitive setup process, getting started with the Economy Lite is a breeze, even for first-time projector users.",

      'Additionally, its array of connectivity options means you can link it with various devices, from laptops to gaming consoles, offering unmatched versatility. Accompanied by a robust built-in speaker system, experience sound quality that complements its stunning visuals. With the "Economy Lite" Projector, you\'re not just investing in a device but a gateway to superior visual experiences.',
    ],
  },
  {
    product: 296,
    slug: "stage-projector-economy",
    name: "Stage Projector Economy",
    short_description:
      "An entry-level projector setup that is perfect for a smaller, carefully lit stage or black box up to 28 ft wide.",
    meta: {
      lumens: 5000,
      throw_ratio: ".24 Throw",
      resolution: "1280x800 (HD)",
      max_width: 42,
      light_source: "Bulb",
      total_price: 62140,
      cost_per_week: 795,
      discounted_price: 695,
    },
    image: "/assets/products/hardware/stage-projector-lite-and-economy.png",
    type: "PROJECTOR",
    variation: "ECONOMY",
    accessories: [
      "extension-cord-50-ft",
      "extension-cord-200-ft",
      "common-mount",
    ],
  },
  {
    product: 297,
    slug: "stage-projector-basic",
    name: "Stage Projector Basic",
    short_description:
      "An affordable solution that pairs high image quality with a huge image, this projector is great for larger productions with controlled lighting and minimal natural light leakage.",
    meta: {
      lumens: 7000,
      throw_ratio: ".35 Throw",
      resolution: "1280x800 (HD)",
      max_width: 42,
      light_source: "Bulb",
      total_price: 62140,
      cost_per_week: 1595,
      discounted_price: 1495,
    },
    image: "/assets/products/hardware/stage-projector-basic.png",
    type: "PROJECTOR",
    variation: "BASIC",
    accessories: [
      "extension-cord-50-ft",
      "extension-cord-200-ft",
      "common-mount",
    ],
  },
  {
    product: 297,
    slug: "stage-projector-standard",
    name: "Stage Projector Standard",
    short_description:
      "Matching exacting image quality with a huge image, the Standard is perfect for larger stages and rooms with natural light leakage. This projector is a universal resource for reliable production values.",
    meta: {
      lumens: 9000,
      throw_ratio: ".35 Throw",
      resolution: "1280x800 (HD)",
      max_width: 42,
      light_source: "laser",
      total_price: 62140,
      cost_per_week: 2595,
      discounted_price: 2495,
    },
    image: "/assets/products/hardware/stage-projector-standard.png",
    type: "PROJECTOR",
    variation: "STANDARD",
    accessories: [
      "extension-cord-50-ft",
      "extension-cord-200-ft",
      "heavy-duty-mount",
    ],
  },
  {
    product: 298,
    slug: "stage-projector-plus",
    name: "Stage Projector Plus",
    short_description:
      "Featuring a powerful laser light source and deluxe .35 ultra short throw lens, this projector is perfect for a production with more stage lights seeking uncompromising image brilliance.",
    meta: {
      lumens: 12000,
      throw_ratio: ".35 Throw",
      resolution: "1280x800 (HD)",
      max_width: 60,
      light_source: "laser",
      total_price: 62140,
      cost_per_week: 2995,
      discounted_price: 2895,
    },
    image: "/assets/products/hardware/stage-projector-plus-and-premium.png",
    type: "PROJECTOR",
    variation: "PLUS",
    accessories: [
      "extension-cord-50-ft",
      "extension-cord-200-ft",
      "heavy-duty-mount",
    ],
  },
  {
    product: 299,
    slug: "stage-projector-premium",
    name: "Stage Projector Premium",
    short_description:
      "A large venue, ultra-bright laser projector that can handle anything you throw at it without sacrificing brightness or size. Paired with our ultra-short .35 premium powered lens, this projector provides unparalleled performance.",
    meta: {
      lumens: 15000,
      throw_ratio: ".35 Throw",
      resolution: "1280x800 (HD)",
      max_width: 60,
      light_source: "laser",
      total_price: 62140,
      cost_per_week: 3495,
      discounted_price: 3395,
    },
    image: "/assets/products/hardware/stage-projector-plus-and-premium.png",
    type: "PROJECTOR",
    variation: "PREMIUM",
    accessories: [
      "extension-cord-50-ft",
      "extension-cord-200-ft",
      "heavy-duty-mount",
    ],
  },
];

export const staticProjectorAccessoriesData = [
  {
    slug: "extension-cord-50-ft",
    name: "Extension 50ft",
    short_description:
      "50ft extension cord for Stage Projector rentals. This is a standard 3-prong extension cord.",
    meta: {
      cost_per_week: 895,
      discounted_price: 595,
    },
    image: "/assets/products/hardware/extension-cord-50-ft.png",
    type: "ACCESSORY",
    variation: "EXTENSION_50",
  },
  {
    slug: "extension-cord-200-ft",
    name: "Extension 200ft",
    short_description:
      "200ft extension cord for Stage Projector rentals. This is a standard 3-prong extension cord.",
    meta: {
      cost_per_week: 995,
      discounted_price: 695,
    },
    image: "/assets/products/hardware/extension-cord-200-ft.png",
    type: "ACCESSORY",
    variation: "EXTENSION_200",
  },
  {
    slug: "common-mount",
    name: "Common Mount",
    short_description:
      "Common projector mount for Stage Projector rentals. This is a standard projector mount.",
    meta: {
      cost_per_week: 420,
      discounted_price: 395,
    },
    image: "/assets/products/hardware/common-mount.png",
    type: "ACCESSORY",
    variation: "COMMON_MOUNT",
  },
  {
    slug: "heavy-duty-mount",
    name: "Heavy Duty Mount",
    short_description:
      "Heavy duty projector mount for Stage Projector rentals. This is a heavy duty projector mount.",
    meta: {
      cost_per_week: 905,
      discounted_price: 859,
    },
    image: "/assets/products/hardware/heavy-duty-mount.png",
    type: "ACCESSORY",
    variation: "HEAVY_DUTY_MOUNT",
  },
  {
    slug: "led-wall-20ft-by-8ft",
    name: "LED Wall 20ft by 8ft",
    short_description:
      "20ft by 8ft LED wall for Stage Projector rentals. This is a standard LED wall.",
    meta: {
      cost_per_week: 105,
      discounted_price: 95,
    },
    image: "/assets/products/hardware/led-wall-20ft-by-8ft.png",
    type: "ACCESSORY",
    variation: "LED_WALL_20_BY_8",
  },
  {
    slug: "permanent-cyc",
    name: "Permanent Cyc",
    short_description:
      "Permanent cyc for Stage Projector rentals. This is a standard permanent cyc.",
    meta: {
      cost_per_week: 55,
      discounted_price: 50,
    },
    image: "/assets/products/hardware/permanent-cyc.png",
    type: "ACCESSORY",
    variation: "PERMANENT_CYC",
  },
];

export type StaticProjectorRentalDataType =
  (typeof staticProjectorRentalData)[number];
export type StaticProjectorAccessoriesDataType =
  (typeof staticProjectorAccessoriesData)[number];
