/* eslint-disable */
import { Box } from "simple-effing-primitive-layout";
import { ReactNode, useState } from "react";
import { CustomPicker } from "react-color";
import { Alpha, Saturation, Hue } from "react-color/lib/components/common";
import { Colors } from "../../../shared";
import CuePopupInput from "../CuePopupInput";
import Button from "../../../shared/Button";
import checkers from "../../../shared/assets/checkers.png";
import CuePopupSelect from "../CuePopupSelect";
import { VideoSet } from "libs/editor-ui/types";

export const PointerCircle = () => {
  const styles = {
    picker: {
      width: "12px",
      height: "12px",
      borderRadius: "6px",
      transform: "translate(-6px, -6px)",
    },
  };

  return <div style={styles.picker} />;
};

export const Pointer = () => {
  const styles = {
    picker: {
      width: "12px",
      height: "12px",
      borderRadius: "6px",
      transform: "translate(-6px, -1px)",
      backgroundColor: "rgb(248, 248, 248)",
    },
  };

  return <div style={styles.picker} />;
};

export const ColorPickerBase = (props: any) => {
  const { width, onChange, rgb, hsl, hsv, renderers, className = "" } = props;
  const styles: any = {
    picker: {
      width,
      background: "#1E1E1E",
      borderRadius: "2px",
      boxSizing: "initial",
      fontFamily: "Menlo",
    },
    saturation: {
      width: "100%",
      paddingBottom: "55%",
      position: "relative",
      borderRadius: "2px 2px 0 0",
      overflow: "hidden",
    },
    Saturation: {
      radius: "2px 2px 0 0",
    },
    body: {
      padding: "16px 16px 12px",
    },
    controls: {
      display: "flex",
    },
    color: {
      width: "32px",
    },
    swatch: {
      marginTop: "6px",
      width: "16px",
      height: "16px",
      borderRadius: "8px",
      position: "relative",
      overflow: "hidden",
    },
    active: {
      absolute: "0px 0px 0px 0px",
      borderRadius: "8px",
      background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
      zIndex: "2",
    },
    toggles: {
      flex: "1",
    },
    hue: {
      height: "10px",
      position: "relative",
      marginBottom: "8px",
    },
    Hue: {
      radius: "2px",
    },
    alpha: {
      height: "10px",
      position: "relative",
    },
    Alpha: {
      radius: "2px",
    },
  };

  return (
    <div style={styles.picker} className={`chrome-picker ${className}`}>
      <div style={styles.saturation}>
        <Saturation
          styles={styles.Saturation}
          hsl={hsl}
          hsv={hsv}
          pointer={PointerCircle as unknown as ReactNode}
          onChange={onChange}
        />
      </div>
      <div style={styles.body}>
        <div style={styles.controls} className="flexbox-fix">
          <div style={styles.toggles}>
            <div style={styles.hue}>
              <Hue
                styles={styles.Hue}
                hsl={hsl}
                pointer={Pointer as unknown as ReactNode}
                onChange={onChange}
              />
            </div>
            <div style={styles.alpha}>
              <Alpha
                styles={styles.Alpha}
                rgb={rgb}
                hsl={hsl}
                pointer={Pointer as unknown as ReactNode}
                renderers={renderers}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorPicker = CustomPicker(ColorPickerBase);

const EffectOptions = ({
  actIndex,
  sceneIndex,
  cueIndex,
  shouldPopupDisplayOnTop,
  setEffectOptionsAreOpen,
  effectOptionsAreOpen,
  onCueUpdate,
  cue,
}: {
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  shouldPopupDisplayOnTop: boolean;
  setEffectOptionsAreOpen: (is: boolean) => unknown;
  effectOptionsAreOpen: boolean;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  cue: VideoSet;
}) => {
  const [background, _background] = useState(
    cue?.effects?.background || "transparent"
  );
  const [effect, _effect] = useState(cue?.effects?.effect || "none");
  const [mask, _mask] = useState(cue?.mask || "none");

  const update = ({ b, e, m }: { b?: any; e?: any; m?: any }) => {
    onCueUpdate(actIndex, sceneIndex, cueIndex, {
      ...cue,
      effects: {
        background: b || background,
        effect: e || effect,
      },
      mask: m || mask,
    });
  };

  return (
    <div onMouseLeave={() => setEffectOptionsAreOpen(false)}>
      <Box
        display={effectOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          <CuePopupSelect
            label="Mask"
            value={mask}
            change={(value) => {
              _mask(value as string);
              update({
                e: effect,
                m: value,
                b: background,
              });
            }}
            options={[["None", "none"]]}
          />
          <CuePopupSelect
            label="Overlay Effect"
            value={effect}
            change={(value) => {
              _effect(value as string);
              update({
                e: value,
                m: mask,
                b: background,
              });
            }}
            options={[
              ["None", "none"],
              ["Rain #1", "rain1"],
            ]}
          />
          <Box parse="d:flex a:flex-end">
            <CuePopupInput
              label="Background Color"
              value={
                typeof background === "string"
                  ? background
                  : `rgba(${background.r},${background.g},${background.b},${background.a})`
              }
              change={(value) => {
                _background(value as string);
              }}
              blur={() => update({})}
              input={{
                style: {
                  height: 32,
                  padding: "0 15px",
                  fontSize: 14,
                  lineHeight: 16,
                },
              }}
              properties={{ flex: 1 }}
            />
            <Box
              parse="h:32 w:32 mw:32 br:4 p:relative ml:10 mb:-5 oa:visible o:visible"
              border="1px solid rgba(255,255,255,0.1)"
              css="--parent"
              style={{
                backgroundImage: "url(" + checkers + ")",
                backgroundSize: "1000%",
              }}
            >
              <Box
                mode="position"
                left="unset"
                bottom="unset"
                top="calc(100% + 10px)"
                parse="r:0 p:absolute w:200"
                css="--children"
              >
                <ColorPicker
                  color={background}
                  onChangeComplete={(data: any) => {
                    _background(data.rgb);
                    update({ b: data.rgb });
                  }}
                />
              </Box>
              <Box
                parse="w:100% h:100% p:absolute t:0 l:0 BR:5"
                color={
                  typeof background === "string"
                    ? background
                    : `rgba(${background.r},${background.g},${background.b},${background.a})`
                }
              />
            </Box>
          </Box>
          <Button
            properties={{ top: 10 }}
            size="small"
            theme="primary"
            label="Reset"
            press={() =>
              onCueUpdate(actIndex, sceneIndex, cueIndex, {
                ...cue,
                effects: undefined,
              })
            }
          />
        </Box>
      </Box>
    </div>
  );
};

export default EffectOptions;
