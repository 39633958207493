import { useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  ReusableGlossBox,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import AnimatedInfiniteSlantedCards from "../animated-infinite-slanted-cards/animated-infinite-slanted-cards";

export const SearchEmpty = () => {
  const images = [...Array(36)].map((_, i) => `/assets/drops/${i + 1}.jpg`);

  const mobile = useMobile();

  return (
    <ReusableSection>
      <ReusableGlossBox parse="pa:48 br:10 c:?foreground p:relative oa:hidden">
        <Box css="p:relative i:4 z:4" width={mobile ? undefined : "50%"}>
          <Rule parse="!_t d:block mb:24" tag="h2">
            Can't find what you are looking for?
          </Rule>
          <Rule parse="!_p d:block mb:24">
            With 3,000 animated drops at your fingertips, curate the show
            collection that unlocks your creativity
          </Rule>
          <DesignedButton
            label="Explore Digital Backdrops"
            href="/digital-backdrops"
            theme="primary"
            size="medium"
          />
        </Box>

        <Box
          parse="oa:visible p:absolute b:unset r:unset t:50% l:50%"
          style={{ transform: "translateX(-50%) rotate(-15deg)" }}
          id="animated-cards"
        >
          <AnimatedInfiniteSlantedCards size={12} rows={2} images={images} />
        </Box>
      </ReusableGlossBox>
    </ReusableSection>
  );
};

export default SearchEmpty;
