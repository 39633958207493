/* eslint-disable @typescript-eslint/no-explicit-any */
import { useServerRegion } from "@with-nx/region";
import { useEffect, useState } from "react";
import { mutate } from "swr";

import ThrottlerController from "./ThrottlerController";
import { ProductsResponse } from "./types";
import useService from "./useService";
import { SortField } from "@with-nx/simple-ui/organisms";

const availableTimeOfDay = ["Night", "Day"];
const availableLocations = ["Interiors", "Exteriors"];
export const availableTags = [
  "Arches",
  "Art Deco",
  "Banners",
  "Barns",
  "Beaches",
  "Borders",
  "Broadway",
  "Cafés",
  "Castles",
  "Caves",
  "Celebration",
  "Christmas",
  "Circus",
  "Circus Banners",
  "Country/Farms",
  "Cut Openings",
  "Dance",
  "Day Skies",
  "Day Snows",
  "Deserts",
  "Disco",
  "Emerald City",
  "European Streets",
  "Event Planner",
  "Fantasy",
  "Flags",
  "Foliage Arches",
  "Foreign Lands",
  "Forest",
  "Gardens",
  "Gymnasiums",
  "Halloween",
  "Hawaiian",
  "Hollywood",
  "Landscape",
  "Las Vegas",
  "Leg Sets",
  "Mardi Gras",
  "Military",
  "Monuments",
  "Mountains",
  "Munchkinlands",
  "Music",
  "Night Skies",
  "Night Snows",
  "Olios",
  "Palace/Parlors",
  "Parks",
  "Patriotic",
  "Photobackings",
  "Plantations",
  "Prisons",
  "Residential",
  "Retro",
  "School",
  "Sewers",
  "Skylines",
  "Small Towns",
  "Space",
  "Sports",
  "Stadiums",
  "Streets",
  "Sweets",
  "Tenements",
  "Through the Decades",
  "Toyshops",
  "Transportation",
  "Travel",
  "Tropicals",
  "Undersea",
  "Villages",
  "Vineyards",
  "Westerns",
  "Winter Scenes",
  "Churches",
  "Photo Real",
  "Abstract",
  "Painterly",
  "Cartoon",
  "Cafés/Bars",
  "Jungle",
  "Offices / Business",
  "Stages",
  "Seabed",
  "Weather Effects",
];

interface UseProductsOptions {
  currentPage?: number;
  pageSize?: number;
  includeDescription?: boolean;
  searchText?: string;
  filterQuery?: string;
  variation?: string;
  selectedVersions?: string[];
  productCategory?: string;
  selectedResources?: string[];
  selectedSettings?: string[];
  selectedStyles?: string[];
  selectedLicensors?: string[];
  searchRequired?: boolean;
  createdAtOrderBy?: "ASC" | "DESC";
  sortBy?: SortField;
}

export const mostPopularTags = [
  {
    count: 1030,
    tag_id: 28,
    name: "Exteriors",
  },
  {
    count: 849,
    tag_id: 86,
    name: "Day",
  },
  {
    count: 782,
    tag_id: 39,
    name: "Interiors",
  },
  {
    count: 759,
    tag_id: 194,
    name: "Streaming",
  },
  {
    count: 685,
    tag_id: 85,
    name: "Night",
  },
  {
    count: 326,
    tag_id: 964,
    name: "Public School Buy List",
  },
  {
    count: 241,
    tag_id: 89,
    name: "Offices / Business",
  },
  {
    count: 235,
    tag_id: 58,
    name: "Residential",
  },
  {
    count: 232,
    tag_id: 40,
    name: "Landscape",
  },
  {
    count: 220,
    tag_id: 67,
    name: "Streets",
  },
  {
    count: 215,
    tag_id: 20,
    name: "Dance",
  },
  {
    count: 193,
    tag_id: 29,
    name: "Fantasy",
  },
  {
    count: 188,
    tag_id: 1,
    name: "Abstract/Pattern",
  },
  {
    count: 169,
    tag_id: 11,
    name: "Castles",
  },
  {
    count: 146,
    tag_id: 191,
    name: "High School Thespian Troupe",
  },
  {
    count: 142,
    tag_id: 188,
    name: "Private School",
  },
].map((t) => t.name);

export function useProducts(options: UseProductsOptions = {}) {
  const region = useServerRegion();
  const [availableTagsMicroservice, setAvailableTagsMicroservice] = useState<
    string[]
  >([]);
  const [loading, _loading] = useState<boolean>(false);

  const {
    currentPage = 1,
    pageSize = 12,
    searchText = "",
    filterQuery = "",
    selectedVersions = [],
    productCategory,
    selectedResources = [],
    selectedSettings = [],
    selectedStyles = [],
    selectedLicensors = [],
    searchRequired = false,
    createdAtOrderBy,
    sortBy,
  } = options;

  const [data, _data] = useState<any>();
  const [error] = useState<string | undefined>(undefined);
  const service = useService("microservice", {
    cache: 60_000,
  });

  const params = new Map();
  params.set("pagination[page]", currentPage.toString());
  params.set("pagination[pageSize]", pageSize.toString());
  params.set("includes[description]", "true");
  params.set("includes[attachments]", "true");

  if (searchText && searchText.length > 0) {
    params.set("filter[searchText]", searchText);
  }
  if (filterQuery && filterQuery.length > 0) {
    params.set(filterQuery, "");
  }
  if (selectedVersions.length > 0) {
    selectedVersions.forEach((version, index) => {
      params.set(`filter[versions][${index}]`, version.toUpperCase());
    });
  }
  if (selectedLicensors.length > 0) {
    selectedLicensors.forEach((licensor, index) => {
      params.set(`filter[licensorsIds][${index}]`, licensor.toString());
    });
  }
  if (selectedResources.includes("choreoGuide")) {
    params.set("filter[resources][0]", "CHOREO_GUIDE");
  }
  if (selectedResources.includes("scenicProjections")) {
    params.set("filter[resources][1]", "SCENIC_PROJECTION_PACKAGE");
  }
  if (selectedSettings.length > 0) {
    selectedSettings.forEach((item, index) => {
      params.set(`filter[themes][${index}]`, item);
    });
  }
  if (selectedStyles.length > 0) {
    selectedStyles.forEach((item, index) => {
      params.set(`filter[events][${index}]`, item);
    });
  }
  if (createdAtOrderBy) {
    params.set("pagination[orderBy][createdAt]", createdAtOrderBy);
  }

  if (sortBy?.value && sortBy?.order) {
    params.set(`pagination[orderBy][${sortBy.value}]`, sortBy.order);
  }

  useEffect(() => {
    if (
      (searchRequired && !searchText) ||
      (searchRequired && searchText.length === 0)
    ) {
      return;
    }

    if (!ThrottlerController.can("products", "reload", 1000)) {
      return;
    }

    (async () => {
      const parameters = (() => {
        const t: {
          [key: string]: string;
        } = {};
        params.forEach((value, key) => {
          t[key] = value;
        });

        return t;
      })();

      _loading(true);

      const data = await service(
        "GET",
        `/ecommerce/products/search/${region?.id || 1}/${productCategory}`,
        parameters
      );

      _loading(false);

      _data(data);
    })();
  }, [
    currentPage,
    pageSize,
    searchText,
    filterQuery,
    selectedVersions,
    productCategory,
    selectedResources,
    selectedLicensors,
    selectedSettings,
    selectedStyles,
    sortBy,
  ]);

  useEffect(() => {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 86_400,
    });

    (async () => {
      try {
        const _request = (await makeRequestToMicroservice(
          "GET",
          "/backstage/tags/categorized"
        )) as {
          category: string;
          tags: {
            name: string;
          }[];
        }[];

        const request: {
          name: string;
        }[] = [];

        _request?.forEach((category) => {
          if (category.category !== "Events") {
            category.tags.forEach((tag) => {
              request.push(tag);
            });
          }
        });

        const filtered = request
          .filter((tag) => {
            if (
              [
                "Day",
                "Night",
                "Sunset",
                "Sunset / Sunrises",
                "Interiors",
                "Exteriors",
                "Morning",
              ].includes(tag.name)
            ) {
              return false;
            }
            return true;
          })
          .map((item) => item.name);
        setAvailableTagsMicroservice(filtered);
      } catch (error) {
        console.error(error);
        setAvailableTagsMicroservice(availableTags);
      }
    })();
  }, []);

  return {
    products: data ? (data as ProductsResponse) : null,
    isLoading: (!error && !data) || loading,
    isError: error as string | undefined,
    mutate,
    availableTimeOfDay,
    availableLocations,
    availableTags: availableTagsMicroservice,
  };
}

export const useProductsTags = () => {
  const [availableTagsMicroservice, setAvailableTagsMicroservice] = useState<
    string[]
  >([]);

  useEffect(() => {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 86_400,
    });

    (async () => {
      try {
        const _request = (await makeRequestToMicroservice(
          "GET",
          "/backstage/tags/categorized"
        )) as {
          category: string;
          tags: {
            name: string;
          }[];
        }[];

        const request: {
          name: string;
        }[] = [];

        _request?.forEach((category) => {
          if (category.category !== "Events") {
            category.tags.forEach((tag) => {
              request.push(tag);
            });
          }
        });

        const filtered = request
          .filter((tag) => {
            if (
              [
                "Day",
                "Night",
                "Sunset",
                "Sunset / Sunrises",
                "Interiors",
                "Exteriors",
                "Morning",
              ].includes(tag.name)
            ) {
              return false;
            }
            return true;
          })
          .map((item) => item.name);
        setAvailableTagsMicroservice(filtered);
      } catch (error) {
        console.error(error);
        setAvailableTagsMicroservice(availableTags);
      }
    })();
  }, []);

  return { availableTags: availableTagsMicroservice };
};
