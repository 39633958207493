import { useTimeout } from "@with-nx/hooks-n-helpers";
import { LoadingContainer } from "@with-nx/simple-ui/molecules";

interface CatalogLayoutWaitProps {
  timeout?: number;
  until: boolean[];
  children: React.ReactNode;
}

export const CatalogLayoutWait = (props: CatalogLayoutWaitProps) => {
  const loaded = props.until.reduce((acc, curr) => acc && curr, true);
  const timeout = useTimeout(props?.timeout || 5000);

  if (loaded || timeout) {
    return <>{props.children}</>;
  }

  return <LoadingContainer height={1000} />;
};
