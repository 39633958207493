import { Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import moment from "moment-timezone";
import { Dispatch, FC, SetStateAction } from "react";

interface TimeSelectorProps {
  selectedTimes?: string[];
  time: string;
  _time: Dispatch<SetStateAction<string>>;
  timeZone: string;
  date: string;
}

export const TimeSelector: FC<TimeSelectorProps> = ({
  selectedTimes,
  time,
  _time,
  timeZone,
  date,
}) => {
  return (
    <>
      {!!selectedTimes?.length && !!date && (
        <>
          <Rule parse="!_h3 d:block ta:center c:var(--black-base) mt:24">
            Select a Time
          </Rule>

          <Box parse="mt:10 mb:12 d:flex j:center fw:wrap">
            {selectedTimes?.map((availabletime) => {
              const selected = availabletime === time;

              const localScheduled = localStorage.getItem(
                `meeting-${date}-${availabletime}`
              );

              return (
                <Box
                  key={availabletime}
                  parse="br:5 mb:8"
                  border={
                    localScheduled
                      ? "1px solid #ff4000"
                      : `${selected ? "2" : "1"}px solid #0075FF`
                  }
                  right={14}
                  style={{
                    cursor: "pointer",
                  }}
                  color={localScheduled ? "#ffd3c4" : selected ? "#CFE3FF" : ""}
                  press={() => {
                    if (localScheduled) {
                      return;
                    }

                    _time(time === availabletime ? "" : availabletime);
                  }}
                >
                  <Rule
                    parse={`!_bm c:${
                      localScheduled ? "#ff4000" : "#0075FF"
                    } d:block pa:8 pl:4 pr:4`}
                    weight={selected ? 700 : 500}
                  >
                    {moment.tz(availabletime, timeZone).format("LT")}
                  </Rule>
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};
