import {
  DesignedBadge as Badge,
  DesignedButton,
  DesignedCard,
  PlaceholderImage,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

import MobileViewSwitcher from "../mobile-view-switcher/mobile-view-switcher";

interface ImageCardsCallToActionProps {
  images: (string | null)[];
  title: string;
  badge?: string;
  description: string;
  button: string;
  url?: string;
  action?: boolean;
  column?: boolean;
  minimal?: boolean;
  cards?: boolean;
  theme?: string;
  lines?: number;
}

export const ImageCardsCallToAction = (props: ImageCardsCallToActionProps) => {
  const {
    images,
    title,
    description,
    button,
    url,
    badge,
    action,
    column,
    minimal,
    theme = "tetriary",
    cards,
    lines,
  } = props;

  const Wrapper = ({ children }: { children: ReactNode }) => (
    <MobileViewSwitcher
      render={(mobile) =>
        minimal ? (
          <Box
            parse="d:flex a:flex-start"
            justify={mobile ? "unset" : "space-between"}
            direction={mobile ? "column" : "row"}
          >
            {children}
          </Box>
        ) : !action ? (
          <Box
            parse="d:flex mb:48 br:10 a:flex-start"
            justify={mobile || column ? "unset" : "space-between"}
            direction={mobile || column ? "column" : "row"}
            style={
              column
                ? {
                    backgroundColor: "#14181D",
                    padding: 20,
                  }
                : undefined
            }
          >
            {children}
          </Box>
        ) : (
          <ReusableGlossBox
            parse="d:flex mb:48 br:10 a:flex-start pa:12"
            justify={mobile || column ? "unset" : "space-between"}
            direction={mobile || column ? "column" : "row"}
            style={
              column
                ? {
                    backgroundColor: "#14181D",
                    padding: 20,
                  }
                : undefined
            }
          >
            {children}
          </ReusableGlossBox>
        )
      }
    />
  );

  return (
    <MobileViewSwitcher
      render={(mobile) => (
        <Wrapper>
          <Box css="f:1" right={mobile ? 0 : 24}>
            <Box
              parse="d:flex a:flex-start"
              bottom={mobile || column ? 12 : 0}
              direction={mobile || column ? "column" : "row"}
            >
              <Box
                parse="d:inline-flex a:center mr:24"
                style={{ flexDirection: "row-reverse" }}
                bottom={mobile || column ? 12 : 0}
              >
                {images.map((image, i) => (
                  <Box
                    key={i}
                    parse="br:5 w:126 h:74 p:relative"
                    left={i === images.length - 1 ? 0 : -100}
                    opacity={(100 - 25 * (images.length - 1 - i)) / 100}
                  >
                    {cards && image ? (
                      <DesignedCard
                        size="16x9"
                        inside={{ image: image || undefined }}
                      />
                    ) : image ? (
                      <img
                        loading="lazy"
                        alt={title}
                        src={image}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                          boxShadow: "0px 2px 16px rgba(10, 15, 13, 0.2)",
                        }}
                      />
                    ) : (
                      <Box
                        parse="w.126 h:74 oa:hidden br:10"
                        style={{
                          boxShadow: "0px 2px 16px rgba(10, 15, 13, 0.2)",
                        }}
                      >
                        <PlaceholderImage
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box>
                <Box css="d:flex a:center" bottom={10}>
                  <Rule
                    display="block"
                    rule="lm"
                    color="var(--font1)"
                    right={10}
                  >
                    {title}
                  </Rule>
                  {badge ? <Badge label={badge} size="small" /> : undefined}
                </Box>
                <Rule
                  display="block"
                  rule="lt"
                  color="var(--font3)"
                  style={
                    lines
                      ? {
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }
                      : {}
                  }
                >
                  {description}
                </Rule>
              </Box>
            </Box>
          </Box>
          <DesignedButton
            theme={theme}
            label={button}
            href={url}
            size="small"
            properties={
              column
                ? {
                    width: "100%",
                    display: "block",
                    align: "center",
                    justify: "center",
                    style: { justifyContent: "center" },
                  }
                : {}
            }
            container={
              column ? { style: { width: "100%", display: "block" } } : {}
            }
          />
        </Wrapper>
      )}
    />
  );
};

export default ImageCardsCallToAction;
