import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { FC, ReactNode, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface AccordionProps {
  title?: string;
  children: ReactNode;
  open?: boolean;
}

export const Accordion: FC<AccordionProps> = ({ title, children, open }) => {
  const [expand, _expand] = useState(!!open);
  const toggleExpand = () => _expand(!expand);

  return (
    <Box
      parse="pa:20 br:10 mt:4 mb:4"
      style={{
        border: "1px solid var(--accent)",
        cursor: "pointer",
      }}
      press={toggleExpand}
    >
      <Box parse="d:flex a:center j:space-between">
        <Rule parse="!ht">{title}</Rule>
        <DesignedIcon
          size={24}
          color="var(--font4)"
          name={expand ? "up/bold" : "down/bold"}
          press={toggleExpand}
        />
      </Box>

      {expand && <Box parse="mt:15">{children}</Box>}
    </Box>
  );
};

export default Accordion;
