import { Box } from "simple-effing-primitive-layout";

import Colors from "./Colors";
import Text from "./Text";

interface Props {
  min: number;
  max: number;
  value: number;
  change: (value: number) => void;
  blur?: () => void;
  label?: string;
}

const Range = ({ min, max, value, change, label, blur }: Props) => {
  return (
    <Box parse="w:100% mb:5 d:block">
      <Text rule="Label Small" color={Colors.font4} display="block">
        {label}
      </Text>
      <Box parse="h:16">
        <input
          className="--range"
          type="range"
          min={min}
          max={max}
          value={value}
          onBlur={() => {
            if (blur) {
              blur();
            }
          }}
          onChange={(event) => {
            if (change) {
              change(parseFloat(event.target.value) || value);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Range;
