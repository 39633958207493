import { array, object, string } from "yup";

import { AttachmentSchema } from "./attachmentSchema";
import { ShowBrandSchema } from "./showBrandSchema";

export const ShowSchema = object({
  id: string(),
  title: string(),
  slug: string(),
  showBrand: ShowBrandSchema,
  attachments: array().of(AttachmentSchema).nullable().optional().default(null),
});
