import { ShowData, VideoSet } from "libs/editor-ui/types";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Icon, Text } from "../../shared";
import CueUtils from "./CueUtils";
import DescriptionOptions from "./options/DescriptionOptions";
import EffectOptions from "./options/EffectOptions";
import FilterOptions from "./options/FilterOptions";
import LineOptions from "./options/LineOptions";
import MoveOptions from "./options/MoveOptions";
import PageOptions from "./options/PageOptions";
import TransitionOptions from "./options/TransitionOptions";

const CueOptions = ({
  enabled,
  sceneIndex,
  sceneCount,
  cue,
  actIndex,
  cueIndex,
  data,
  press,
  onCueDisable,
  onCueEnable,
  onCueDuplicate,
  onCueTeleport,
  onCueUpdate,
  thumbnail,
  loop,
  action,
}: {
  enabled?: boolean;
  sceneIndex: number;
  sceneCount?: number;
  cue: VideoSet;
  actIndex: number;
  cueIndex: number;
  data: ShowData;
  press: (props: {
    cue: VideoSet;
    actIndex: number;
    sceneIndex: number;
    cueIndex: number;
  }) => unknown;
  onCueDisable: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onCueEnable: (actIndex: number, sceneIndex: number, cueIndex: number) => void;
  onCueDuplicate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onCueTeleport: (
    teleportMode: string,
    oldActIndex: number,
    oldSceneIndex: number,
    oldCueIndex: number,
    newActIndex: number,
    newSceneIndex: number,
    newCueIndex: number
  ) => void;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  thumbnail?: string;
  loop: string | boolean;
  action: string | boolean;
}) => {
  const [optionsAreOpen, setOptionsAreOpen] = useState(false);
  const [moveOptionsAreOpen, setMoveOptionsAreOpen] = useState(false);
  const [lineOptionsAreOpen, setLineOptionsAreOpen] = useState(false);
  const [descriptionOptionsAreOpen, setDescriptionOptionsAreOpen] =
    useState(false);
  const [pageOptionsAreOpen, setPageOptionsAreOpen] = useState(false);
  const [filterOptionsAreOpen, setFilterOptionsAreOpen] = useState(false);
  const [effectOptionsAreOpen, setEffectOptionsAreOpen] = useState(false);
  const [transitionOptionsAreOpen, setTransitionOptionsAreOpen] =
    useState(false);

  const [popupTeleportMode, setPopupTeleportMode] = useState<string>("push");
  const [popupActIndex, setPopupActIndex] = useState<number>(0);
  const [popupSceneIndex, setPopupSceneIndex] = useState<number>(0);
  const [popupCueIndex, setPopupCueIndex] = useState<number>(0);

  const closeAllOtherOptions = () => {
    setMoveOptionsAreOpen(false);
    setLineOptionsAreOpen(false);
    setDescriptionOptionsAreOpen(false);
    setPageOptionsAreOpen(false);
    setFilterOptionsAreOpen(false);
    setTransitionOptionsAreOpen(false);
    setEffectOptionsAreOpen(false);
  };

  useEffect(() => {
    if (!optionsAreOpen) {
      closeAllOtherOptions();
      try {
        const elements = document?.getElementsByClassName("--draggable");
        for (let index = 0; index < elements.length; index++) {
          const element = elements[index];
          element?.setAttribute("draggable", "true");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const elements = document?.getElementsByClassName("--draggable");
        for (let index = 0; index < elements.length; index++) {
          const element = elements[index];
          element?.setAttribute("draggable", "false");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [optionsAreOpen]);

  const changes = CueUtils.anAdvancedChangeMadeToCue(cue);

  const OPTIONS = [
    {
      label: enabled ? "Disable" : "Enable",
      icon: enabled ? "hide" : "show",
      press: () => {
        if (enabled) {
          onCueDisable(actIndex, sceneIndex, cueIndex);
        } else {
          onCueEnable(actIndex, sceneIndex, cueIndex);
        }
      },
      hide: false,
    },
    {
      label: "Duplicate",
      icon: "duplicate",
      press: () => onCueDuplicate(actIndex, sceneIndex, cueIndex),
      hide: false,
    },
    {
      label: "Move",
      icon: "apps",
      press: () => {
        closeAllOtherOptions();
        setMoveOptionsAreOpen(true);
      },
      hide: false,
    },
    {
      label: "Edit Description",
      icon: "type",
      press: () => {
        closeAllOtherOptions();
        setDescriptionOptionsAreOpen(true);
      },
      hide: false,
    },
    {
      label: "Edit Page Number",
      icon: "type",
      press: () => {
        closeAllOtherOptions();
        setPageOptionsAreOpen(true);
      },
      hide: false,
    },
    {
      label: "Edit Line",
      icon: "type",
      press: () => {
        closeAllOtherOptions();
        setLineOptionsAreOpen(true);
      },
      hide: false,
    },
    {
      label: "Filters",
      icon: "picture",
      advanced: true,
      dictate: changes.includes("filter"),
      press: () => {
        closeAllOtherOptions();
        setFilterOptionsAreOpen(true);
      },
      hide: false,
    },
    {
      label: "Transitions",
      icon: "film",
      advanced: true,
      dictate: changes.includes("transition"),
      press: () => {
        closeAllOtherOptions();
        setTransitionOptionsAreOpen(true);
      },
      hide: false,
    },
    {
      label: "Effects",
      icon: "fade",
      advanced: true,
      dictate: changes.includes("effect"),
      press: () => {
        closeAllOtherOptions();
        setEffectOptionsAreOpen(true);
      },
      hide: false,
    },
  ];

  return (
    <>
      <Box
        parse="d:inline-flex a:center j:flex-end mw:16 mh:16 w:16 h:16 oa:visible o:visible p:relative"
        css={
          changes.length > 0
            ? undefined
            : !enabled || optionsAreOpen
            ? undefined
            : "--children"
        }
      >
        <Box
          css="--more"
          press={() => setOptionsAreOpen(!optionsAreOpen)}
          parse="br:5 mw:20 mh:20 w:20 h:20 d:inline-flex a:center j:center"
          color={Colors.accent}
          border="1px solid rgba(255,255,255,0.1)"
        >
          {!enabled ? (
            <Icon name="hide" size={12} color={Colors.font3} />
          ) : (
            <>
              <Box
                css="--more"
                parse="w:3 h:3 mr:2 br:999"
                color={Colors.font3}
              />
              <Box
                css="--more"
                parse="w:3 h:3 mr:2 br:999"
                color={Colors.font3}
              />
              <Box css="--more" parse="w:3 h:3 br:999" color={Colors.font3} />
            </>
          )}
        </Box>

        <div onMouseLeave={() => setOptionsAreOpen(false)}>
          <Box
            display={optionsAreOpen ? "block" : "none"}
            parse={
              "w:240 pl:15 pt:15 pr:15 pb:5 r:0 p:absolute br:5 z:4 i:4 " +
              (CueUtils.shouldPopupDisplayOnTop(sceneIndex, sceneCount || 0)
                ? "b:25"
                : "t:25")
            }
            layer={4}
            color={Colors.background}
          >
            {enabled ? (
              <Box
                parse="w:100% mb:10 d:flex a:center"
                css="--more"
                press={() => press({ cue, actIndex, sceneIndex, cueIndex })}
              >
                <Icon color={Colors.font4} name="next" size={16} />
                <Text left={10} color={Colors.font1} rule="Label Tiny">
                  Cue Next / Jump To
                </Text>
              </Box>
            ) : undefined}
            {OPTIONS.map((option, o) =>
              option.hide ? null : (
                <Box
                  key={o}
                  parse="w:100% mb:10 d:flex a:center j:space-between"
                  css="--more"
                  press={option.press}
                >
                  <Box parse="d:inline-flex a:center">
                    <Icon color={Colors.font4} name={option.icon} size={16} />
                    <Text left={10} color={Colors.font1} rule="Label Tiny">
                      {option.label}
                    </Text>
                    {option.advanced === true ? (
                      <Box
                        parse="d:inline-flex a:center j:center h:16 pl:5 pr:5 br:5 ml:10"
                        color={Colors.branding}
                      >
                        <Icon
                          color={Colors.background}
                          name="stageplayer"
                          size={9}
                        />
                        <Text
                          size={9}
                          left={2}
                          color={Colors.background}
                          weight="800"
                        >
                          ADVANCED
                        </Text>
                      </Box>
                    ) : undefined}
                  </Box>
                  {option.dictate ? (
                    <Box parse="w:6 h:6 br:999" color={Colors.positive} />
                  ) : undefined}
                </Box>
              )
            )}
          </Box>
          <MoveOptions
            setMoveOptionsAreOpen={setMoveOptionsAreOpen}
            moveOptionsAreOpen={moveOptionsAreOpen}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            popupTeleportMode={popupTeleportMode}
            setPopupTeleportMode={setPopupTeleportMode}
            data={data}
            popupActIndex={popupActIndex}
            setPopupActIndex={setPopupActIndex}
            popupSceneIndex={popupSceneIndex}
            setPopupSceneIndex={setPopupSceneIndex}
            popupCueIndex={popupCueIndex}
            setPopupCueIndex={setPopupCueIndex}
            setOptionsAreOpen={setOptionsAreOpen}
            onCueTeleport={onCueTeleport}
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
          />
          <LineOptions
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            setLineOptionsAreOpen={setLineOptionsAreOpen}
            lineOptionsAreOpen={lineOptionsAreOpen}
            onCueUpdate={onCueUpdate}
            cue={cue}
          />
          <DescriptionOptions
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            setDescriptionOptionsAreOpen={setDescriptionOptionsAreOpen}
            descriptionOptionsAreOpen={descriptionOptionsAreOpen}
            onCueUpdate={onCueUpdate}
            cue={cue}
          />
          <PageOptions
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            setPageOptionsAreOpen={setPageOptionsAreOpen}
            pageOptionsAreOpen={pageOptionsAreOpen}
            onCueUpdate={onCueUpdate}
            cue={cue}
          />
          <FilterOptions
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            setFilterOptionsAreOpen={setFilterOptionsAreOpen}
            filterOptionsAreOpen={filterOptionsAreOpen}
            onCueUpdate={onCueUpdate}
            cue={cue}
            thumbnail={thumbnail}
          />
          <TransitionOptions
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            setTransitionOptionsAreOpen={setTransitionOptionsAreOpen}
            transitionOptionsAreOpen={transitionOptionsAreOpen}
            onCueUpdate={onCueUpdate}
            cue={cue}
            loop={loop}
            action={action}
          />
          <EffectOptions
            actIndex={actIndex}
            sceneIndex={sceneIndex}
            cueIndex={cueIndex}
            shouldPopupDisplayOnTop={CueUtils.shouldPopupDisplayOnTop(
              sceneIndex,
              sceneCount || 0
            )}
            setEffectOptionsAreOpen={setEffectOptionsAreOpen}
            effectOptionsAreOpen={effectOptionsAreOpen}
            onCueUpdate={onCueUpdate}
            cue={cue}
          />
        </div>
      </Box>
    </>
  );
};

export default CueOptions;
