/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon, {
  AvailableIconName,
} from "../designed-icon/designed-icon";
import Rule from "../rule/rule";

type DesignedRadioSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    rule?: string;
    icon?: number;
  };
};

const Sizes: DesignedRadioSizes = {
  large: {
    height: 24,
    padding: 10,
    rule: "ls",
    icon: 12,
  },
  medium: {
    height: 20,
    padding: 10,
    rule: "lt",
    icon: 10,
  },
  small: {
    height: 16,
    padding: 7,
    rule: "lt",
    icon: 8,
  },
};

export interface DesignedRadioProps {
  icon?: AvailableIconName;
  label?: string | null | undefined;
  press?: () => void | null | undefined;
  properties?: any;
  active?: boolean;
  disable?: boolean;
  partial?: boolean;
  size?: string;
}

export const DesignedRadio = ({
  label = "",
  press,
  properties,
  active = false,
  disable = false,
  partial = false,
  size,
}: DesignedRadioProps) => {
  const _size = size ? Sizes[size] || Sizes["medium"] : Sizes["medium"];
  const color = disable ? Colors.font4 : Colors.font1;

  const [value, _value] = useState<boolean>(active);
  useEffect(() => {
    _value(active);
  }, [active]);

  return (
    <Box
      parse="d:inline-flex a:center"
      press={() => {
        _value(!value);
        if (press) {
          press();
        }
      }}
      {...properties}
    >
      <Box
        parse={`h:${_size.height} w:${_size.height} br:999 d:inline-flex a:center j:center`}
        color={
          disable
            ? Colors.foreground
            : value || partial
            ? Colors.primary
            : Colors.accent
        }
      >
        {value ? (
          <Box parse={`h:${_size.icon} w:${_size.icon} br:999`} color={color} />
        ) : partial ? (
          <DesignedIcon
            color={color}
            size={_size.icon || 16}
            name={partial ? "minus/filled" : "check/filled"}
          />
        ) : undefined}
      </Box>
      {label ? (
        <Rule
          style={{ whiteSpace: "nowrap" }}
          left={_size.padding}
          rule={_size.rule}
          color={color}
        >
          {label}
        </Rule>
      ) : undefined}
    </Box>
  );
};

export default DesignedRadio;
