import { ShowData } from "libs/editor-ui/types";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../../../shared";
import CuePopupSelect from "../CuePopupSelect";
import CueUtils from "../CueUtils";

const MoveOptions = ({
  setMoveOptionsAreOpen,
  moveOptionsAreOpen,
  shouldPopupDisplayOnTop,
  popupTeleportMode,
  setPopupTeleportMode,
  data,
  popupActIndex,
  setPopupActIndex,
  popupSceneIndex,
  setPopupSceneIndex,
  popupCueIndex,
  setPopupCueIndex,
  setOptionsAreOpen,
  onCueTeleport,
  actIndex,
  sceneIndex,
  cueIndex,
}: {
  setMoveOptionsAreOpen: (is: boolean) => unknown;
  moveOptionsAreOpen: boolean;
  shouldPopupDisplayOnTop: boolean;
  popupTeleportMode: string;
  setPopupTeleportMode: (m: string) => unknown;
  data: ShowData;
  popupActIndex: number;
  setPopupActIndex: (n: number) => unknown;
  popupSceneIndex: number;
  setPopupSceneIndex: (n: number) => unknown;
  popupCueIndex: number;
  setPopupCueIndex: (n: number) => unknown;
  setOptionsAreOpen: (is: boolean) => unknown;
  onCueTeleport: (
    teleportMode: string,
    oldActIndex: number,
    oldSceneIndex: number,
    oldCueIndex: number,
    newActIndex: number,
    newSceneIndex: number,
    newCueIndex: number
  ) => void;
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
}) => {
  return (
    <div onMouseLeave={() => setMoveOptionsAreOpen(false)}>
      <Box
        display={moveOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " + shouldPopupDisplayOnTop
            ? "b:25"
            : "t:25"
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          <Box parse="d:flex a:center mb:0">
            <Box parse="f:1 mr:5">
              <CuePopupSelect
                label="Before / After"
                value={popupTeleportMode}
                options={[
                  ["After", "push"],
                  ["Before", "append"],
                ]}
                change={(popupTeleportMode) =>
                  setPopupTeleportMode(popupTeleportMode as string)
                }
              />
            </Box>
            <Box parse="f:1 ml:5">
              <CuePopupSelect
                label="Act"
                value={popupActIndex}
                options={CueUtils.getActsNamesFromData(data)}
                change={(popupActIndex) =>
                  setPopupActIndex(Number(popupActIndex))
                }
              />
            </Box>
          </Box>

          <Box parse="d:flex a:center mb:10">
            <Box parse="f:1 mr:5">
              <CuePopupSelect
                label="Scene"
                value={popupSceneIndex}
                options={CueUtils.getSceneNamesFromData(data, popupActIndex)}
                change={(popupSceneIndex) =>
                  setPopupSceneIndex(Number(popupSceneIndex))
                }
              />
            </Box>
            <Box parse="f:1 ml:5">
              <CuePopupSelect
                label="Move"
                value={popupCueIndex}
                options={CueUtils.getCueNamesFromData(
                  data,
                  popupActIndex,
                  popupSceneIndex
                )}
                change={(popupCueIndex) =>
                  setPopupCueIndex(Number(popupCueIndex))
                }
              />
            </Box>
          </Box>

          <Box
            parse="pt:8 pb:8 pr:10 pl:10 br:5 d:inline-flex"
            color={Colors.entity}
            css="--more"
            press={() => {
              setOptionsAreOpen(false);

              if (onCueTeleport) {
                onCueTeleport(
                  popupTeleportMode,
                  actIndex,
                  sceneIndex,
                  cueIndex,
                  popupActIndex,
                  popupSceneIndex,
                  popupCueIndex
                );
              }
            }}
          >
            <Text color={Colors.font1} rule="Label Small">
              Move
            </Text>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MoveOptions;
