import { Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

export const SolutionsSearch = ({
  title,
  children,
  description,
  titleTag,
}: {
  title: string | JSX.Element | JSX.Element[];
  titleTag?: string;
  description?: string | JSX.Element | JSX.Element[];
  children: unknown;
}) => {
  return (
    <div className="container mx-auto max-w-7xl">
      <Box parse="pt:48 pb:48" css="p:relative i:1 z:1">
        <div className="container">
          {typeof title === "string" ? (
            <Rule parse="!hl d:block mb:24" tag={titleTag || "h2"}>
              {title}
            </Rule>
          ) : (
            title
          )}
          {typeof description === "string" ? (
            <Rule parse={`!pl d:block mb:24 c:?font3 w:100%`}>
              {description}
            </Rule>
          ) : (
            description
          )}
        </div>
        {children}
      </Box>
    </div>
  );
};

export default SolutionsSearch;
