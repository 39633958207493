import {
  DesignedIcon,
  DesignedSelect,
  LoadingIcon,
  Rule,
} from "@with-nx/simple-ui/atoms";
import Moment from "moment";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import CalendarInput from "../calendar-input/calendar-input";
import CalendarUtils from "./CalendarUtils";

type theme = "dark" | "light";

type ThemeStyle = {
  border: string;
  background: string;
  arrowColor: string;
  color: string;
  disabledColor?: string;
  active?: {
    background: string;
    color: string;
  };
  disabled?: {
    color: string;
    strike: boolean;
  };
};

const themes: Record<"dark" | "light", ThemeStyle> = {
  dark: {
    border: "1px dashed var(--accent)",
    background: "transparent",
    arrowColor: "rgb(89, 104, 123)",
    color: "var(--font3)",
  },
  light: {
    border: "none",
    background: "var(--grey-10)",
    arrowColor: "#0075FF",
    disabledColor: "#A5B3BF",
    color: "#222730",
    active: {
      background: "#0075FF",
      color: "#FFF",
    },
    disabled: {
      color: "#A5B3BF",
      strike: true,
    },
  },
};

const CalendarBox = ({
  children,
  ratio = 1,
  press,
  mode = "none",
  disabled,
  theme = "dark",
}: {
  children?: unknown;
  ratio?: number;
  active?: boolean;
  press?: () => void;
  mode?: "circle" | "middle" | "start" | "end" | "none";
  disabled?: boolean;
  theme?: theme;
}) => {
  const styles = themes[theme];

  return (
    <Box
      parse={`w:${ratio * 42} pt:42 p:relative`}
      press={press}
      style={{ minWidth: 42 }}
      position="relative"
    >
      {["middle"].includes(mode) ? (
        <Box parse="h:24 c:?accent w:100% p:absolute l:0 t:9 b:unset r:unset z:2 i:2"></Box>
      ) : undefined}
      {["start"].includes(mode) ? (
        <Box parse="h:24 c:?accent w:24 p:absolute l:24 t:9 b:unset r:unset z:2 i:2"></Box>
      ) : undefined}
      {["end"].includes(mode) ? (
        <Box parse="h:24 c:?accent w:24 p:absolute l:0 t:9 b:unset r:unset z:2 i:2"></Box>
      ) : undefined}
      {["start", "end", "circle"].includes(mode) ? (
        <Box
          parse={`h:32 c:${
            styles?.active?.background || "?font1"
          } br:999 w:32 p:absolute l:5 t:5 b:unset r:unset z:3 i:3`}
          mode="position"
          left={5}
        ></Box>
      ) : undefined}

      <Box
        parse="p:absolute w:100% h:100% t:0 l:0 d:flex a:center j:center z:4 i:4"
        opacity={disabled ? 0.25 : 1}
        style={{
          textDecoration:
            disabled && styles.disabled?.strike ? "line-through" : "none",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const CalendarRow = ({ children }: { children: unknown }) => {
  return <Box parse="p:relative d:flex">{children}</Box>;
};

interface CalendarProps {
  change?: (value: string[]) => void;
  value?: string[];
  range?: boolean;
  later?: boolean;
  allow?: number[];
  hideInputs?: boolean;
  startDateLabel?: string;
  endDateLabel?: string;
  error?: string;
  theme?: theme;
  unavailable?: (string | undefined)[];
  loading?: boolean;
  onCalendarChange?: (calendar: any) => void;
}

export const Calendar = ({
  change,
  value,
  range,
  allow,
  later,
  hideInputs = false,
  startDateLabel = "Start Date",
  endDateLabel = "End Date",
  error,
  theme = "dark",
  unavailable,
  loading,
  onCalendarChange,
}: CalendarProps) => {
  const [visible, _visible] = useState<string>(
    !!value && value?.length > 0 && value[0] !== ""
      ? Moment(value[0]).format("YYYY-MM-DD")
      : CalendarUtils.today()
  );

  const [dates, _dates] = useState<string[]>(value || [CalendarUtils.today()]);
  const styles = themes[theme];
  const calendar = CalendarUtils.calendar(visible);

  useEffect(() => {
    if (value?.[0] !== dates?.[0]) {
      _dates(value || []);
    }
  }, [value]);

  const isDisabled = (date: string, day: number) => {
    if (allow && !allow.includes(day)) {
      return true;
    }

    if (later && date <= Moment().format("YYYY-MM-DD")) {
      return true;
    }

    if (unavailable && unavailable.includes(date)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (onCalendarChange) {
      onCalendarChange(calendar);
    }
  }, [JSON.stringify(calendar)]);

  const isCurrentMonth = Moment().month() === Moment(visible).month();
  const disablePrevious = loading || (later && isCurrentMonth);

  return (
    <Box parse="d:flex fd:column">
      {range && !hideInputs ? (
        <>
          <Box parse="d:flex a:center mt:12">
            <Box parse="f:1 mr:5">
              <CalendarInput
                label={startDateLabel}
                value={dates?.[0]}
                size="small"
                change={(date) => {
                  const day = Moment(date).isoWeekday() - 1;
                  if (allow && !allow.includes(day)) {
                    return;
                  }
                  _dates([date, dates[1]]);
                }}
              />
            </Box>
            <Box parse="f:1 ml:5">
              <CalendarInput
                label={endDateLabel}
                value={dates?.[1]}
                size="small"
                change={(date) => {
                  const day = Moment(date).isoWeekday() - 1;
                  if (allow && !allow.includes(day)) {
                    return;
                  }
                  _dates([dates[0], date]);
                }}
              />
            </Box>
          </Box>

          {error && (
            <Rule
              display="block"
              rule="lt"
              parse="mb:12"
              color="var(--negative)"
            >
              {error}
            </Rule>
          )}
        </>
      ) : undefined}

      {theme === "light" && (
        <Box parse="d:flex j:center mb:10">
          <Box right={20}>
            <DesignedIcon
              name="left/bold"
              size={24}
              press={() => {
                if (disablePrevious) {
                  return;
                }

                _visible(
                  Moment(visible).subtract(1, "month").format("YYYY-MM-DD")
                );
              }}
              color={
                disablePrevious ? styles.disabled?.color : styles.arrowColor
              }
            />
          </Box>

          <Box>
            <Rule parse="!_bl c:var(--black-base)">
              {Moment(visible).format("MMMM")}
            </Rule>
          </Box>

          <Box left={20}>
            <DesignedIcon
              name="right/bold"
              size={24}
              press={() => {
                if (loading) {
                  return;
                }

                _visible(Moment(visible).add(1, "month").format("YYYY-MM-DD"));
              }}
              color={loading ? styles.disabled?.color : styles.arrowColor}
            />
          </Box>
        </Box>
      )}

      <Box parse="d:inline-flex p:relative">
        {loading && (
          <Box
            parse="p:absolute w:100% h:100% t:0 l:unset c:var(--font-4) d:flex a:center j:center br:10 z:999"
            style={{
              background: "#FFF",
              opacity: 0.7,
            }}
          >
            <LoadingIcon width="24px" height="24px" />
          </Box>
        )}

        <Box
          parse="pa:10 br:10"
          border={styles.border}
          color={styles.background}
        >
          {theme === "dark" && (
            <CalendarRow>
              <CalendarBox>
                <DesignedIcon
                  name="left/bold"
                  size={16}
                  press={() => {
                    _visible(
                      Moment(visible).subtract(1, "month").format("YYYY-MM-DD")
                    );
                  }}
                />
              </CalendarBox>
              <CalendarBox ratio={5}>
                <DesignedSelect
                  size="small"
                  minimalistic={true}
                  label="Month"
                  value={CalendarUtils.month(visible)}
                  options={CalendarUtils.months()}
                  change={(month: string) => {
                    const mask: string = Moment(visible).format("YYYY-@-DD");
                    _visible(mask.replace("@", month.padStart(2, "0")));
                  }}
                />
                <DesignedSelect
                  size="small"
                  minimalistic={true}
                  label="Year"
                  value={CalendarUtils.year(visible)}
                  options={CalendarUtils.years()}
                  change={(year: string) => {
                    const mask: string = Moment(visible).format("@-MM-DD");
                    _visible(mask.replace("@", year));
                  }}
                />
              </CalendarBox>
              <CalendarBox>
                <DesignedIcon
                  name="right/bold"
                  size={16}
                  press={() => {
                    _visible(
                      Moment(visible).add(1, "month").format("YYYY-MM-DD")
                    );
                  }}
                />
              </CalendarBox>
            </CalendarRow>
          )}

          <CalendarRow>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                Mo
              </Rule>
            </CalendarBox>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                Tu
              </Rule>
            </CalendarBox>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                We
              </Rule>
            </CalendarBox>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                Th
              </Rule>
            </CalendarBox>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                Fr
              </Rule>
            </CalendarBox>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                Sa
              </Rule>
            </CalendarBox>
            <CalendarBox>
              <Rule rule="lt" color="var(--font4)">
                Su
              </Rule>
            </CalendarBox>
          </CalendarRow>

          {calendar.map((row, r) => (
            <CalendarRow key={r}>
              {row.map((day, d) =>
                day ? (
                  <CalendarBox
                    key={`calendar-box-${day.date}`}
                    active={CalendarUtils.active(day.date, dates)}
                    mode={CalendarUtils.mode(day.date, dates)}
                    disabled={isDisabled(day.date, d)}
                    press={() => {
                      if (isDisabled(day.date, d)) {
                        return;
                      }

                      const newDate = day.date;

                      if (range) {
                        const start = value?.[0];
                        const end = value?.[1];

                        if (!start) {
                          change?.([newDate]);
                        } else if (start && end) {
                          change?.([newDate, ""]);
                        } else {
                          change?.([start || "", newDate]);
                        }
                      } else {
                        change?.([newDate]);
                      }
                    }}
                    theme={theme}
                  >
                    <Rule
                      rule="lt"
                      color={
                        !["none", "middle"].includes(
                          CalendarUtils.mode(day.date, dates)
                        )
                          ? styles?.active?.color || "var(--background)"
                          : styles.color
                      }
                    >
                      {day.day}
                    </Rule>
                  </CalendarBox>
                ) : (
                  <CalendarBox />
                )
              )}
            </CalendarRow>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
