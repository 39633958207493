import { useEffect, useState } from "react";

export function useWindowSize(initial?: { width?: number; height?: number }) {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: initial?.width,
    height: initial?.height,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { ...windowSize, ready: Boolean(windowSize?.width) };
}
