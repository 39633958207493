import { Ecommerce } from "@with-nx/ecommerce";
import Moment from "moment";

import { StageGear, StageGearProduct } from "./types/stage-gear";

export class StageGearHelpers {
  static product(cart: Ecommerce.Cart.State, id: string | number) {
    const product = cart.data?.nonLicensedItems?.find(
      (item) => String(item.product.id) === String(id)
    );

    if (!product) {
      return null;
    }

    return {
      id: product?.product?.id,
      start: product?.rentalStart,
      end: product?.rentalEnd,
      purchasable: product?.product?.gearResource?.isPurchasable,
    } as StageGear.Helpers.Product;
  }

  static information(cart: Ecommerce.Cart.State, id: string | number) {
    const product = cart.data?.nonLicensedItems?.find(
      (item) => String(item.product.id) === String(id)
    );

    if (!product) {
      return null;
    }

    return {
      id: product?.product?.id,
      name: product?.product?.gearResource?.name,
      description: product?.product?.gearResource?.category?.name,
      price: Number(product?.subtotal?.products),
      start: product?.rentalStart,
      end: product?.rentalEnd,
      purchasable: product?.product?.gearResource?.isPurchasable,
      thumbnail: product?.product.gearResource?.attachments?.find(
        (a) => a.name === "image_thumbnail"
      )?.blob?.signedUrl,
      resource: product?.product?.gearResource,
    } as StageGear.Helpers.Information;
  }

  static allProductsInformation(cart: Ecommerce.Cart.State) {
    const products = cart.data?.nonLicensedItems;

    if (!products?.length) {
      return null;
    }

    return products.map(
      (product) =>
        ({
          id: product?.product?.id,
          name: product?.product?.gearResource?.name,
          description: product?.product?.gearResource?.category?.name,
          price: Number(product?.subtotal?.products),
          start: product?.rentalStart,
          end: product?.rentalEnd,
          purchasable: product?.product?.gearResource?.isPurchasable,
          thumbnail: product?.product.gearResource?.attachments?.find(
            (a) => a.name === "image_thumbnail"
          )?.blob?.signedUrl,
          resource: product?.product?.gearResource,
        } as StageGear.Helpers.Information)
    );
  }

  static products(cart: Ecommerce.Cart.State) {
    const products = cart.data?.nonLicensedItems?.filter(
      (item) => item.product.type === "STAGE_GEAR"
    );

    return products?.map((product) => ({
      id: product?.product?.id,
      start: product?.rentalStart,
      end: product?.rentalEnd,
      purchasable: product?.product?.gearResource?.isPurchasable,
    })) as StageGear.Helpers.Product[];
  }

  static meta(product: StageGearProduct) {
    /* Example Product (Variant, Variant) */

    const groups: [string, string][] = [];
    const variants: {
      [key: string]: string;
    } = {};

    const name = product?.name;

    product?.variationInformation?.map((info) => {
      groups.push([String(info?.name), String(info?.value)]);
      variants[String(info?.name)] = String(info?.value);
    });

    const individual = groups.length <= 1;

    return {
      name: name?.split("(")?.[0]?.trim() || name,
      variants,
      groups,
      additional: groups?.map((g) => g[1])?.join(", "),
      individual,
    };
  }

  static get purchasable() {
    return {
      ideal: (() => {
        /**
         * Calculate the closest monday that is at least 3 months away from now.
         * And to allow customer to put their cart on hold.
         */

        const now = Moment().add(3, "months");
        const monday = now.clone().day(1);
        return {
          start: monday.format("YYYY-MM-DD"),
          end: monday.clone().add(1, "day").format("YYYY-MM-DD"),
        };
      })(),
    };
  }
}
