import { Box } from "simple-effing-primitive-layout";

import { Colors, Icon, Text } from "./";

type Theme = {
  color: string;
  background: string;
  border: string;
};

type Themes = {
  [key: string]: Theme;
};

const themes: Themes = {
  branding: {
    color: Colors.font1,
    background: Colors.branding,
    border: "none",
  },
  general: {
    color: Colors.font4,
    background: Colors.accent,
    border: "none",
  },
  foreground: {
    color: Colors.font1,
    background: Colors.entity,
    border: "none",
  },
  positive: {
    color: Colors.font1,
    background: Colors.positive,
    border: "none",
  },
  negative: {
    color: Colors.font1,
    background: Colors.negative,
    border: "none",
  },
  warning: {
    color: Colors.font1,
    background: Colors.warning,
    border: "none",
  },
  ghost: {
    color: Colors.font4,
    background: "transparent",
    border: "2px solid #2C2C2C",
  },
  borderless: {
    color: Colors.font4,
    background: "transparent",
    border: "none",
  },
  disable: {
    color: Colors.accent,
    background: Colors.foreground,
    border: "none",
  },
  tetriary: {
    color: Colors.branding,
    background: "transparent",
    border: "2px solid " + Colors.branding,
  },
  bordered: {
    color: Colors.font3,
    background: "transparent",
    border: "2px solid " + Colors.accent,
  },
};

interface Props {
  icon?: string | null | undefined;
  label?: string | null | undefined;
  theme?: string;
  press?: () => void | null | undefined;
  properties?: {
    [key: string]: unknown;
  };
  disable?: boolean;
  size?: string;
}

const Button = ({
  icon,
  label = "",
  theme = "general",
  press,
  properties,
  disable = false,
  size,
}: Props) => {
  const _theme = disable
    ? themes["disable"]
    : themes[theme] || themes["general"];

  return (
    <Box
      parse={
        size === "small"
          ? "h:32 pr:6 pl:6 br:5 d:inline-flex a:center j:flex-start"
          : "h:32 pr:10 pl:10 br:5 d:inline-flex a:center j:flex-start"
      }
      color={_theme.background}
      border={_theme.border}
      press={!disable ? press : undefined}
      {...properties}
    >
      {icon ? <Icon size={16} color={_theme.color} name={icon} /> : undefined}
      <Text
        style={{ whiteSpace: "nowrap" }}
        left={icon ? 10 : 0}
        rule={size === "small" ? "Label Tiny" : "Label Small"}
        color={_theme.color}
      >
        {label}
      </Text>
    </Box>
  );
};

export default Button;
