import { Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { ReactNode } from "react";

import ReusableSlantedContainer from "../reusable-slanted-container/reusable-slanted-container";

export const ReusableContainerSlantedCallToAction = ({
  gradients = "none",
  title,
  description,
  children,
  justify,
  index,
  md = 18,
  lg = 10,
  bottom = 196,
  top,
  slanted = true,
}: {
  gradients?: "none" | "green" | "blue" | "yellow" | "purple";
  title?: string;
  description?: string;
  children?: ReactNode;
  justify?:
    | "center"
    | "space-around"
    | "end"
    | "space-evenly"
    | "start"
    | "space-between";
  index?: number | string;
  md?: number;
  lg?: number;
  bottom?: number;
  top?: number;
  slanted?: boolean;
}) => {
  return (
    <ReusableSlantedContainer
      bottom={bottom}
      gradients={(() => {
        switch (gradients) {
          case "green":
            return [
              "linear-gradient(102.84deg, rgba(172, 226, 118, 0.2) 38.06%, rgba(115, 212, 255, 0.2) 82.92%)",
              "linear-gradient(102.84deg, rgba(172, 226, 118, 0.2) 38.06%, rgba(115, 212, 255, 0.2) 82.92%)",
            ];
          case "yellow":
            return [
              "linear-gradient(102.84deg, rgba(226, 181, 118, 0.4) 38.06%, rgba(250, 219, 176, 0.4) 82.92%)",
              "linear-gradient(102.84deg, rgba(226, 181, 118, 0.4) 38.06%, rgba(250, 219, 176, 0.4) 82.92%)",
            ];
          case "purple":
            return [
              "linear-gradient(102.84deg, rgba(255, 0, 149, 0.4) 38.06%, rgba(115, 212, 255, 0.4) 82.92%)",
              "linear-gradient(102.84deg, rgba(255, 0, 149, 0.4) 38.06%, rgba(115, 212, 255, 0.4) 82.92%)",
            ];

          case "blue":
            return [
              "linear-gradient(to right, rgba(115, 212, 255, 0.8) 38.06%, rgba(115, 137, 253, 0.9) 82.92%)",
              "linear-gradient(to left, rgba(115, 212, 255, 0.8) 38.06%, rgba(115, 137, 253, 0.9) 82.92%)",
            ];

          default:
            return [];
        }
      })()}
      index={index}
      slanted={slanted}
      top={top}
    >
      <div className="container">
        <Row align="middle" justify={justify}>
          <Col xs={24} sm={24} md={md} lg={lg}>
            {title ? (
              <Rule
                parse="!ds d:block"
                display="block"
                bottom={12}
                style={(() => {
                  switch (gradients) {
                    case "green":
                      return {
                        background:
                          "linear-gradient(125.95deg, #ACE276 4.36%, #87E2FF 105.47%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        color: "transparent",
                        textFillColor: "transparent",
                      };

                    case "blue":
                      return {
                        background:
                          "linear-gradient(125.95deg, #00FFFF 4.36%, #D2F4FF 105.47%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        color: "transparent",
                        textFillColor: "transparent",
                      };

                    case "yellow":
                      return {
                        background:
                          "linear-gradient(125.95deg, #E2B576 4.36%, #FFF5E8 105.47%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        color: "transparent",
                        textFillColor: "transparent",
                      };

                    case "purple":
                      return {
                        background:
                          "linear-gradient(125.95deg, #FF0095 4.36%, #73FFF0 105.47%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        color: "transparent",
                        textFillColor: "transparent",
                      };

                    default:
                      return {};
                  }
                })()}
              >
                {title}
              </Rule>
            ) : undefined}
            {description ? (
              <Rule parse="!ps" display="block" bottom={12}>
                {description}
              </Rule>
            ) : undefined}
            {children}
          </Col>
        </Row>
      </div>
    </ReusableSlantedContainer>
  );
};

export default ReusableContainerSlantedCallToAction;
