import { useEffect, useState } from "react";

import Formatter from "./Formatter";

export const useDebug = (gup = "debug") => {
  const [debug, _debug] = useState<boolean>(false);

  useEffect(() => {
    _debug(Boolean(Formatter.gup(gup)));
  }, []);

  return debug;
};

export default useDebug;
