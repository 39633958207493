/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export interface EditorCardProps {
  id?: number | string;
  children?: React.ReactNode;
  index: number;
  move: (dragIndex: number, hoverIndex: number) => void;
  type: string;
  render?: () => React.ReactNode;
}

const EditorCard = ({
  id,
  children,
  index,
  move,
  type,
  render,
}: EditorCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (
        item as {
          index: number;
        }
      ).index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset?.y! - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      move(dragIndex, hoverIndex);
      (
        item as {
          index: number;
        }
      ).index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const props = {
    "data-handler-id": handlerId,
  };

  return (
    <div
      ref={ref}
      style={{ opacity }}
      {...(render ? {} : props)}
      className="--draggable"
    >
      {render ? render() : children}
    </div>
  );
};

export default EditorCard;
