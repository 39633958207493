export const getWeeksLabelFromDays = (rentDays?: number) => {
  if (!rentDays) return 0;

  const weeks = Math.floor(rentDays / 7);

  return getWeeksLabel(weeks);
};

export const getWeeksLabel = (weeks: number) => {
  if (weeks > 0) {
    return `${weeks > 1 ? weeks : ""} week${weeks > 1 ? "s" : ""}`;
  }

  return "";
};
