/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DesignedButton,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import React from "react";
import { Box } from "simple-effing-primitive-layout";

import ReusableMockup from "../reusable-mockup/reusable-mockup";

export class StagePlayerInAction extends React.PureComponent<
  {
    video?: string;
    title?: string;
    titleTag?: string;
    subtitle?: string;
    isMobile?: boolean;
  },
  {
    componentYPosition: number;
    scrollYPosition: number;
    windowHeight: number;
    progress: number;
    calendly: boolean;
  }
> {
  private reference: HTMLVideoElement | null = null;
  private container: HTMLDivElement | null = null;

  constructor(props: any) {
    super(props);
    this.listener = this.listener.bind(this);

    this.state = {
      componentYPosition: 0,
      scrollYPosition: 0,
      windowHeight: 0,
      progress: 0,
      calendly: false,
    };
  }

  listener = () => {
    if (this.reference) {
      const componentYPosition =
        this.container?.getBoundingClientRect().top || 0;
      const scrollYPosition = window.document.body.scrollTop || 0;
      const windowHeight = window.innerHeight || 0;

      let progress = 0;

      if (componentYPosition < 0) {
        progress = (Math.abs(componentYPosition) / (windowHeight * 2)) * 100;
      }

      progress = progress < 0 ? 0 : progress > 100 ? 100 : progress;

      this.setState({
        progress,
        componentYPosition,
        scrollYPosition,
        windowHeight,
      });

      /**
       * @comment
       * Reverted back to the previous loop behaviour due to Quentin's request.
       * const totalVideoDuration = this.reference.duration;
       * this.reference.currentTime = (progress * totalVideoDuration) / 100;
       */
    }
  };

  override componentDidMount() {
    if (window) {
      window.document.body.addEventListener("scroll", this.listener);

      this.setState({
        componentYPosition: this.container?.getBoundingClientRect().top || 0,
        scrollYPosition: window.document.body.scrollTop,
        windowHeight: window.innerHeight,
      });
    }
  }

  override componentWillUnmount(): void {
    if (window) {
      window.document.body.removeEventListener("scroll", this.listener);
    }
  }

  override render() {
    return (
      <div
        ref={(container) => (this.container = container)}
        style={{
          height: (() => {
            /**
             * @comment
             * Reverted back due to Quentin's request.
             * return "300vh"
             */
            return undefined;
          })(),
          position: "relative",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
          }}
        >
          <ReusableSection
            top={0}
            bottom={0}
            properties={{
              style: {
                position: "relative",
                paddingTop: 128,
                paddingBottom: 64,
                backgroundImage: `url(/assets/section/stage-player-desktop-background.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              },
            }}
          >
            <div
              className="container"
              style={{ position: "relative", zIndex: 2 }}
            >
              <Row gutter={[36, 18]} align="middle">
                <Col xs={24}>
                  <Box
                    parse={`d:flex a:center j:space-between fd:${
                      this.props.isMobile ? "column-reverse" : "row"
                    }`}
                  >
                    <Box>
                      <Rule
                        parse="!dt d:block mb:4 mr:32"
                        tag={this.props.titleTag || "h6"}
                      >
                        {this.props.title ||
                          "Easy-to-Use StagePlayer Included on Every Order"}
                      </Rule>
                      {this.props.subtitle && (
                        <Rule parse="!ll d:block mb:12">
                          {this.props.subtitle}
                        </Rule>
                      )}
                    </Box>

                    <img
                      width={200}
                      height={56}
                      src="/assets/logo/logo-stage-player.svg"
                      alt="StagePlayer"
                      loading="lazy"
                      style={{ marginRight: "32px" }}
                    />
                  </Box>
                </Col>
                {}
                <Col xs={24} sm={24} md={12}>
                  <ReusableMockup
                    autoplay={true}
                    video={
                      this.props.video ||
                      "/assets/video/stage-player-in-action.mp4"
                    }
                    reference={(reference: HTMLVideoElement) =>
                      (this.reference = reference)
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Rule parse="!ht d:block mb:24 c:?font3" tag="p">
                    StagePlayer In Action
                  </Rule>
                  <Rule parse="!pl d:block mb:24 c:?font1">
                    Designed to help you perform anywhere, StagePlayer was
                    created for Theatre-Makers by Theatre-Makers. Boasting
                    minimal system requirements, and compatible with both Mac
                    and Windows, StagePlayer was developed for the uncertainty
                    of live productions. Offering a simple design with intuitive
                    and easily controlled playback, it&apos;s now easier than
                    ever to implement digital scenery into your production.
                  </Rule>
                  <DesignedButton
                    size="medium"
                    label="Request a Demo"
                    press={() =>
                      this.setState({ calendly: !this.state.calendly })
                    }
                  />
                </Col>
              </Row>
            </div>
            <Box
              parse="p:absolute t:0 l:0 w:100% h:100% z:1 i:1"
              style={{
                backgroundImage:
                  "linear-gradient(180.03deg, #0E1216 -0.38%, rgba(15, 17, 21, 0) 17.9%), linear-gradient(180.03deg, #0E1216 -0.38%, rgba(15, 17, 21, 0) 17.9%, rgba(15, 17, 21, 0) 85.34%, #0F1115 99.98%)",
              }}
            />
          </ReusableSection>
        </div>
      </div>
    );
  }
}

export default StagePlayerInAction;
