import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";
import { Step } from "./stepper";

export interface StepperIndicatorsProps {
  steps: Step[];
  current: number;
}

export const StepperIndicators: FC<StepperIndicatorsProps> = ({
  steps,
  current,
}) => (
  <Box
    parse="d:flex j:center w:100% mt:auto"
    style={{
      columnGap: "15px",
    }}
  >
    {steps.map((_step, index) => {
      const isCurrent = index === current;

      return (
        <Box
          key={`step-indicator-${index}`}
          parse="h:10 w:10 br:50%"
          color={isCurrent ? "#91969D" : "rgba(217, 217, 217, 1)"}
        />
      );
    })}
  </Box>
);
