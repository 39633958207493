import "./component-gallery.module.css";

import { useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedPagination } from "@with-nx/simple-ui/atoms";
import { createRef, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ComponentGalleryProps {
  items: JSX.Element[];
  per: number;
}

function calculateImageWidthPerPage(perPage: number, totalWidth: number) {
  const displayAmountOfImagesAsOverflow = 2;
  const totalImagesToDisplayPerPage = perPage + displayAmountOfImagesAsOverflow;
  return totalWidth / (totalImagesToDisplayPerPage - 1);
}

export const ComponentGallery = (props: ComponentGalleryProps) => {
  const { items, per } = props;
  const [width, _width] = useState<number>(0);
  const [current, _current] = useState<number>(0);
  const [pages] = useState<number>(items.length - 1);

  const ref = createRef<HTMLDivElement>();
  const mobile = useMobile();

  useEffect(() => {
    if (ref.current) {
      const _w = ref.current.offsetWidth;
      const calculate = calculateImageWidthPerPage(mobile ? 1 : per, _w);
      _width(calculate);
    }
  }, [width, ref.current, mobile]);

  const MARGIN_SIZE = mobile ? 8 : 36;

  return (
    <Box>
      <div
        className="--gallery --no-scrollbar"
        ref={ref}
        onScroll={(event) => {
          const round = Math.round(event.currentTarget.scrollLeft / width);
          _current(round);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          marginLeft: -(MARGIN_SIZE / 2),
          marginRight: -(MARGIN_SIZE / 2),
        }}
      >
        <Box style={{ minWidth: width / 2 }} width={width / 2}></Box>
        {items.map((item, i) => {
          return (
            <Box
              key={i}
              parse={`pl:${MARGIN_SIZE / 2} pr:${MARGIN_SIZE / 2}`}
              style={{ minWidth: width }}
              width={width}
            >
              {item}
            </Box>
          );
        })}
        <Box style={{ minWidth: width / 2 }} width={width / 2}></Box>
      </div>
      <Box parse="mt:24" css="d:flex a:center j:center">
        <DesignedPagination
          press={(value) => {
            if (ref.current) {
              ref.current.scrollTo({ left: value * width, behavior: "smooth" });
            }
          }}
          mode="lines"
          size={pages}
          value={current}
        />
      </Box>
    </Box>
  );
};

export default ComponentGallery;
