/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from "framer-motion";
import React from "react";

export class AutoScroller extends React.PureComponent<
  {
    children: JSX.Element | JSX.Element[];
    duration?: number;
    paddings?: number;
    top?: number;
    manual?: boolean;
    background?: string;
  },
  {
    reference: HTMLDivElement | null;
    animation: HTMLDivElement | null;
    hover: boolean;
    position: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      reference: null,
      animation: null,
      hover: false,
      position: 0,
    };
  }

  override render() {
    const count = React.Children.count(this.props.children);
    const paddings = this.props.paddings || 50;

    return (
      <div
        key="slider"
        id="slider"
        className="xxl:pb-16"
        style={{
          paddingTop: this.props.top === undefined ? 32 : this.props.top,
          display: "flex",
          alignItems: "center",
          width: "calc(100%)",
          paddingLeft: "10px",
          overflowX: this.props.manual ? "auto" : "hidden",
          marginBottom: "-2rem",
          maskImage:
            "linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) calc(100% - 20%), rgba(255,255,255,0)",
          WebkitMaskImage:
            "linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) calc(100% - 20%), rgba(255,255,255,0)",
        }}
        ref={(ref) => this.setState({ reference: ref })}
      >
        <motion.div
          ref={(ref) => this.setState({ animation: ref })}
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() =>
            this.setState({
              hover: false,
              position: this?.state?.reference?.scrollLeft || 0,
            })
          }
          animate={
            this.props.manual
              ? undefined
              : this.state.reference && !this.state.hover
              ? {
                  x: -(
                    (this.state.reference?.scrollWidth || 0) -
                    (this.state.reference?.clientWidth || 0)
                  ),
                }
              : undefined
          }
          initial={{ x: 0 }}
          whileHover={{
            transition: {
              duration: 1,
            },
          }}
          transition={{
            delay: 0.1,
            duration: this.props.duration || count * 2 || 15,
            repeat: Infinity,
            repeatType: "mirror",
            repeatDelay: 0,
            type: "tween",
            ease: "linear",
          }}
          className="space-x-4 md:space-x-6 xl:space-x-8"
          style={{ display: "inline-flex", background: this.props.background }}
        >
          <div style={{ width: paddings }} />
          {this.props.children}
          <div style={{ width: paddings }} />
        </motion.div>
      </div>
    );
  }
}

export default AutoScroller;
