import { number, object, string } from "yup";

export const LicensorSchema = object({
  id: number(),
  name: string(),
  abbreviation: string().optional().nullable(),
});

export const ProductLicensorSchema = object({
  licensor: object({
    id: number(),
    name: string(),
    abbreviation: string().optional().nullable(),
  }),
  region: object({
    id: number(),
  })
    .optional()
    .nullable(),
});
