import { Filters, VideoSet } from "libs/editor-ui/types";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors } from "../../../shared";
import Button from "../../../shared/Button";
import Range from "../../../shared/Range";
import CuePopupInput from "../CuePopupInput";

const Option = ({
  filters,
  option,
  setFilters,
  min = 0,
  max = 100,
  label,
  change,
}: {
  filters: Filters;
  option: keyof Filters;
  setFilters: (filters: Filters) => unknown;
  min?: number;
  max?: number;
  label: string;
  change: () => void;
}) => (
  <Box parse="d:flex a:flex-end">
    <Box parse="f:1">
      <Range
        label=""
        min={min}
        max={max}
        value={filters[option]}
        change={(value) => {
          const temporary = { ...filters };
          temporary[option] = value;
          setFilters(temporary);
        }}
        blur={change}
      />
    </Box>
    <Box parse="w:50 ml:10">
      <CuePopupInput
        label={label}
        value={filters[option].toString()}
        change={(value) => {
          const temporary = { ...filters };
          temporary[option] = value ? parseInt(value as string) : 0;
          setFilters(temporary);
          change();
        }}
        input={{ type: "number", min, max }}
        blur={change}
      />
    </Box>
  </Box>
);

const FilterOptions = ({
  shouldPopupDisplayOnTop,
  setFilterOptionsAreOpen,
  filterOptionsAreOpen,
  onCueUpdate,
  cue,
  thumbnail,
  actIndex,
  sceneIndex,
  cueIndex,
}: {
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  shouldPopupDisplayOnTop: boolean;
  setFilterOptionsAreOpen: (is: boolean) => unknown;
  filterOptionsAreOpen: boolean;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  cue: VideoSet;
  thumbnail?: string;
}) => {
  const [filters, setFilters] = useState<{
    grayscale: number;
    brightness: number;
    contrast: number;
    opacity: number;
    saturate: number;
    "hue-rotate": number;
  }>({
    grayscale: 0,
    brightness: 100,
    contrast: 100,
    opacity: 100,
    saturate: 100,
    "hue-rotate": 0,
  });

  useEffect(() => {
    const temporary = {
      grayscale: cue?.filters?.grayscale || 0,
      brightness: cue?.filters?.brightness || 100,
      contrast: cue?.filters?.contrast || 100,
      opacity: cue?.filters?.opacity || 100,
      saturate: cue?.filters?.saturate || 100,
      "hue-rotate": cue?.filters?.["hue-rotate"] || 0,
    };
    setFilters(temporary);
  }, [filterOptionsAreOpen]);

  const update = () => {
    onCueUpdate(actIndex, sceneIndex, cueIndex, {
      ...cue,
      filters,
    });
  };

  const css = `grayscale(${filters.grayscale}%) brightness(${filters.brightness}%) contrast(${filters.contrast}%) opacity(${filters.opacity}%) saturate(${filters.saturate}%) hue-rotate(${filters["hue-rotate"]}deg)`;

  return (
    <div onMouseLeave={() => setFilterOptionsAreOpen(false)}>
      <Box
        display={filterOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          {typeof thumbnail === "string" ? (
            <img
              src={thumbnail}
              style={{
                display: "block",
                width: "100%",
                height: 168.75,
                borderRadius: 4,
                border: "1px solid rgba(255,255,255,0.1)",
                marginBottom: 15,
                filter: css,
              }}
              loading="lazy"
            />
          ) : undefined}
          <Option
            label="Grayscale"
            option="grayscale"
            filters={filters}
            setFilters={setFilters}
            change={update}
          />
          <Option
            label="Brightness"
            option="brightness"
            filters={filters}
            setFilters={setFilters}
            change={update}
            max={200}
          />
          <Option
            label="Contrast"
            option="contrast"
            filters={filters}
            setFilters={setFilters}
            change={update}
            max={200}
          />
          <Option
            label="Opacity"
            option="opacity"
            filters={filters}
            setFilters={setFilters}
            change={update}
          />
          <Option
            label="Saturate"
            option="saturate"
            filters={filters}
            setFilters={setFilters}
            change={update}
            max={200}
          />
          <Option
            label="Hue"
            option="hue-rotate"
            filters={filters}
            setFilters={setFilters}
            change={update}
            min={0}
            max={360}
          />
          <Button
            properties={{ top: 10 }}
            size="small"
            theme="primary"
            label="Reset"
            press={() =>
              onCueUpdate(actIndex, sceneIndex, cueIndex, {
                ...cue,
                filters: undefined,
              })
            }
          />
        </Box>
      </Box>
    </div>
  );
};

export default FilterOptions;
