import { FC, ReactNode } from "react";

interface ModalHeaderProps {
  children?: ReactNode;
}

export const ModalHeader: FC<ModalHeaderProps> = ({ children }) => (
  <header
    style={{
      flex: 0,
      color: "var(--font1)",
      paddingInlineStart: "var(--modal-space-6)",
      paddingInlineEnd: "var(--modal-space-4)",
      paddingTop: "var(--modal-space-4)",
      paddingBottom: "var(--modal-space-4)",
      fontSize: "var(--modal-font-size-xl)",
      fontWeight: "600",
    }}
  >
    {children}
  </header>
);
