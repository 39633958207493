import {
  Formatter,
  useLicensorImage,
  useMobile,
} from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

import ReusableGradientHero from "../reusable-gradient-hero/reusable-gradient-hero";

const SolutionsHeroSponsor = ({
  sponsor,
  label,
}: {
  sponsor?: string;
  label?: string;
}) => {
  const image = useLicensorImage(sponsor, sponsor);

  if (image) {
    return (
      <Box css="d:inline-flex fd:column a:center h:auto" parse="w:256 mb:16">
        {label ? <Rule parse="!lt d:block mb:8px">{label}</Rule> : undefined}
        <img
          src={Formatter.image(image?.image)}
          alt={label}
          style={{
            width: 256,
            height: "auto",
            display: "block",
            pointerEvents: "none",
          }}
          loading="lazy"
        />
      </Box>
    );
  }

  return null;
};

export const SolutionsHero = ({
  title,
  logo,
  description,
  buttons,
  src,
  slanted,
  sponsors,
  right,
  bottom,
}: {
  logo?: string;
  src?: string;
  title: string;
  description: string;
  buttons: (
    | "request-information"
    | "schedule-a-call"
    | "schedule-a-call-with-production-specialist"
  )[];
  right?: ReactNode;
  bottom?: ReactNode;
  slanted?: boolean;
  sponsors?: string[];
}) => {
  const mobile = useMobile();
  const router = useRouter();

  return (
    <ReusableGradientHero
      slanted={slanted}
      src={src}
      backdrop={
        "/assets/libs/simple-ui/solutions-components/assets/solutions-gradient.webp"
      }
      right={
        right ? (
          right
        ) : sponsors && !mobile ? (
          <Box css="d:flex fd:column a:flex-end">
            {sponsors.map((sponsor, s) => (
              <SolutionsHeroSponsor
                label={sponsors.length === 1 ? "Proud Sponsor of" : undefined}
                key={s}
                sponsor={sponsor}
              />
            ))}
          </Box>
        ) : undefined
      }
    >
      {logo ? (
        <img
          loading="lazy"
          src={logo}
          alt={title}
          style={{
            height: 110,
            width: "auto",
            display: "block",
          }}
        />
      ) : undefined}
      <Rule parse={`!_t d:block mb:12 ${logo ? "mt:-12" : ""}`}>{title}</Rule>
      <Rule parse="!_p d:block mb:32">{description}</Rule>
      <Box css="d:flex a:center fw:wrap">
        {buttons.map((button, b) => {
          switch (button) {
            case "request-information":
              return (
                <DesignedButton
                  key={b}
                  label="Request Information"
                  theme="white"
                  size={mobile ? "small" : "large"}
                  href={`/contact/start?mode=learn-more&referrer=${router.asPath}`}
                  properties={{ right: 12, bottom: 12 }}
                />
              );

            case "schedule-a-call-with-production-specialist":
              return (
                <DesignedButton
                  key={b}
                  label="Schedule a call with a Production Specialist"
                  theme="white"
                  size={mobile ? "small" : "large"}
                  href="/contact/calendar"
                  properties={{ right: 12, bottom: 12 }}
                />
              );

            case "schedule-a-call":
              return (
                <DesignedButton
                  key={b}
                  label="Schedule a Call"
                  theme="tetriary-white"
                  size={mobile ? "small" : "large"}
                  href="/contact/calendar"
                  properties={{ right: 12, bottom: 12 }}
                />
              );

            default:
              return null;
          }
        })}
      </Box>
      {bottom}
    </ReusableGradientHero>
  );
};

export default SolutionsHero;
