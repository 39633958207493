import React from "react";

interface PlaceholderImageProps extends React.HTMLAttributes<HTMLDivElement> {
  [key: string]: unknown;
}

export const PlaceholderImage = (props: PlaceholderImageProps) => {
  const _style = props["style"] || {};

  return (
    <div
      {...props}
      style={{
        backgroundImage: `url(/assets/section/placeholder.webp)`,
        backgroundSize: "248px",
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        paddingTop: "100%",
        ...(_style as React.CSSProperties),
      }}
    />
  );
};

export default PlaceholderImage;
