/* eslint-disable no-case-declarations */
import { DesignedCard, Rule } from "@with-nx/simple-ui/atoms";
import { useId } from "react";
import { Box } from "simple-effing-primitive-layout";

export type EditorDataBlock =
  | {
      id?: string;
      type: "paragraph";
      data: {
        text: string;
      };
    }
  | {
      id?: string;
      type: "list";
      unordered?: boolean;
      data: {
        text: string[];
        items?: string[];
        style?: string;
      };
    }
  | {
      id?: string;
      type: "bold";
      data: {
        text: string;
      };
    }
  | {
      id?: string;
      type: "header";
      data: {
        text: string;
        level: number;
      };
    }
  | {
      id?: string;
      type: "image";
      data: {
        file: {
          url: string;
        };
        withBorder?: boolean;
        withBackground?: boolean;
        stretched?: boolean;
        caption?: string;
      };
    }
  | {
      id?: string;
      type: "embed";
      data: {
        service: string;
        source: string;
        embed: string;
        width: number;
        height: number;
        caption: string;
      };
    };

export type EditorData = {
  time?: number;
  blocks?: EditorDataBlock[];
};

export interface RendererProps {
  data?: EditorData;
}

const addRelToAnchor = (html: string) => {
  if (typeof window !== "undefined") {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    doc.querySelectorAll("a").forEach((a) => {
      a.setAttribute("rel", "nofollow");
    });
    const formattedString = doc.body.innerHTML;
    return formattedString;
  }

  return html;
};

export const Renderer = ({ data }: RendererProps) => {
  return (
    <Box>
      {!data?.blocks
        ? null
        : data?.blocks.map((block) => {
            try {
              const id = useId();

              return (
                <Box parse="d:block mb:24" key={id}>
                  {(() => {
                    switch (block.type) {
                      case "paragraph":
                        return (
                          <Rule rule="ps" color="var(--font3)">
                            <Box
                              native={{
                                dangerouslySetInnerHTML: {
                                  __html: addRelToAnchor(block?.data?.text),
                                },
                              }}
                              css="--cms"
                            />
                          </Rule>
                        );

                      case "list":
                        const items = block?.data?.items || block?.data?.text;

                        return (
                          <Box>
                            {items.map((t: string, tt: number) => (
                              <Box
                                key={`data-text-${tt}`}
                                parse="d:flex a:flex-start"
                                top={tt === 0 ? 0 : 12}
                              >
                                <Box
                                  parse="w:24"
                                  style={{
                                    minWidth: 24,
                                  }}
                                >
                                  <Rule
                                    rule="ps"
                                    color="var(--font3)"
                                    width="100%"
                                    display="block"
                                    weight="bold"
                                  >
                                    {block?.unordered ||
                                    block?.data?.style === "unordered"
                                      ? "•"
                                      : `${tt + 1}.`}
                                  </Rule>
                                </Box>
                                <Box parse="f:1">
                                  <Rule
                                    rule="ps"
                                    color="var(--font4)"
                                    width="100%"
                                    display="block"
                                    native={{
                                      dangerouslySetInnerHTML: {
                                        __html: t,
                                      },
                                    }}
                                  ></Rule>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        );

                      case "bold":
                        return (
                          <Rule rule="pm" weight="bold" color="var(--font2)">
                            {block?.data?.text}
                          </Rule>
                        );

                      case "header":
                        return (
                          <Rule
                            rule={(() => {
                              switch (block.data.level) {
                                case 1:
                                  return "hm";
                                case 2:
                                  return "hs";
                                case 3:
                                  return "ht";

                                default:
                                  return "ht";
                              }
                            })()}
                            color="var(--font1)"
                          >
                            {block.data.text}
                          </Rule>
                        );

                      case "image":
                        return (
                          <img
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "auto",
                              display: "block",
                              borderRadius: 10,
                              overflow: "hidden",
                            }}
                            alt={block.data.caption || "Broadway Media"}
                            src={block.data.file.url}
                          />
                        );

                      case "embed":
                        return (
                          <DesignedCard
                            size="16x9"
                            embed={block.data.source}
                            muted={false}
                            loop={false}
                            controls={true}
                            playing={false}
                          />
                        );

                      default:
                        return null;
                    }
                  })()}
                </Box>
              );
            } catch (error) {
              console.error(error);
              return null;
            }
          })}
    </Box>
  );
};

export default Renderer;
