import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "@with-nx/auth";
import {
  Formatter,
  Pipedrive,
  staticProjectorAccessoriesData,
  staticProjectorRentalData,
  useBusiness,
  UseProjectorProjector,
  useRepresentative,
  Validators,
} from "@with-nx/hooks-n-helpers";
import { useRegion, useStatesCodes } from "@with-nx/region";
import {
  DesignedButton,
  DesignedInput,
  DesignedRadio,
  DesignedSelect,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { PopupModal as PopupModalCalendly } from "react-calendly";
import { Box } from "simple-effing-primitive-layout";

import CalendarInput from "../calendar-input/calendar-input";
import Captcha from "../captcha/captcha";
import ReusableCheckboxSidebar from "../reusable-checkbox-sidebar/reusable-checkbox-sidebar";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

type CalendlyDetails = {
  name?: string;
  organization?: string;
  organization_type?: string;
  email?: string;
  phone_number?: string;
  show?: string;
  opening_night?: string;
  message?: string;
};

export type ContactDetails = {
  name?: string;
  organization?: string;
  organization_type?: string;
  email?: string;
  phone_number?: string;
  show?: string;
  opening_night?: string;
  message?: string;
};

export type GetStartedDetails = {
  name?: string;
  organization?: string;
  organization_type?: string;
  email?: string;
  phone_number?: string;
  show?: string;
  opening_night?: string;
  closing_night?: string;
  message?: string;
  customer_type?: "New Customer" | "Returning Customer";
  region?: string;
  state?: string;
  products?: string[];
};

export type HelpDetails = {
  broadway_media_order_number?: string;
  name?: string;
  email?: string;
  phone_number?: string;
  show?: string;
  performance_date?: string;
  subject?: string;
  message?: string;
};

interface ContactFormProps {
  initial?: ContactDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
  value?: CalendlyDetails;
  action?: "get-started" | string;
  referrer?: string;
  mobile?: boolean;
}

interface ContactFormModalProps {
  open: boolean;
  close: () => void;
  submit: () => void;
  initial?: ContactDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
}

interface HelpFormProps {
  initial?: HelpDetails;
  change?: (details: HelpDetails, valid: boolean) => void;
}

interface HelpFormModalProps {
  open: boolean;
  close: () => void;
  submit: () => void;
  initial?: HelpDetails;
  change?: (details: HelpDetails, valid: boolean) => void;
}

interface ContactFormModalProps {
  open: boolean;
  close: () => void;
  submit: () => void;
  initial?: ContactDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
}

interface CalendlyFormProps {
  initial?: CalendlyDetails;
  change?: (details: CalendlyDetails, valid: boolean) => void;
}

interface GetStartedFormProps {
  initial?: GetStartedDetails;
  value?: GetStartedDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
  action?: "get-started" | string;
  referrer?: string;
}

export interface CalendlyFormModalProps {
  calendly?: string;
  origin: string;
  open: boolean;
  redirect?: boolean;
  close: () => void;
  submit: () => void;
  initial?: CalendlyDetails;
  change?: (details: CalendlyDetails, valid: boolean) => void;
}

interface ProjectorSalesQuoteFormModalProps {
  open: boolean;
  close: () => void;
  submit: () => void;
  initial?: CalendlyDetails;
  change?: (details: CalendlyDetails, valid: boolean) => void;
  projector: UseProjectorProjector;
}

export const ContactForm = (props: ContactFormProps) => {
  const [values, _values] = useState<ContactDetails>(props?.initial || {});
  const [valid, _valid] = useState(true);
  const [focus, _focus] = useState(true);
  const business = useBusiness();

  useEffect(() => {
    let _valid = valid;

    if (values.name?.length === 0 || values.name === undefined) {
      _valid = false;
    }

    if (
      values.organization?.length === 0 ||
      values.organization === undefined
    ) {
      _valid = false;
    }

    if (
      values.organization_type?.length === 0 ||
      values.organization_type === undefined
    ) {
      _valid = false;
    }

    if (values.email?.length === 0 || values.email === undefined) {
      _valid = false;
    }

    if (
      values.phone_number?.length === 0 ||
      values.phone_number === undefined
    ) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  useEffect(() => {
    if (props.action === "get-started" && typeof props.referrer === "string") {
      if (props.referrer === "/scenic-projections") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Scenic Projections Landing page and I have some questions...",
        }));
      }
      if (props.referrer === "/choreography-guides") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Choreography Guides Landing page and I have some questions...",
        }));
      }
      if (props.referrer.includes("/shows/")) {
        const show: string | undefined = props.referrer.split("/")[3];
        if (show) {
          const friendly = show
            ?.split("-")
            ?.map((i) => {
              if (i === "kids") {
                return "KIDS";
              } else if (i === "jr") {
                return "JR.";
              }
              return Formatter.friendly(i);
            })
            .join(" ");

          _values((values) => ({
            ...values,
            message: `I just came from the ${friendly} landing page and I have some questions...`,
            show: friendly,
          }));
        }
      }
    }
  }, [props.action, props.referrer]);

  return (
    <Row gutter={[36, 18]}>
      <Col xs={24}>
        <DesignedInput
          label="Name"
          value={values.name}
          change={(name) => _values({ ...values, name })}
          bottom={12}
          convert="capitalize"
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedInput
          label="Organization Name"
          value={values.organization}
          change={(organization) => _values({ ...values, organization })}
          bottom={12}
          convert="capitalize"
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedSelect
          icon="down"
          label="Organization Type"
          value={values.organization_type}
          change={(organization_type) =>
            _values({ ...values, organization_type })
          }
          empty
          bottom={12}
          options={business.types.map((i) => [i.name, i.name])}
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedInput
          label="Email"
          value={values.email}
          change={(email) => _values({ ...values, email })}
          bottom={12}
          focus={() => _focus(true)}
          blur={() => {
            _valid(Validators.isValidEmailAddress(values.email || ""));
            _focus(false);
          }}
          convert="lowercase"
          error={
            focus
              ? undefined
              : valid
              ? undefined
              : "Please enter a valid email address."
          }
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedInput
          label="Phone Number"
          value={values.phone_number}
          change={(phone_number) => _values({ ...values, phone_number })}
          bottom={12}
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12} sm={props.mobile ? 24 : 16}>
        <DesignedInput
          label="Show"
          value={values.show}
          change={(show) => _values({ ...values, show })}
          bottom={12}
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12} sm={props.mobile ? 24 : 8}>
        <DesignedInput
          label="Opening Night"
          value={values.opening_night}
          change={(opening_night) => _values({ ...values, opening_night })}
          bottom={12}
          type="date"
        />
      </Col>
      <Col xs={24}>
        <DesignedInput
          label="Message"
          value={values.message}
          change={(message) => _values({ ...values, message })}
          bottom={12}
          textarea={true}
        />
      </Col>
    </Row>
  );
};

export const GetStartedForm = (props: GetStartedFormProps) => {
  const [values, _values] = useState<GetStartedDetails>({
    customer_type: "New Customer",
    region: "🇺🇸 United States",
    state: "AL",
    ...props?.initial,
  });
  const [valid, _valid] = useState(true);
  const [ready, _ready] = useState(false);
  const business = useBusiness();

  const region = useRegion();

  const openingNightError = (() => {
    if (values?.opening_night?.length) {
      if (!moment(values?.opening_night, "YYYY-MM-DD", true).isValid()) {
        return "Closing night date is not valid.";
      }

      if (moment(values?.opening_night).isBefore(moment())) {
        return "Opening Night date must be in the future";
      }
    }
    return undefined;
  })();

  const closingNightDateError = (() => {
    if (values?.closing_night?.length) {
      if (!moment(values?.closing_night, "YYYY-MM-DD", true).isValid()) {
        return "Closing night date is not valid.";
      }

      if (
        moment(values?.closing_night).isBefore(moment(values?.opening_night))
      ) {
        return "Closing night date must be greater or equal than Opening night date.";
      }
    }

    return undefined;
  })();

  const emailError = (() => {
    if (!Validators.isValidEmailAddress(values.email || "")) {
      return "Please enter a valid email address.";
    }

    return undefined;
  })();

  useEffect(() => {
    let _valid = valid;

    if (
      values.name?.length === 0 ||
      values.name === undefined ||
      !values?.name?.includes(" ")
    ) {
      _valid = false;
    }

    if (
      Number(values.organization?.length) < 1 ||
      values.organization === undefined
    ) {
      _valid = false;
    }

    if (
      !values.organization_type?.length ||
      values.organization_type === undefined
    ) {
      _valid = false;
    }

    if (!Validators.isValidEmailAddress(values.email || "")) {
      _valid = false;
    }

    if (!values.phone_number?.length || values.phone_number === undefined) {
      _valid = false;
    }

    if (!values.opening_night?.length || values.opening_night === undefined) {
      _valid = false;
    }

    if (!values.closing_night?.length || values.closing_night === undefined) {
      _valid = false;
    }

    if (moment(values.opening_night).isBefore(moment())) {
      _valid = false;
    }

    if (moment(values.closing_night).isBefore(moment(values.opening_night))) {
      _valid = false;
    }

    if (!values.customer_type?.length || values.customer_type === undefined) {
      _valid = false;
    }

    if (!values.show?.length || values.show === undefined) {
      _valid = false;
    }

    if (!values.region?.length || values.region === undefined) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  useEffect(() => {
    if (props.action === "get-started" && typeof props.referrer === "string") {
      if (props.referrer === "/scenic-projections") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Scenic Projections Landing page and I have some questions...",
        }));
        _values((values) => ({
          ...values,
          products: [...(values.products || []), "Digital Scenery"],
        }));
      }
      if (props.referrer === "/choreography-guides") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Choreography Guides Landing page and I have some questions...",
        }));
        _values((values) => ({
          ...values,
          products: [...(values.products || []), "Choreography Guides"],
        }));
      }
      if (props.referrer.includes("/stage-gear")) {
        _values((values) => ({
          ...values,
          message:
            "I just came from the StageGear Landing page and I have some questions...",
          products: [
            ...(values.products || []),
            "Projector Hardware",
            "Wireless Microphone Systems",
          ],
        }));
      }
      if (props.referrer.includes("/bodymics")) {
        _values((values) => ({
          ...values,
          message:
            "I just came from the BodyMics Landing page and I have some questions...",
          products: [
            ...(values.products || []),
            "Projector Hardware",
            "Wireless Microphone Systems",
          ],
        }));
      }
      if (props.referrer.includes("/shows/")) {
        const show: string | undefined = props.referrer.split("/")[3];
        if (show) {
          const friendly = show
            ?.split("-")
            ?.map((i) => {
              if (i === "kids") {
                return "KIDS";
              } else if (i === "jr") {
                return "JR.";
              }
              return Formatter.friendly(i);
            })
            .join(" ");

          const _ps: string[] = [];

          if (props.referrer.includes("scenic-projections")) {
            _ps.push("Digital Scenery");
          }

          if (props.referrer.includes("choreography-guides")) {
            _ps.push("Choreography Guides");
          }

          _values((values) => ({
            ...values,
            message: `I just came from the ${friendly} landing page and I have some questions...`,
            show: friendly,
            products: [...(values.products || []), ..._ps],
          }));
        }
      }
      if (props.referrer.includes("/projectors/")) {
        const item: string | undefined = props.referrer.split("/").at(-1);
        if (item) {
          const hardware =
            staticProjectorRentalData.find((i) => i.slug === item) ||
            staticProjectorAccessoriesData.find((i) => i.slug === item);

          const _ps: string[] = [];
          _ps.push("Projector Hardware");

          _values((values) => ({
            ...values,
            message: `I’m interested in a "StageProjector" ${hardware?.name}...`,
            products: [...(values.products || []), ..._ps],
          }));
        }
      }
    }
  }, [props.action, props.referrer]);

  useEffect(() => {
    if (region.loading) {
      return;
    }

    _values((v) => ({
      ...v,
      region: region.r,
    }));

    setTimeout(() => {
      _ready(true);
    }, 200);
  }, [region.loading]);

  const states = useStatesCodes(values.region === "🇨🇦 Canada" ? "CA" : "US");

  if (!ready) {
    return null;
  }

  return (
    <Row gutter={[36, 18]}>
      <Col xs={24}>
        <Rule display="block" rule="lt" color="#AFAFAF" bottom={12} right={5}>
          Have we worked together before?
        </Rule>
        <Box parse="d:flex a:center fw:wrap">
          <DesignedRadio
            size="large"
            label="New Customer"
            active={values.customer_type === "New Customer"}
            press={() => _values({ ...values, customer_type: "New Customer" })}
            properties={{ right: 24 }}
          />
          <DesignedRadio
            size="large"
            label="Returning Customer"
            active={values.customer_type === "Returning Customer"}
            press={() =>
              _values({ ...values, customer_type: "Returning Customer" })
            }
            properties={{ right: 24 }}
          />
        </Box>
      </Col>
      <Col
        xs={24}
        sm={
          values.region === "🇺🇸 United States" || values.region === "🇨🇦 Canada"
            ? 12
            : 24
        }
      >
        <DesignedSelect
          label="Where are you located?"
          value={values.region}
          change={(region) => {
            _values({ ...values, region, state: undefined });
          }}
          options={region.regions?.map((i) => [i, i]) || []}
          bottom={12}
        />
      </Col>
      {values.region === "🇺🇸 United States" ? (
        <Col xs={24} sm={12}>
          <DesignedSelect
            label="Which state are you located?"
            value={values.state}
            change={(state) => _values({ ...values, state })}
            options={states?.map(([i, ii]) => [i, ii]) || []}
            bottom={12}
            required
            empty
          />
        </Col>
      ) : undefined}

      {values.region === "🇨🇦 Canada" ? (
        <Col xs={24} sm={12}>
          <DesignedSelect
            label="Which province are you located?"
            value={values.state}
            change={(state) => _values({ ...values, state })}
            options={states?.map(([i, ii]) => [i, ii]) || []}
            bottom={12}
            required
            empty
          />
        </Col>
      ) : undefined}

      <Col xs={24}>
        <DesignedInput
          label="Name"
          value={values.name}
          change={(name) => _values({ ...values, name })}
          bottom={12}
          error={(() => {
            if (!values?.name?.includes(" ")) {
              return "Please enter your full name.";
            }
            return undefined;
          })()}
          convert="capitalize"
        />
      </Col>
      <Col xs={12}>
        <DesignedInput
          label="Organization Name"
          value={values.organization}
          change={(organization) => _values({ ...values, organization })}
          bottom={12}
          convert="capitalize"
        />
      </Col>
      <Col xs={12}>
        <DesignedSelect
          label="Organization Type"
          change={(organization_type) =>
            _values({ ...values, organization_type })
          }
          empty
          bottom={12}
          options={business.types.map((i) => [
            i.businessType.toString(),
            i.name,
          ])}
        />
      </Col>
      <Col xs={12}>
        <DesignedInput
          label="Email"
          value={values.email}
          change={(email) => _values({ ...values, email })}
          bottom={12}
          blur={() => {
            _valid(Validators.isValidEmailAddress(values.email || ""));
          }}
          error={emailError}
          convert="lowercase"
        />
      </Col>
      <Col xs={12}>
        <DesignedInput
          label="Phone Number"
          value={values.phone_number}
          change={(phone_number) => _values({ ...values, phone_number })}
          bottom={12}
        />
      </Col>
      <Col xs={12} sm={8}>
        <DesignedInput
          label="Show"
          value={values.show}
          change={(show) => _values({ ...values, show })}
          bottom={12}
        />
      </Col>
      <Col xs={12} sm={8}>
        <DesignedInput
          label="Opening Night"
          value={values.opening_night}
          change={(opening_night) => _values({ ...values, opening_night })}
          bottom={12}
          type="date"
          error={openingNightError}
        />
      </Col>
      <Col xs={12} sm={8}>
        <DesignedInput
          label="Closing Night"
          value={values.closing_night}
          change={(closing_night) => _values({ ...values, closing_night })}
          bottom={12}
          type="date"
          error={closingNightDateError}
        />
      </Col>
      <Col xs={24}>
        <DesignedInput
          label="Message"
          value={values.message}
          change={(message) => _values({ ...values, message })}
          bottom={12}
          textarea={true}
        />
      </Col>
      <Col xs={24}>
        <Rule display="block" rule="lt" color="#AFAFAF" bottom={12} right={5}>
          What products are you interested in?
        </Rule>
        <ReusableCheckboxSidebar
          minimal
          theme="light"
          size="xl"
          groups={{
            items: {
              expanded: true,
              label: "",
              items: [
                {
                  label: "Digital Scenery",
                  value: "Digital Scenery",
                  active: values.products?.includes("Digital Scenery"),
                },
                {
                  label: "Projector Hardware",
                  value: "Projector Hardware",
                  active: values.products?.includes("Projector Hardware"),
                },
                {
                  label: "Wireless Microphone Systems",
                  value: "Wireless Microphone Systems",
                  active: values.products?.includes(
                    "Wireless Microphone Systems"
                  ),
                },
                {
                  label: "Choreography Guides",
                  value: "Choreography Guides",
                  active: values.products?.includes("Choreography Guides"),
                },
                {
                  label: "Other Resources",
                  value: "Other Resources",
                  active: values.products?.includes("Other Resources"),
                },
              ],
            },
          }}
          change={(groups) => {
            const is = (groups["items"].items.map((item) => item.value) ||
              []) as string[];
            _values({ ...values, products: is });
          }}
        />
      </Col>
    </Row>
  );
};

export const ContactFormModal = (props: ContactFormModalProps) => {
  const [valid, _valid] = useState(false);
  const [captcha, _captcha] = useState(false);

  return (
    <>
      <Captcha verify={() => _captcha(true)} />
      <Modal
        isOpen={props.open}
        blockScrollOnMount
        onClose={props.close}
        size={{ base: "4xl" }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
          <ModalHeader color="var(--font1)">Contact Us</ModalHeader>
          <ModalBody>
            <Box parse="pl:24 pr:24">
              <ContactForm
                change={(details, valid) => {
                  _valid(valid);
                  props?.change?.(details, valid);
                }}
                initial={props.initial}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <VStack>
              <DesignedButton
                press={props.submit}
                disable={!valid || !captcha}
                label="Submit"
                theme="primary"
                size="small"
              />
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const HelpForm = (props: HelpFormProps) => {
  const [values, _values] = useState<HelpDetails>(props?.initial || {});
  const [valid, _valid] = useState(true);
  const [focus, _focus] = useState(true);

  useEffect(() => {
    let _valid = valid;

    if (values.name?.length === 0 || values.name === undefined) {
      _valid = false;
    }

    if (values.email?.length === 0 || values.email === undefined) {
      _valid = false;
    }

    if (
      values.phone_number?.length === 0 ||
      values.phone_number === undefined
    ) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  return (
    <>
      <Row gutter={[36, 18]}>
        <Col xs={24}>
          <DesignedInput
            required
            label="Broadway Media Order Number"
            value={values.broadway_media_order_number}
            change={(broadway_media_order_number) =>
              _values({ ...values, broadway_media_order_number })
            }
            bottom={12}
          />
          <DesignedInput
            required
            label="Name"
            value={values.name}
            change={(name) => _values({ ...values, name })}
            bottom={12}
            convert="capitalize"
          />
          <DesignedInput
            required
            label="Email"
            value={values.email}
            change={(email) => _values({ ...values, email })}
            bottom={12}
            focus={() => _focus(true)}
            blur={() => {
              _valid(Validators.isValidEmailAddress(values.email || ""));
              _focus(false);
            }}
            convert="lowercase"
            error={
              focus
                ? undefined
                : valid
                ? undefined
                : "Please enter a valid email address."
            }
          />
          <DesignedInput
            required
            label="Phone Number"
            value={values.phone_number}
            change={(phone_number) => _values({ ...values, phone_number })}
            bottom={12}
          />
          <DesignedSelect
            required
            label="Subject"
            value={values.subject}
            change={(subject) => _values({ ...values, subject })}
            bottom={12}
            options={[
              ["Other", "Other"],
              ["Software Install", "Software Install"],
              ["Software Malfunction", "Software Malfunction"],
              ["Projector Install", "Projector Install"],
              ["Projector Malfunction", "Projector Malfunction"],
              ["Account Access", "Account Access"],
              ["Billing Inquiry", "Billing Inquiry"],
            ]}
          />
          <CalendarInput
            required
            label="Performance Date"
            value={values.performance_date}
            change={(performance_date) =>
              _values({ ...values, performance_date })
            }
            bottom={12}
          />
          <DesignedInput
            required
            label="Message"
            value={values.message}
            change={(message) => _values({ ...values, message })}
            bottom={12}
            textarea={true}
          />
        </Col>
      </Row>
    </>
  );
};

export const HelpFormModal = (props: HelpFormModalProps) => {
  const [valid, _valid] = useState(false);

  return (
    <>
      <Modal
        isOpen={props.open}
        blockScrollOnMount
        onClose={props.close}
        size={{ base: "xl" }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
          <ModalHeader color="var(--font1)">Help Ticket</ModalHeader>
          <ModalBody>
            <Box parse="pl:24 pr:24">
              <HelpForm
                change={(details, valid) => {
                  _valid(valid);
                  props?.change?.(details, valid);
                }}
                initial={props.initial}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <VStack>
              <DesignedButton
                press={props.submit}
                disable={
                  valid
                    ? false
                    : ["Please make sure all required fields are filled out."]
                }
                label="Submit"
                theme="primary"
                size="small"
              />
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const CalendlyForm = (props: CalendlyFormProps) => {
  const [values, _values] = useState<ContactDetails>(props?.initial || {});
  const [valid, _valid] = useState(true);
  const [focus, _focus] = useState(true);

  useEffect(() => {
    let _valid = valid;

    if (values.name?.length === 0 || values.name === undefined) {
      _valid = false;
    }

    if (
      values.organization?.length === 0 ||
      values.organization === undefined
    ) {
      _valid = false;
    }

    if (
      values.organization_type?.length === 0 ||
      values.organization_type === undefined
    ) {
      _valid = false;
    }

    if (values.email?.length === 0 || values.email === undefined) {
      _valid = false;
    }

    if (
      values.phone_number?.length === 0 ||
      values.phone_number === undefined
    ) {
      _valid = false;
    }

    if (
      values.phone_number?.length === 0 ||
      values.phone_number === undefined
    ) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  const [captcha, _captcha] = useState(false);

  return (
    <>
      <Captcha verify={() => _captcha(true)} />
      <Row gutter={[36, 18]}>
        <Col xs={24}>
          <DesignedInput
            label="Name"
            value={values.name}
            change={(name) => _values({ ...values, name })}
            bottom={12}
            convert="capitalize"
          />
        </Col>
        <Col xs={12}>
          <DesignedInput
            label="Organization Name"
            value={values.organization}
            change={(organization) => _values({ ...values, organization })}
            bottom={12}
            convert="capitalize"
          />
        </Col>
        <Col xs={12}>
          <DesignedSelect
            label="Organization Type"
            value={values.organization_type}
            change={(organization_type) =>
              _values({ ...values, organization_type })
            }
            bottom={12}
            empty={true}
            options={[
              ["Elementary School", "Elementary School"],
              ["Middle School", "Middle School"],
              ["High School", "High School"],
              ["University", "University"],
              ["Community Theatre", "Community Theatre"],
              ["Military", "Military"],
              ["Religious", "Religious"],
              ["Camp", "Camp"],
              ["Childrens Theatre", "Childrens Theatre"],
              ["Professional", "Professional"],
              ["Dance", "Dance"],
              ["Other", "Other"],
            ]}
          />
        </Col>
        <Col xs={12}>
          <DesignedInput
            disable={!captcha}
            label="Email"
            value={values.email}
            change={(email) => _values({ ...values, email })}
            bottom={12}
            focus={() => _focus(true)}
            blur={() => {
              _valid(Validators.isValidEmailAddress(values.email || ""));
              _focus(false);
            }}
            convert="lowercase"
            error={
              focus
                ? undefined
                : valid
                ? undefined
                : "Please enter a valid email address."
            }
          />
        </Col>
        <Col xs={12}>
          <DesignedInput
            label="Phone Number"
            value={values.phone_number}
            change={(phone_number) => _values({ ...values, phone_number })}
            bottom={12}
          />
        </Col>
        <Col xs={12} sm={16}>
          <DesignedInput
            label="Show"
            value={values.show}
            change={(show) => _values({ ...values, show })}
            bottom={12}
          />
        </Col>
        <Col xs={12} sm={8}>
          <DesignedInput
            label="Opening Night"
            value={values.opening_night}
            change={(opening_night) => _values({ ...values, opening_night })}
            bottom={12}
          />
        </Col>
        <Col xs={24}>
          <DesignedInput
            label="Message"
            value={values.message}
            change={(message) => _values({ ...values, message })}
            bottom={12}
            textarea={true}
          />
        </Col>
      </Row>
    </>
  );
};

export const CalendlyFormModalElement = (props: CalendlyFormModalProps) => {
  const { calendly } = useRegion();
  const [element, _element] = useState<HTMLElement | null>(null);
  const auth = useAuth();
  const { representative, loading } = useRepresentative();

  useEffect(() => {
    _element(document.getElementById("__next"));
  }, [auth]);

  if (!element || loading) {
    return null;
  }

  return (
    <>
      {props.open ? (
        <PopupModalCalendly
          onModalClose={() => props.close()}
          open={props.open}
          rootElement={element}
          url={props.calendly || representative?.calendlyLink || calendly}
          prefill={{
            name: auth.authState.session?.user.name,
            email: auth.authState.session?.user.email,
          }}
          pageSettings={(() => {
            const override = false;
            if (override) {
              return {
                textColor: "#FFFFFF",
                backgroundColor: "#1e242d",
                primaryColor: "#e2b576",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
              };
            }
            return {
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
            };
          })()}
        />
      ) : undefined}
    </>
  );
};

export const CalendlyFormModal = (props: CalendlyFormModalProps) => {
  if (props.open) {
    return <CalendlyFormModalElement {...props} />;
  }
  return null;
};

export const ProjectorSalesQuoteFormModal = (
  props: ProjectorSalesQuoteFormModalProps
) => {
  const [valid, _valid] = useState(false);
  const [data, _data] = useState<CalendlyDetails>({});
  const router = useRouter();
  const [element, _element] = useState<HTMLElement | null>(null);
  const auth = useAuth();

  useEffect(() => {
    _element(document.getElementById("__next"));

    if (auth.authState.status === "authenticated") {
      _data({
        name: auth.authState.session?.user.name,
        email: auth.authState.session?.user.email,
        organization: auth.authState.session?.user.organization,
      });
    }
  }, [auth]);

  const [captcha, _captcha] = useState(false);

  if (!element) {
    return null;
  }

  return (
    <>
      {props.open ? <Captcha verify={() => _captcha(true)} /> : undefined}
      <Modal
        isOpen={props.open}
        blockScrollOnMount
        onClose={props.close}
        size={{ base: "4xl" }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
          <ModalBody>
            <Box parse="pl:24 pr:24 pt:24 mb:20 d:flex a:center j:space-between">
              <Rule parse="!ht">Request Sales Quote</Rule>
            </Box>
            <Box parse="pl:24 pr:24 mb:12">
              <Rule parse="!pl c:?font3">
                If units are available for sales, they usually ship in 12 weeks.
                Please fill in the following information and we will reach out
                to you.
              </Rule>
            </Box>
            <Box parse="pl:24 pr:24 pt:24 mb:20 d:flex a:center j:space-between">
              <Rule parse="!ht">We`re excited to get to know you!</Rule>
            </Box>
            <Box parse="pl:24 pr:24">
              {auth.authState.status === "authenticated" ? (
                <ContactForm
                  change={(details, valid) => {
                    _valid(valid);
                    _data(details);
                    props?.change?.(details, valid);
                  }}
                  initial={data}
                  value={data}
                />
              ) : (
                <ContactForm
                  change={(details, valid) => {
                    _valid(valid);
                    _data(details);
                    props?.change?.(details, valid);
                  }}
                  initial={props.initial}
                />
              )}
            </Box>
            <Box parse="pa:24 d:flex j:flex-end">
              <DesignedButton
                press={async () => {
                  if (!captcha) {
                    return;
                  }

                  Pipedrive.deal({
                    email: data.email || "",
                    phone: data.phone_number || "",
                    name: data?.name
                      ? data?.name?.split(" ")?.[0] || data?.name
                      : "",
                    surname: data?.name
                      ? data?.name
                          ?.split(" ")
                          ?.splice(1, data?.name?.split(" ")?.length)
                          ?.join(" ") || "*"
                      : "",
                    organization: data.organization || "",
                    data: Object.entries(data).map(([key, value]) => [
                      key.toUpperCase(),
                      value || "",
                    ]),
                  });

                  router.push("/get-started");
                }}
                disable={!valid || !captcha}
                label="Submit"
                theme="primary"
                size="small"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactForm;
