import { useServiceForm } from "./useService";

type UseContactType = "help" | "support" | "contact";
type UseContactDetails = {
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  subject: string;
  message: string;
  phone?: string;
  meta?: [string, string][];
  files?: Blob[];
};

export const useContact = () => {
  const makeRequestToAccounts = useServiceForm("accounts");

  const send = async (type: UseContactType, details: UseContactDetails) => {
    const form = new FormData();

    form.append("email", details.email);
    form.append("firstName", details.firstName);
    form.append("lastName", details.lastName);
    form.append("organizationName", details.organizationName);
    form.append("subject", details.subject);
    form.append("message", details.message);

    if (details.phone) {
      form.append("phone", details.phone);
    }

    if (details.meta) {
      details.meta.map((item, i) => {
        form.append(`meta[${i}][0]`, item[0]);
        form.append(`meta[${i}][1]`, item[1]);
      });
    }

    if (details.files) {
      details.files.map((file, i) => {
        form.append(`files[${i}]`, file);
      });
    }

    await makeRequestToAccounts("POST", `/api/v2/support/email/${type}`, form);
  };

  return {
    send,
  };
};

export default useContact;
