import { Act, ShowData } from "libs/editor-ui/types";

export interface EditorActWrapperProps {
  act: Act;
  actIndex: number;
  render: (props: EditorActWrapperProps) => React.ReactNode;
  children: React.ReactNode;
  enabled: boolean;
  onActMove: (oldActIndex: number, newActIndex: number) => void;
  onActDuplicate: (actIndex: number) => void;
  onActDisable: (actIndex: number) => void;
  onActEnable: (actIndex: number) => void;
  data: ShowData;
  onDangerousDataUpdate?: (data: ShowData) => void;
}

const EditorActWrapper = (props: EditorActWrapperProps) => {
  const { render } = props;

  return render(props) as JSX.Element;
};

export default EditorActWrapper;
