import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";
import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { Step } from "./stepper";

export interface StepperSidebarProps {
  steps: Step[];
  current: number;
  mobile?: boolean;
}

export const StepperSidebar: FC<StepperSidebarProps> = ({
  steps,
  current,
  mobile,
}) => {
  return (
    <Box
      parse={`
        d:flex 
        fd:column 
        c:var(--grey-10) 
        pa:92
        z:2
        ${mobile ? "w:100% pa:30 pt:42 pb:65" : "mw:424"}`}
      style={{
        borderRadius: mobile ? "20px 20px 0 0" : "10px 0 0 10px",
      }}
    >
      {steps.map((step, index) => {
        const isLast = index === steps.length - 1;
        const isCurrentOrPrevious = index <= current;

        return (
          <Box key={`step-sidebar-item-${index}`}>
            <Box parse="d:flex mb:2">
              <Box
                parse={`h:40 w:40 mr:26 br:50% d:flex a:center j:center `}
                border="2px solid var(--grey-40)"
                color={isCurrentOrPrevious ? "rgba(145, 150, 157, 0.75)" : ""}
              >
                <DesignedIcon
                  name="tick"
                  color={isCurrentOrPrevious ? "#D2D2D2" : "var(--grey-40)"}
                />
              </Box>
              <Box parse="d:flex fd:column">
                <Rule parse="!ls c:var(--grey-40)" weight="700">
                  STEP {index + 1}
                </Rule>

                <Rule parse="!ps c:var(--grey-60)" weight="700">
                  {step.title}
                </Rule>
              </Box>
            </Box>

            {!isLast && (
              <Box parse="h:37 w:2 c:var(--entity) br:5 ml:20 mb:6" />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
