import { Box } from "simple-effing-primitive-layout";

export const ReusableSlantedContainer = ({
  children,
  gradients,
  gradient = ["#181D25", "var(--background)"],
  feather,
  direction = "to bottom",
  bottom = 96,
  top = 32,
  still = false,
  index = 1,
  spotlights,
  slanted = true,
}: {
  children: unknown;
  gradients?: string[];
  gradient?: string[];
  feather?: string[];
  bottom?: number;
  top?: number;
  still?: boolean;
  direction?: string;
  index?: number | string;
  spotlights?: boolean;
  slanted?: boolean;
}) => {
  return (
    <Box
      parse={`w:100% p:relative pb:${bottom} pt:${top} i:${index} z:${index}`}
      style={{ overflow: "visible" }}
      css="reusable-slanted-container"
    >
      <Box
        parse={`z:${index} i:${index} w:100% p:absolute l:0 t:${
          still ? 0 : "-64px"
        } b:unset r:unset ox:hidden`}
        height="calc(100% + 64px)"
      >
        <Box
          parse="w:100% h:100% p:relative oa:hidden"
          style={{
            ...(slanted
              ? {
                  clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 80%)",
                  WebkitClipPath: "polygon(0 0, 100% 64px, 100% 100%, 0 100%)",
                }
              : {}),
            backgroundImage: `linear-gradient(${direction}, ${gradient
              .map((gradient) => gradient)
              .join(", ")})`,
          }}
        >
          {spotlights ? <Spotlights /> : undefined}
        </Box>
      </Box>
      {feather ? (
        <Box
          parse={`z:${index} i:${index} w:100% p:absolute l:0 t:${
            still ? 0 : "-64px"
          } b:unset r:unset ox:hidden`}
          height="calc(100% + 64px)"
        >
          <Box
            parse="w:100% h:100% p:relative oa:hidden"
            style={{
              clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 80%)",
              WebkitClipPath: "polygon(0 0, 100% 64px, 100% 100%, 0 100%)",
              backgroundImage: `linear-gradient(to bottom, ${feather
                .map((f) => f)
                .join(", ")})`,
            }}
          />
        </Box>
      ) : undefined}
      {gradients?.map((gradient, g) => (
        <Box
          key={g}
          parse={`z:2 i:2 w:50% h:50% p:absolute o:0.2 l:${
            (g + 0.5) * 25
          }% t:25% b:unset r:unset`}
          style={{
            backgroundImage: gradient,
            filter: "blur(95px)",
          }}
        />
      ))}
      <Box css="z:3 i:3 p:relative oa:visible">{children}</Box>
    </Box>
  );
};

const Spotlights = () => {
  return (
    <svg
      width="429"
      height="492"
      viewBox="0 0 429 492"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ position: "absolute", top: -72, right: -72 }}
    >
      <rect
        width="409.104"
        height="396.987"
        fill="url(#pattern0)"
        fillOpacity="0.4"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_2024_165921"
            transform="matrix(0.001716 0 0 0.00176838 -0.0594172 0)"
          />
        </pattern>
        <image
          id="image0_2024_165921"
          width="652"
          height="643"
          xlinkHref="/assets/section/spotlights.webp"
        />
      </defs>
    </svg>
  );
};

export default ReusableSlantedContainer;
