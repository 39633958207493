import { UploadedFile } from "./useFile";

const BASE64 = base64;

export function open(
  accept: string,
  multiple: boolean,
  callback: (arg: Event) => void
): void {
  const input = document.createElement("input");
  input.style.display = "none";
  document.body.appendChild(input);
  input.type = "file";
  if (accept !== "*") input.accept = accept;
  input.multiple = multiple;
  input.addEventListener("change", (arg) => {
    callback(arg);
    document.body.removeChild(input);
  });
  input.dispatchEvent(new MouseEvent("click"));
}

export function handle(
  file: File,
  accept: string,
  multiple: boolean,
  base64: boolean,
  callback: (file?: UploadedFile) => void
): void {
  if (file) {
    const _name = file.name.split(".").shift() as string;
    const _extension = file.name.split(".").pop() as string;

    if (
      !accept.includes("*") &&
      !accept.toLocaleLowerCase().includes(_extension)
    ) {
      callback(undefined);
      return;
    }

    if (base64) {
      BASE64(file, (value) => {
        callback({
          name: _name,
          extension: _extension,
          file: file,
          base64: value,
        });
      });
    } else {
      callback({
        name: _name,
        extension: _extension,
        file: file,
        base64: "",
      });
    }
  } else {
    callback(undefined);
  }
}

export function base64(file: File, callback: (arg: string) => void) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result as string);
  };
}
