import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ExternalLinkButtonProps {
  label: string;
  href: string;
}

export const ExternalLinkButton: FC<ExternalLinkButtonProps> = ({
  label,
  href,
}) => {
  return (
    <Box
      style={{
        display: "inline-block",
        minWidth: 350,
        cursor: "pointer",
      }}
      element="a"
      native={{
        href,
        rel: "nofollow",
      }}
    >
      <Box parse="d:flex j:space-between a:center br:10 pa:20 c:var(--foreground)">
        <Rule parse="!ht">{label}</Rule>
        <DesignedIcon name="external/bold" />
      </Box>
    </Box>
  );
};
