/* eslint-disable no-control-regex */
export class Fuzzy {
  static simplify(word: string): string {
    const normalized: string = word.normalize("NFKD");
    const simplified: string = normalized.replace(/[^\x00-\x7F]/g, "");
    return simplified.toLowerCase();
  }

  static slugify(s: string) {
    return s
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  static levenstein(word: string, target: string): number {
    if (word.length === 0) return target.length;
    if (target.length === 0) return word.length;
    const matrix: number[][] = [];
    for (let i = 0; i <= target.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= word.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= target.length; i++) {
      for (let j = 1; j <= word.length; j++) {
        let cost = 1;
        if (target[i - 1] === word[j - 1]) cost = 0;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }
    return matrix[target.length][word.length];
  }

  static similarity(word: string, target: string): number {
    if (word.length === 0 || target.length === 0) return 0;
    word = this.simplify(word);
    target = this.simplify(target);
    return (
      1 - this.levenstein(word, target) / Math.max(word.length, target.length)
    );
  }
}
