import { useRouter } from "next/router";
import { useEffect } from "react";

export const Redirect = ({
  before,
  to,
}: {
  before?: () => void;
  to: string;
}) => {
  const router = useRouter();

  useEffect(() => {
    (async () => {
      await before?.();
      router.push(to);
    })();
  }, []);

  return null;
};
