/* eslint-disable @typescript-eslint/no-explicit-any */
import "./loading-shimmer.module.css";

import { Box } from "simple-effing-primitive-layout";

export const LoadingShimmer = (props: any) => {
  return (
    <Box
      radius={5}
      css="--shimmer"
      {...props}
      style={{
        animationDuration: "2s",
        ...props.style,
      }}
    />
  );
};

export default LoadingShimmer;
