import { FC, ReactNode, useState } from "react";
import { Box } from "simple-effing-primitive-layout";
import { StepperSidebar } from "./stepper-sidebar";
import { StepperIndicators } from "./stepper-indicators";
import { StepperActions } from "./stepper-actions";
import { useMobile } from "@with-nx/hooks-n-helpers";

export interface Step {
  title: ReactNode;
  render: () => JSX.Element;
  action?: {
    label: string;
    onClick?: () => void;
    hideIcon?: boolean;
    loading?: boolean;
  };
  hideButtons?: boolean;
}

export interface StepperProps {
  steps: Step[];
  current: number;
  _current: (value: number) => void;
  nextHelperText?: ReactNode;
  hidePreviousButton?: boolean;
  hideSidebar?: boolean;
  hideBullets?: boolean;
}

export const Stepper: FC<StepperProps> = ({
  steps,
  nextHelperText,
  current,
  _current,
  hidePreviousButton,
  hideSidebar,
  hideBullets,
}) => {
  const mobile = useMobile(991);
  const next = () => {
    if (current < steps.length - 1) {
      _current(current + 1);
    }
  };

  const previous = () => {
    if (current > 0) {
      _current(current - 1);
    }
  };

  return (
    <>
      <Box
        parse={`d:flex c:transparent w:100% z:1 oa:hidden
          ${mobile ? "fd:column h:auto" : "mh:695px"}`}
        borderRadius={mobile ? "20px 20px 10px 10px" : "10px"}
      >
        {hideSidebar ? undefined : (
          <StepperSidebar current={current} steps={steps} mobile={mobile} />
        )}
        <Box
          parse={`
            d:flex 
            fd:column 
            c:#FFF
            ${
              mobile
                ? "pt:40 pl:30 pr:27 pb:40 oa:hidden mt:-35px"
                : "f:1 pt:100 pl:51 pr:51 pb:51"
            }
          `}
          style={{
            borderRadius: mobile ? "0 0 30px 30px" : "0 10px 10px 0",
          }}
        >
          <Box>{steps[current]?.render()}</Box>
          {!mobile && !hideBullets && (
            <StepperIndicators current={current} steps={steps} />
          )}
        </Box>
      </Box>

      {!steps[current]?.hideButtons && (
        <StepperActions
          hidePreviousButton={hidePreviousButton}
          current={current}
          next={steps[current]?.action?.onClick || next}
          nextHelperText={nextHelperText}
          nextLabel={steps[current]?.action?.label}
          previous={previous}
          mobile={mobile}
          hideIcon={steps[current]?.action?.hideIcon}
          loading={!!steps[current]?.action?.loading}
        />
      )}
    </>
  );
};
