import { ShowData } from "libs/editor-ui/types";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Icon, Text } from "../../shared";
import ActUtils from "./ActUtils";
import FilterOptions from "./options/FilterOptions";

const ActOptions = ({
  enabled,
  actIndex,
  data,
  onActDuplicate,
  onActEnable,
  onActDisable,
  onDangerousDataUpdate,
  thumbnail,
}: {
  enabled?: boolean;
  actIndex: number;
  data: ShowData;
  onActDuplicate: (actIndex: number) => unknown;
  onActEnable: (actIndex: number) => unknown;
  onActDisable: (actIndex: number) => unknown;
  onDangerousDataUpdate: (data: ShowData) => unknown;
  thumbnail?: string;
}) => {
  const [optionsAreOpen, setOptionsAreOpen] = useState(false);
  const [filterOptionsAreOpen, setFilterOptionsAreOpen] = useState(false);

  const closeAllOtherOptions = () => {
    setFilterOptionsAreOpen(false);
  };

  useEffect(() => {
    if (!optionsAreOpen) {
      closeAllOtherOptions();
      try {
        const elements = document?.getElementsByClassName("--draggable");
        for (let index = 0; index < elements.length; index++) {
          const element = elements[index];
          element?.setAttribute("draggable", "true");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const elements = document?.getElementsByClassName("--draggable");
        for (let index = 0; index < elements.length; index++) {
          const element = elements[index];
          element?.setAttribute("draggable", "false");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [optionsAreOpen]);

  const OPTIONS = [
    {
      label: enabled ? "Disable" : "Enable",
      icon: enabled ? "hide" : "show",
      press: () => {
        if (enabled) {
          onActDisable(actIndex);
        } else {
          onActEnable(actIndex);
        }
      },
      hide: false,
    },
    {
      label: "Duplicate",
      icon: "duplicate",
      press: () => onActDuplicate(actIndex),
      hide: false,
    },
    {
      label: "Filters",
      icon: "picture",
      advanced: true,
      press: () => {
        closeAllOtherOptions();
        setFilterOptionsAreOpen(true);
      },
      hide: false,
    },
  ];

  return (
    <>
      <Box
        parse="d:inline-flex a:center j:flex-end mw:16 mh:16 w:16 h:16 oa:visible o:visible p:relative"
        css={!enabled || optionsAreOpen ? undefined : "--children"}
      >
        <Box
          css="--more"
          press={() => setOptionsAreOpen(!optionsAreOpen)}
          parse="br:5 mw:20 mh:20 w:20 h:20 d:inline-flex a:center j:center"
          color={Colors.accent}
          border="1px solid rgba(255,255,255,0.1)"
        >
          {!enabled ? (
            <Icon name="hide" size={12} color={Colors.font3} />
          ) : (
            <>
              <Box
                css="--more"
                parse="w:3 h:3 mr:2 br:999"
                color={Colors.font3}
              />
              <Box
                css="--more"
                parse="w:3 h:3 mr:2 br:999"
                color={Colors.font3}
              />
              <Box css="--more" parse="w:3 h:3 br:999" color={Colors.font3} />
            </>
          )}
        </Box>

        <div onMouseLeave={() => setOptionsAreOpen(false)}>
          <Box
            display={optionsAreOpen ? "block" : "none"}
            parse={
              "w:240 pl:15 pt:15 pr:15 pb:5 r:0 p:absolute br:5 z:4 i:4 " +
              (ActUtils.shouldPopupDisplayOnTop() ? "b:25" : "t:25")
            }
            layer={4}
            color={Colors.background}
          >
            {OPTIONS.map((option, o) =>
              option.hide ? null : (
                <Box
                  key={o}
                  parse="w:100% mb:10 d:flex a:center"
                  css="--more"
                  press={option.press}
                >
                  <Icon color={Colors.font4} name={option.icon} size={16} />
                  <Text left={10} color={Colors.font1} rule="Label Tiny">
                    {option.label}
                  </Text>
                  {option.advanced === true ? (
                    <Box
                      parse="d:inline-flex a:center j:center h:16 pl:5 pr:5 br:5 ml:10"
                      color={Colors.branding}
                    >
                      <Icon
                        color={Colors.background}
                        name="stageplayer"
                        size={9}
                      />
                      <Text
                        size={9}
                        left={2}
                        color={Colors.background}
                        weight="800"
                      >
                        ADVANCED
                      </Text>
                    </Box>
                  ) : undefined}
                </Box>
              )
            )}
          </Box>

          <FilterOptions
            shouldPopupDisplayOnTop={ActUtils.shouldPopupDisplayOnTop()}
            setFilterOptionsAreOpen={setFilterOptionsAreOpen}
            filterOptionsAreOpen={filterOptionsAreOpen}
            onDangerousDataUpdate={onDangerousDataUpdate}
            data={data}
            thumbnail={thumbnail}
            actIndex={actIndex}
          />
        </div>
      </Box>
    </>
  );
};

export default ActOptions;
