import {
  DesignedBadge,
  DesignedCheck,
  DesignedCheckTheme,
  DesignedIcon,
  DesignedInput,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export type ReusableCheckboxSidebarItem = {
  label: string;
  value: string | number | boolean;
  active?: boolean;
};

export type ReusableCheckboxSidebarGroup = {
  label: string;
  items: ReusableCheckboxSidebarItem[];
  expanded?: boolean;
  more?: boolean;
  search?: string;
};

export type ReusableCheckboxSidebarGroups = {
  [key: string]: ReusableCheckboxSidebarGroup;
};

export interface ReusableCheckboxSidebarInterface {
  groups: ReusableCheckboxSidebarGroups;
  change?: (value: ReusableCheckboxSidebarGroups) => void;
  minimal?: boolean;
  showLabel?: boolean;
  selectAll?: boolean;
  hasSearch?: boolean;
  theme?: DesignedCheckTheme;
  size?: string;
}
/*
 * @param change will return all groups but only active groups.
 */

export const ReusableCheckboxSidebar = ({
  groups,
  change,
  minimal,
  showLabel,
  selectAll = false,
  hasSearch = true,
  theme = "dark",
  size,
}: ReusableCheckboxSidebarInterface) => {
  const [_groups, __groups] = useState(groups);

  useEffect(() => {
    if (selectAll) {
      __groups(groups);
    }
  }, [groups, selectAll]);

  const returnOnlyActiveGroupItems = (
    _groups: ReusableCheckboxSidebarGroups
  ) => {
    const temporary = Object.keys(_groups).reduce((acc, key) => {
      const group = _groups[key];
      const newGroup = { ...group };
      newGroup.items = group.items.filter((item) => item.active);
      return { ...acc, [key]: newGroup };
    }, {});
    return temporary;
  };

  const disableAllGroupItems = () => {
    const temporary = Object.keys(_groups).reduce((acc, key) => {
      const group = _groups[key];
      const newGroup = { ...group };
      newGroup.items = group.items.map((item) => ({ ...item, active: false }));
      return { ...acc, [key]: newGroup };
    }, {});
    return temporary;
  };

  const isAnyItemActive = () => {
    return Object.keys(_groups).some((key) => {
      const group = _groups[key];
      return group.items.some((item) => item.active);
    });
  };

  const countActiveItems = () => {
    return Object.keys(_groups).reduce((acc, key) => {
      const group = _groups[key];
      return acc + group.items.filter((item) => item.active).length;
    }, 0);
  };

  const expandCollapseGroup = (key: string, expanded: boolean) => {
    const temporary = { ..._groups };
    temporary[key].expanded = expanded;
    __groups(temporary);
  };

  const activateDisableGroupItem = (_g: string, _i: number) => {
    const temporary = { ..._groups };
    temporary[_g].items[_i].active = !temporary[_g].items[_i].active;
    __groups(temporary);
    return temporary;
  };

  const searchGroup = (_g: string, search: string) => {
    const temporary = { ..._groups };
    temporary[_g].search = search;
    __groups(temporary);
    return temporary;
  };

  const moreGroup = (_g: string, more = true) => {
    const temporary = { ..._groups };
    temporary[_g].more = more;
    __groups(temporary);
    return temporary;
  };

  return (
    <Box parse={minimal ? undefined : "pa:20 c:#1E242D br:10"}>
      <Box
        parse={
          minimal ? undefined : "pb:14 mb:20 d:flex a:center j:space-between"
        }
        style={minimal ? undefined : { borderBottom: "1px solid #2D3542" }}
      >
        {minimal ? undefined : (
          <Box css="d:inline-flex a:center">
            <Rule parse="!ht d:block mr:5" tag="span">
              Filters
            </Rule>
            {isAnyItemActive() ? (
              <DesignedBadge
                color="white"
                background="?primary"
                label={countActiveItems().toString()}
              />
            ) : undefined}
          </Box>
        )}
        {isAnyItemActive() && !minimal ? (
          <Rule
            parse="!ls c:?primary"
            press={() => {
              const temporary = disableAllGroupItems();
              const activeGroupsItems = returnOnlyActiveGroupItems(temporary);
              change?.(activeGroupsItems);
            }}
          >
            Clear All
          </Rule>
        ) : undefined}
      </Box>
      {Object.entries(_groups).map(([g, group]) => {
        return (
          <Box parse={size === "xl" ? "" : "mb:20"} key={g}>
            {minimal ? (
              showLabel ? (
                <Rule parse="!lt" color="var(--font4)">
                  {group.label}
                </Rule>
              ) : undefined
            ) : (
              <Box
                parse="d:flex a:center j:space-between"
                press={() => {
                  expandCollapseGroup(
                    g,
                    group.expanded === undefined ? false : !group.expanded
                  );
                }}
              >
                <Rule parse="!lm">{group.label}</Rule>
                <DesignedIcon
                  size={20}
                  color="var(--font4)"
                  name={group.expanded ? "up/bold" : "down/bold"}
                />
              </Box>
            )}

            {group.expanded !== false ? (
              <Box>
                {group.items.length >= 10 && hasSearch ? (
                  <Box parse="mt:12">
                    <DesignedInput
                      size="small"
                      label="Search"
                      placeholder="Search tags"
                      value={group.search || ""}
                      change={(search) => searchGroup(g, search)}
                    />
                  </Box>
                ) : undefined}

                {group.items.map((item, i) => {
                  if (
                    group.items.length >= 10 &&
                    !group.more &&
                    i >= 10 &&
                    !group.search?.length &&
                    hasSearch
                  ) {
                    return null;
                  }

                  if (group.search) {
                    if (group.search?.length > 0) {
                      if (
                        !item.label
                          .toLowerCase()
                          .includes(group.search.toLowerCase())
                      ) {
                        return null;
                      }
                    }
                  }

                  return (
                    <Box parse="mt:12" key={i}>
                      <DesignedCheck
                        size={size || "medium"}
                        theme={theme}
                        label={item.label}
                        active={item.active}
                        press={() => {
                          const temporary = activateDisableGroupItem(g, i);
                          const activeGroupsItems =
                            returnOnlyActiveGroupItems(temporary);
                          change?.(activeGroupsItems);
                        }}
                      />
                    </Box>
                  );
                })}
                {group.items.length >= 10 && !group.more && hasSearch ? (
                  <Box parse="mt:12" press={() => moreGroup(g, true)}>
                    <Rule parse="!lt c:?primary">Show all tags</Rule>
                  </Box>
                ) : undefined}
              </Box>
            ) : (
              false
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ReusableCheckboxSidebar;
