import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedTag from "../designed-tag/designed-tag";
import Rule from "../rule/rule";

interface DesignedTabProps {
  tabs: string[];
  tab?: string;
  press: (tab: string) => void;
  mode?: "default" | "line" | "pricing";
  size?: "large" | "medium" | "small";
  properties?: {
    [key: string]: unknown;
  };
  color?: string;
  background?: string;
}

const slugify = (s: string) =>
  s
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

const DesignedTabLineItem = ({
  press,
  label,
  active,
  tag,
}: {
  press: () => void;
  label: string;
  tag?: string;
  active: boolean;
}) => {
  return (
    <Box
      press={press}
      parse="mr:32 d:inline-flex pb:20 a:center"
      style={{
        borderBottom: active
          ? `2px solid ${Colors.primary}`
          : "2px solid transparent",
      }}
      native={{
        cypress: `tab-${slugify(label)}`,
      }}
    >
      <Rule
        style={{ whiteSpace: "nowrap" }}
        rule="ls"
        color={active ? Colors.primary : Colors.font1}
      >
        {label}
      </Rule>
      {tag ? (
        <DesignedTag
          properties={{
            left: 6,
          }}
          label={tag}
          size="small"
          color={Colors.background}
          background={Colors.primary}
        />
      ) : undefined}
    </Box>
  );
};

export const DesignedTab = (props: DesignedTabProps) => {
  const {
    tabs,
    tab,
    press,
    size = "small",
    mode = "default",
    properties,
    color = Colors.font1,
    background = Colors.entity,
  } = props;
  const [current, _current] = useState(tab || tabs[0]);
  useEffect(() => {
    _current(tab || tabs[0]);
  }, [tab]);

  const PADDINGS = {
    large: 10,
    medium: 5,
    small: 3,
  };

  const handleTabClick = (label: string) => {
    if (press) {
      press(label);
    }
    _current(label);
  };

  switch (mode) {
    case "line":
      return (
        <Box
          parse={`d:flex a:center ox:auto`}
          css="--no-scrollbar"
          style={{ borderBottom: "1px solid #2D3542" }}
          {...properties}
        >
          {tabs.map((label) => {
            const _label = label?.includes("(") ? label.split("(")[0] : label;
            const _tag = label?.includes("(")
              ? label.split("(")[1]?.replace(")", "")
              : undefined;

            return (
              <DesignedTabLineItem
                key={label}
                label={_label}
                tag={_tag}
                press={() => handleTabClick(label)}
                active={label === current}
              />
            );
          })}
        </Box>
      );

    case "pricing":
      return (
        <Box
          parse={`d:flex a:center h:42 br:6 pa:3 c:?primary`}
          {...properties}
        >
          {tabs.map((label) => (
            <Box
              key={label}
              parse="br:5 h:36 d:flex a:center j:center f:1"
              color={label === current ? "white" : "transparent"}
              press={() => handleTabClick(label)}
            >
              <Rule parse="!ls c:?background">{label}</Rule>
            </Box>
          ))}
        </Box>
      );

    default:
      return (
        <Box
          parse={`d:inline-flex br:10 a:center pa:${PADDINGS[size]} c:${Colors.accent}`}
          {...properties}
        >
          {tabs.map((label) => (
            <DesignedTag
              background={label === current ? background : "transparent"}
              color={label === current ? color : Colors.font4}
              press={() => handleTabClick(label)}
              label={label}
              size={size}
            />
          ))}
        </Box>
      );
  }
};

export default DesignedTab;
