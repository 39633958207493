import {
  CSSProperties,
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";

interface ModalContentProps {
  children?: ReactNode;
  closeOnEsc?: boolean;
  centered?: boolean;
  contentIsFocusable?: boolean;
  hideContentWrapper?: boolean;
  maxWidth?: number;
  onClose(): void;
  style?: CSSProperties;
}

export const ModalContent: FC<ModalContentProps> = ({
  children,
  closeOnEsc,
  centered,
  contentIsFocusable,
  onClose,
  hideContentWrapper,
  maxWidth,
  style,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (modalRef?.current && !contentIsFocusable) {
      modalRef.current.focus();
    }
  }, [contentIsFocusable]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        if (closeOnEsc) {
          onClose?.();
        }

        event.stopPropagation();
      }
    },
    [closeOnEsc, onClose]
  );

  return (
    <div
      tabIndex={1}
      ref={modalRef}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: centered ? "center" : "flex-start",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "var(--modal-z-index)",
        overflow: "auto",
        outline: "none",
      }}
      onKeyDown={onKeyDown}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        onClose();
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <section
        className="modal__content"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          maxWidth: maxWidth || "var(--modal-sizes-lg)",
          outline: "2px solid transparent",
          outlineOffset: "2px",
          color: "inherit",
          marginTop: centered ? "auto" : "4rem",
          marginBottom: centered ? "auto" : "4rem",
          marginInlineStart: "auto",
          marginInlineEnd: "auto",
          zIndex: "var(--modal-z-index)",
          animation: "fadeIn 0.3s forwards",
          borderRadius: "var(--modal-radii-md)",
          ...(!hideContentWrapper
            ? {
                backgroundColor: "var(--foreground)",
                boxShadow: "var(--modal-shadow)",
              }
            : {}),
          ...style,
        }}
        role="dialog"
        tabIndex={-1}
        aria-modal={true}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
      >
        {children}
      </section>
    </div>
  );
};
