import { mixed, number, object, string } from "yup";

export const DiscountSchema = object({
  id: string(),
  type: mixed().oneOf([
    "DISCRETIONARY",
    "PROMOTIONAL",
    "TRADE_ORGANIZATION",
    string(),
  ]),
  percentage: number().optional().nullable(),
  amount: string().optional().nullable(),
  promotionalDiscount: object({
    id: string(),
    promotionName: string(),
    promotionCode: string(),
    percentage: number().optional().nullable(),
    amount: string().optional().nullable(),
  }).optional(),
  tradeDiscount: object({
    id: string(),
    association: string(),
    membershipNumber: string(),
    percentage: number().optional().nullable(),
    amount: string().optional().nullable(),
  }).optional(),
});
