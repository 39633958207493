export class Currency {
  static format(
    value: number,
    currency: string,
    digits?: number,
    locale?: string
  ) {
    return new Intl.NumberFormat(locale || "en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: digits || 2,
    }).format(value);
  }

  static formatInteger(value: number, currency: string, locale?: string) {
    return new Intl.NumberFormat(locale || "en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  static symbol(n: number, symbol: string = "$") {
    return symbol + n.toLocaleString();
  }
}

export default Currency;
