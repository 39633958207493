import { useGradient } from "@with-nx/hooks-n-helpers";

export const GradientHolder = ({
  src,
  render,
}: {
  src: string;
  render: (data: ReturnType<typeof useGradient>) => JSX.Element;
}) => {
  const gradient = useGradient(src);

  return render(gradient);
};

export default GradientHolder;
