import { ChangeEvent, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon from "../designed-icon/designed-icon";
import Rule from "../rule/rule";

interface DesignedStepperProps {
  size: number;
  value: number;
  weight?: "filled" | "bold" | "regular";
  press?: (value: number) => void;
  properties?: {
    [key: string]: unknown;
  };
  mode?: "default" | "input";
  minValue?: number;
  maxValue?: number;
  disable?: boolean;
}

export const DesignedStepper = (props: DesignedStepperProps) => {
  const {
    weight = "regular",
    value,
    press,
    properties,
    mode,
    minValue = 0,
    maxValue = Number.MAX_SAFE_INTEGER,
    disable,
  } = props;

  const height = weight === "filled" ? 24 : weight === "bold" ? 20 : 16;
  const rule = weight === "filled" ? "ll" : weight === "bold" ? "ls" : "lt";

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(e.target.value, 10);
    if (
      !isNaN(inputValue) &&
      inputValue >= minValue &&
      inputValue <= maxValue
    ) {
      if (press) {
        press(inputValue);
      }
      _data(inputValue);
    }
  };

  const [data, _data] = useState<number>(value);
  useEffect(() => {
    _data(value);
  }, [value]);

  return (
    <Box
      parse="d:inline-flex a:center"
      {...properties}
      opacity={disable ? 0.5 : properties?.["disable"] || 0}
    >
      <Box
        color={Colors.foreground}
        width={height}
        height={height}
        radius={5}
        parse="d:inline-flex a:center j:center"
        press={() => {
          if (disable) {
            return;
          }

          if (data > minValue) {
            if (press) {
              press(data - 1);
            }
            _data(data - 1);
          }
        }}
      >
        <DesignedIcon
          name="minus"
          weight={weight}
          size={16}
          color={Colors.entity}
        />
      </Box>

      {mode === "input" ? (
        <input
          type="number"
          value={data}
          min={minValue}
          max={maxValue}
          onChange={handleInputChange}
          disabled={disable}
          style={{
            height: "32px",
            paddingRight: "10px",
            paddingLeft: "10px",
            borderRadius: "5px",
            backgroundColor: "#272E38",
            border: "var(--border)",
            color: Colors.font2,
            textAlign: "center",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        />
      ) : (
        <Rule left={10} right={10} rule={rule} color={Colors.font2}>
          {data}
        </Rule>
      )}

      <Box
        color={Colors.foreground}
        width={height}
        height={height}
        radius={5}
        parse="d:inline-flex a:center j:center"
        press={() => {
          if (disable) {
            return;
          }

          if (data < maxValue) {
            if (press) {
              press(data + 1);
            }
            _data(data + 1);
          }
        }}
      >
        <DesignedIcon
          name="plus"
          weight={weight}
          size={16}
          color={Colors.entity}
        />
      </Box>
    </Box>
  );
};

export default DesignedStepper;
