/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";

import useService from "./useService";

interface GlobalAsset {
  name: string;
  file?: string;
}

export const useGlobalAssets = () => {
  const makeRequestToCMS = useService("accounts", {
    cache: 1_800_000,
  });
  const [data, _data] = useState<GlobalAsset[] | undefined>(undefined);

  const hydrate = useCallback(() => {
    (async () => {
      const request = (await makeRequestToCMS(
        "GET",
        "/api/v2/support/global_assets"
      )) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, []);

  useEffect(() => {
    hydrate();
  }, []);

  return { globalAssets: data || [], loading: !data };
};
