import AirplayIcon from "./assets/icons/Airplay.svg";
import AppsIcon from "./assets/icons/Apps.svg";
import BackIcon from "./assets/icons/Back.svg";
import CloseIcon from "./assets/icons/Close.svg";
import DownloadIcon from "./assets/icons/Download.svg";
import DuplicateIcon from "./assets/icons/Duplicate.svg";
import FadeIcon from "./assets/icons/Fade.svg";
import FilmIcon from "./assets/icons/Film.svg";
import HelpIcon from "./assets/icons/Help.svg";
import HideIcon from "./assets/icons/Hide.svg";
import KeyIcon from "./assets/icons/Key.svg";
import LibraryIcon from "./assets/icons/Library.svg";
import LogoutIcon from "./assets/icons/Logout.svg";
import NextIcon from "./assets/icons/Next.svg";
import OffIcon from "./assets/icons/Off.svg";
import OnIcon from "./assets/icons/On.svg";
import PauseIcon from "./assets/icons/Pause.svg";
import PictureIcon from "./assets/icons/Picture.svg";
import RefreshIcon from "./assets/icons/Refresh.svg";
import SettingsIcon from "./assets/icons/Settings.svg";
import ShowIcon from "./assets/icons/Show.svg";
import StagePlayerIcon from "./assets/icons/StagePlayer.svg";
import ToolsIcon from "./assets/icons/Tools.svg";
import TrashIcon from "./assets/icons/Trash.svg";
import TypeIcon from "./assets/icons/Type.svg";
import UploadIcon from "./assets/icons/Upload.svg";
import WarningIcon from "./assets/icons/Warning.svg";

interface Props {
  name: string;
  size: number | string;
  color: string;
  press?: () => void | null | undefined;
}

const Icon = ({ name, size, color, press }: Props) => {
  let icon = "";

  switch (name) {
    case "airplay":
      icon = AirplayIcon;
      break;
    case "apps":
      icon = AppsIcon;
      break;
    case "back":
      icon = BackIcon;
      break;
    case "close":
      icon = CloseIcon;
      break;
    case "download":
      icon = DownloadIcon;
      break;
    case "duplicate":
      icon = DuplicateIcon;
      break;
    case "fade":
      icon = FadeIcon;
      break;
    case "film":
      icon = FilmIcon;
      break;
    case "help":
      icon = HelpIcon;
      break;
    case "hide":
      icon = HideIcon;
      break;
    case "key":
      icon = KeyIcon;
      break;
    case "library":
      icon = LibraryIcon;
      break;
    case "logout":
      icon = LogoutIcon;
      break;
    case "next":
      icon = NextIcon;
      break;
    case "off":
      icon = OffIcon;
      break;
    case "on":
      icon = OnIcon;
      break;
    case "pause":
      icon = PauseIcon;
      break;
    case "picture":
      icon = PictureIcon;
      break;
    case "refresh":
      icon = RefreshIcon;
      break;
    case "settings":
      icon = SettingsIcon;
      break;
    case "show":
      icon = ShowIcon;
      break;
    case "stageplayer":
      icon = StagePlayerIcon;
      break;
    case "tools":
      icon = ToolsIcon;
      break;
    case "trash":
      icon = TrashIcon;
      break;
    case "type":
      icon = TypeIcon;
      break;
    case "upload":
      icon = UploadIcon;
      break;
    case "warning":
      icon = WarningIcon;
      break;
    default:
      icon = "";
      break;
  }

  icon = (
    icon as unknown as {
      src: string;
    }
  )?.src
    ? (
        icon as unknown as {
          src: string;
        }
      ).src
    : icon;

  return (
    <span
      onClick={press}
      className="--icon"
      style={{
        width: size,
        height: size,
        backgroundColor: color,
        mask: "url(" + icon + ")",
        WebkitMask: "url(" + icon + ")",
        cursor: press ? "pointer" : undefined,
        maskSize: "cover",
        WebkitMaskSize: "cover",
      }}
    />
  );
};

export default Icon;
