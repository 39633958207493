import type { Blob } from "@rails/activestorage";

export class UploadsUtils {
  static async createDirectUpload(
    file: File,
    onProgress?: (percentage: number) => void
  ) {
    const base = process.env["NEXT_PUBLIC_API_ORIGIN"] + "/api/v2/";
    const url = new URL("direct_uploads", `${base}`);
    const upload = (await import("@rails/activestorage")).DirectUpload;
    const direct = new upload(file, url.toString(), {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener(
          "progress",
          ({ loaded, total }) =>
            onProgress && onProgress((loaded / total) * 100)
        );
      },
    });
    return new Promise<Blob>((resolve, reject) => {
      direct.create((error, attributes) => {
        if (error) reject(error);
        else resolve(attributes);
      });
    });
  }
}

export default UploadsUtils;
