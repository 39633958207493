import { useAuth } from "@with-nx/auth";
import { useBusiness } from "@with-nx/hooks-n-helpers";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { SteppedGetStartedForm } from "../contact-form/stepped-get-started-form";
import { GetStartedDetails } from "../contact-form/contact-form";

export const AutomaticGetStarted = () => {
  const [ready, _ready] = useState(false);
  const [valid, _valid] = useState(false);
  const [details, _details] = useState<GetStartedDetails | undefined>(
    undefined
  );

  const router = useRouter();

  const submit = async () => {
    router.push("/contact/start/thank-you");
  };

  const auth = useAuth();
  const business = useBusiness();

  useEffect(() => {
    setTimeout(() => {
      _ready(true);
    }, 200);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <SteppedGetStartedForm
        value={details}
        initial={{
          name: auth?.authState?.session?.user?.name,
          email: auth?.authState?.session?.user?.email,
          customer_type: !auth?.authState?.session?.user?.email
            ? "New Customer"
            : "Returning Customer",
          organization_type: business?.type?.name,
          organization: auth?.authState?.session?.user?.organization,
        }}
        action="get-started"
        referrer={window?.location?.pathname}
        change={(details, valid) => {
          _details(details);
          _valid(valid);
        }}
        submit={submit}
        align="left"
      />
    </>
  );
};

export default AutomaticGetStarted;
