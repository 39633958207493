import {
  DesignedBadge,
  DesignedIcon,
  DesignedTag,
  LoadingShimmer,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

interface AppliedDiscountItemProps {
  loading?: boolean;
  name?: string;
  badge?: string;
  bottom?: number;
  mode?: "approved" | "pending" | "expired";
}

export const AppliedDiscountItem = (props: AppliedDiscountItemProps) => {
  const { name, badge, loading, bottom = 10, mode } = props;

  return loading ? (
    <Box parse="br:5 pa:16 d:flex a:center c:?foreground" bottom={bottom}>
      <LoadingShimmer parse="c:?foreground h:24 w:24 mr:16 br:5" />
      <Box parse="f:1 d:flex a:center j:space-between">
        <Box parse="d:flex a:center f:1 mr:10 fw:wrap">
          <LoadingShimmer parse="c:?foreground h:28 mt:2 mb:2 w:193 mr:10 br:5" />
          <LoadingShimmer parse="c:?foreground h:24 w:86 br:5" />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box parse="br:5 pa:16 d:flex a:center c:?foreground" bottom={bottom}>
      <Box parse="w:24 h:24 mr:16">
        <DesignedIcon name="discount/regular" size={24} color="var(--font1)" />
      </Box>
      <Box parse="f:1 d:flex a:center j:space-between">
        <Box parse="d:inline-flex a:center f:1 mr:10 fw:wrap">
          <Rule rule="ll" display="block" right={10}>
            {name}
          </Rule>
          {badge ? <DesignedBadge label={badge} size="small" /> : undefined}
          {mode ? (
            <DesignedTag
              background={
                mode === "approved" ? "var(--positive)" : "var(--accent)"
              }
              label={
                mode === "approved"
                  ? "Approved"
                  : mode === "expired"
                  ? "Expired"
                  : "Pending Approval"
              }
              size="tiny"
            />
          ) : undefined}
        </Box>
      </Box>
    </Box>
  );
};

export default AppliedDiscountItem;
