import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { PlaceholderImage } from "@with-nx/simple-ui/atoms";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface GenericContentHolderProps {
  image?: string;
  webp?: string;
  video?: string;
  alt?: string;
  pop?: boolean;
  reference?: (reference: unknown) => unknown;
  autoplay?: boolean;
  fit?: "cover" | "contain";
  [key: string]: unknown;
  hidden?: boolean;
}

export const GenericContentHolder = (props: GenericContentHolderProps) => {
  const { image, video, pop = true, reference, autoplay, webp } = props;
  const [open, _open] = useState(false);

  if (props.hidden) {
    return null;
  }

  return (
    <>
      {pop ? (
        <Modal
          onClose={() => _open(false)}
          closeOnOverlayClick={true}
          isOpen={open}
        >
          <ModalOverlay />
          <ModalContent className="bg-brand-foreground rounded-brand">
            {image ? (
              <img
                loading="lazy"
                alt={props.alt}
                src={image}
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: 5,
                  overflow: "hidden",
                }}
              />
            ) : video ? (
              <video
                src={video}
                loop={true}
                autoPlay={true}
                muted={true}
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: 5,
                  overflow: "hidden",
                }}
              />
            ) : (
              <PlaceholderImage
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderRadius: 5,
                  overflow: "hidden",
                }}
              />
            )}
          </ModalContent>
        </Modal>
      ) : undefined}
      <Box
        press={
          pop
            ? () => {
                if (pop) {
                  _open(true);
                }
              }
            : undefined
        }
        {...{ ...props, reference: undefined }}
      >
        {image && webp ? (
          <img
            loading="lazy"
            alt={props.alt}
            src={(webp as string) || (image as string)}
            style={{
              width: "100%",
              height: "100%",
              objectFit: props.fit || "cover",
            }}
            className="full-image-important"
          />
        ) : image ? (
          <img
            loading="lazy"
            alt={props.alt}
            src={image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: props.fit || "cover",
            }}
            ref={(ref) => reference?.(ref)}
          />
        ) : video ? (
          <video
            src={video}
            loop={true}
            autoPlay={autoplay}
            muted={true}
            style={{
              width: "100%",
              height: "100%",
              objectFit: props.fit || "cover",
            }}
            ref={(ref) => reference?.(ref)}
          />
        ) : (
          <PlaceholderImage style={{ width: "100%", height: "100%" }} />
        )}
      </Box>
    </>
  );
};

export default GenericContentHolder;
