const Colors = {
  background: "#0E1115",
  foreground: "#1E242D",
  accent: "#2D3542",
  entity: "#59687B",
  font1: "#FFFFFF",
  font2: "#E1E1E1",
  font3: "#AFAFAF",
  font4: "#878787",
  positive: "#00A04D",
  negative: "#FC2A50",
  warning: "#FF9500",
  branding: "#E2B576",
  secondary: "#FADBB0",
  tetriary: "#806135",
};

export default Colors;
