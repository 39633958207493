import Formatter from "../Formatter";
import {
  Act,
  CueSheet,
  CurtainWarmer,
  Scene,
  ServerAct,
  ServerCurtainWarmer,
  ServerScene,
  ServerShow,
  ServerVideoSet,
  ShowData,
  VideoSet,
} from "./types/editor";

export class ShowSync {
  static createShowDataFromServerData(serverData: ServerShow) {
    const showData = this.transformShowData(serverData);

    return showData;
  }

  static transformShowData = (serverData: ServerShow): ShowData => {
    return {
      curtainWarmer: serverData.curtain_warmer
        ? this.transformCurtainWarmer(serverData.curtain_warmer)
        : undefined,
      acts: serverData.acts.map((act) =>
        this.transformAct(act, serverData.sets)
      ),
      version: 1,
      options: {
        curtainWarmers: {
          useMaster: false,
          endOfAct: true,
          startOfShow: true,
        },
        blackouts: {
          afterWarmers: true,
          endOfScene: false,
          endOfAct: false,
        },
      },
    };
  };

  static transformCurtainWarmer = (
    data: ServerCurtainWarmer
  ): CurtainWarmer => ({
    curtainWarmer: data.curtain_warmer,
    curtainWarmerThumbnail: data.curtain_warmer_thumbnail,
  });

  static transformAct = (act: ServerAct, sets: ServerVideoSet[]): Act => ({
    ...act,
    sortIdentifier: Formatter.uuid(),
    hidden:
      act.hidden !== undefined
        ? act.hidden
        : act?.active === undefined || act?.active === null
        ? false
        : !act.active,
    scenes: act.scenes.map((scene) => this.transformScene(scene, sets)),
  });

  static transformScene = (
    scene: ServerScene,
    sets: ServerVideoSet[]
  ): Scene => ({
    ...scene,
    disabled:
      scene.disabled !== undefined
        ? scene.disabled
        : scene?.active === undefined || scene?.active === null
        ? false
        : !scene.active,
    sortIdentifier: Formatter.uuid(),
    sets: scene.sets.map((setRef) => {
      const serverSet = sets.find(
        (set) => set.identifier === setRef.id
      ) as ServerVideoSet;
      return this.transformVideoSet(serverSet);
    }),
  });

  static transformVideoSet = (item: ServerVideoSet): VideoSet => ({
    identifier: item.identifier,
    action: item.action,
    loop: item.loop,
    still: item.still,
    nextSet: item.next_set,
    thumbnail: item.thumbnail,
    name: item.name,
    cueDescription: item.cue_description,
    cueLine: item.cue_line,
    cuePage: item.cue_page_number,
    enabled:
      item.enabled !== undefined
        ? !item.enabled
        : item?.active === undefined || item?.active === null
        ? true
        : !item.active,
  });

  static transformShowDataToCueSheet = (show: ShowData): CueSheet => {
    const sheet: CueSheet = {
      current: 0,
      items: [],
    };

    sheet.items.push({
      type: "blackout",
      identifier: Formatter.uuid(),
      name: "Blackout",
    });

    show.acts.map((act) => {
      act.scenes.map((scene) => {
        scene.sets.map((set) => {
          if (set.action && set.loop) {
            sheet.items.push({
              type: "set",
              identifier: Formatter.uuid(),
              name: set.name,
              set,
            });
          } else if (set.action || set.loop) {
            sheet.items.push({
              type: "video",
              identifier: Formatter.uuid(),
              name: set.name,
              set,
            });
          } else if (set.still) {
            sheet.items.push({
              type: "image",
              identifier: Formatter.uuid(),
              name: set.name,
              set,
            });
          }
        });

        sheet.items.push({
          type: "blackout",
          identifier: Formatter.uuid(),
          name: "Blackout",
        });
      });
    });

    return sheet;
  };
}

export default ShowSync;
