import { useEffect, useState } from "react";

import styles from "./designed-typer.module.css";

interface DesignedTyperProps {
  sequence: string[];
  wait?: boolean;
  speed?: number;
  delay?: number;
  update?: (full: string) => void;
  press?: (sequence: string) => void;
}

export const DesignedTyper = (props: DesignedTyperProps) => {
  const [text, _text] = useState("");
  const [index, _index] = useState<number>(0);
  const [mode, _mode] = useState<"typing" | "deleting">("typing");
  const {
    sequence,
    wait = false,
    speed = 100,
    delay = 2000,
    update,
    press,
  } = props;

  useEffect(() => {
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, speed));

      if (wait) {
        return;
      }

      const full = sequence[index];
      if (update) {
        update(full);
      }

      if (mode === "typing") {
        if (text.length !== full.length) {
          _text(full.substring(0, text.length + 1));
        } else {
          await new Promise((resolve) => setTimeout(resolve, delay));
          _mode("deleting");
          _index(index === sequence.length - 1 ? 0 : index + 1);
        }
      } else if (mode === "deleting") {
        if (text.length !== 0) {
          _text(text.substring(0, text.length - 1));
        } else {
          if (wait) {
            await new Promise((resolve) => setTimeout(resolve, delay));
          }
          _mode("typing");
        }
      }
    })();
  }, [wait, text, mode, index]);

  return (
    <span className="--typer" onClick={() => press?.(sequence[index])}>
      {text} <span className={styles?.["--typer-animation"]} />
    </span>
  );
};

export default DesignedTyper;
