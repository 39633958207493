import { Scene, ShowData } from "libs/editor-ui/types";

export interface EditorSceneWrapperProps {
  scene: Scene;
  actIndex: number;
  sceneIndex: number;
  render: (props: EditorSceneWrapperProps) => React.ReactNode;
  children: React.ReactNode;
  enabled: boolean;
  onSceneMove: (
    actIndex: number,
    oldSceneIndex: number,
    newSceneIndex: number
  ) => void;
  onSceneDuplicate: (actIndex: number, sceneIndex: number) => void;
  onSceneDisable: (actIndex: number, sceneIndex: number) => void;
  onSceneEnable: (actIndex: number, sceneIndex: number) => void;
  actEnabled?: boolean;
  data: ShowData;
  onDangerousDataUpdate?: (data: ShowData) => void;
}

const EditorSceneWrapper = (props: EditorSceneWrapperProps) => {
  const { render } = props;

  return render(props) as JSX.Element;
};

export default EditorSceneWrapper;
