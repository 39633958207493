/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@with-nx/auth";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { ShoppingCartMethodProductType } from "../cart";
import useShoppingCart from "./useShoppingCart";

export const useSimpleShoppingCart = () => {
  const [items, _items] = useState<Array<number | string>>([]);
  const [pitems, _pitems] = useState<Array<number | string>>([]);
  const auth = useAuth();
  const token = auth?.authState?.session?.token as string;
  const { methods, utils } = useShoppingCart();
  const authenticated = auth?.authState?.status === "authenticated";
  const router = useRouter();

  const add = useCallback(
    async (
      id: string | number,
      type: ShoppingCartMethodProductType,
      options?: {
        proLicense?: boolean;
        rentalStart?: string;
        rentalEnd?: string;
      }
    ) => {
      if (auth.authState.status !== "authenticated") {
        if (window) {
          const _url = new URL("/auth", window.location.origin);
          _url.searchParams.set("redirect", window.location.pathname);
          router.push(_url.toString());
        }
      } else {
        (() => {
          if (options?.proLicense && items.includes(id)) {
            _items((prevItems) => prevItems.filter((item) => item !== id));
          } else if (!options?.proLicense && pitems.includes(id)) {
            _pitems((prevItems) => prevItems.filter((item) => item !== id));
          }
        })();

        methods.product.add({ id, token, options, type });
        if (options?.proLicense) {
          _pitems((prevItems) => [...prevItems, id]);
        } else {
          _items((prevItems) => [...prevItems, id]);
        }
      }
    },
    [items, pitems, auth?.authState?.status]
  );

  const added = useCallback(
    (id, pro?: boolean) => {
      if (pro && pitems.includes(id)) {
        return true;
      }
      if (!pro && items.includes(id)) {
        return true;
      }

      if (pro === undefined) {
        return utils.exists({
          id,
        });
      }
      return utils.exists({
        id,
        options: { proLicense: pro },
      });
    },
    [items, pitems]
  );

  const remove = useCallback(
    async (id) => {
      await methods.product.remove({ id });
      _items((prevItems) => prevItems.filter((item) => item !== id));
      _pitems((prevItems) => prevItems.filter((item) => item !== id));
    },
    [items, pitems]
  );

  const transform = (data: any) => {
    return data as any;
  };

  const discount = async (code: string) => {
    if (auth.authState.status !== "authenticated") {
      if (window) {
        const _url = new URL("/auth", window.location.origin);
        _url.searchParams.set("redirect", window.location.pathname);
        router.push(_url.toString());
      }
    } else {
      await methods.discount.promotional.apply(code);
    }
  };

  const ready = auth.authState.status === "authenticated";

  return {
    add,
    added,
    authenticated,
    transform,
    items,
    discount,
    remove,
    ready,
  };
};

export default useSimpleShoppingCart;
