import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  DesignedCard,
  DesignedIcon,
  PlaceholderImage,
  Rule,
} from "@with-nx/simple-ui/atoms";
import {
  MobileViewSwitcher,
  TABLET_BREAKPOINT,
} from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import Image from "next/image";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ReusableSimpleHeroSectionProps {
  image?: string;
  title?: string;
  description?: string;
  logo?: string;
  licensor?: {
    image: string;
    url: string;
    name: string;
  };
  show?: {
    image?: string;
    color?: string;
    name?: string;
  };
  close?: () => void;
  gradient?: "top-bottom" | "top";
  container?: boolean;
  press?: () => void;
}

const ReusableSimpleHeroSectionContainer = ({
  container,
  children,
}: {
  container: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  return container ? (
    <div className="container">{children}</div>
  ) : (
    <Box parse="pl:48 pr:48">{children}</Box>
  );
};

export const ReusableSimpleHeroSection = ({
  image,
  title,
  description,
  logo,
  licensor,
  close,
  gradient = "top-bottom",
  container = true,
  press,
  show,
}: ReusableSimpleHeroSectionProps) => {
  const _image =
    "/assets/libs/simple-ui/reusable-simple-hero-section/assets/radial-cold-gradient.webp";

  const [more, _more] = useState(false);

  return (
    <MobileViewSwitcher
      width={TABLET_BREAKPOINT}
      render={(mobile) => (
        <Box css="p:relative w:100% oa:hidden" parse="h:450">
          <Modal
            isOpen={more}
            blockScrollOnMount={false}
            onClose={() => {
              _more(false);
            }}
            size={{ base: "sm", md: "lg" }}
          >
            <ModalOverlay />
            <ModalContent borderRadius="md" backgroundColor="var(--foreground)">
              <ModalHeader color="var(--font1)">{title}</ModalHeader>
              <ModalBody>
                <Rule
                  rule="ls"
                  display="block"
                  bottom={24}
                  color="var(--font4)"
                >
                  {description}
                </Rule>
              </ModalBody>
            </ModalContent>
          </Modal>
          {close ? (
            <Box css="l:unset b:unset p:absolute z:4 i:4" parse="t:24 r:24">
              <DesignedIcon
                name="multiply/bold"
                color="#FFFFFF"
                press={close}
              />
            </Box>
          ) : undefined}
          <Box
            parse={`z:5 i:5 p:absolute w:100% h:100% t:0 l:0 pt:${
              mobile ? 16 : 48
            } pb:${mobile ? 16 : 48} d:flex a:flex-end`}
          >
            <ReusableSimpleHeroSectionContainer container={container}>
              <Row gutter={[36, 18]}>
                <Col xs={24} sm={24} md={24} lg={15} xl={16}>
                  {logo ? (
                    <img
                      loading="lazy"
                      alt={title}
                      style={{
                        height: 73,
                        width: "auto",
                      }}
                      src={logo}
                    />
                  ) : undefined}
                  <Box css="d:flex a:flex-start">
                    {show && !mobile && description ? (
                      <Box parse="w:38% mr:24">
                        <DesignedCard
                          size="16x9"
                          inside={{
                            large: show.image ? undefined : show.name,
                            image: show.image,
                            color: show.color || "#000000",
                          }}
                        />
                      </Box>
                    ) : undefined}
                    <Box css="f:1">
                      {title ? (
                        <Box css="d:flex a:center j:space-between">
                          <Rule
                            rule="dt"
                            color="var(--font1)"
                            display="block"
                            press={press}
                          >
                            {title?.trim()}
                          </Rule>
                          {mobile && licensor ? (
                            <img
                              loading="lazy"
                              alt={licensor.name}
                              style={{
                                height: 110,
                                width: "auto",
                                cursor: "pointer",
                              }}
                              src={licensor.image}
                              onClick={() => {
                                if (window) {
                                  window.location.href = licensor.url;
                                }
                              }}
                            />
                          ) : undefined}
                        </Box>
                      ) : undefined}
                      {description ? (
                        <Rule
                          rule="ls"
                          color="var(--font3)"
                          display="block"
                          top={mobile && licensor ? 0 : 22}
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            lineClamp: 4,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {description}
                        </Rule>
                      ) : undefined}
                      {description && description?.length > 384 ? (
                        <Rule
                          rule="lt"
                          color="var(--primary)"
                          display="block"
                          top={6}
                          press={() => _more(true)}
                        >
                          Read More
                        </Rule>
                      ) : undefined}
                    </Box>
                  </Box>
                </Col>
              </Row>
            </ReusableSimpleHeroSectionContainer>
          </Box>
          {licensor && !mobile ? (
            <Box
              parse={`z:4 i:4 p:absolute w:100% h:100% t:0 l:0 pt:${
                mobile ? 16 : 48
              } pb:${mobile ? 16 : 48} d:flex a:flex-end`}
            >
              <ReusableSimpleHeroSectionContainer container={container}>
                <Box css="d:flex a:center j:flex-end">
                  <img
                    loading="lazy"
                    alt={licensor.name}
                    style={{
                      height: "auto",
                      width: 128,
                      cursor: "pointer",
                    }}
                    src={licensor.image}
                    onClick={() => {
                      if (window) {
                        window.location.href = licensor.url;
                      }
                    }}
                  />
                </Box>
              </ReusableSimpleHeroSectionContainer>
            </Box>
          ) : undefined}
          <Box
            parse="z:3 i:3 p:absolute w:100% h:100% t:0 l:0 oa:hidden"
            style={{
              backgroundImage:
                gradient === "top-bottom"
                  ? "linear-gradient(270.05deg, rgba(14, 17, 21, 0) 0.05%, rgba(14, 17, 21, 0.4) 99.97%), linear-gradient(181.28deg, rgba(14, 17, 21, 0) 1.1%, #0E1115 98.91%)"
                  : gradient === "top"
                  ? "linear-gradient(180deg, #0E1115 0%, rgba(14, 17, 21, 0) 40.83%, rgba(14, 17, 21, 0) 60.83%, #0E1115 100%)"
                  : undefined,
            }}
          />
          <Box
            parse="z:2 i:2 p:absolute w:100% h:100% t:0 l:0 oa:hidden"
            opacity={image ? 0.5 : 1}
          >
            <Image src={_image} layout="fill" objectFit="cover" />
          </Box>

          {image ? (
            <Box
              parse="z:1 i:1 p:absolute w:100% h:100% t:0 l:0"
              id="-reusable-simple-hero-section"
            >
              <Image
                src={image}
                layout="fill"
                objectFit="cover"
                objectPosition="top center"
                priority
              />
            </Box>
          ) : (
            <PlaceholderImage
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          )}
        </Box>
      )}
    />
  );
};

export default ReusableSimpleHeroSection;
