import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";

import useService from "../useService";
import CollectionSync, {
  DropCollectionDownloadResponse,
  DropCollectionDownloadScheme,
} from "./CollectionSync";
import EditorSync, { EditorSyncData, EditorSyncFiles } from "./EditorSync";
import RentalSync, {
  RentalDownloadResponse,
  RentalDownloadScheme,
} from "./RentalSync";
import ShowSync from "./ShowSync";
import { ShowData } from "./types/editor";

type EditorSyncMeta = {
  type: "collection" | "rental";
  id: string;
  name: string;
  data: DropCollectionDownloadResponse | RentalDownloadResponse;
};

export const useStagePlayerEditorSync = ({ rental }: { rental?: string }) => {
  const [data, _data] = useState<ShowData | undefined>(undefined);
  const [files, _files] = useState<EditorSyncFiles | undefined>(undefined);
  const [loading, _loading] = useState(true);
  const [error, _error] = useState<boolean | string>(false);
  const makeRequestToAccount = useService("accounts");
  const auth = useAuth();
  const token = auth.authState.session?.token;
  const [meta, _meta] = useState<EditorSyncMeta | undefined>(undefined);
  const [history, _history] = useState<EditorSyncData[]>([]);

  const methods = {
    get: async (id: string) => {
      try {
        if (!token) {
          return;
        }

        const latest = await EditorSync.getLatestVersion(token, id);

        if (latest) {
          if (EditorSync.verifyEditorData(latest?.editorData)) {
            return latest.editorData as ShowData;
          }
        }

        return undefined;
      } catch (error) {
        console.warn(error);
        return undefined;
      }
    },
    create: async (id: string, data: ShowData) => {
      try {
        if (!token) {
          return;
        }

        return await EditorSync.createVersion(token, id, data);
      } catch (error) {
        console.warn(error);
        return undefined;
      }
    },
    all: async (id: string) => {
      try {
        if (!token) {
          return;
        }

        const versions = await EditorSync.getLatestVersions(token, id);

        return versions.filter((version) =>
          EditorSync.verifyEditorData(version?.editorData)
        );
      } catch (error) {
        console.warn(error);
        return undefined;
      }
    },
  };

  const helpers = {
    collection: {
      get: async (id: string) => {
        try {
          const dropCollection = await DropCollectionDownloadScheme.validate(
            await makeRequestToAccount(
              "POST",
              "/api/v2/stageplayer/drop-collection-downloads",
              {
                data: {
                  type: "drop-collection-downloads",
                  relationships: {
                    "drop-collection": {
                      data: {
                        type: "drop-collections",
                        id: id,
                      },
                    },
                  },
                },
              },
              {
                "Content-Type": "application/vnd.api+json",
                Authorization: `Bearer ${token}`,
              }
            )
          );

          const manifest =
            CollectionSync.createServerDataFromDropCollections(dropCollection);

          if (manifest) {
            _meta({
              type: "collection",
              id,
              name: "Drop Collection Editor",
              data: dropCollection,
            });
            const files =
              CollectionSync.createFilesFromDropCollections(dropCollection);
            _files(files);
            const show = ShowSync.createShowDataFromServerData(manifest);

            const latest = await methods.get(id);

            _data(latest || show);
            _loading(false);
          } else {
            _error(
              "An error occurred while trying to create show data from your drop collection. Please try again later."
            );
          }
        } catch (error) {
          console.log("🎭", error);
          _error(
            "An error occurred while trying to download your drop collection. Please try again later."
          );
        }
      },
    },
    rental: {
      get: async (id: string, override?: boolean) => {
        try {
          const rental = await RentalDownloadScheme.validate(
            await makeRequestToAccount(
              "POST",
              "/api/v2/stageplayer/rental-downloads",
              {
                data: {
                  type: "rental-downloads",
                  relationships: {
                    rental: {
                      data: {
                        type: "rentals",
                        id: id,
                      },
                    },
                  },
                },
              },
              {
                "Content-Type": "application/vnd.api+json",
                Authorization: `Bearer ${token}`,
              }
            )
          );

          const manifest = RentalSync.createServerDataFromRental(rental);

          if (manifest) {
            _meta({
              type: "rental",
              id,
              name: "Show Editor",
              data: rental,
            });
            const show = ShowSync.createShowDataFromServerData(manifest);
            const files = RentalSync.createFilesFromRental(rental);
            _files(files);

            const latest = override ? undefined : await methods.get(id);

            _data(latest || show);
            _loading(false);
          } else {
            _error(
              "An error occurred while trying to create show data from your rental. Please try again later."
            );
          }
        } catch (error) {
          console.log("🎭", error);
          _error(
            "An error occurred while trying to download your rental. Please try again later."
          );
        }
      },
      add: {
        drops: async (id: string[]) => {
          await makeRequestToAccount(
            "PUT",
            `/api/v2/scenic_projections_rentals/${rental}/add_digital_drop_scene`,
            {
              digital_drop_scene_id: Number(id?.[0]),
            },
            {
              Authorization: `Bearer ${token}`,
            }
          );

          helpers.rental.get(String(rental), true);
        },
      },
    },
  };

  useEffect(() => {
    if (!token || !rental) {
      return;
    }

    if (rental.startsWith("@")) {
      helpers.collection.get(rental.replace("@", ""));
    } else {
      helpers.rental.get(rental);
    }

    (async () => {
      const all = await methods.all(rental);
      _history(all || []);
    })();
  }, [token, rental]);

  const set = (data: ShowData) => {
    _data(data);
  };

  return { data, loading, error, helpers, files, set, meta, methods, history };
};

export default useStagePlayerEditorSync;
