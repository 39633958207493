/* eslint-disable @typescript-eslint/no-explicit-any */
import { UploadedFile } from "@with-nx/file";
import { jwtDecode } from "jwt-decode";
import { useRouter } from "next/router";
import * as React from "react";
import { createContext, useContext } from "react";

import { BASE_URL, STORAGE_KEY } from "./utils";
import { CampaignHelper } from "./CampaignHelper";
import RegionMeta from "libs/region/src/meta";

export interface AccountDocument extends UploadedFile {
  status?: string;
  uploaded_at?: string;
  expiration_date?: string;
}

export interface Session {
  token: string;
  user: {
    id: string;
    email: string;
    name: string;
    organization: string;
    coi?: AccountDocument;
    drivers_license?: AccountDocument;
    tax_exemption_document?: AccountDocument;
  };
  choreo_key?: {
    token: string;
    timestamp: string;
    slug: string;
  };
  global_access_key?: {
    token: string;
    timestamp: string;
    rental_ids?: number[];
  };
  is_lead?: boolean;
}

export interface CurrentSession {
  account: {
    email: string;
    type: string;
    name: string;
    organization: string;
    subscription: {
      tier: string;
      start_date: string;
      current_period_end: string;
      current_period_start: string;
      auto_renew: boolean;
      plan_interval: string;
      storage_limit_in_gb: number;
      storage_usage_in_gb: number;
    };
  };
}

export type AuthState = {
  status: "uninitialized" | "unauthenticated" | "authenticated" | "authorized";
  session?: Session;
  currentSession?: CurrentSession;
};

export type Auth = {
  authState: AuthState;
  isLoading: boolean;
  currentSessionState: CurrentSession | null;
  login: (session: Session) => void;
  logout: () => void;
  update: (
    session: Session,
    data?: {
      name?: string;
      organization?: string;
      phone_number?: string;
      business_type?: number;
      region_id?: number;
    }
  ) => void;
  session?: {
    token: string;
  } | null;
};

export const AuthContext = createContext<Auth | undefined>(undefined);

export const withAuthContext = (Component: React.ComponentType<any>) => {
  const Wrapper = (props: any) => {
    const auth = useAuth();

    return <Component {...props} auth={auth} />;
  };

  return Wrapper;
};

export class AuthProviderClass extends React.Component<
  {
    children: React.ReactNode;
    configuration: {
      development?: boolean;
      host?: boolean;
    };
  },
  Auth
> {
  constructor(props: {
    children: React.ReactNode;
    configuration: {
      development?: boolean;
      host?: boolean;
    };
  }) {
    super(props);

    this.state = {
      authState: {
        status: "uninitialized",
      },
      isLoading: true,
      currentSessionState: null,
      login: this.login,
      logout: this.logout,
      update: this.update,
    };
  }

  login = (session: Session, refresh?: boolean) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(session));
    if (session.choreo_key || session.global_access_key) {
      console.log("🔒", "Authorized");
      this.setState((s) => ({
        ...s,
        authState: {
          status: "authorized",
          session,
        },
        isLoading: false,
      }));
    } else {
      console.log("🔒", "Login", refresh ? "Refresh" : "Direct");
      this.setState((s) => ({
        ...s,
        authState: {
          status: "authenticated",
          session,
        },
        isLoading: false,
      }));
    }

    this.forceUpdate();
  };

  logout = () => {
    localStorage.removeItem(STORAGE_KEY);
    this.setState((s) => ({
      ...s,
      authState: {
        status: "unauthenticated",
      },
    }));

    this.forceUpdate();
  };

  update = async (
    session: Session,
    data?: {
      name?: string;
      organization?: string;
      phone_number?: string;
      business_type?: number;
      region_id?: number;
    }
  ) => {
    const { token } = session;
    const { success, error } = await editAccount(token, data);

    if (success) {
      const currentSession = await getCurrentSession(token);
      this.setState((s) => ({
        ...s,
        authState: {
          status: "authenticated",
          session,
        },
        currentSessionState: currentSession,
      }));
    } else {
      throw new Error(error);
    }

    this.forceUpdate();
  };

  override async componentDidMount() {
    try {
      const local = localStorage.getItem(STORAGE_KEY);
      if (local) {
        const parse = JSON.parse(local);

        const parsed = (() => {
          if (this.props?.configuration?.host) {
            if (parse?.["token"]) {
              return parse;
            } else {
              const _data = parse?.["item"]?.data;
              return typeof _data === "string" ? JSON.parse(_data) : _data;
            }
          } else {
            return parse;
          }
        })();

        this.login(parsed);

        if (parsed?.token) {
          console.log("🎾", "Campaigns Reload", Boolean(parsed?.token));
          CampaignHelper.attach(parsed?.token);
        }

        const decodedToken = decodeToken(parsed?.token);
        const shouldRefresh = decodedToken?.exp
          ? decodedToken?.exp * 1000 < Date.now()
          : true;

        if (shouldRefresh) {
          getSession(parsed?.token)
            .then((session) => {
              console.log("🔒", "Refresh");
              this.login(session, true);
            })
            .catch((error) => {
              console.log("🔒", "Logout", error);
              this.logout();
            });
        }
      } else {
        console.log("🔒", "Logout", "No Local Found");
        this.logout();
      }
    } catch (error) {
      console.log("🔒", "Error", error);
      this.logout();
    }
  }

  override render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const AuthGate = ({ children }: { children: JSX.Element }) => {
  const router = useRouter();
  const auth = useAuth();

  if (auth.authState.status === "uninitialized") {
    /** @todo initialize prior to HTML render somehow */
  }

  if (auth.authState.status === "authorized") {
    const session = auth?.authState?.session;

    if (session?.global_access_key?.rental_ids?.length) {
      if ("/library/rentals" !== router.asPath) {
        router.push("/library/rentals");
        return null;
      }
    }

    if (session?.choreo_key?.slug) {
      const slug = `/library/choreography-guides/${session?.choreo_key?.slug}`;

      if (slug !== router.asPath) {
        router.push(slug);
        return null;
      }
    }
  }

  return children;
};

export function useAuth(origin?: string) {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error(
      `useAuth must be used within an "AuthProvider" - ${origin}`
    );

  return context;
}

export async function createSession(email: string, password: string) {
  const url = new URL("session", BASE_URL);
  const body = JSON.stringify({ session: { email, password } });
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body,
  });
  const parsed = await response.json();

  if (!parsed || !parsed.success || !parsed.data) throw new Error();
  return parsed.data as Session;
}

export async function createSessionByKey({
  choreo_key,
  global_access_key,
}: {
  choreo_key?: string;
  global_access_key?: string;
}) {
  const url = new URL("session", BASE_URL);

  const body = JSON.stringify({
    session: choreo_key ? { choreo_key } : { global_access_key },
  });

  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body,
  });
  const parsed = await response.json();
  if (!parsed || !parsed.success || !parsed.data) throw new Error();
  return parsed.data as Session;
}

export async function createSessionToken(token: string) {
  const url = new URL("session", BASE_URL);
  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const parsed = await response.json();
  if (!parsed || !parsed.success || !parsed.data) throw new Error();
  return parsed.data as Session;
}

export async function getSession(token: string) {
  const url = new URL("session", BASE_URL);

  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const parsed = await response.json();

  if (!parsed || !parsed.success || !parsed.data) throw new Error();
  return parsed.data as Session;
}

export async function getCurrentSession(token: string) {
  const url = new URL("account", BASE_URL);
  const response = await fetch(url.toString(), {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const parsed = await response.json();
  return parsed.data as CurrentSession;
}

export async function resetPassword(email: string) {
  const url = new URL("session/reset_password", BASE_URL);
  const body = JSON.stringify({ session: { email } });
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body,
  });
  const parsed = await response.json();
  if (!parsed || !parsed.success) throw new Error();
}

export async function updatePassword(new_password: string, token: string) {
  const url = new URL("update_passwords", BASE_URL);
  const body = JSON.stringify({
    update_password: {
      new_password,
      token,
    },
  });
  const response = await fetch(url.toString(), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body,
  });
  const parsed = await response.json();

  if (!parsed) throw new Error();

  return parsed;
}

export async function editAccount(
  token: string,
  data?: {
    name?: string;
    organization?: string;
    phone_number?: string;
    business_type?: number;
    region_id?: number;
  }
): Promise<{ success: boolean; error?: string }> {
  const url = new URL("account", BASE_URL);
  const body = JSON.stringify({ account: data });
  const response = await fetch(url.toString(), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "/",
    },
    body,
  });
  const parsed = await response.json();
  if (!parsed) throw new Error();

  if (parsed.error) {
    return { success: false, error: String(parsed.error) };
  }

  if (parsed.errors) {
    if (parsed.errors.length) {
      return { success: false, error: parsed.errors[0].details };
    }
  }
  return { success: true };
}

export async function createAccount(
  name: string,
  email: string,
  password: string,
  additional: {
    organization: string;
    business_type: number;
    region_id: number;
    phone_number?: string;
    country?: string;
    state?: string;
  }
): Promise<{ success: boolean; error?: string }> {
  const url = new URL("account", BASE_URL);
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "/",
    },
    body: JSON.stringify({
      account: {
        name,
        email,
        password,
        ...additional,
        business_type: additional?.business_type || 11,
      },
      organization: {
        name: additional.organization,
        type: additional?.business_type || 11,
      },
    }),
  });
  const parsed = await response.json();
  if (!parsed) throw new Error();

  if (parsed.error) {
    return { success: false, error: String(parsed.error) };
  }

  if (parsed.errors) {
    if (parsed.errors.length) {
      return { success: false, error: parsed.errors[0].details };
    }
  }

  if (parsed.data) {
    if (parsed?.data?.token) {
      if (additional.country) {
        try {
          const countryName =
            RegionMeta[additional.country]?.name || "United States of America";
          const countryCode = RegionMeta[additional.country]?.code || "US";
          const stateName = RegionMeta[additional.country]?.name;

          await fetch(
            `${process.env["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"]}/backstage/customer/address`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${parsed?.data?.token}`,
                "Content-Type": "application/json",
                Accept: "/",
              },
              body: JSON.stringify({
                line1: "-",
                line2: "-",
                postalCode: "-",
                defaultShipping: true,
                defaultBilling: true,
                location: {
                  country: {
                    code: countryCode,
                    name: countryName,
                  },
                  state: {
                    code: -1,
                    name: stateName || "-",
                    countryCode: countryCode,
                  },
                  city: {
                    id: -1,
                    name: "-",
                    stateCode: -1,
                  },
                },
              }),
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    }

    return { success: true };
  }

  return { success: true };
}

export async function sendAccessEmails(
  slug: string,
  emails: string[],
  token: string
) {
  const url = new URL("session/send_access_emails", BASE_URL);
  const body = JSON.stringify({ slug, emails });
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body,
  });
  const parsed = await response.json();
  if (!parsed || !parsed.success) throw new Error();
}

const decodeToken = (token: string) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};
