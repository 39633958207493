export class Validators {
  static isValidEmailAddress(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;

    return regex.test(email);
  }

  static isValidFullName(value: string) {
    if (!value) return false;

    const words = value.trim().split(" ");
    const nameHasAtLeastTwoWords = words.length > 1;

    return nameHasAtLeastTwoWords;
  }
}

export default Validators;
