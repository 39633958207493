import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Props {
  render: (
    onActMoveCallback: (dragIndex: number, hoverIndex: number) => unknown
  ) => React.ReactNode;
  mutate: (dragIndex: number, hoverIndex: number) => unknown;
}

const ActMoverWrapper = ({ render, mutate }: Props) => {
  const onActMoveCallback = useCallback(
    (dragIndex: number, hoverIndex: number) => mutate(dragIndex, hoverIndex),
    [mutate]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {render(onActMoveCallback)}
    </DndProvider>
  );
};

export default ActMoverWrapper;
