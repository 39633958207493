import { UseCMSTestimonial, useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedPagination } from "@with-nx/simple-ui/atoms";
import {
  GridStepScroller,
  TestimonialCard,
} from "@with-nx/simple-ui/organisms";
import { createRef, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface TestimonialGalleryProps {
  testimonials: UseCMSTestimonial[];
  per: number;
  showOnlyOne?: boolean;
  variant?: "default" | "large";
  showQuotes?: boolean;
  minWidth?: number;
  hideRightSpacing?: boolean;
}

function calculateImageWidthPerPage(perPage: number, totalWidth: number) {
  const displayAmountOfImagesAsOverflow = 2;
  const totalImagesToDisplayPerPage = perPage + displayAmountOfImagesAsOverflow;

  return totalWidth / (totalImagesToDisplayPerPage - 1);
}

export const TestimonialGallery = (props: TestimonialGalleryProps) => {
  const {
    testimonials,
    per,
    showOnlyOne = false,
    variant = "default",
    showQuotes = false,
    minWidth = 369,
    hideRightSpacing = false,
  } = props;

  const [width, _width] = useState<number>(minWidth);
  const [current, _current] = useState<number>(1);
  const [manual, _manual] = useState<boolean>(false);
  const [timeout, _timeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [pages] = useState<number>(testimonials.length - 1);

  const ref = createRef<HTMLDivElement>();
  const mobile = useMobile();

  useEffect(() => {
    if (ref.current) {
      const _w = ref.current.offsetWidth;

      const calculate = calculateImageWidthPerPage(mobile ? 1 : per, _w);
      ref.current.scrollLeft = calculate;
      if (calculate >= width) {
        _width(calculate);
      }

      if (timeout) {
        clearInterval(timeout);
      }

      if (!manual) {
        _timeout(
          setInterval(() => {
            _current((c) => (c === pages - 1 ? 0 : c + 1));
          }, 6000)
        );
      }
    }

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [width, ref.current, mobile, manual]);

  useEffect(() => {
    try {
      if (ref.current) {
        ref.current.scrollTo({ left: current * width, behavior: "smooth" });
      }
    } catch (error) {
      console.error(error);
    }
  }, [current]);

  if (testimonials.length === 0) {
    return null;
  }

  const MARGIN_SIZE = mobile ? 8 : 36;

  if (mobile) {
    return (
      <GridStepScroller
        xs={24}
        items={testimonials.map((item, i) => (
          <TestimonialCard
            key={i}
            reverse={true}
            ratings={false}
            picture={null}
            variant={variant}
            showQuotes={showQuotes}
            {...item}
          />
        ))}
      />
    );
  }

  return (
    <Box>
      <div
        className="--gallery --no-scrollbar"
        ref={ref}
        style={{
          display: "flex",
          alignItems: "flex-start",
          overflowX: "auto",
          marginLeft: -(MARGIN_SIZE / 2),
          marginRight: -(MARGIN_SIZE / 2),
          justifyContent:
            testimonials?.length <= per + 2 ? "center" : undefined,
        }}
      >
        <Box style={{ minWidth: width / 2 }} width={width / 2}></Box>
        {testimonials.map((item, i) => {
          const hide = showOnlyOne && i !== current;

          return (
            <Box
              key={i}
              parse={`pl:${MARGIN_SIZE / 2} pr:${MARGIN_SIZE / 2} d:${
                hide ? "none" : "block"
              }`}
              style={{
                minWidth: width,
                opacity: hide ? 0 : 1,
                transition: "opacity 0.5s ease-in-out",
              }}
              width={width}
            >
              <TestimonialCard
                key={i}
                reverse={true}
                ratings={false}
                picture={null}
                variant={variant}
                showQuotes={showQuotes}
                {...item}
              />
            </Box>
          );
        })}
        {hideRightSpacing ? undefined : (
          <Box style={{ minWidth: width / 2 }} width={width / 2}></Box>
        )}
      </div>

      {!showOnlyOne && (
        <Box css="d:flex  a:center j:center" parse="mt:24">
          <DesignedPagination
            press={(value) => {
              _current(value);

              if (timeout) {
                clearInterval(timeout);
              }

              _manual(true);
            }}
            mode="lines"
            size={pages}
            value={current}
          />
        </Box>
      )}
    </Box>
  );
};

export default TestimonialGallery;
