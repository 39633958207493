import { useEffect, useState } from "react";

import Formatter from "./Formatter";

export const useGradient = (image: string) => {
  const [colors, _colors] = useState<{
    r: number;
    g: number;
    b: number;
    error?: unknown;
    message?: string;
    ready?: boolean;
  }>({
    r: 0,
    g: 0,
    b: 0,
    ready: false,
  });
  const [data, _data] = useState("");

  const rgb = async (
    base64: string
  ): Promise<{
    r: number;
    g: number;
    b: number;
    error?: unknown;
    message?: string;
  }> => {
    const _: {
      r: number;
      g: number;
      b: number;
      error?: unknown;
      count?: number;
      message?: string;
    } = await new Promise((resolve) => {
      const image = document.createElement("img");

      image.onload = function () {
        if (!image) {
          resolve({
            r: 0,
            g: 0,
            b: 0,
            error: "Image could not be found unfortunately.",
          });
        }
        const canvas = document.createElement("canvas");
        const context = canvas.getContext && canvas.getContext("2d");
        const size = 10;
        const rgb = { r: 0, g: 0, b: 0 };

        try {
          image.crossOrigin = "Anonymous";
        } catch (error) {
          console.error("IMAGE", "ERROR", error);
        }

        let data;
        let width = 0;
        let height = 0;
        let length = 0;
        let i = -4;
        const count = 0;

        if (!context) {
          resolve({
            r: 0,
            g: 0,
            b: 0,
            error: "Context can not be created unfortunately.",
          });

          return;
        }

        height = canvas.height =
          image.naturalHeight || image.offsetHeight || image.height;
        width = canvas.width =
          image.naturalWidth || image.offsetWidth || image.width;

        context.drawImage(image, 0, 0);

        try {
          data = context.getImageData(0, 0, width, height);
        } catch (e) {
          resolve({
            r: 0,
            g: 0,
            b: 0,
            error:
              "Security error, can't get the context.getImageData unfortunately. The browser does not support it.",
          });

          return;
        }

        length = data.data.length;

        const colors: {
          [key: string]: number;
        } = {};

        while ((i += size * 4) < length) {
          const _r = data.data[i];
          const _g = data.data[i + 1];
          const _b = data.data[i + 2];

          if (_r > 0 && _g > 0 && _b > 0 && _r < 255 && _g < 255 && _b < 255) {
            colors[hex({ r: _r, g: _g, b: _b })] =
              (colors[hex({ r: _r, g: _g, b: _b })] || 0) + 1;
          }
        }

        let max = "";

        Object.keys(colors).forEach((key) => {
          if (max === "" || colors[key] > colors[max]) {
            max = key;
          }
        });

        const formatted =
          max.length > 0 ? Formatter.rgb(max) : { r: 0, g: 0, b: 0 };

        rgb.r = formatted?.r || 0;
        rgb.g = formatted?.g || 0;
        rgb.b = formatted?.b || 0;

        resolve({
          ...rgb,
          count,
          message: "Calculated RGB has successfully been returned.",
        });
      };

      image.src = base64;
    });
    return _;
  };

  const base64 = async (url: string): Promise<string> => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  const hex = ({ r, g, b }: { r: number; g: number; b: number }) => {
    const _ = (c: number) => {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    };

    return "#" + _(r) + _(g) + _(b);
  };

  useEffect(() => {
    return;
    /** */
    if (!image) {
      return;
    }

    (async () => {
      if (typeof image === "string") {
        if (image.length > 0) {
          const _base64 = await base64(image);
          const _rgb = await rgb(_base64);
          _colors({ ..._rgb, ready: true });
          _data(_base64);
        }
      }
    })();
  }, [image]);

  return {
    src: image,
    colors: {
      r: 0,
      g: 0,
      b: 0,
      ready: true,
    },
    hex: "#000000",
    ready: true,
  };
  /** */
  return { src: data, colors, hex: hex(colors), ready: colors.ready };
};

export default useGradient;
