import { Box } from "simple-effing-primitive-layout";

import Rule from "../rule/rule";
import DesignedIcon, {
  AvailableIconName,
} from "../designed-icon/designed-icon";

export interface DesignedSelectProps {
  icon?: AvailableIconName;
  label?: string;
  hint?: string;
  value?: string | string[];
  disable?: boolean;
  error?: string | boolean;
  positive?: string;
  size?: string;
  type?: string;
  placeholder?: string;
  bottom?: number;
  required?: boolean;
  focus?: () => void;
  blur?: () => void;
  change?: (value: string, event?: unknown) => void;
  properties?: {
    [key: string]: unknown;
  };
  container?: {
    [key: string]: unknown;
  };
  native?: {
    [key: string]: unknown;
  };
  options: Array<Array<string> | [string] | [string, string]>;
  minimalistic?: boolean;
  empty?: boolean;
  theme?: DesignedSelectTheme;
}

type DesignedSelectTheme = "light" | "dark";

type DesignedSelectSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    size?: number;
    weight?: string;
    label?: {
      rule?: string;
      bottom?: number;
    };
    hint?: {
      top?: number;
    };
  };
};

const Themes = {
  light: {
    label: {
      color: "#171B20",
    },
    container: {
      color: "#FFF",
      border: {
        default: "1.3px solid var(--grey-40)",
        focus: "1.3px solid var(--hoover)",
        filled: "2px solid var(--grey-60)",
        error: "1.3px solid var(--error)",
      },
    },
    select: {
      color: "var(--grey-80)",
      error: "var(--error)",
    },
  },
  dark: {
    label: {
      color: "#AFAFAF",
    },
    container: {
      color: "#272E38",
      border: {
        default: "var(--border)",
        focus: "var(--border)",
        filled: "var(--border)",
        error: "1px solid var(--negative)",
      },
    },
    select: {
      color: "#FFF",
      error: "#FFF",
    },
  },
};

const Sizes: DesignedSelectSizes = {
  xl: {
    height: 56,
    padding: 30,
    size: 20,
    weight: "500",
    label: {
      rule: "pm",
      bottom: 10,
    },
    hint: {
      top: 10,
    },
  },
  large: {
    height: 48,
    padding: 16,
    size: 18,
    weight: "600",
    label: {
      rule: "lt",
      bottom: 5,
    },
    hint: {
      top: 10,
    },
  },
  medium: {
    height: 40,
    padding: 16,
    size: 16,
    weight: "600",
    label: {
      rule: "lt",
      bottom: 5,
    },
    hint: {
      top: 10,
    },
  },
  small: {
    height: 32,
    padding: 10,
    size: 14,
    weight: "600",
    label: {
      rule: "lt",
      bottom: 5,
    },
    hint: {
      top: 10,
    },
  },
};

export const DesignedSelect = ({
  icon,
  label,
  hint,
  value,
  disable,
  error,
  focus,
  blur,
  change,
  properties,
  container,
  size = "medium",
  placeholder,
  native,
  bottom = 10,
  positive,
  options,
  minimalistic,
  required,
  empty,
  theme = "dark",
}: DesignedSelectProps) => {
  const _size = size ? Sizes[size] || Sizes["medium"] : Sizes["medium"];
  const _theme = Themes[theme];

  const selected = !!value && value?.toString()?.trim() !== "";
  const containerBorder = () => {
    let border: "default" | "focus" | "filled" | "error" = "default";

    if (theme === "light") {
      if (selected) border = "filled";
      if (error) border = "error";
    }

    return _theme.container.border[border];
  };

  return minimalistic ? (
    <select
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      autoComplete="off"
      value={value}
      required={required}
      disabled={disable}
      style={{
        background: "#272E38",
        backgroundColor: "#272E38",
        marginLeft: 2.5,
        marginRight: 2.5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        border: "1px solid var(--accent)",
        outline: "none",
        outlineColor: "none",
        height: _size.height,
        fontSize: _size.size,
        fontWeight: _size.weight,
        fontFamily: "Figtree",
        color: _theme.select[error ? "error" : "color"],
        WebkitAppearance: "none",
        MozAppearance: "none",
      }}
      placeholder={placeholder}
      onChange={(event) => {
        if (change) {
          change(event.target.value, event);
        }
      }}
      onFocus={() => {
        if (focus) {
          focus();
        }
      }}
      onBlur={() => {
        if (blur) {
          blur();
        }
      }}
      {...native}
    >
      {empty ? (
        <option value="" selected disabled>
          Select
        </option>
      ) : undefined}
      {options?.map((option, index) => (
        <option key={index} value={option[0]}>
          {option[1] || option[0]}
        </option>
      ))}
    </select>
  ) : (
    <Box parse="w:auto" bottom={bottom} {...container}>
      {label ? (
        <Rule
          display="block"
          rule={_size.label?.rule}
          color={_theme.label.color}
          bottom={_size.label?.bottom}
        >
          {label} {required ? "*" : null}
        </Rule>
      ) : undefined}
      <Box
        parse={`h:${_size.height} pr:${_size.padding} pl:${_size.padding} br:5 d:flex a:center j:flex-start  oa:hidden P:relative`}
        color={_theme.container.color}
        border={containerBorder()}
        {...properties}
      >
        {icon ? (
          <Box parse="p:absolute r:30 d:flex">
            <DesignedIcon
              name={icon}
              size={20}
              color={_theme.select[error ? "error" : "color"]}
            />
          </Box>
        ) : undefined}

        <select
          value={value}
          required={required}
          disabled={disable}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          autoComplete="off"
          style={{
            background: _theme.container.color,
            backgroundColor: _theme.container.color,
            outline: "none",
            outlineColor: "none",
            border: "none",
            height: (_size.height || 40) - 2,
            flex: 1,
            fontSize: _size.size,
            fontWeight: _size.weight,
            fontFamily: "Figtree",
            color: _theme.select[error ? "error" : "color"],

            ...(icon
              ? {
                  paddingRight: "30px",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  appearance: "none",
                }
              : {}),
          }}
          placeholder={placeholder}
          onChange={(event) => {
            if (change) {
              change(event.target.value, event);
            }
          }}
          onFocus={() => {
            if (focus) {
              focus();
            }
          }}
          onBlur={() => {
            if (blur) {
              blur();
            }
          }}
          {...native}
        >
          {empty ? (
            <option value="" selected disabled>
              Select
            </option>
          ) : undefined}
          {options.map((option) => {
            return (
              <option key={option[0]} value={option[0]}>
                {option[1] || option[0]}
              </option>
            );
          })}
        </select>
      </Box>
      {hint ? (
        <Rule
          display="block"
          rule="lt"
          color="var(--font4)"
          top={_size.hint?.top || 0}
          weight={500}
        >
          {hint}
        </Rule>
      ) : undefined}
      {error && typeof error === "string" && error.length > 0 ? (
        <Rule
          display="block"
          rule="lt"
          color={_theme.select.error}
          top={_size.hint?.top || 0}
        >
          {error}
        </Rule>
      ) : undefined}
      {positive && positive.length > 0 ? (
        <Rule display="block" rule="lt" color="var(--positive)">
          {positive}
        </Rule>
      ) : undefined}
    </Box>
  );
};

export default DesignedSelect;
