import { Act, ShowData } from "libs/editor-ui/types";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../shared";
import ActOptions from "./act/ActOptions";
import Mover from "./Mover";

interface Props {
  children: React.ReactNode;
  data: ShowData;
  enabled: boolean;
  act: Act;
  actIndex: number;
  onActDuplicate: (act: number) => void;
  onActDisable: (act: number) => void;
  onActEnable: (act: number) => void;
  onDangerousDataUpdate: (data: ShowData) => void;
  thumbnail?: string;
}

const ActWrapper = (props: Props) => {
  const {
    children,
    enabled,
    act,
    actIndex,
    onActDisable,
    onActEnable,
    onActDuplicate,
    data,
    onDangerousDataUpdate,
    thumbnail,
  } = props;

  const [expanded, setExpanded] = useState(true);
  return (
    <Box parse="w:100%">
      <div>
        <Box
          css="--parent"
          parse="w:100% pa:15 d:flex a:center"
          color={enabled ? Colors.foreground : Colors.background}
          style={{ borderBottom: "1px solid rgba(255,255,255,0.1)" }}
        >
          <Mover
            press={() => setExpanded(!expanded)}
            right={15}
            css={expanded ? "--children" : undefined}
            vertical={!expanded}
          />
          <Text
            rule="Label Tiny"
            color={Colors.font4}
            opacity={enabled ? 1 : 0.5}
          >
            {act.name}
          </Text>
          <Box flex={1} right={15} />

          <ActOptions
            thumbnail={thumbnail}
            enabled={enabled}
            actIndex={actIndex}
            data={data}
            onActDuplicate={onActDuplicate}
            onActEnable={onActEnable}
            onActDisable={onActDisable}
            onDangerousDataUpdate={onDangerousDataUpdate}
          />
        </Box>
      </div>
      <Box
        parse="w:100%"
        display={expanded ? "block" : "none"}
        style={{ transition: "height 0.2s ease-in-out" }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ActWrapper;
