import { array, number, object, string } from "yup";

import { AttachmentSchema } from "./attachmentSchema";
import { ProductLicensorSchema } from "./licensorSchema";
import { ShowSchema } from "./showSchema";

export const ProductScenicProjectionSchema = object({
  id: string(),
  name: string(),
  show: ShowSchema,
});

export const SearchProductScenicProjectionSchema = object({
  id: string(),
  name: string(),
  attachments: array().of(AttachmentSchema).nullable(),
  product: object({
    id: number(),
    type: string(),
    variation: string().nullable().optional(),
    licensors: array().of(ProductLicensorSchema).nullable().optional(),
  }).nullable(),
});
