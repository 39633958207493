import { FC, ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ModalFooterProps {
  children?: ReactNode;
}

export const ModalFooter: FC<ModalFooterProps> = ({ children }) => (
  <Box parse="d:flex a:center j:flex-end pt:var(--modal-space-4) pb:var(--modal-space-4) pl:var(--modal-space-6) pr:var(--modal-space-6)">
    {children}
  </Box>
);
