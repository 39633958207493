import mapValues from "lodash/mapValues";
import { array, InferType, lazy, object, string } from "yup";

import { EditorSyncFiles } from "./EditorSync";
import { ServerAct, ServerShow, ServerVideoSet } from "./types/editor";

export class CollectionSync {
  static createServerDataFromDropCollections(
    dropCollections: DropCollectionDownloadResponse
  ) {
    const thumbnails: { file: string; url: string }[] = [];
    const videos: { file: string; url: string }[] = [];
    const files: { file: string; url: string }[] = [];
    Object.entries(dropCollections.data.attributes.manifest.files).map(
      ([key, value]) => {
        files.push({ file: key, url: value.url || "" });
      }
    );

    if (!files.length) {
      return;
    }

    const manifest: ServerShow = {
      sets: [],
      acts: [],
      curtain_warmer: {
        curtain_warmer: videos[0]?.file,
        curtain_warmer_thumbnail: thumbnails[0]?.file,
      },
    };

    const _drops: ServerAct = {
      name: "Drop Collection",
      thumbnail: null,
      scenes: [
        {
          name: "Scene 1",
          thumbnail: null,
          sets: [],
        },
      ],
    };
    dropCollections.data.attributes.manifest.drops?.map((drop, d) => {
      const _drop: ServerVideoSet = {
        identifier: `drop-${d}`,
        action: null,
        loop: drop.loop,
        thumbnail: drop.thumbnail,
        still: null,
        name: drop.name,
        cue_page_number: "",
        cue_description: drop.name,
        cue_line: "",
      };
      _drops.scenes[0]?.sets.push({ id: `drop-${d}` });
      manifest.sets.push(_drop);
    });
    if (dropCollections.data.attributes.manifest.drops?.length) {
      manifest.acts.push(_drops);
    }

    return manifest;
  }

  static createFilesFromDropCollections(
    dropCollections: DropCollectionDownloadResponse
  ) {
    const files: EditorSyncFiles = {};
    Object.entries(dropCollections.data.attributes.manifest.files).map(
      ([key, value]) => {
        if (value.url) {
          files[key] = value.url;
        }
      }
    );

    return files;
  }
}

export default CollectionSync;

export const DropCollectionDownloadScheme = object({
  data: object({
    id: string().required(),
    type: string().required(),
    attributes: object({
      manifest: object({
        drops: array()
          .of(
            object({
              name: string().required(),
              thumbnail: string().nullable(),
              loop: string().nullable(),
            })
          )
          .nullable(),
        files: lazy((obj) =>
          object(
            mapValues(obj, () =>
              object({
                url: string(),
              })
            )
          )
        ),
      }),
    }),
  }),
});

export type DropCollectionDownloadResponse = InferType<
  typeof DropCollectionDownloadScheme
>;
