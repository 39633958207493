import { Ecommerce, ShoppingCartHelpers } from "@with-nx/ecommerce";
import { ThrottlerController } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedInput,
  DesignedStepper,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import moment from "moment";
import { useCallback, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Rule from "../../../../atoms/src/lib/rule/rule";
import Colors from "../../../../helpers/src/lib/Colors";
import CalendarInput from "../calendar-input/calendar-input";

export interface CustomizableProductionProps {
  update?: (details: Ecommerce.Payload.Production.CreateProduction) => void;
  create?: (
    details: Ecommerce.Payload.Production.CreateProduction,
    move: boolean
  ) => void;
  remove?: () => void;
  onContinuePress?: () => void;
  onAddAnotherPress?: () => void;
  production?: Ecommerce.Payload.Production.Production;
  abbreviation?: string | null;
  mode?: "physical-only" | "royalty-only" | "default";
  loading?: boolean;
  index: number;
  last?: boolean;
}

export const CustomizableProduction = ({
  production,
  update,
  create,
  onAddAnotherPress,
  remove,
  abbreviation,
  mode,
  loading,
  index,
  last,
  onContinuePress,
}: CustomizableProductionProps) => {
  const [details, _details] =
    useState<Ecommerce.Payload.Production.CreateProduction>({
      line: 0,
      id: production?.id,
      showTitle: production?.showTitle,
      organizationName: production?.organizationName,
      firstPerformance: production?.firstPerformance,
      lastPerformance: production?.lastPerformance,
      licensorCode: production?.licensorCode,
      additionalWeeks: production?.additionalWeeks,
    });

  const firstPerformanceDateError = (() => {
    if (details?.firstPerformance?.length) {
      if (moment(details?.firstPerformance).isBefore(moment())) {
        return "First performance date must be in the future";
      }
    }
    return undefined;
  })();

  const lastPerformanceDateError = (() => {
    if (details?.lastPerformance?.length) {
      if (moment(details?.lastPerformance).isBefore(moment())) {
        return "Last performance date must be in the future";
      }
      if (details?.firstPerformance?.length) {
        if (
          moment(details?.lastPerformance).isBefore(
            moment(details?.firstPerformance)
          )
        ) {
          return "Last performance date must be greater or equal than first performance date.";
        }
      }
    }
    return undefined;
  })();

  const organizationNameError = (() => {
    if (details?.organizationName?.length) {
      if (details?.organizationName.length < 3) {
        return "Organization name must be at least 3 characters";
      }
    }
    return undefined;
  })();

  const showTitleError = (() => {
    if (details?.showTitle?.length) {
      if (details?.showTitle.length < 3) {
        return "Show title must be at least 3 characters";
      }
    }
    return undefined;
  })();

  const areDetailsFilled = ShoppingCartHelpers.productionDetailsValid(
    "non-licensed",
    {
      ...details,
      additionalWeeks: details?.additionalWeeks || 0,
    }
  );

  const handleInputBlur = useCallback(() => {
    if (areDetailsFilled) {
      if (update && ThrottlerController.can("production", "action", 5000)) {
        update?.(details);
      }
    }
  }, [areDetailsFilled, details]);

  const isSameDetails = () =>
    !!production &&
    JSON.stringify(details) ===
      JSON.stringify({ line: details.line, ...production });

  return (
    <>
      <Box
        parse="br:10 pa:20 mb:12"
        color={create ? "transparent" : Colors.foreground}
        border={create ? "1px solid rgba(255,255,255,0.1)" : undefined}
        native={{
          cypress: "cart-item",
        }}
      >
        <Box parse="mt:16">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Rule parse="!hs">Production #{index + 1}</Rule>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <DesignedInput
                size="small"
                label="Show Title"
                required
                value={details.showTitle}
                change={(showTitle) => _details({ ...details, showTitle })}
                error={showTitleError}
                blur={handleInputBlur}
                native={{
                  cypress: `field-show-title`,
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <DesignedInput
                size="small"
                label="Organization Name"
                value={details.organizationName}
                required
                change={(organizationName) =>
                  _details({ ...details, organizationName })
                }
                error={organizationNameError}
                blur={handleInputBlur}
                native={{
                  cypress: `field-organization-name`,
                }}
                convert="capitalize"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <CalendarInput
                label="First Performance"
                value={details?.firstPerformance}
                required
                change={(firstPerformance) =>
                  _details({
                    ...details,
                    firstPerformance,
                    lastPerformance: firstPerformance,
                  })
                }
                error={firstPerformanceDateError}
                native={{
                  cypress: `field-first-performance`,
                }}
                size="small"
                blur={handleInputBlur}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <CalendarInput
                label="Last Performance"
                value={details?.lastPerformance}
                required
                change={(lastPerformance) => {
                  _details({ ...details, lastPerformance });
                }}
                blur={handleInputBlur}
                error={lastPerformanceDateError}
                size="small"
                native={{
                  cypress: `field-last-performance`,
                }}
              />
            </Col>
            {abbreviation ? (
              <Col xs={24} sm={24} md={12} lg={8}>
                <div className="flex justify-between items-center">
                  <DesignedInput
                    tooltip={`If you need help locating your ${abbreviation} Access Code, or if you've received rights through another licensors please call 1-800-277-0343 for additional assistance.`}
                    size="small"
                    label={`${abbreviation} Access Code Number`}
                    value={details.licensorCode || ""}
                    required
                    change={(licensorCode) =>
                      _details({ ...details, licensorCode })
                    }
                    blur={handleInputBlur}
                    native={{
                      cypress: `field-access-code`,
                    }}
                  />
                </div>
              </Col>
            ) : undefined}
            {mode === "physical-only" ? undefined : (
              <Col xs={24} sm={12} md={4}>
                <Rule display="block" rule="lt" color="#AFAFAF" bottom={5}>
                  Early Access Weeks
                </Rule>
                <DesignedStepper
                  disable={!areDetailsFilled}
                  minValue={0}
                  maxValue={52}
                  size={99}
                  mode="input"
                  value={details.additionalWeeks || 0}
                  press={(additionalWeeks) => {
                    _details({ ...details, additionalWeeks });
                  }}
                />
              </Col>
            )}
            <Col xs={24}>
              <Box parse="d:flex a:center j:space-between">
                {production ? (
                  <DesignedButton
                    loading={loading}
                    label="Update"
                    theme="primary"
                    properties={{ left: 5 }}
                    press={() => update?.(details)}
                    disable={!areDetailsFilled || isSameDetails()}
                  />
                ) : (
                  <DesignedButton
                    loading={loading}
                    label="Create and Continue"
                    disable={!areDetailsFilled}
                    press={() => {
                      _details({ line: 0 });
                      create?.(details, true);
                    }}
                  />
                )}

                {remove ? (
                  <DesignedButton
                    loading={loading}
                    label="Delete"
                    theme="negative"
                    properties={{ left: 5 }}
                    press={remove}
                  />
                ) : undefined}
              </Box>
            </Col>
          </Row>
        </Box>
      </Box>

      {last ? (
        <>
          <DesignedButton
            theme="tetriary"
            loading={loading}
            label={create ? "Create" : "Add Another Production"}
            disable={!areDetailsFilled}
            press={
              create
                ? () => {
                    _details({ line: 0 });
                    create?.(details, false);
                  }
                : onAddAnotherPress
            }
          />

          <DesignedButton
            properties={{
              left: 12,
            }}
            loading={loading}
            label="Continue"
            press={() => {
              onContinuePress?.();
            }}
          />
        </>
      ) : undefined}
    </>
  );
};

export default CustomizableProduction;
