import {
  DesignedButton,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import GenericContentHolder from "../generic-content-holder/generic-content-holder";

export interface ClientDetailsProps {
  image?: string;
  name?: string;
  url?: string;
  awards?: string[];
  description?: string;
  label?: string;
}

export const ClientDetails: React.FC<ClientDetailsProps> = (
  props: ClientDetailsProps
) => {
  const {
    image,
    name = "",
    url = "",
    awards = [],
    description = "",
    label = "Read Full Biography",
  } = props;

  return (
    <ReusableGlossBox parse="pa:32 br:10">
      <Box parse="d:flex a:flex-start">
        <GenericContentHolder
          image={image}
          parse="w:80 h:80 br:999 oa:hidden mr:32"
          alt={name || "Client Image"}
        />
        <Box parse="f:1">
          <Box parse="d:inline-flex a:center mb:12">
            <Rule parse="!hm c:?font1">{name}</Rule>
          </Box>
          <Box parse="w:100% mb:12 d:flex a:center fw:wrap">
            {awards.map((award) => (
              <Box
                parse="br:4 d:inline-flex mr:12 mb:12 pl:10 pr:10 a:center h:27"
                color="rgba(116, 97, 47, 0.7)"
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M7.1276 11.4318L6.34751 17.305C6.29238 17.7201 6.74135 18.015 7.10041 17.7996L9.74157 16.2149C9.89991 16.1199 10.0977 16.1199 10.2561 16.2149L12.8974 17.7997C13.2564 18.0151 13.7054 17.7202 13.6503 17.3052L12.87 11.4242"
                    fill="#EB8D00"
                  />
                  <path
                    d="M9.99834 12.2727C12.9271 12.2727 15.3014 9.89844 15.3014 6.96966C15.3014 4.04087 12.9271 1.66663 9.99834 1.66663C7.06956 1.66663 4.69531 4.04087 4.69531 6.96966C4.69531 9.89844 7.06956 12.2727 9.99834 12.2727Z"
                    fill="url(#paint0_linear_1509_87334)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1509_87334"
                      x1="9.99834"
                      y1="1.66663"
                      x2="9.99834"
                      y2="12.2727"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FAFF00" />
                      <stop offset="1" stopColor="#FFC700" />
                    </linearGradient>
                  </defs>
                </svg>

                <Rule parse="!ls c:#FFF500 ml:6 d:inline-block">{award}</Rule>
              </Box>
            ))}
          </Box>
          <Rule parse="!lm c:?font3 d:block mb:32">{description}</Rule>
          <DesignedButton
            theme="tetriary"
            size="medium"
            label={label}
            href={url}
          />
        </Box>
      </Box>
    </ReusableGlossBox>
  );
};

export default ClientDetails;
