import mapValues from "lodash/mapValues";
import { array, boolean, InferType, lazy, object, string } from "yup";

import { EditorSyncFiles } from "./EditorSync";
import { ServerAct, ServerShow, ServerVideoSet } from "./types/editor";

export class RentalSync {
  static createServerDataFromRental(rental: RentalDownloadResponse) {
    const thumbnails: { file: string; url: string }[] = [];
    const videos: { file: string; url: string }[] = [];
    const files: { file: string; url: string }[] = [];

    Object.entries(rental.data.attributes.manifest.files).map(
      ([key, value]) => {
        files.push({ file: key, url: value.url || "" });
      }
    );
    rental.data.attributes.manifest.acts?.map((act) => {
      act.scenes?.map((scene) => {
        scene.sets?.map((set) => {
          /* Meta Stuff */
          thumbnails.push({
            file: set.thumbnail || "",
            url:
              rental.data.attributes.manifest.files[set.thumbnail || ""]?.url ||
              "",
          });
          if (set.loop) {
            videos.push({
              file: set.loop || "",
              url:
                rental.data.attributes.manifest.files[set.loop || ""]?.url ||
                "",
            });
          }
          if (set.action) {
            videos.push({
              file: set.action || "",
              url:
                rental.data.attributes.manifest.files[set.action || ""]?.url ||
                "",
            });
          }

          /* Important Stuff */
        });
      });
    });

    if (!files.length) {
      return;
    }

    const manifest: ServerShow = {
      sets: [],
      acts: [],
      curtain_warmer: {
        curtain_warmer: videos[0]?.file,
        curtain_warmer_thumbnail: thumbnails[0]?.file,
      },
    };

    rental.data.attributes.manifest.acts?.map((act, a) => {
      act.scenes?.map((scene, s) => {
        scene.sets?.map((cue, c) => {
          manifest.sets.push({
            identifier: `cue-${a}-${s}-${c}`,
            action: cue.action,
            loop: cue.loop,
            thumbnail: cue.thumbnail,
            still: null,
            name: cue.name || "",
            cue_page_number: cue.cue_page_number || "",
            cue_description: cue.cue_description || "",
            cue_line: cue.cue_line || "",
          });

          if (!manifest.acts[a]) {
            manifest.acts[a] = {
              name: act.name || "",
              thumbnail: act.thumbnail,
              scenes: [],
              active: !act.hidden,
            };
          }

          if (!manifest.acts[a]?.scenes[s]) {
            manifest.acts[a]!.scenes[s] = {
              name: scene.name || "",
              thumbnail: scene.thumbnail || null,
              sets: [],
              active: !scene.disabled,
            };
          }

          manifest.acts[a]!.scenes[s]!.sets.push({
            id: `cue-${a}-${s}-${c}`,
            active: Boolean(cue.enabled),
          });
        });
      });
    });

    const _drops: ServerAct = {
      name: "Digital Drops",
      thumbnail: null,
      scenes: [
        {
          name: "Scene 1",
          thumbnail: null,
          sets: [],
        },
      ],
    };
    rental.data.attributes.manifest.drops?.map((drop, d) => {
      const _drop: ServerVideoSet = {
        identifier: `drop-${d}`,
        action: null,
        loop: drop.loop,
        thumbnail: drop.thumbnail,
        still: null,
        name: drop.name,
        cue_page_number: "",
        cue_description: "",
        cue_line: "",
      };
      _drops.scenes[0]?.sets.push({ id: `drop-${d}` });
      manifest.sets.push(_drop);
    });
    if (rental.data.attributes.manifest.drops?.length) {
      manifest.acts.push(_drops);
    }

    return manifest;
  }

  static createFilesFromRental(dropCollections: RentalDownloadResponse) {
    const files: EditorSyncFiles = {};
    Object.entries(dropCollections.data.attributes.manifest.files).map(
      ([key, value]) => {
        if (value.url) {
          files[key] = value.url;
        }
      }
    );

    return files;
  }
}

export default RentalSync;

export const RentalDownloadScheme = object({
  data: object({
    id: string().required(),
    type: string().required(),
    attributes: object({
      manifest: object({
        drops: array()
          .of(
            object({
              name: string().required(),
              thumbnail: string().nullable(),
              loop: string().nullable(),
            })
          )
          .nullable(),
        acts: array()
          .of(
            object({
              name: string().nullable(),
              thumbnail: string().nullable(),
              hidden: boolean().optional().nullable(),
              scenes: array()
                .of(
                  object({
                    name: string().nullable(),
                    thumbnail: string().nullable(),
                    disabled: boolean().optional().nullable(),
                    sets: array()
                      .of(
                        object({
                          name: string().nullable(),
                          thumbnail: string().nullable(),
                          action: string().nullable(),
                          loop: string().nullable(),
                          cue_page_number: string().nullable(),
                          cue_description: string().nullable(),
                          cue_line: string().nullable(),
                          enabled: boolean().optional().nullable(),
                        })
                      )
                      .nullable(),
                  })
                )
                .nullable(),
            })
          )
          .nullable(),
        files: lazy((obj) =>
          object(
            mapValues(obj, () =>
              object({
                url: string(),
              })
            )
          )
        ),
      }),
    }),
  }),
});

export type RentalDownloadResponse = InferType<typeof RentalDownloadScheme>;
