import {
  Formatter,
  useCMSWorkshops,
  useMobile,
} from "@with-nx/hooks-n-helpers";
import { ReusableSection } from "@with-nx/simple-ui/atoms";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { BlueUnderline } from "@with-nx/simple-ui/molecules";
import {
  GridStepScroller,
  ReusablePreSlantedContainer,
} from "@with-nx/simple-ui/organisms";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export const ReusableWorkshops = () => {
  const mobile = useMobile();
  const workshops = useCMSWorkshops({});
  const [showAllWorkshops, setShowAllWorkshops] = useState(false);

  const _workshops = workshops.workshops.filter((workshop, w) =>
    mobile ? true : w >= 4 && !showAllWorkshops ? false : true
  );

  return workshops.workshops.length > 0 ? (
    <ReusablePreSlantedContainer active={false} amount={16}>
      <ReusableSection>
        <div className="container">
          <div id="classes" />
          <Box
            css="d:flex a:baseline j:center p:relative oy:visible fw:wrap"
            parse="mb:24"
          >
            <Box css="p:relative">
              <Rule
                parse="!hl d:inline-block p:relative"
                display="block"
                css="--blue-gradient-text"
                bottom={0}
                right={12}
                style={{ marginBottom: 0 }}
              >
                Gain New Skills
                <BlueUnderline />
              </Rule>
            </Box>
            <Rule
              parse="!hl d:inline-block"
              display="block"
              bottom={12}
              right={12}
            >
              with Free Classes and Workshops
            </Rule>
          </Box>
        </div>

        <GridStepScroller
          gutter={[3, 3]}
          xs={24}
          sm={24}
          md={6}
          items={_workshops.map((item, i) => {
            return (
              <Box
                key={i}
                parse="br:10 pa:24 oa:hidden h:100% d:flex fd:column j:space-between"
                border="var(--border)"
              >
                <Box>
                  <Box parse="mb:16 w:32 h:32">
                    <img
                      loading="lazy"
                      height={32}
                      width={32}
                      alt={item.title}
                      src={Formatter.image(item.image)}
                    />
                  </Box>
                  <Rule parse="!ht d:block mb:4">{item.title}</Rule>
                  <Rule parse="!ps d:block mb:4">{item.description}</Rule>
                </Box>
                <Box parse="mt:16">
                  <DesignedButton
                    size="small"
                    theme="tetriary-white"
                    label={item.buttonText}
                    href={item.buttonURL}
                  />
                </Box>
              </Box>
            );
          })}
        />

        {workshops.workshops.length > 4 && !showAllWorkshops && !mobile ? (
          <div className="container">
            <Box css="d:flex a:center j:flex-end" parse="mt:24">
              <DesignedButton
                label="See All Workshops"
                press={() => setShowAllWorkshops(true)}
                theme="tetriary"
              />
            </Box>
          </div>
        ) : undefined}
      </ReusableSection>
    </ReusablePreSlantedContainer>
  ) : undefined;
};
