/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ecommerce, ShoppingCartMethods } from "@with-nx/ecommerce";
import { StageGearStaticData } from "@with-nx/static";
import Moment from "moment";

import useService from "../useService";
import { StageGearHelpers } from "./StageGearHelpers";
import {
  StageGear,
  StageGearGroup,
  StageGearProduct,
} from "./types/stage-gear";

export class StageGearMethods {
  static async available(
    id: string | number,
    start: string,
    end: string,
    purchasable: boolean = false,
    quantity = 1
  ) {
    const _start = Moment(start).subtract(purchasable ? 0 : 1, "week");
    const _end = Moment(purchasable ? "9999-01-01" : end).add(
      purchasable ? 0 : 1,
      "week"
    );

    if (!_start.isValid) {
      throw new Error("Invalid start date");
    }

    if (!_end.isValid) {
      throw new Error("Invalid end date");
    }

    const makeRequestToMicroservice = useService("microservice", {
      cache: 60_000,
      bypass: true,
    });

    try {
      const check = await makeRequestToMicroservice(
        "GET",
        `/ecommerce/products/stage-gear/${id}/check-availability-v2?startDate=${_start.format(
          "YYYY-MM-DD"
        )}&endDate=${_end.format("YYYY-MM-DD")}&quantity=${quantity}`
      );
      return String(check?.["status"]) !== String(422);
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  static async swap(
    token: string,
    cart: Ecommerce.Cart.State,
    original: string | number,
    next: string | number
  ) {
    const product = StageGearHelpers.product(cart, original);

    if (!product) {
      throw new Error("000X1 Original product not found");
    }

    const available = await StageGearMethods.available(
      next,
      String(product.start),
      String(product.end),
      Boolean(product.purchasable)
    );

    if (!available) {
      throw new Error(
        "The product that you are trying to swap to is not available for the dates that you have selected."
      );
    }

    try {
      await ShoppingCartMethods.addProduct({
        token,
        id: next,
        type: "STAGE_GEAR",
        options: {
          rentalStart: String(product.start),
          rentalEnd: String(product.end),
        },
      });
      await ShoppingCartMethods.removeProduct({
        token,
        id: original,
      });
      return true;
    } catch (error) {
      throw new Error("000X2 Unable to add product to cart");
    }
  }

  static async controls(cart: Ecommerce.Cart.State) {
    const control: StageGear.Control = {
      available: true,
      products: [],
    };

    const products = StageGearHelpers.products(cart);

    if (products?.length === 0) {
      control.available = true;
      return control;
    }

    await Promise.all(
      products.map(async (product) => {
        const available = await StageGearMethods.available(
          product.id,
          String(product.start),
          String(product.end),
          Boolean(product.purchasable)
        );

        if (!available) {
          control.available = false;

          control.products.push({
            id: product.id,
            available,
          });
        }
      })
    );

    return control;
  }

  static async options(cart: Ecommerce.Cart.State, id: string | number) {
    const options: StageGear.Methods.Information[] = [];

    const product = cart?.data?.nonLicensedItems?.find(
      (item) => String(item.product.id) === String(id)
    );

    if (!product) {
      return options;
    }

    const makeRequestToMicroservice = useService("microservice", {
      cache: 1_800_000,
    });

    const table = await makeRequestToMicroservice(
      "GET",
      "/ecommerce/price-tables",
      {
        "pagination[page]": "1",
        "pagination[pageSize]": "50",
        "filter[region_id]": "1",
        "filter[type]": "STAGE_GEAR",
      }
    );

    const products = await makeRequestToMicroservice(
      "GET",
      `/ecommerce/products/search/1/stage-gear`,
      {
        "pagination[page]": 1,
        "pagination[pageSize]": 10,
        "filter[category_slugs][0]":
          product?.product?.gearResource?.category?.slug || "projectors",
        "includes[attachments]": "true",
      }
    );

    const results: StageGearProduct[] = products?.["result"] || [];

    results?.forEach((result) => {
      if (String(result?.product?.id) === String(product?.product?.id)) {
        return;
      }

      const pricing = table?.["result"]?.find(
        (item: any) => item.product?.id === result?.product?.id
      );
      const thumbnail = product?.product.gearResource?.attachments?.find(
        (a) => a.name === "image_thumbnail"
      )?.blob?.signedUrl;

      options.push({
        id: result?.product?.id,
        name: result?.name,
        description: String(product?.product?.gearResource?.category?.name),
        price: Number(pricing.price || 0),
        purchasable: Boolean(product?.product?.gearResource?.isPurchasable),
        thumbnail,
        resource: result,
      });
    });

    return options;
  }

  static groups(products: StageGearProduct[]) {
    const map = new Map<string, StageGearProduct[]>();

    products.map((product) => {
      const meta = StageGearHelpers.meta(product);
      const name = meta.name;

      map.set(name, [...(map.get(name) || []), product]);
    });

    const groups: StageGearGroup[] = [];

    map.forEach((entries, key) => {
      groups.push({
        name: key,
        products: entries,
      });
    });

    return groups;
  }

  static async getCategories() {
    const categories = StageGearStaticData.isCategoriesEmpty()
      ? await this.fetchStageGearCategoriesSSR()
      : StageGearStaticData.getAllCategories();

    const index = categories.findIndex(
      (category: { slug: string }) => category.slug === "accessories"
    );

    const accessories = categories.splice(index, 1);

    return [...categories, ...accessories];
  }

  static async fetchStageGearCategoriesSSR(slug?: string) {
    const service = useService("microservice");

    try {
      const params = {
        "pagination[page]": 1,
        "pagination[pageSize]": 10,
        "pagination[orderBy][0][slug]": "ASC",
        "includes[attachments]": "true",
        ...(slug ? { "filter[slugs][0]": slug } : {}),
      };

      const response = await service(
        "GET",
        `/backstage/stage-gear/categories`,
        params
      );

      if (response?.["result"]) {
        return response?.["result"];
      }

      return [];
    } catch (error) {
      console.error(error);
    }
  }

  static async getProducts(categorySlug: string, productSlug?: string) {
    return StageGearStaticData.isProductsEmpty()
      ? await this.fetchStageGearProductsSSR(categorySlug, productSlug)
      : StageGearStaticData.getProductByCategorySlug(categorySlug);
  }

  static async fetchStageGearProductsSSR(
    categorySlug: string,
    productSlug?: string
  ) {
    const service = useService("microservice");

    try {
      const params = {
        "pagination[page]": 1,
        "pagination[pageSize]": 50,
        "filter[category_slugs][0]": categorySlug,
        "includes[attachments]": "true",
        ...(productSlug ? { "filter[slugs][0]": productSlug } : {}),
      };

      const response = await service(
        "GET",
        `/ecommerce/products/search/1/stage-gear`,
        params
      );

      return response?.["result"] || [];
    } catch (error) {
      console.error(error);
    }
  }
}
