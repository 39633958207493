import { Cookies } from "./Cookies";

export class CampaignHelper {
  static async attach(token?: string) {
    const campaigns: string[] = Cookies.get("campaigns")
      ? JSON.parse(Cookies.get("campaigns") as string)
      : [];

    if (campaigns && campaigns?.length) {
      Promise.all(
        campaigns.map(async (campaign) =>
          fetch(
            `${process.env["NEXT_PUBLIC_CMS_API_ORIGIN"]}/api/v1/user-campaign/${campaign}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      )
        .catch((error) => {
          console.error("🎾", "Campaigns Error", error);
        })
        .finally(() => {
          Cookies.remove("campaigns");
        });
    }
  }

  static store(campaign: string) {
    let campaigns = [campaign];

    if (Cookies.get("campaigns")) {
      const stored = JSON.parse(Cookies.get("campaigns") as string);

      if (!stored.includes(campaign)) {
        campaigns = [...stored, campaign];
      }
    }

    Cookies.set("campaigns", JSON.stringify(campaigns));
  }
}
