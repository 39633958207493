import { boolean, number, object, string } from "yup";

export const AddressSchema = object({
  id: string(),
  line1: string().nullable(),
  line2: string().nullable(),
  postalCode: string().nullable(),
  defaultShipping: boolean(),
  defaultBilling: boolean(),
  location: object({
    city: object({
      id: number(),
      name: string(),
      stateCode: string().optional(),
      state_code: string().optional(),
    }),
    state: object({
      code: string(),
      name: string(),
      countryCode: string().optional(),
      country_code: string().optional(),
    }),
    country: object({
      code: string(),
      name: string(),
    }),
  }),
});
