import partners from "../../../apps/catalog/data/partners.json";

export class PartnerStaticData {
  static isPartnersEmpty() {
    return partners.length === 0;
  }

  static getAllPartners() {
    return partners;
  }
}
