import {
  useCMSBlogs,
  useCMSProductionPhoto,
  useCMSTestimonials,
} from "./useCMS";
import { useMicroserviceShowBrand } from "./useMicroserviceShows";
import useService from "./useService";

export const useShowVariantOverviewEmpty = ({
  showVariantId,
  showBrandId,
}: {
  showVariantId?: string;
  showBrandId?: string;
}) => {
  const testimonials = useCMSTestimonials(
    {
      filter: {
        showVariantTags: [showVariantId as string],
        showBrandTags: [showBrandId as string],
      },
    },
    Boolean(showBrandId)
  );

  const blogs = useCMSBlogs(
    {
      filter: {
        showVariantTags: [showVariantId as string],
        showBrandTags: [showBrandId as string],
      },
    },
    Boolean(showBrandId)
  );

  const images = useCMSProductionPhoto(
    !showVariantId || !showBrandId
      ? {}
      : {
          filter: {
            showVariantTags: [showVariantId as string],
            showBrandTags: [showBrandId as string],
          },
        },
    Boolean(showBrandId)
  );

  return {
    empty:
      testimonials.loading || blogs.loading || images.loading
        ? false
        : testimonials.testimonials.length === 0 &&
          blogs.blogs.length === 0 &&
          images.images.length === 0,
    loading: testimonials.loading || blogs.loading || images.loading,
  };
};

export const requestShowBrandOnlyShowSSR = async (showBrandSlug: string) => {
  const makeRequestToMicroservice = useService("microservice");

  const data = await makeRequestToMicroservice(
    "GET",
    `/backstage/show-brands/slug/${showBrandSlug}`,
    {
      "includes[attachments]": "true",
      "includes[shows]": "true",
      "includes[shows_attachments]": "true",
    }
  );

  return { only: data?.["shows"]?.length === 1 };
};

export const useShowBrandOnlyShow = ({
  showBrandSlug,
}: {
  showBrandSlug?: string;
}) => {
  const showBrand = useMicroserviceShowBrand({
    slug: showBrandSlug,
  });

  return {
    loading: showBrand.loading,
    only: showBrand.loading ? false : showBrand?.data?.shows?.length === 1,
  };
};

export default useShowVariantOverviewEmpty;
