/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseCMSProductionPhoto } from "./useCMS";
import useService from "./useService";

export class ProductionPhotoHelper {
  static async redirect(photo: UseCMSProductionPhoto): Promise<void> {
    if (!window?.location?.pathname) {
      return;
    }

    const _variant = photo?.showVariantTags?.[0];
    if (_variant) {
      const variant = await this.variant(_variant);
      if (variant) {
        window.location.pathname = variant;
        return;
      }
    }
  }

  static async variant(id: string): Promise<string | undefined> {
    const service = useService("accounts");

    const request = (await service("GET", `/api/v2/shows/${id}`)) as any;

    return request?.data?.attributes?.slug
      ? `/shows/${request?.data?.attributes?.slug}/${request?.data?.attributes?.slug}`
      : undefined;
  }
}

export default ProductionPhotoHelper;
