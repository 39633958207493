import { MutableRefObject, RefObject, useEffect, useState } from "react";

export const useVisible = (
  reference: RefObject<HTMLDivElement> | MutableRefObject<HTMLDivElement>,
  disconnect?: boolean
) => {
  const [visible, _visible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        _visible(true);
        console.log("👀", "Visible", entry.boundingClientRect);

        if (disconnect) {
          observer.disconnect();
        }
      }
    });

    if (!reference?.current) {
      return;
    }

    observer.observe(reference.current);

    return () => {
      observer.disconnect();
    };
  }, [reference]);

  return { visible };
};

export default useVisible;
