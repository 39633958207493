/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import clone from "lodash/clone";
import { v4 as uuidv4 } from "uuid";

import { Act, Scene, ShowData, VideoSet } from "../types";

export const MoveHelper = (
  data: unknown[],
  oldIndex: number,
  newIndex: number
) => {
  if (newIndex >= data.length) {
    let k = newIndex - data.length + 1;
    while (k--) {
      data.push(undefined);
    }
  }
  data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
  return data;
};

export const TeleportHelper = (
  data: ShowData,
  teleportMode: "append" | "push",
  oldActIndex: number,
  oldSceneIndex: number,
  oldCueIndex: number,
  newActIndex: number,
  newSceneIndex: number,
  newCueIndex: number
) => {
  const clonedSceneData = clone(data);

  const clonedCueData = clone(
    clonedSceneData.acts[oldActIndex]?.scenes[oldSceneIndex]?.sets[oldCueIndex]
  );

  if (!clonedCueData) {
    return;
  }

  clonedSceneData.acts[oldActIndex]?.scenes[oldSceneIndex]?.sets.splice(
    oldCueIndex,
    1
  );

  switch (teleportMode) {
    case "append":
      if (newCueIndex === 0) {
        if (clonedSceneData.acts[newActIndex]?.scenes[newSceneIndex]?.sets) {
          (
            clonedSceneData.acts[newActIndex].scenes[newSceneIndex] as Scene
          ).sets = [
            clonedCueData,
            ...(
              clonedSceneData.acts[newActIndex].scenes[newSceneIndex] as Scene
            ).sets,
          ];
        }
      } else {
        if (clonedSceneData.acts[newActIndex]?.scenes[newSceneIndex]?.sets) {
          clonedSceneData.acts[newActIndex].scenes[newSceneIndex].sets.splice(
            newCueIndex,
            0,
            clonedCueData
          );
        }
      }
      break;
    case "push":
      if (
        newCueIndex >
        clonedSceneData.acts[newActIndex]?.scenes[newSceneIndex]?.sets.length! -
          1
      ) {
        clonedSceneData.acts[newActIndex].scenes[newSceneIndex].sets.push(
          clonedCueData
        );
      } else {
        clonedSceneData.acts[newActIndex].scenes[newSceneIndex].sets.splice(
          newCueIndex + 1,
          0,
          clonedCueData
        );
      }
      break;
    default:
      break;
  }

  return clonedSceneData;
};

export const ActMoveHelper = (
  data: ShowData,
  oldActIndex: number,
  newActIndex: number
) => {
  const clonedSceneData = clone(data);
  clonedSceneData.acts = MoveHelper(
    data.acts,
    oldActIndex,
    newActIndex
  ) as Act[];
  return clonedSceneData;
};

export const SceneMoveHelper = (
  data: ShowData,
  act: number,
  oldSceneIndex: number,
  newSceneIndex: number
) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act] as Act).scenes = MoveHelper(
    data.acts[act]?.scenes || [],
    oldSceneIndex,
    newSceneIndex
  ) as Scene[];
  return clonedSceneData;
};

export const CueMoveHelper = (
  data: ShowData,
  act: number,
  scene: number,
  oldCueIndex: number,
  newCueIndex: number
) => {
  const clonedSceneData = clone(data);

  if ((clonedSceneData.acts[act].scenes[scene] as Scene).sets) {
    (clonedSceneData.acts[act].scenes[scene] as Scene).sets = MoveHelper(
      data?.acts[act]?.scenes[scene]?.sets || [],
      oldCueIndex,
      newCueIndex
    ) as VideoSet[];
  }

  return clonedSceneData;
};

export const CueTeleportHelper = (
  data: ShowData,
  teleportMode: "append" | "push",
  oldActIndex: number,
  oldSceneIndex: number,
  oldCueIndex: number,
  newActIndex: number,
  newSceneIndex: number,
  newCueIndex: number
) => {
  let clonedSceneData = clone(data);
  clonedSceneData = TeleportHelper(
    clonedSceneData,
    teleportMode,
    oldActIndex,
    oldSceneIndex,
    oldCueIndex,
    newActIndex,
    newSceneIndex,
    newCueIndex
  ) as ShowData;

  return clonedSceneData;
};

export const ActDuplicateHelper = (data: ShowData, act: number) => {
  const clonedSceneData = clone(data);
  const _act = clonedSceneData.acts[act] as Act;
  clonedSceneData.acts?.splice(act, 0, {
    ..._act,
    sortIdentifier: uuidv4(),
  });
  return clonedSceneData;
};

export const SceneDuplicateHelper = (
  data: ShowData,
  act: number,
  scene: number
) => {
  const clonedSceneData = clone(data);
  const _scene = (clonedSceneData.acts[act] as Act).scenes[scene] as Scene;
  clonedSceneData.acts[act]?.scenes?.splice(scene, 0, {
    ..._scene,
    sortIdentifier: uuidv4(),
  });
  return clonedSceneData;
};

export const CueDuplicateHelper = (
  data: ShowData,
  act: number,
  scene: number,
  cue: number
) => {
  const clonedSceneData = clone(data);
  const _cue = (clonedSceneData.acts[act]?.scenes[scene] as Scene).sets[
    cue
  ] as VideoSet;
  clonedSceneData.acts[act]?.scenes[scene]?.sets?.splice(cue, 0, {
    ..._cue,
    identifier: uuidv4(),
  });
  return clonedSceneData;
};

export const ActEnableHelper = (data: ShowData, act: number) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act] as Act).hidden = false;
  return clonedSceneData;
};

export const ActDisableHelper = (data: ShowData, act: number) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act] as Act).hidden = true;
  return clonedSceneData;
};

export const SceneEnableHelper = (
  data: ShowData,
  act: number,
  scene: number
) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act].scenes[scene] as Scene).disabled = false;
  return clonedSceneData;
};

export const SceneDisableHelper = (
  data: ShowData,
  act: number,
  scene: number
) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act].scenes[scene] as Scene).disabled = true;
  return clonedSceneData;
};

export const CueEnableHelper = (
  data: ShowData,
  act: number,
  scene: number,
  cue: number
) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act].scenes[scene].sets[cue] as VideoSet).enabled =
    true;
  return clonedSceneData;
};

export const CueDisableHelper = (
  data: ShowData,
  act: number,
  scene: number,
  cue: number
) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act].scenes[scene].sets[cue] as VideoSet).enabled =
    false;
  return clonedSceneData;
};

export const CueUpdateHelper = (
  data: ShowData,
  act: number,
  scene: number,
  cue: number,
  value: VideoSet
) => {
  const clonedSceneData = clone(data);
  (clonedSceneData.acts[act].scenes[scene] as Scene).sets[cue] = value;
  return clonedSceneData;
};

export const DangerousDataUpdateHelper = (data: ShowData) => {
  const clonedSceneData = clone(data);
  return clonedSceneData;
};

export const ShowDataMiddleware = (data: ShowData): ShowData => {
  const _data = clone(data);

  _data?.acts?.map((act: Act, a: number) => {
    act?.scenes?.map((scene: Scene, s: number) => {
      scene?.sets?.map((cue: VideoSet, c: number) => {
        if (!_data?.acts?.[a]?.scenes?.[s]?.sets?.[c]) {
          return;
        }

        if (cue?.originalIndexes === undefined) {
          (_data.acts[a].scenes[s].sets[c] as VideoSet).originalIndexes = [
            a,
            s,
            c,
          ].join(",");
        }
        (_data.acts[a].scenes[s].sets[c] as VideoSet).currentIndexes = [
          a,
          s,
          c,
        ].join(",");
      });
    });
  });

  return _data;
};

export default {
  MoveHelper,
  TeleportHelper,
  ActMoveHelper,
  SceneMoveHelper,
  CueMoveHelper,
  ActDuplicateHelper,
  SceneDuplicateHelper,
  CueDuplicateHelper,
  ActEnableHelper,
  ActDisableHelper,
  SceneEnableHelper,
  SceneDisableHelper,
  CueEnableHelper,
  CueDisableHelper,
  CueTeleportHelper,
  CueUpdateHelper,
  ShowDataMiddleware,
  DangerousDataUpdateHelper,
};
