import { useCMSTestimonials, useMobile } from "@with-nx/hooks-n-helpers";
import { ReusableSection, Rule } from "@with-nx/simple-ui/atoms";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { Box } from "simple-effing-primitive-layout";

import TestimonialGallery from "../testimonial-gallery/testimonial-gallery";

export const SolutionsTestimonials = ({
  title,
  titleTag,
}: {
  title: string;
  titleTag?: string;
}) => {
  const { testimonials } = useCMSTestimonials({
    exists: {
      solutionTags: true,
    },
    per: 20,
  });

  if (testimonials.length === 0) {
    return null;
  }

  return (
    <ReusableSection>
      <div className="container">
        <Rule parse="!dt d:block mb:24 c:?font1 ta:left" tag={titleTag || "h2"}>
          {title}
        </Rule>
      </div>
      <TestimonialGallery testimonials={testimonials} per={3} />
    </ReusableSection>
  );
};

export interface TestimonialCardProps {
  image?: string;
  picture?: string | null;
  description?: string;
  name?: string;
  title?: string;
  reverse?: boolean;
  ratings?: boolean;
  properties?: {
    [key: string]: unknown;
  };
  variant?: "default" | "large";
  showQuotes?: boolean;
}

export const TestimonialCard = (props: TestimonialCardProps) => {
  const {
    image,
    description,
    name,
    title,
    properties,
    reverse = false,
    ratings = true,
    variant = "default",
    showQuotes = false,
  } = props;

  const mobile = useMobile();

  return (
    <Box
      parse={`br:10 pa:20 c:${variant === "large" ? "transparent" : "#1A1E23"} 
      d:flex fd:${reverse ? "column-reverse" : "column"} p:relative`}
      {...properties}
    >
      <Box css="d:flex a:center">
        {image ? (
          <Box css="br:999 oa:hidden o:hidden" parse="w:48 h:48 mr:10">
            <img
              width={32}
              height={32}
              alt={name}
              src={image}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        ) : null}

        <Box>
          <Rule parse="!lm d:block" color="var(--font1)">
            {name}
          </Rule>
          <Rule
            parse={`!ls d:block ${
              variant === "large" ? (mobile ? "ml:56" : "ml:80") : ""
            }`}
            color="var(--font4)"
          >
            {title}
          </Rule>
        </Box>
      </Box>

      {ratings ? (
        <Box parse={reverse ? "mb:16" : "mt:16"}>
          <svg width="166" height="22" viewBox="0 0 166 22" fill="none">
            <path
              d="M10.3155 0.897461C10.6165 -0.0203844 11.9149 -0.0203866 12.2159 0.897458L14.1715 6.86077C14.3062 7.27146 14.6895 7.54915 15.1217 7.54915H21.4304C22.4014 7.54915 22.8027 8.79349 22.0147 9.36075L16.9268 13.0232C16.5731 13.2778 16.425 13.7323 16.5608 14.1464L18.508 20.0838C18.8096 21.0035 17.759 21.7725 16.9735 21.207L11.8499 17.5188C11.5009 17.2676 11.0304 17.2676 10.6814 17.5188L5.5578 21.207C4.77228 21.7725 3.72177 21.0035 4.02338 20.0838L5.97053 14.1464C6.10635 13.7323 5.95828 13.2778 5.60454 13.0232L0.516664 9.36074C-0.271368 8.79349 0.129919 7.54915 1.10088 7.54915H7.40961C7.84183 7.54915 8.22513 7.27146 8.35982 6.86077L10.3155 0.897461Z"
              fill="url(#paint0_linear_1509_19423)"
            />
            <path
              d="M46.1826 0.897461C46.4836 -0.0203844 47.782 -0.0203861 48.083 0.897459L50.0387 6.86077C50.1734 7.27146 50.5567 7.54915 50.9889 7.54915H57.2976C58.2686 7.54915 58.6699 8.79349 57.8818 9.36075L52.794 13.0232C52.4402 13.2778 52.2922 13.7323 52.428 14.1464L54.3751 20.0838C54.6767 21.0035 53.6262 21.7725 52.8407 21.207L47.7171 17.5188C47.3681 17.2676 46.8976 17.2676 46.5486 17.5188L41.425 21.207C40.6395 21.7725 39.5889 21.0035 39.8905 20.0838L41.8377 14.1464C41.9735 13.7323 41.8254 13.2778 41.4717 13.0232L36.3838 9.36074C35.5958 8.79349 35.9971 7.54915 36.9681 7.54915H43.2768C43.709 7.54915 44.0923 7.27146 44.227 6.86077L46.1826 0.897461Z"
              fill="url(#paint1_linear_1509_19423)"
            />
            <path
              d="M82.0498 0.897461C82.3508 -0.0203844 83.6492 -0.0203861 83.9502 0.897459L85.9058 6.86077C86.0405 7.27146 86.4238 7.54915 86.8561 7.54915H93.1648C94.1357 7.54915 94.537 8.79349 93.749 9.36075L88.6611 13.0232C88.3074 13.2778 88.1593 13.7323 88.2951 14.1464L90.2423 20.0838C90.5439 21.0035 89.4934 21.7725 88.7079 21.207L83.5842 17.5188C83.2352 17.2676 82.7648 17.2676 82.4158 17.5188L77.2921 21.207C76.5066 21.7725 75.4561 21.0035 75.7577 20.0838L77.7049 14.1464C77.8407 13.7323 77.6926 13.2778 77.3389 13.0232L72.251 9.36075C71.463 8.79349 71.8643 7.54915 72.8352 7.54915H79.144C79.5762 7.54915 79.9595 7.27146 80.0942 6.86077L82.0498 0.897461Z"
              fill="url(#paint2_linear_1509_19423)"
            />
            <path
              d="M117.917 0.897461C118.218 -0.0203844 119.516 -0.0203861 119.817 0.897459L121.773 6.86077C121.908 7.27146 122.291 7.54915 122.723 7.54915H129.032C130.003 7.54915 130.404 8.79349 129.616 9.36075L124.528 13.0232C124.175 13.2778 124.026 13.7323 124.162 14.1464L126.109 20.0838C126.411 21.0035 125.361 21.7725 124.575 21.207L119.451 17.5188C119.102 17.2676 118.632 17.2676 118.283 17.5188L113.159 21.207C112.374 21.7725 111.323 21.0035 111.625 20.0838L113.572 14.1464C113.708 13.7323 113.56 13.2778 113.206 13.0232L108.118 9.36075C107.33 8.79349 107.731 7.54915 108.702 7.54915H115.011C115.443 7.54915 115.827 7.27146 115.961 6.86077L117.917 0.897461Z"
              fill="url(#paint3_linear_1509_19423)"
            />
            <path
              d="M153.784 0.897461C154.085 -0.0203847 155.384 -0.0203865 155.685 0.897458L157.64 6.86077C157.775 7.27146 158.158 7.54915 158.59 7.54915H164.899C165.87 7.54915 166.271 8.79349 165.483 9.36075L160.395 13.0232C160.042 13.2778 159.894 13.7323 160.029 14.1464L161.977 20.0838C162.278 21.0035 161.228 21.7725 160.442 21.207L155.319 17.5188C154.97 17.2676 154.499 17.2676 154.15 17.5188L149.026 21.207C148.241 21.7725 147.19 21.0035 147.492 20.0838L149.439 14.1464C149.575 13.7323 149.427 13.2778 149.073 13.0232L143.985 9.36075C143.197 8.79349 143.599 7.54915 144.57 7.54915H150.878C151.311 7.54915 151.694 7.27146 151.829 6.86077L153.784 0.897461Z"
              fill="url(#paint4_linear_1509_19423)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1509_19423"
                x1="168.369"
                y1="10.8325"
                x2="-2"
                y2="10.8325"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F2B8A6" />
                <stop offset="1" stopColor="#E2CE1E" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1509_19423"
                x1="168.369"
                y1="10.8325"
                x2="-2"
                y2="10.8325"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F2B8A6" />
                <stop offset="1" stopColor="#E2CE1E" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1509_19423"
                x1="168.369"
                y1="10.8325"
                x2="-2"
                y2="10.8325"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F2B8A6" />
                <stop offset="1" stopColor="#E2CE1E" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1509_19423"
                x1="168.369"
                y1="10.8325"
                x2="-2"
                y2="10.8325"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F2B8A6" />
                <stop offset="1" stopColor="#E2CE1E" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1509_19423"
                x1="168.369"
                y1="10.8325"
                x2="-2"
                y2="10.8325"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F2B8A6" />
                <stop offset="1" stopColor="#E2CE1E" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      ) : undefined}

      <Box parse="p:relative">
        {showQuotes && (
          <Box parse="d:inline-block mr:12 p:absolute t:8px">
            <RiDoubleQuotesL color="var(--font4)" size={mobile ? 48 : 68} />
          </Box>
        )}

        <Rule
          parse={`!${variant === "large" ? (mobile ? "ht" : "hl") : "pm"} 
          ${reverse ? "mb" : "mt"}:16 d:inline-block 
          ${
            variant === "large" ? (mobile ? "ml:56 mt:28" : "ml:80 mt:28") : ""
          } p:relative`}
          color="var(--font1)"
          weight="400"
          tag="p"
        >
          {description
            ? description?.length > 256
              ? description.slice(0, 256) + "..."
              : description
            : null}
        </Rule>

        {showQuotes && (
          <Box parse={`d:inline-block p:absolute b:0 r:${mobile ? "-32" : ""}`}>
            <RiDoubleQuotesR color="var(--font4)" size={mobile ? 48 : 68} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SolutionsTestimonials;
