import ShoppingCartHelpers from "./ShoppingCartHelpers";
import ShoppingCartMethods from "./ShoppingCartMethods";
import ShoppingCartUtils from "./ShoppingCartUtils";

export class ShoppingCart {
  static helpers = ShoppingCartHelpers;
  static utils = ShoppingCartUtils;
  static methods = ShoppingCartMethods;
}

export default ShoppingCart;
