import { boolean, object, string } from "yup";

export const ShippingAddressSchema = object({
  id: string().nullable(),
  line1: string().nullable(),
  line2: string().nullable(),
  postalCode: string().nullable(),
  defaultShipping: boolean(),
  defaultBilling: boolean(),
  location: object({
    city: object({
      id: string().nullable(),
      name: string().nullable(),
      state_code: string().nullable(),
    }).nullable(),
    state: object({
      code: string().nullable(),
      name: string().nullable(),
      countryCode: string().nullable(),
    }).nullable(),
    country: object({
      code: string().nullable(),
      name: string().nullable(),
    }).nullable(),
  }),
  createdAt: string().nullable(),
  updatedAt: string().nullable(),
}).nullable();
