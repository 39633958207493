export function isJSON(string: string) {
  try {
    JSON.parse(string);
  } catch (error) {
    return false;
  }
  return true;
}

export function getHostProduction() {
  return "https://catalog.broadwaymedia.com/_cdc";
}

export function getHost() {
  return "http://localhost:4200/_cdc";
}

export const BASE_URL = process.env["NEXT_PUBLIC_API_ORIGIN"] + "/api/v2/";
export const STORAGE_KEY = "broadway-media-session-v2";
