import { Box } from "simple-effing-primitive-layout";

export const ReusablePreSlantedContainer = ({
  children,
  active = true,
  amount = 32,
  style,
}: {
  children: unknown;
  active?: boolean;
  amount?: number;
  style?: {
    [key: string]: unknown;
  };
}) => {
  return (
    <Box
      style={{ paddingBottom: active ? "calc(64px + 96px)" : amount, ...style }}
    >
      {children}
    </Box>
  );
};

export default ReusablePreSlantedContainer;
