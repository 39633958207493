import { getCookie, setCookie, deleteCookie } from "cookies-next";

export class Cookies {
  static get(key: string) {
    return getCookie(key);
  }

  static set(key: string, value: string) {
    const expires = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

    return setCookie(key, value, {
      expires,
    });
  }

  static remove(key: string) {
    return deleteCookie(key);
  }
}
