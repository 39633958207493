/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";

export interface EditorSyncProcess extends NodeJS.Process {
  EDITOR_SYNC_API?: string;
}

export type EditorSyncRequestParams = {
  [key: string]: unknown;
};

export type EditorSyncData = {
  _id: string;
  referenceId: string;
  userReferenceId: number;
  editorData: unknown;
  version: number;
};

export type EditorSyncFiles = {
  [key: string]: string;
};

export class EditorSync {
  static get url() {
    const __ = process.env?.["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"];

    try {
      if (process !== undefined) {
        return (process as EditorSyncProcess)?.EDITOR_SYNC_API || __;
      }
    } catch (error) {
      return __;
    }

    return __;
  }

  static async get(
    token: string,
    endpoint: string,
    params?: EditorSyncRequestParams
  ) {
    const _url = (() => {
      let temporary = "";
      const parameters = params ? Object.entries(params) : [];

      parameters.map(([key, value]: [string, unknown], i: number) => {
        temporary += `${i === 0 ? "?" : "&"}${key}=${value as string}`;
      });

      return this.url + endpoint + temporary;
    })();

    const request = await fetch(_url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      const json = await request.json();
      return json;
    } catch (error) {
      return {
        statusCode: 404,
        message: "Something went wrong.",
        error: "Not Found",
      };
    }
  }

  static async post(
    token: string,
    endpoint: string,
    params?: EditorSyncRequestParams
  ) {
    const _url = (() => {
      return this.url + endpoint;
    })();

    const request = await fetch(_url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    try {
      const json = await request.json();
      return json;
    } catch (error) {
      return {
        statusCode: 404,
        message: "Something went wrong.",
        error: "Not Found",
      };
    }
  }

  static async getLatestVersions(token: string, rental: string | number) {
    const data: EditorSyncData[] = [];
    const request = await this.get(
      token,
      `/stageplayer/editor/versions/${rental}`,
      {
        page: "1",
        pageSize: "20",
      }
    );

    if (!request.error) {
      request.result.map((result: EditorSyncData) => data.push(result));
    }

    return data;
  }

  static async getLatestVersion(token: string, rental: string | number) {
    const request = await this.get(
      token,
      `/stageplayer/editor/versions/${rental}/latest`,
      {}
    );

    if (!request.error) {
      return request as EditorSyncData;
    }

    return false;
  }

  static async getVersion(
    token: string,
    rental: string | number,
    version: number
  ) {
    const request = await this.get(
      token,
      `/stageplayer/editor/versions/${rental}/${version.toString()}`,
      {}
    );

    if (!request.error) {
      return request as EditorSyncData;
    }

    return false;
  }

  static async createVersion(
    token: string,
    rental: string | number,
    data: unknown
  ) {
    const request = await this.post(token, `/stageplayer/editor/versions`, {
      referenceId: rental,
      editorData: data,
    });

    if (!request.error) {
      return {
        _id: request._id,
        referenceId: rental,
        editorData: data,
        userReferenceId: 0,
        version: request.version,
      } as EditorSyncData;
    }

    return false;
  }

  static verifyEditorData(data: any) {
    const schema = Yup.object().shape({
      acts: Yup.array().of(
        Yup.object({
          act_id: Yup.number(),
          hidden: Yup.boolean(),
          name: Yup.string(),
          sortIdentifier: Yup.string().nullable().optional(),
          thumbnail: Yup.string().nullable().optional(),
          scenes: Yup.array().of(
            Yup.object({
              disabled: Yup.boolean(),
              name: Yup.string(),
              scene_id: Yup.number(),
              sortIdentifier: Yup.string().nullable().optional(),
              thumbnail: Yup.string().nullable().optional(),
              sets: Yup.array().of(
                Yup.object({
                  action: Yup.string().nullable().optional(),
                  cueDescription: Yup.string().nullable().optional(),
                  cueLine: Yup.string().nullable().optional(),
                  cuePage: Yup.string().nullable().optional(),
                  enabled: Yup.boolean().nullable().optional(),
                  identifier: Yup.string().nullable().optional(),
                  loop: Yup.string().nullable().optional(),
                  name: Yup.string().nullable().optional(),
                  thumbnail: Yup.string().nullable().optional(),
                })
              ),
            })
          ),
        })
      ),
      curtainWarmer: Yup.object().shape({
        curtainWarmer: Yup.string(),
        curtainWarmerThumbnail: Yup.string(),
      }),
      options: Yup.object().shape({
        blackouts: Yup.object().shape({
          afterWarmers: Yup.boolean(),
          endOfAct: Yup.boolean(),
          endOfScene: Yup.boolean(),
        }),
        curtainWarmers: Yup.object().shape({
          endOfAct: Yup.boolean(),
          startOfShow: Yup.boolean(),
          useMaster: Yup.boolean(),
        }),
      }),
      version: Yup.number(),
    });

    const validate = schema.isValidSync(data);

    if (!validate) {
      //console log errors
      console.log("latest is valid", validate);
      schema.validate(data).catch((error) => {
        console.log("latest error", error);
      });
    }

    return validate;
  }
}

export default EditorSync;
