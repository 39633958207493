import {
  DesignedButton,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import AnimatedInfiniteSlantedCards from "../animated-infinite-slanted-cards/animated-infinite-slanted-cards";

export const CreateYourAccount = () => {
  const images = [...Array(36)].map((_, i) => `/assets/drops/${i + 1}.jpg`);

  return (
    <ReusableGlossBox parse="pa:48 mb:24 br:10 c:?foreground p:relative oa:hidden">
      <Box parse="p:relative i:4 z:4">
        <Rule parse="!_t d:block mb:24" tag="h2">
          Ready to create your account?
        </Rule>
        <Rule parse="!_p d:block mb:24">
          Sign up now to explore our vast catalog of scenic projections,
          choreography guides, and more, featuring over 3000+ digital backdrops.
        </Rule>
        <DesignedButton
          label="Sign Up Now"
          href="/auth?form=register"
          theme="primary"
          size="medium"
        />
      </Box>
      <Box
        parse="oa:visible p:absolute b:unset r:unset t:50% l:50%"
        style={{ transform: "translateX(-50%) rotate(-15deg)" }}
        id="animated-cards"
      >
        <AnimatedInfiniteSlantedCards size={12} rows={2} images={images} />
      </Box>
    </ReusableGlossBox>
  );
};

export default CreateYourAccount;
