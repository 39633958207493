import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../../shared";

const CuePopupTextarea = ({
  label,
  value,
  change,
}: {
  label?: string;
  value: string | number;
  change: (value: string | number) => void;
}) => {
  const [storedValue, setStoredValue] = useState(value);

  return (
    <>
      <Box parse="w:100% mb:10">
        <Text rule="Label Tiny" size={10} color={Colors.font4}>
          {label}
        </Text>
        <Box>
          <textarea
            className="--textarea"
            value={storedValue}
            onChange={(event) => setStoredValue(event.target.value)}
          />
        </Box>
      </Box>
      <Box
        parse="pt:8 pb:8 pr:10 pl:10 br:5 d:inline-flex"
        color={Colors.entity}
        press={() => change(storedValue)}
        css="--more"
      >
        <Text color={Colors.font1} rule="Label Small">
          Edit
        </Text>
      </Box>
    </>
  );
};

export default CuePopupTextarea;
