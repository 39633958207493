/* eslint-disable @typescript-eslint/no-explicit-any */
import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { createRef, useCallback, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { UploadedFile, useFile } from "./useFile";
import { handle as HANDLE } from "./utils";

interface AreaProps {
  accept?: string;
  multiple?: boolean;
  base64?: boolean;
  upload?: (files?: UploadedFile[]) => void;
  height?: number;
}

export const Area = ({
  accept = ".png, .jpg, .pdf",
  multiple = false,
  base64 = false,
  upload,
  height = 40,
}: AreaProps) => {
  const { change, file, loading, open } = useFile({
    accept,
    multiple,
    base64,
  });
  const [drag, _drag] = useState<boolean>(false);

  useEffect(() => {
    if (upload) {
      upload(file);
    }
  }, [file]);

  const ref = createRef<HTMLDivElement>();
  const hd = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const hdi = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.items && e.dataTransfer?.items.length > 0) {
      _drag(true);
    }
  };
  const hdo = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    _drag(false);
  };
  const drop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    _drag(false);
    if (e?.dataTransfer?.files && e?.dataTransfer?.files?.length > 0) {
      const _file = e.dataTransfer.files ? e.dataTransfer.files : [];

      e.dataTransfer.clearData();

      const _files: any[] = [];

      for (let i = 0; i < _file.length; i++) {
        _files.push(_file[i]);
      }

      const temporary = multiple ? file || [] : [];

      (async () => {
        await Promise.all(
          _files.map(async (f, i) => {
            if (!multiple && i > 0) {
              return;
            }

            await new Promise((resolve) => {
              HANDLE(f, accept, multiple, base64, (_) => {
                if (_) {
                  temporary.push(_);
                  resolve(_);
                }
              });
            });
          })
        );
        change(temporary);
      })();
    }
  };

  useEffect(() => {
    const div: HTMLDivElement | null = ref.current;

    if (div !== null) {
      div.addEventListener("dragenter", hdi);
      div.addEventListener("dragleave", hdo);
      div.addEventListener("dragover", hd);
      div.addEventListener("drop", drop);
    }

    return () => {
      if (div !== null) {
        div.removeEventListener("dragenter", hdi);
        div.removeEventListener("dragleave", hdo);
        div.removeEventListener("dragover", hd);
        div.removeEventListener("drop", drop);
      }
    };
  }, []);

  const remove = useCallback(
    (index: number) => {
      if (!file?.length) {
        return;
      }

      const temporary = [...file];
      temporary.splice(index, 1);
      change(temporary);
    },
    [file]
  );

  return (
    <Box bottom={24}>
      <div ref={ref}>
        <Box
          press={open}
          parse={`h:${height} w:100% br:5 d:flex a:center j:center`}
          color="rgba(255,255,255,0.025)"
          border={`${drag ? 2 : 1}px dashed rgba(255, 255, 255, 0.${
            drag ? 5 : 1
          })`}
        >
          {loading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16px"
              height="16px"
              viewBox="0 0 16 16"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="8"
                cy="8"
                fill="none"
                stroke="#6F6F6F"
                strokeWidth="2"
                r="6"
                strokeDasharray="28.274333882308138 11.42477796076938"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 8 8;360 8 8"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
            </svg>
          ) : (
            <Rule color="#6F6F6F" rule="lt">
              Drop Files Here | {accept.toUpperCase()}
            </Rule>
          )}
        </Box>
      </div>
      {file?.map((f, i) => (
        <Box parse="mt:5 d:flex a:center" key={i}>
          <DesignedIcon
            name="multiply/bold"
            color="#59687B"
            size={12}
            press={() => remove(i)}
          />
          <Rule rule="lt" color="#6F6F6F" left={5}>
            {f.name}.{f.extension}
          </Rule>
        </Box>
      ))}
    </Box>
  );
};

export default Area;
