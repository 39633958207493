import { getSession, useAuth } from "@with-nx/auth";
import { useState } from "react";

import { useServiceForm } from "./useService";

export const useAccountDocuments = () => {
  const auth = useAuth();

  const [uploading, _uploading] = useState<boolean>(false);
  const [error, _error] = useState<string>("");
  const [success, _success] = useState<boolean | undefined>();

  const upload = async (
    driverLicense?: File,
    certificateOfInsurance?: File,
    taxExemption?: File
  ) => {
    try {
      if (!driverLicense && !certificateOfInsurance && !taxExemption) {
        _error("Please upload at least one of the documents.");
        return;
      }

      _uploading(true);
      _success(undefined);
      _error("");

      const makeRequestToAccounts = useServiceForm("accounts");

      const form = new FormData();

      if (driverLicense) {
        form.append("account[drivers_license]", driverLicense as Blob);
      }

      if (certificateOfInsurance) {
        form.append("account[coi]", certificateOfInsurance as Blob);
      }

      if (taxExemption) {
        form.append("account[tax_exemption_document]", taxExemption as Blob);
      }

      const token = auth.authState.session?.token;
      const response = await makeRequestToAccounts(
        "PATCH",
        "/api/v2/account",
        form,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.["error"]) {
        throw new Error(response?.["error"]);
      }

      if (token) {
        getSession(token).then((session) => {
          console.log("🔒", "Refresh");
          auth.login(session);
        });
      }

      _success(true);
    } catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);

      _error(message);
    } finally {
      _uploading(false);
    }
  };

  return {
    upload,
    uploading,
    success,
    error,
    _error,
  };
};
