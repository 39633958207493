import { array, object, string } from "yup";

import { AttachmentSchema } from "./attachmentSchema";

export const ShowBrandSchema = object({
  id: string(),
  name: string(),
  slug: string().nullable().optional(),
  logoColor: string().nullable().optional(),
  attachments: array().of(AttachmentSchema),
});
