import { useEffect, useState } from "react";

type UsePrintCallback = (items: unknown[]) => boolean;
type UsePrintItems = unknown[];

export const usePrint = (
  callback: UsePrintCallback,
  items: UsePrintItems,
  t?: boolean
) => {
  const [print, _print] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const noprint = params.get("noprint");
    if (noprint) {
      return;
    }

    if (callback(items) && !print && !t) {
      _print(true);
      setTimeout(() => {
        window?.print();
      }, 1000);
    }
  }, [callback, items, print]);

  const trigger = () => {
    if (print) {
      return;
    }
    _print(true);
    setTimeout(() => {
      window?.print();
    }, 1000);
  };

  return { trigger, print };
};

export const useNoPrint = () => {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window?.location?.search);
    const noprint = params?.get("noprint");

    return Boolean(noprint);
  }

  return false;
};

export class PrintHelper {
  static url(url: string, auto = false) {
    const frame = document.createElement("iframe");
    frame.classList.add("print-helper");
    frame.src = url;
    document.body.appendChild(frame);
    frame.onload = () => {
      frame.contentWindow?.addEventListener("afterprint", () => {
        document.body.removeChild(frame);
      });

      if (auto) {
        frame.contentWindow?.print();
      }
    };
  }

  static print() {
    window.print();
  }
}

export default usePrint;
