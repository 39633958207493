import { Act } from "libs/editor-ui/types";

const shouldPopupDisplayOnTop = () => {
  return false;
};

const anAdvancedChangeMadeToAct = (act?: Act): string[] => {
  if (!act) {
    return [] as string[];
  }

  const changes: string[] = [];

  try {
    if (act?.mask && act.mask !== "none") {
      changes.push("effect");
    }
  } catch (error) {
    console.error(error);
  }

  return changes;
};

const ActUtils = {
  shouldPopupDisplayOnTop,
  anAdvancedChangeMadeToAct,
};

export default ActUtils;
