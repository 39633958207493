import { Formatter, UseCMSBlog } from "@with-nx/hooks-n-helpers";
import { ReusableSection } from "@with-nx/simple-ui/atoms";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { GridBlog, GridStepScroller } from "@with-nx/simple-ui/organisms";
import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ReusableBlogListProps {
  title?: string;
  seeMore?: boolean;
  blogs: UseCMSBlog[];
}
export const ReusableBlogList: FC<ReusableBlogListProps> = ({
  title,
  blogs,
  seeMore = true,
}) => {
  return blogs.length > 0 ? (
    <ReusableSection background="transparent">
      <div className="container">
        <Rule parse="!hl d:block mb:36">{title}</Rule>
      </div>

      <GridStepScroller
        xs={24}
        sm={24}
        md={12}
        lg={8}
        xl={8}
        items={blogs.map((blog, b) => {
          if (b > 2) {
            return null;
          }

          return (
            <GridBlog
              key={blog._id}
              date={blog.date}
              description={blog.description}
              image={Formatter.compressed(blog.coverImage, 256, true)}
              title={blog.title}
              url={`/blog/${blog.slug}`}
              transparent={false}
              properties={{
                height: "100%",
              }}
            />
          );
        })}
      />

      {seeMore && (
        <div className="container">
          <Box css="d:flex j:flex-end" parse="mt:24">
            <DesignedButton
              size="small"
              theme="tetriary"
              label="See More Stories"
              href="/blog"
            />
          </Box>
        </div>
      )}
    </ReusableSection>
  ) : null;
};
