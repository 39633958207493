import { useAuth } from "@with-nx/auth";
import useService from "./useService";
import { useCallback } from "react";
import Toast from "react-hot-toast";

export const useGuidesAuth = () => {
  const auth = useAuth();

  const login = useCallback(async () => {
    try {
      const makeRequestToAccounts = useService("accounts", {
        bypass: true,
      });

      const token = auth.authState.session?.token;

      const request = await makeRequestToAccounts(
        "POST",
        "/api/v2/guides/oauth/sign-in",
        undefined,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (request?.["url"]) {
        const url = request["url"];
        const _url = new URL(url);
        const params = new URLSearchParams(_url.search);
        const code = params.get("code");

        const origin = process.env["NEXT_PUBLIC_GUIDES_ORIGIN"];
        const redirect = `${origin}/oauth?code=${code}`;

        return { url, code, redirect };
      } else {
        Toast.error("There was a problem logging in. Please try again later.");
        return;
      }
    } catch (error) {
      console.error(error);
      Toast.error("There was a problem logging in. Please try again later.");
      return;
    }
  }, [auth.authState.session]);

  return {
    login,
  };
};

export default useGuidesAuth;
