import { Box } from "simple-effing-primitive-layout";

interface LoadingContainerProps {
  height?: number | string;
}

export const LoadingContainer = (props: LoadingContainerProps) => {
  return (
    <Box
      css="w:100% d:flex a:center j:center __loading__"
      height={props.height || 200}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        preserveAspectRatio="xMidYMid"
        className="-simple-ui-designed-button-transition"
      >
        <circle
          cx="8"
          cy="8"
          fill="none"
          stroke="rgba(255,255,255,0.5)"
          strokeWidth="2"
          r="6"
          strokeDasharray="28.274333882308138 11.42477796076938"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 8 8;360 8 8"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </Box>
  );
};

export default LoadingContainer;
