import { useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import AnimatedInfiniteSlantedCards from "../animated-infinite-slanted-cards/animated-infinite-slanted-cards";

export const UpgradeToYearly = ({
  description,
  update,
}: {
  description: string;
  update: () => unknown;
}) => {
  const images = [...Array(36)].map((_, i) => `/assets/drops/${i + 1}.jpg`);

  const mobile = useMobile();

  return (
    <ReusableGlossBox parse="pa:48 mb:24 br:10 c:?foreground p:relative oa:hidden">
      <Box css="p:relative z:4" width={mobile ? undefined : "50%"}>
        <Rule parse="!_t d:block mb:24" tag="h2">
          Upgrade To Annual Subscription
        </Rule>
        <Rule parse="!_p d:block mb:24">{description}</Rule>
        <DesignedButton
          label="Upgrade My Membership"
          press={update}
          theme="primary"
          size="medium"
        />
      </Box>

      <Box
        parse="oa:visible p:absolute b:unset r:unset t:50% l:50%"
        style={{ transform: "translateX(-50%) rotate(-15deg)" }}
        id="animated-cards"
      >
        <AnimatedInfiniteSlantedCards size={12} rows={2} images={images} />
      </Box>
    </ReusableGlossBox>
  );
};

export default UpgradeToYearly;
