import { Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

interface DesignedBadgeProps {
  label: string;
  size?: string;
  background?: string;
  color?: string;
  children?: JSX.Element | JSX.Element[];
}

export const DesignedBadge = (props: DesignedBadgeProps) => {
  const { label, background = "?foreground", color = "var(--font2)" } = props;

  return (
    <Box
      parse={`br:5 h:24 pl:8 pr:8 c:${background} d:inline-flex a:center j:center`}
    >
      {props.children}
      <Rule rule="lt" color={color}>
        {label}
      </Rule>
    </Box>
  );
};

export default DesignedBadge;
