/* eslint-disable @typescript-eslint/no-explicit-any */
import { useServerRegion } from "@with-nx/region";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import useService from "./useService";
import ServiceError from "./ServiceError";

export interface UseMicroserviceShowVariant {
  id: string;
  title: string;
  public: boolean;
  slug: string;
  logoColor: string;
  marketingDescription: string;
  showBrand?: {
    id: string;
    name: string;
    logoColor: string;
    dropsCount: number;
    slug: string;
    marketingDescription: string;
    attachments?: {
      id: string;
      name: string;
      recordType: string;
      recordId: string;
      blob: {
        signedUrl: string;
      };
    }[];
  };
  choreoGuide?: {
    id: string;
    producer?: string;
    otherCredits?: string;
    commercialVideoUrl?: string;
    metaTitle?: string;
    metaDescription?: string;
    marketingDescription?: string;
    choreographer?: {
      id: string;
      name: string;
      biography: string;
      attachments?: {
        id: string;
        name: string;
        blob: {
          signedUrl: string;
        };
      }[];
    };
    attachments?: {
      id: string;
      name: string;
      blob: {
        signedUrl: string;
      };
    }[];
    songs?: {
      id: string;
      name: string;
      position: number;
      description?: string;
      attachments?: {
        id: string;
        name: string;
        blob: {
          signedUrl: string;
        };
      }[];
    }[];
  };
  scenicProjections?: {
    id: string;
    name: string;
    isDefault: boolean;
    public: boolean;
    commercialVideoUrl?: string;
    metaTitle?: string;
    metaDescription?: string;
    marketingDescription?: string;
    attachments?: {
      id: string;
      name: string;
      recordType: string;
      recordId: string;
      blob: {
        signedUrl: string;
      };
    }[];
    acts?: {
      id: string;
      name: string;
      position: number;
      scenes?: {
        id: string;
        name: string;
        position: number;
        attachments?: {
          id: string;
          name: string;
          recordType: string;
          recordId: string;
          blob: {
            signedUrl: string;
          };
        }[];
      }[];
    }[];
  }[];
  attachments?: {
    id: string;
    name: string;
    recordType: string;
    recordId: string;
    blob: {
      signedUrl: string;
    };
  }[];
}

interface UseMicroserviceShowBrand {
  id: string;
  name: string;
  logoColor: string;
  dropsCount: number;
  slug: string;
  metaTitle?: string;
  metaDescription?: string;
  marketingDescription?: string;
  attachments?: {
    id: string;
    name: string;
    recordType: string;
    recordId: string;
    blob: {
      signedUrl: string;
    };
  }[];
  shows?: {
    id: string;
    title: string;
    public: boolean;
    slug: string;
    logoColor: string;
    marketingDescription?: string;
    attachments?: {
      id: string;
      name: string;
      recordType: string;
      recordId: string;
      blob: {
        signedUrl: string;
      };
    }[];
  }[];
}

interface UseMicroserviceDrops {
  id: string;
  name: string;
  public: boolean;
  product?: {
    id: number;
    enabled: boolean;
  };
  attachments?: {
    id: string;
    name: string;
    blob: {
      signedUrl: string;
    };
  }[];
}

interface UseMicroserviceScene {
  id: string;
  name: string;
  attachments?: {
    id: string;
    name: string;
    recordType: string;
    recordId: string;
    blob: {
      signedUrl: string;
    };
  }[];
}

export const fetchShowVariant = async (
  slug: string,
  filter: Record<string, string>,
  cache?: number
) => {
  const makeRequestToMicroservice = useService("microservice", {
    cache,
  });

  return makeRequestToMicroservice(
    "GET",
    `/backstage/shows/slug/${slug}`,
    filter
  );
};

export const requestVariantSSR = async (slug: string) => {
  try {
    const request = await fetchShowVariant(slug, {
      "includes[attachments]": "true",
      "includes[showBrand]": "true",
      "includes[showBrand_attachments]": "true",
      "includes[choreoGuide]": "true",
      "includes[choreoGuide_attachments]": "true",
      "includes[choreoGuide_choreographer]": "true",
      "includes[choreoGuide_choreographer_attachments]": "true",
      "includes[choreoGuide_songs]": "true",
      "includes[choreoGuide_songs_attachments]": "true",
      "includes[scenicProjections]": "true",
    });

    return request;
  } catch (error) {
    if ((error as ServiceError)?.status) {
      return undefined;
    }

    throw error;
  }
};

export const useMicroserviceShowVariant = ({
  slug,
}: {
  slug?: string;
  overview?: boolean;
}) => {
  const [loading, _loading] = useState<boolean>(true);
  const [data, _data] = useState<UseMicroserviceShowVariant | undefined>(
    undefined
  );

  useEffect(() => {
    if (!slug) {
      return;
    }

    (async () => {
      const request = await fetchShowVariant(
        slug,
        {
          "includes[attachments]": "true",
          "includes[showBrand]": "true",
          "includes[showBrand_attachments]": "true",
          "includes[choreoGuide]": "true",
          "includes[choreoGuide_attachments]": "true",
          "includes[choreoGuide_choreographer]": "true",
          "includes[choreoGuide_choreographer_attachments]": "true",
          "includes[choreoGuide_songs]": "true",
          "includes[choreoGuide_songs_attachments]": "true",
          "includes[scenicProjections]": "true",
        },
        1_800_000
      );

      if (request) {
        _data(request as UseMicroserviceShowVariant);
        _loading(false);
      }
    })();
  }, [slug]);

  return {
    loading,
    data,
  };
};

export const fetchScenesSSR = async ({
  slug,
  id,
}: {
  slug?: string;
  id?: string;
}) => {
  const makeRequestToMicroservice = useService("microservice", {});

  const request = await makeRequestToMicroservice(
    "GET",
    `/backstage/scenic-projections/show/slug/${slug}`,
    {
      "pagination[page]": "1",
      "pagination[pageSize]": "15",
      "includes[acts]": "true",
      "includes[acts_scenes]": "true",
      "includes[acts_scenes_attachments]": "true",
    }
  );

  const scenes: UseMicroserviceScene[] = [];
  const projection = request?.["result"]?.find(
    (i: any) => String(i?.id) === id
  );
  projection?.acts
    ?.sort((a: any, b: any) => Number(a?.position) - Number(b?.position))
    ?.map((act: any) => {
      act?.scenes
        ?.sort((a: any, b: any) => Number(a?.position) - Number(b?.position))
        ?.map((scene: any) => scenes.push(scene));
    });

  return scenes;
};

export const useMicroserviceScenes = (
  {
    slug,
    id,
  }: {
    slug?: string;
    id?: string | number;
  },
  ready?: boolean
) => {
  const [loading, _loading] = useState<boolean>(true);
  const [data, _data] = useState<UseMicroserviceScene[]>([]);

  useEffect(() => {
    if (!slug || ready === false || !id) {
      return;
    }

    (async () => {
      const makeRequestToMicroservice = useService("microservice", {
        cache: 1_800_000,
      });

      const request = await makeRequestToMicroservice(
        "GET",
        `/backstage/scenic-projections/show/slug/${slug}`,
        {
          "pagination[page]": "1",
          "pagination[pageSize]": "15",
          "includes[acts]": "true",
          "includes[acts_scenes]": "true",
          "includes[acts_scenes_attachments]": "true",
        }
      );

      const scenes: UseMicroserviceScene[] = [];
      const projection = request?.["result"]?.find(
        (i: any) => String(i?.id) === id
      );
      projection?.acts
        ?.sort((a: any, b: any) => Number(a?.position) - Number(b?.position))
        ?.map((act: any) => {
          act?.scenes
            ?.sort(
              (a: any, b: any) => Number(a?.position) - Number(b?.position)
            )
            ?.map((scene: any) => scenes.push(scene));
        });

      _data(scenes);
      _loading(false);
    })();
  }, [slug, ready]);

  return {
    loading,
    data,
  };
};

export const useMicroserviceShowVariants = ({
  size = 24,
  page = 1,
}: {
  page?: number;
  size?: number;
}) => {
  const [loading, _loading] = useState<boolean>(true);
  const [data, _data] = useState<UseMicroserviceShowVariant[]>([]);

  useEffect(() => {
    (async () => {
      const makeRequestToMicroservice = useService("microservice", {
        cache: 1_800_000,
      });

      const request = await makeRequestToMicroservice(
        "GET",
        `/backstage/shows`,
        {
          "pagination[page]": page.toString(),
          "pagination[pageSize]": size.toString(),
          "includes[attachments]": "true",
        }
      );

      if (request?.["result"]) {
        _data(request?.["result"] as UseMicroserviceShowVariant[]);
        _loading(false);
      }
    })();
  }, [page, size]);

  return {
    loading,
    data,
  };
};

export const useMicroserviceShowBrand = ({
  slug,
  redirect,
}: {
  slug?: string;
  redirect?: boolean;
}) => {
  const [loading, _loading] = useState<boolean>(true);
  const [data, _data] = useState<UseMicroserviceShowBrand | undefined>(
    undefined
  );
  const router = useRouter();

  useEffect(() => {
    if (!slug) {
      return;
    }

    (async () => {
      const makeRequestToMicroservice = useService("microservice", {
        cache: 1_800_000,
        bypass: Boolean(redirect),
      });

      const request = await makeRequestToMicroservice(
        "GET",
        `/backstage/show-brands/slug/${slug}`,
        {
          "includes[attachments]": "true",
          "includes[shows]": "true",
          "includes[shows_attachments]": "true",
        }
      );

      if (Boolean(redirect) && request?.["status"] === 404) {
        /**
         * @comment
         * This means the show brand was unable to find, usually comes from old redirections.
         */

        const s = await makeRequestToMicroservice(
          "GET",
          `/backstage/shows/slug/${slug}`,
          {
            "includes[attachments]": "true",
            "includes[showBrand]": "true",
          }
        );

        if (s?.["showBrand"]) {
          const show = s as UseMicroserviceShowVariant;
          const brand = show?.showBrand;

          if (brand?.slug) {
            router.push(`/shows/${brand?.slug}/${show?.slug}`);
          }
        } else if (s?.["status"] === 404) {
          router.replace("/404");
        }
      } else if (request) {
        _data(request as UseMicroserviceShowBrand);
        _loading(false);
      }
    })();
  }, [slug]);

  return {
    loading,
    data,
  };
};

export const requestMicroserviceDropsSSR = async (slug: string) => {
  const makeRequestToMicroservice = useService("microservice");

  const request = await makeRequestToMicroservice(
    "GET",
    `/backstage/show-brands/slug/${slug}/digital-drops`,
    {
      "pagination[page]": "1",
      "pagination[pageSize]": "50",
      "includes[attachments]": "true",
      "includes[product]": "true",
    }
  );

  return request?.["result"];
};

export const useMicroserviceDrops = (
  { slug }: { slug?: string },
  ready?: boolean
) => {
  const [data, _data] = useState<UseMicroserviceDrops[] | undefined>(undefined);
  const [loading, _loading] = useState<boolean>(true);

  useEffect(() => {
    if (!slug || ready === false) {
      return;
    }

    (async () => {
      const makeRequestToMicroservice = useService("microservice", {
        cache: 1_800_000,
      });

      const request = await makeRequestToMicroservice(
        "GET",
        `/backstage/show-brands/slug/${slug}/digital-drops`,
        {
          "pagination[page]": "1",
          "pagination[pageSize]": "50",
          "includes[attachments]": "true",
          "includes[product]": "true",
        }
      );

      if (request?.["result"]) {
        _data(request?.["result"]);
        _loading(false);
      }
    })();
  }, [slug, ready]);

  return {
    data,
    loading,
  };
};

interface UseMicroserviceDigitalDrops {
  id: number | string;
  product: number | string;
  title: string;
  thumbnail: string;
  video: string;
  data: any;
}

export const useMicroserviceDigitalDrops = (
  options: {
    page?: number;
    size?: number;
  } = {
    page: 1,
    size: 24,
  }
) => {
  const [data, _data] = useState<UseMicroserviceDigitalDrops[] | undefined>(
    undefined
  );
  const [loading, _loading] = useState<boolean>(true);
  const region = useServerRegion();

  useEffect(() => {
    (async () => {
      const makeRequestToMicroservice = useService("microservice", {
        cache: 1_800_000,
      });

      const request = await makeRequestToMicroservice(
        "GET",
        `/ecommerce/products/search/${region?.id}/digital-drops`,
        {
          "pagination[page]": options?.page?.toString() || "1",
          "pagination[pageSize]": options?.size?.toString() || "24",
        }
      );

      const drops: UseMicroserviceDigitalDrops[] = [];

      if (request?.["result"]) {
        request?.["result"]?.map((item: any) => {
          drops.push({
            id: item.id,
            product: item?.product?.id,
            title: item.name,
            thumbnail: item?.attachments?.find(
              (i: any) => i.name === "preview_still"
            )?.blob?.signedUrl,
            video: item?.attachments?.find(
              (i: any) => i.name === "preview_video"
            )?.blob?.signedUrl,
            data: item,
          });
        });
      }

      _data(drops);
      _loading(false);
    })();
  }, [name]);

  return {
    data,
    loading,
  };
};
