import { useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export const CookiePolicyBanner = () => {
  const [display, _display] = useState(false);
  const mobile = useMobile();

  useEffect(() => {
    if (!localStorage.getItem("cookie-policy-accepted")) {
      _display(true);
    }
  }, []);

  const accept = () => {
    localStorage.setItem("cookie-policy-accepted", "true");
    _display(false);
  };

  if (!display) {
    return null;
  }

  return (
    <Box
      parse={`pa:20 br:10 c:?foreground p:fixed b:${
        mobile ? "112" : "24"
      } r:24 i:999`}
      width="calc(100% - 48px)"
      style={{ maxWidth: 312 }}
    >
      <Rule parse="!ls d:block mb:4">Cookies</Rule>
      <Rule parse="!lt d:block mb:10 c:?font2">
        By using this website, you are automatically aware that we use cookies
        and our cookie policy.{" "}
        <a
          rel="nofollow"
          style={{ textDecoration: "underline" }}
          href="/legal/cookie-policy"
          target="_blank"
        >
          Want to learn more?
        </a>
      </Rule>
      <DesignedButton
        label="Accept"
        press={accept}
        theme="tetriary-accent"
        size="small"
      />
    </Box>
  );
};

export default CookiePolicyBanner;
