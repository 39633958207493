import { VideoSet } from "libs/editor-ui/types";
import { Box } from "simple-effing-primitive-layout";

import { Colors } from "../../../shared";
import CuePopupTextarea from "../CuePopupTextarea";

const LineOptions = ({
  actIndex,
  sceneIndex,
  cueIndex,
  shouldPopupDisplayOnTop,
  setLineOptionsAreOpen,
  lineOptionsAreOpen,
  onCueUpdate,
  cue,
}: {
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  shouldPopupDisplayOnTop: boolean;
  setLineOptionsAreOpen: (is: boolean) => unknown;
  lineOptionsAreOpen: boolean;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  cue: VideoSet;
}) => {
  return (
    <div onMouseLeave={() => setLineOptionsAreOpen(false)}>
      <Box
        display={lineOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          <CuePopupTextarea
            label="Cue Line"
            value={cue?.cueLine || ""}
            change={(cueLine) => {
              setLineOptionsAreOpen(false);
              onCueUpdate(actIndex, sceneIndex, cueIndex, {
                ...cue,
                cueLine: cueLine as string,
              });
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default LineOptions;
