const Dark = {
  background: "#0E1115",
  foreground: "#1E242D",
  accent: "#2D3542",
  entity: "#59687B",
  action: "#E2B576",
  font1: "#FFFFFF",
  font2: "#E1E1E1",
  font3: "#AFAFAF",
  font4: "#878787",
};

const Light = {
  background: "#FFFFFF",
  foreground: "#F7F7F7",
  accent: "#DDDDDD",
  entity: "#A7A7A7",
  action: "#E2B576",
  font1: "#000000",
  font2: "#1E1E1E",
  font3: "#505050",
  font4: "#909090",
};

const General = {
  positive: "#00A04D",
  negative: "#FC2A50",
  warning: "#FF9500",
  notice: "#02070E",
  primary: "#E2B576",
  secondary: "#FADBB0",
  tetriary: "#806135",
};

export const Colors = {
  ...General,
  ...Dark,
  dark: Dark,
  light: Light,
};

export default Colors;
