import { FC } from "react";

interface BlueUnderlineProps {
  height?: string;
  top?: string;
}

export const BlueUnderline: FC<BlueUnderlineProps> = ({
  height = "9",
  top = "100%",
}) => {
  return (
    <svg
      width="100%"
      height={height}
      viewBox="0 0 216 9"
      fill="none"
      style={{
        position: "absolute",
        width: "100%",
        top,
        zIndex: 1,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.915 3.26095C215.034 2.05223 207.461 1.54329 184.7 1.11918C163.463 0.716267 138.739 -0.450056 95.5433 0.186117C62.5251 0.67385 21.4126 2.62478 21.4126 3.70628C21.4126 4.49089 23.0154 4.5121 38.3224 3.81231C52.4674 3.17613 59.7201 3.02769 68.5758 2.85805C33.0732 4.5121 25.4998 5.5936 6.58646 6.63268C-5.27445 7.29006 1.65778 9.17737 5.98541 8.98652C6.02548 8.98652 42.7912 7.29181 66.4119 6.73871C112.187 5.66685 148.316 5.69941 183.699 7.1202C194.878 7.56552 196.121 7.56552 196.121 7.01417C196.121 6.0387 193.075 5.29671 188.307 5.14827C180.854 4.89381 152.163 4.17259 139.18 3.96054C138.178 3.93933 138.659 3.53663 139.541 3.49422C144.83 3.21854 186.824 3.43039 203.333 3.72727C215.475 3.93933 216.356 3.91833 215.915 3.26095Z"
        fill="url(#paint0_linear_1758_112139)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1758_112139"
          x1="-1.60932e-06"
          y1="5.12653"
          x2="230.795"
          y2="5.12649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#46D3F2" />
          <stop offset="1" stopColor="#1875FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BlueUnderline;
