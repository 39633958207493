import { useEffect, useState } from "react";

export const useProduction = () => {
  const [production, _production] = useState(false);

  useEffect(() => {
    if (
      window.location.host.split(".")[0] === "www" ||
      window.location.host.split(".")[0] === "broadwaymedia"
    ) {
      _production(true);
    }
  }, []);

  return production;
};
