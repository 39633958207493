import { DesignedIcon } from "@with-nx/simple-ui/atoms";
import { FC } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ModalCloseButtonProps {
  onClose(): void;
  hasTitle?: boolean;
}

export const ModalCloseButton: FC<ModalCloseButtonProps> = ({
  onClose,
  hasTitle,
}) => (
  <Box
    parse={`p:absolute t:var(--modal-space-2) r:var(--modal-space-2) ${
      hasTitle ? "w:var(--modal-space-8) h:var(--modal-space-8)" : ""
    }`}
    style={{ zIndex: "var(--modal-z-index)" }}
    press={(e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onClose();
    }}
  >
    <Box
      element="button"
      parse="w:100% h:100% d:flex a:center j:center"
      style={{
        cursor: "pointer",
      }}
    >
      <Box
        parse="d:flex a:center j:center w:var(--modal-space-3) h:var(--modal-space-3)"
        aria-label="Close"
      >
        <DesignedIcon name="close" size={12} color="var(--font1)" />
      </Box>
    </Box>
  </Box>
);
