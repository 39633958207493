import { useAuth } from "@with-nx/auth";
import { useServerRegion, UseServerRegionMetaCode } from "@with-nx/region";
import moment from "moment";
import { useEffect, useState } from "react";

import useService from "./useService";

export type SubscriptionTiers = "basic" | "standard" | "advanced" | "free";
export type SubscriptionIntervals = "monthly" | "yearly" | "otp";
export type SubscriptionPlanIntervals = "month" | "year" | "otp";

export enum SubscriptionTierFriendly {
  basic = "Basic",
  standard = "Standard",
  advanced = "Advanced",
  free = "Free",
}

export enum SubscriptionIntervalFriendly {
  monthly = "Monthly",
  yearly = "Annual",
}

export enum SubscriptionPlanIntervalFriendly {
  month = "Monthly",
  year = "Annual",
  otp = "OTP",
}

export type Subscription = {
  tier: SubscriptionTiers;
  start_date: string;
  current_period_start: string;
  current_period_end: string;
  auto_renew: boolean;
  plan_interval: SubscriptionPlanIntervals;
  storage_usage_in_bytes: number;
  storage_limit_in_bytes: number;
};

export type SubscriptionsPricing = {
  name?: string;
  price: number;
  currency: string;
};

export async function updateSubscription(token: string) {
  const makeRequestToBackstage = useService("accounts");

  const response = await makeRequestToBackstage(
    "POST",
    "/api/v2/stageplayer/billing_portal_sessions",
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  );

  return response["url"];
}

export async function createSubscription(
  token: string,
  tier: SubscriptionTiers,
  interval: SubscriptionIntervals,
  region?: "us" | "au" | "uk"
) {
  const makeRequestToBackstage = useService("accounts");

  const response = await makeRequestToBackstage(
    "POST",
    "/api/v2/stageplayer/checkout_sessions",
    {
      tier: tier,
      interval: interval,
      success_url: `${window.location.origin}/subscriptions/${tier}/${interval}/success`,
      cancel_url: `${window.location.origin}/subscriptions/${tier}/${interval}/cancel`,
      region,
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  );

  return response["url"];
}

export async function getSubscription(token: string) {
  const makeRequestToBackstage = useService("accounts");

  const response = await makeRequestToBackstage(
    "GET",
    "/api/v2/account",
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );

  const subscription = response["data"]?.account
    ?.subscription as Subscription | null;

  return subscription;
}

export async function getSubscriptionPricing(
  region: "us" | "uk" | "au",
  tier: "standard" | "basic" | "advanced" | "free",
  interval: "monthly" | "yearly" | "otp"
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "GET",
    `/api/v2/stageplayer/prices/${region || "us"}/${tier}/${interval}`
  );

  const pricing = response as SubscriptionsPricing | null;

  return pricing &&
    !(
      pricing as unknown as {
        errors: unknown;
      }
    )?.["errors"]
    ? { ...pricing, price: pricing.price / 100 }
    : null;
}

export function isSubscriptionActive(subscription: Subscription | null) {
  if (subscription?.current_period_end) {
    const end = moment(subscription.current_period_end);
    if (moment().isAfter(end)) {
      return false;
    }
    return true;
  }

  return false;
}

export const useSubscription = (options?: { tier: SubscriptionTiers }) => {
  const auth = useAuth();

  const [data, _data] = useState<Subscription | null>(null);
  const [pricing, _pricing] = useState<{
    monthly: SubscriptionsPricing | null;
    yearly: SubscriptionsPricing | null;
    oneTimePurchase: SubscriptionsPricing | null;
  }>({
    monthly: null,
    yearly: null,
    oneTimePurchase: null,
  });
  const [error, _error] = useState(false);
  const [loading, _loading] = useState(true);

  const region = useServerRegion();

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      _loading(false);
      return;
    }

    const token = auth?.authState?.session?.token as string;

    const fetch = async () => {
      try {
        const subscription = await getSubscription(token);
        _data(subscription);
      } catch (error) {
        _error(true);
        _loading(false);
      } finally {
        _loading(false);
      }
    };

    fetch();
  }, [auth.authState.status]);

  useEffect(() => {
    (async () => {
      const regionCode = region?.meta?.code as UseServerRegionMetaCode;
      const tier = options?.tier || "standard";

      const [monthly, yearly, oneTimePurchase] = await Promise.all([
        getSubscriptionPricing(regionCode, tier, "monthly"),
        getSubscriptionPricing(regionCode, tier, "yearly"),
        getSubscriptionPricing(regionCode, tier, "otp"),
      ]);

      _pricing({
        monthly,
        yearly,
        oneTimePurchase,
      });
    })();
  }, []);

  const helpers = {
    create: async (
      tier: SubscriptionTiers,
      interval: SubscriptionIntervals,
      stay?: boolean
    ) => {
      const token = auth?.authState?.session?.token as string;
      const url = await createSubscription(
        token,
        tier,
        interval,
        region?.meta?.code as UseServerRegionMetaCode
      );

      if (stay) {
        window.location.href = url;
      } else {
        window.open(url, "_blank");
      }
    },
    update: async () => {
      const token = auth?.authState?.session?.token as string;
      const url = await updateSubscription(token);

      window.open(url, "_blank");
    },
  };

  const active = isSubscriptionActive(data);

  return {
    data,
    error,
    loading,
    helpers,
    active,
    pricing,
  };
};
