import { StageGearHelpers } from "./StageGearHelpers";
import { StageGearMethods } from "./StageGearMethods";

export class StageGearController {
  static get helpers() {
    return StageGearHelpers;
  }

  static get methods() {
    return StageGearMethods;
  }
}
