import { useMobile } from "@with-nx/hooks-n-helpers";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { Box } from "simple-effing-primitive-layout";

interface ProductLandingHeroProps {
  image?: string;
  imagePosition?: string;
  minHeight?: number;
  title?: string | JSX.Element | JSX.Element[];
  description?: string | JSX.Element | JSX.Element[];
  primaryButtonLabel?: string;
  primaryButton?: JSX.Element;
  primaryButtonHref?: string;
  primaryButtonPress?: () => void;
  secondaryButton?: JSX.Element;
  secondaryButtonLabel?: string;
  secondaryButtonHref?: string;
  secondaryButtonPress?: () => void;
  children?: JSX.Element | JSX.Element[];
}

export const ProductLandingHero = (props: ProductLandingHeroProps) => {
  const {
    image = "/assets/hero/blue-gradient-hero.png",
    imagePosition = "center bottom",
    minHeight = 600,
    title,
    description,
    primaryButtonLabel,
    primaryButtonPress,
    primaryButtonHref,
    secondaryButtonLabel,
    secondaryButtonPress,
    secondaryButtonHref,
    children,
    primaryButton,
    secondaryButton,
  } = props;

  const mobile = useMobile();

  return (
    <Box
      style={{
        backgroundImage: `url(${image})`,
        minHeight: mobile ? undefined : minHeight,
        backgroundSize: "contain",
        backgroundPosition: imagePosition,
        backgroundRepeat: "no-repeat",
        marginTop: mobile ? 48 : 96,
        marginBottom: mobile ? 48 : undefined,
      }}
    >
      <Box css="ta:center container text-align-center">
        <Box css="p:relative i:3">
          <Row align="middle" justify="center">
            <Col xl={12} lg={12} md={16} sm={20} xs={24}>
              <Rule parse="!_t d:block mb:20 ta:center">{title}</Rule>
              <Rule parse="!_p d:block mb:20 ta:center c:?font3">
                {description}
              </Rule>
              <Box css="d:flex a:flex-start j:center a:baseline">
                {primaryButton ? (
                  <Box parse="mr:12 mb:12">{primaryButton}</Box>
                ) : primaryButtonLabel ? (
                  <Box parse="mr:12 mb:12">
                    <DesignedButton
                      label={primaryButtonLabel}
                      theme="primary"
                      press={primaryButtonPress}
                      href={primaryButtonHref}
                      properties={{
                        id: `btn-${primaryButtonLabel}`,
                        cypress: `btn-${primaryButtonLabel}`,
                      }}
                    />
                  </Box>
                ) : undefined}
                {secondaryButton ? (
                  <Box parse="mr:12 mb:12">{secondaryButton}</Box>
                ) : secondaryButtonLabel ? (
                  <Box parse="mr:12 mb:12">
                    <DesignedButton
                      label={secondaryButtonLabel}
                      theme="tetriary"
                      press={secondaryButtonPress}
                      href={secondaryButtonHref}
                      properties={{
                        id: `btn-${secondaryButtonLabel}`,
                        cypress: `btn-${secondaryButtonLabel}`,
                      }}
                    />
                  </Box>
                ) : undefined}
              </Box>
            </Col>
          </Row>
        </Box>
        {children}
      </Box>
    </Box>
  );
};
