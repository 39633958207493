import { DesignedIcon, DesignedInput, Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";
import { useEffect, useRef, useState } from "react";

import moment from "moment-timezone";
import { useOuterClick } from "@with-nx/simple-ui/molecules";

export const TimeZoneSelector = ({
  timeZone,
  _timeZone,
}: {
  timeZone: string;
  _timeZone: any;
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  useOuterClick(wrapper, () => _open(false));

  const [search, _search] = useState("");
  const timeZones = moment.tz
    .names()
    .filter((timeZone) =>
      timeZone?.toLowerCase().includes(search?.toLowerCase())
    );
  const [open, _open] = useState(false);

  const getCurrentTime = (timeZone: string) =>
    `(${moment().tz(timeZone).format("LT")})`;

  const [time, _time] = useState(getCurrentTime(timeZone));

  useEffect(() => {
    const interval = setInterval(() => {
      _time(getCurrentTime(timeZone));
    }, 60000);

    _time(getCurrentTime(timeZone));
    return () => clearInterval(interval);
  }, [timeZone]);

  return (
    <Box parse="d:flex j:center p:relative w:100% mt:20 c:var(--white) i:var(--modal-z-index)">
      {open && (
        <Box
          tag="ul"
          parse="p:absolute w:100% c:#FFF b:100% mb:10"
          border="1px solid var(--hoover)"
          style={{
            borderRadius: "5px",
          }}
          reference={wrapper}
        >
          <Box parse="pa:10">
            <DesignedInput
              placeholder="Search..."
              theme="light"
              change={(value) => {
                _search(value);
              }}
              value={search}
              native={{
                autoFocus: open,
              }}
            />
          </Box>

          <Box parse="h:200 oa:auto">
            {timeZones?.length === 0 && !!search && (
              <Box tag="li" parse="d:flex w:100% j:center c:#FFF z:7 pa:10 ">
                No results found for &nbsp; <strong>"{search}"</strong>
              </Box>
            )}

            {timeZones.map((timeZone, index) => {
              return (
                <Box
                  key={`show-suggestion-${index}`}
                  tag="li"
                  parse="d:flex w:100% j:space-between c:#FFF z:7"
                  press={() => _timeZone(timeZone)}
                >
                  <div
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 30,
                      paddingRight: 30,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      background: "#FFF",
                    }}
                    className="autocomplete-li"
                  >
                    <span>{timeZone?.replace(/_/g, " ")}</span>
                    <span>{getCurrentTime(timeZone)}</span>
                  </div>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}

      <Rule parse="!_bl d:inline-block c:var(--black-base) mr:20" weight={700}>
        Time Zone:
      </Rule>

      <Box
        parse="d:flex a:center"
        style={{
          cursor: "pointer",
        }}
        press={() => {
          if (open === false) {
            _open(true);
          }
        }}
      >
        <DesignedIcon name="earth" color="var(--black-base)" size={24} />

        <Box left={8} right={8}>
          <Rule parse="!_bl c:var(--black-base) d:block">
            {timeZone} {time}{" "}
          </Rule>
        </Box>

        <DesignedIcon
          name={`${open ? "up" : "down"}/bold`}
          color="var(--black-base)"
          size={16}
        />
      </Box>
    </Box>
  );
};
