import { Formatter, useCMSPartners, useMobile } from "@with-nx/hooks-n-helpers";
import { Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

export const SolutionsPartnerShowcase = ({ name }: { name: string }) => {
  const mobile = useMobile();

  const partners = useCMSPartners({});
  const partner = partners.partners.find((i) => i.name === name);

  if (!partner) {
    return null;
  }

  return (
    <Box parse="pt:48 pb:48" css="d:flex a:center">
      <div className="container">
        <Box
          parse={
            mobile
              ? "pa:15 br:10 d:flex fd:column"
              : "pa:24 br:10 d:flex a:center j:space-between"
          }
          style={{
            backgroundImage: `url(/assets/libs/simple-ui/solutions-components/assets/solution-partner-background.webp)`,
            backgroundSize: "cover",
          }}
        >
          <img
            loading="lazy"
            width={mobile ? "100%" : "325"}
            height="auto"
            alt={partner.name}
            src={Formatter.image(partner.image)}
          />
          <Box style={{ width: "100%", maxWidth: 452 }}>
            <Rule parse="!ht d:block mb:12">
              Proud Sponsors of {partner.name}
            </Rule>
            <Rule parse="!ls d:block mb:12">{partner.description}</Rule>
            <Rule
              tag="a"
              to={`/partners/${partner.slug}`}
              parse="!ls c:#FF9900"
            >{`Explore the Partnership >`}</Rule>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
