import {
  Currency,
  UseProjectorAccessory,
  UseProjectorProjector,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { DesignedCard, Rule } from "@with-nx/simple-ui/atoms";
import {
  FiGitPullRequest,
  FiShoppingCart,
  FiTool,
  FiVideo,
} from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

interface ProjectorBundleProps {
  projector?: UseProjectorProjector;
  mount?: UseProjectorAccessory;
  cord?: UseProjectorAccessory;
  press?: ({
    projector,
    cord,
    mount,
  }: {
    projector?: string;
    cord?: string;
    mount?: string;
  }) => unknown;
  ready?: boolean;
}

export const ProjectorBundle = ({
  projector,
  mount,
  cord,
  press,
  ready,
}: ProjectorBundleProps) => {
  const region = useServerRegion();
  const price =
    (projector?.meta?.discounted_price || 0) +
    (mount?.meta?.discounted_price || 0) +
    (cord?.meta?.discounted_price || 0);

  return (
    <Box parse="br:10 oa:hidden c:#2D3542">
      <DesignedCard
        properties={{ bottom: 0 }}
        size="16x9"
        inside={{ image: projector?.image }}
      />
      <Box parse="pl:16 pr:16 pt:0 pb:12">
        <Rule parse="!lm d:block mb:8">{projector?.name}</Rule>
        <Box css="d:flex">
          <Box>
            <Box parse="d:flex a:center h:16 mb:13">
              <FiVideo size={16} color="var(--font4)" title="camera icon" />
              <Rule parse="!lt d:inline-block ml:5 c:?font4">Projector:</Rule>
            </Box>
            <Box parse="d:flex a:center h:16 mb:13">
              <FiGitPullRequest
                size={16}
                color="var(--font4)"
                title="cords icon"
              />
              <Rule parse="!lt d:inline-block ml:5 c:?font4">Cords: </Rule>
            </Box>
            <Box parse="d:flex a:center h:16 mb:0">
              <FiTool size={16} color="var(--font4)" title="tool icon" />
              <Rule parse="!lt d:inline-block ml:5 c:?font4">Mount:</Rule>
            </Box>
          </Box>
          <Box parse="f:1 ml:16">
            <Box parse="d:flex a:center h:16 mb:13">
              <Rule parse="!ls c:?font1">{projector?.name}</Rule>
            </Box>
            <Box parse="d:flex a:center h:16 mb:13">
              <Rule parse="!ls c:?font1">{cord?.name}</Rule>
            </Box>
            <Box parse="d:flex a:center h:16 mb:0">
              <Rule parse="!ls c:?font1">{mount?.name}</Rule>
            </Box>
          </Box>
        </Box>
        <Box parse="c:#59687B h:1 w:100% o:0.5 mt:12 mb:12" />
        <Box
          parse="d:flex a:center j:space-between"
          press={() => {
            press?.({
              projector: projector?.slug,
              cord: cord?.slug,
              mount: mount?.slug,
            });
          }}
        >
          <Rule parse="!lm">
            {Currency.format(price, region?.currency?.code || "USD")}
          </Rule>
          <Box css="d:inline-flex a:center">
            <FiShoppingCart
              size={18}
              color={ready ? "var(--primary)" : "var(--font4)"}
              title="shopping cart icon"
            />
            <Rule
              parse="!ls d:inline-block ml:5"
              color={ready ? "var(--primary)" : "var(--font4)"}
            >
              Order Now
            </Rule>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectorBundle;
