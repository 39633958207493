import React from "react";

interface DebounceContainerProps {
  value?: string;
  change?: (value?: string) => unknown;
  delay: number;
  render: (options: {
    value?: string;
    set: (value?: string) => unknown;
  }) => JSX.Element | JSX.Element[] | null;
}

interface DebounceContainerState {
  value?: string;
  delay?: number;
  timeout?: NodeJS.Timeout;
}

export class DebounceContainer extends React.Component<
  DebounceContainerProps,
  DebounceContainerState
> {
  constructor(props: DebounceContainerProps) {
    super(props);
    this.state = {
      value: props.value,
      delay: props.delay,
    };
  }

  override componentDidUpdate(prevProps: DebounceContainerProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }

    if (prevProps.delay !== this.props.delay) {
      this.setState({ delay: this.props.delay });
    }
  }

  override render(): React.ReactNode {
    return this.props.render({
      value: this.state.value,
      set: (value?: string) => {
        if (this.state.timeout) {
          clearTimeout(this.state.timeout);
        }
        this.setState({
          value,
          timeout: setTimeout(() => {
            if (this.state.value?.trim() !== this.props.value?.trim()) {
              this.props.change?.(value);
            }
          }, this.state.delay),
        });
      },
    });
  }
}

export default DebounceContainer;
