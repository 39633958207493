import useService from "./useService";

export class AnalyticsHelper {
  static async collect(showId?: string) {
    if (!showId) return;

    try {
      const makeRequestToMicroservice = useService("microservice");
      await makeRequestToMicroservice("POST", `/analytics/resources/collect`, {
        showId,
      });
    } catch (error) {
      console.log("Analytics collect request error:", error);
    }
  }
}
