import { useState, useEffect } from "react";

export const useMediaQuery = (size: number) => {
  const createMediaQuery = (query: string) => {
    if (typeof window === "undefined") {
      return undefined;
    }

    const mediaQueryList = window.matchMedia(query);
    return {
      mediaQueryList,
      matches: mediaQueryList.matches,
    };
  };

  const [sizes, _sizes] = useState({
    smaller: Boolean(createMediaQuery(`(max-width: ${size}px)`)?.matches),
  });

  useEffect(() => {
    const smaller = createMediaQuery(`(max-width: ${size}px)`);

    const handle = () => {
      _sizes({
        smaller: Boolean(smaller?.mediaQueryList.matches),
      });
    };

    smaller?.mediaQueryList.addEventListener("change", handle);

    return () => {
      smaller?.mediaQueryList.removeEventListener("change", handle);
    };
  }, []);

  return sizes;
};

export default useMediaQuery;
