import { createRef, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export const SafeAreaAbsolutePosition = ({
  children,
  position,
}: {
  children: JSX.Element;
  position:
    | "top-right"
    | "top-left"
    | "bottom-right"
    | "bottom-left"
    | "bottom-center";
}) => {
  const p = (
    position:
      | "top-right"
      | "top-left"
      | "bottom-right"
      | "bottom-left"
      | "bottom-center"
  ) => {
    const properties = {
      top: "unset",
      right: "unset",
      bottom: "unset",
      left: "unset",
      position: "absolute",
      mode: "position",
      index: 4,
      transform: "none",
    };

    switch (position) {
      case "top-right":
        properties["bottom"] = "calc(100% + 4px)";
        properties["left"] = "calc(100% + 4px)";
        break;

      case "top-left":
        properties["bottom"] = "calc(100% + 4px)";
        properties["right"] = "calc(100% + 4px)";
        break;

      case "bottom-right":
        properties["top"] = "calc(100% + 4px)";
        properties["left"] = "calc(100% + 4px)";
        break;

      case "bottom-left":
        properties["top"] = "calc(100% + 4px)";
        properties["right"] = "calc(100% + 4px)";
        break;

      case "bottom-center":
        properties["top"] = "calc(100% + 4px)";
        properties["left"] = "50%";
        properties["transform"] = "translateX(-50%)";
        break;
    }

    return properties;
  };

  const [style, _style] = useState(p(position));
  const [ready, _ready] = useState(false);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const bounds = ref.current?.getBoundingClientRect();
    let doesClipTroughRight = false;
    let doesClipTroughBottom = false;
    let doesClipTroughTop = false;
    let doesClipTroughLeft = false;

    if (bounds) {
      const { width, height } = bounds;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const { top, right, bottom, left } = bounds;

      doesClipTroughRight = right + width > windowWidth;
      doesClipTroughBottom = bottom + height > windowHeight;
      doesClipTroughTop = top < 0;
      doesClipTroughLeft = left < 0;
    }

    const safe = {
      top: "unset",
      right: "unset",
      bottom: "unset",
      left: "unset",
      position: "absolute",
      mode: "position",
      index: 4,
      transform: "none",
    };

    if (doesClipTroughRight) {
      safe["right"] = "calc(100% + 4px)";
    }

    if (doesClipTroughBottom) {
      safe["bottom"] = "calc(100% + 4px)";
    }

    if (doesClipTroughTop) {
      safe["top"] = "calc(100% + 4px)";
    }

    if (doesClipTroughLeft) {
      safe["left"] = "calc(100% + 4px)";
    }

    if (
      doesClipTroughBottom ||
      doesClipTroughLeft ||
      doesClipTroughRight ||
      doesClipTroughTop
    ) {
      _style(safe);
    }

    _ready(true);
  }, []);

  return (
    <Box style={style} opacity={ready ? 1 : 0}>
      <div ref={ref}>{children}</div>
    </Box>
  );
};

export default SafeAreaAbsolutePosition;
