import { Col } from "antd";
import { Box } from "simple-effing-primitive-layout";

import {
  Autocomplete,
  DesignedInput,
  DesignedRadio,
  DesignedSelect,
  Rule,
} from "@with-nx/simple-ui/atoms";
import ReusableCheckboxSidebar from "../reusable-checkbox-sidebar/reusable-checkbox-sidebar";
import { ShowDataStatic } from "@with-nx/static";
import { countryRequiresState } from "./helpers";
import { FC } from "react";
import { GetStartedDetails } from "./contact-form";
import { useBusiness } from "@with-nx/hooks-n-helpers";
import { Regions } from "@with-nx/region";

interface FormFieldsProps {
  mobile: boolean;
  span?: number;
  field: string;
  values: GetStartedDetails;
  handleChange: (field: string, value: string | string[]) => void;
  handleFocus: (field: string) => void;
  errors: Record<string, boolean | string>;
  debouncedHandleBlur: (field: string) => void;
  clearFieldError: (field: string) => void;
  regions?: Regions[];
  states?: [string, string][];
}

export const FormFields: FC<FormFieldsProps> = ({
  mobile,
  field,
  values,
  span,
  handleChange,
  handleFocus,
  debouncedHandleBlur,
  clearFieldError,
  errors,
  regions,
  states,
}) => {
  const size = mobile ? "medium" : "xl";

  switch (field) {
    case "customer_type":
      return (
        <>
          <Rule
            display="block"
            rule="ht"
            color="#D2D2D2"
            weight="700"
            bottom={24}
          >
            Have we worked together before?
          </Rule>
          <Box
            parse={`d:flex a:center fw:wrap ${
              mobile ? "fd:column a:flex-start" : ""
            }`}
          >
            <DesignedRadio
              size="large"
              label="Returning Customer"
              active={values.customer_type === "Returning Customer"}
              press={() => handleChange("customer_type", "Returning Customer")}
              properties={{ right: 24, bottom: mobile ? 20 : 0 }}
            />
            <DesignedRadio
              size="large"
              label="New Customer"
              active={values.customer_type === "New Customer"}
              press={() => handleChange("customer_type", "New Customer")}
              properties={{ right: 24 }}
            />
          </Box>
        </>
      );
    case "name":
      return (
        <Col xs={24}>
          <DesignedInput
            label="What is your name?"
            hint={!!errors["name"] ? "" : "Please enter your full name"}
            value={values.name}
            change={(name) => handleChange("name", name)}
            focus={() => handleFocus("name")}
            blur={() => debouncedHandleBlur("name")}
            error={errors["name"]}
            placeholder="Your name"
            convert="capitalize"
            size={size}
            theme="light"
            native={{
              cypress: "name-field",
            }}
          />
        </Col>
      );
    case "email":
      return (
        <Col xs={24} sm={24} md={24} xl={span || 12}>
          <DesignedInput
            label="What is your e-mail?"
            value={values.email}
            change={(email) => handleChange("email", email)}
            blur={() => debouncedHandleBlur("email")}
            focus={() => handleFocus("email")}
            error={errors["email"]}
            placeholder="Your e-mail"
            convert="lowercase"
            size={size}
            theme="light"
            native={{
              cypress: "email-field",
            }}
          />
        </Col>
      );
    case "phone_number":
      return (
        <Col xs={24} sm={24} md={24} xl={span || 12}>
          <DesignedInput
            label="What is your phone number?"
            value={values.phone_number}
            change={(phone_number) =>
              handleChange("phone_number", phone_number)
            }
            blur={() => debouncedHandleBlur("phone_number")}
            focus={() => handleFocus("phone_number")}
            error={errors["phone_number"]}
            placeholder="Your phone number"
            size={size}
            theme="light"
            native={{
              cypress: "phone_number-field",
            }}
          />
        </Col>
      );
    case "organization":
      return (
        <Col xs={24} sm={24} md={24} xl={span || 12}>
          <DesignedInput
            label="What is your organization name?"
            value={values.organization}
            change={(organization) =>
              handleChange("organization", organization)
            }
            blur={() => debouncedHandleBlur("organization")}
            focus={() => handleFocus("organization")}
            error={errors["organization"]}
            convert="capitalize"
            placeholder="Your organization name"
            size={size}
            theme="light"
            native={{
              cypress: "organization-field",
            }}
          />
        </Col>
      );
    case "organization_type":
      const business = useBusiness();

      return (
        <Col xs={24} sm={24} md={24} xl={span || 12}>
          <DesignedSelect
            icon="down"
            label="Organization Type"
            value={values.organization_type}
            change={(organization_type) =>
              handleChange("organization_type", String(organization_type))
            }
            blur={() => debouncedHandleBlur("organization_type")}
            focus={() => handleFocus("organization_type")}
            error={errors["organization_type"]}
            empty
            options={business.types.map((i) => [
              i.businessType.toString(),
              i.name,
            ])}
            size={size}
            theme="light"
            native={{
              cypress: "organization_type-field",
            }}
          />
        </Col>
      );
    case "region":
    case "state":
      return (
        <>
          {field === "region" && (
            <Col xs={24} sm={24} md={24} xl={span || 12}>
              <DesignedSelect
                icon="down"
                label="Where are you located?"
                value={values.region}
                change={(region) => handleChange("region", region)}
                blur={() => debouncedHandleBlur("region")}
                focus={() => handleFocus("region")}
                error={errors["region"]}
                options={regions?.map((i) => [i, i]) || []}
                size={size}
                theme="light"
                empty
                hint={errors["region"] ? "" : "Required"}
                native={{
                  cypress: "region-field",
                }}
              />
            </Col>
          )}

          <>
            {countryRequiresState(values?.region || "none") ? (
              <Col xs={24} sm={24} md={24} xl={span || 12}>
                <DesignedSelect
                  icon="down"
                  label={`Which ${
                    values.region === "🇺🇸 United States" ? "state" : "province"
                  } are you located?`}
                  value={values.state}
                  change={(state) => handleChange("state", state)}
                  blur={() => debouncedHandleBlur("state")}
                  focus={() => handleFocus("state")}
                  error={errors["state"]}
                  options={states?.map(([i, ii]) => [i, ii]) || []}
                  empty
                  size={size}
                  theme="light"
                  native={{
                    cypress: "state-field",
                  }}
                />
              </Col>
            ) : undefined}
          </>
        </>
      );
    case "show":
      return (
        <Col xs={span || 24}>
          <Autocomplete
            label="Show"
            hint={errors["show"] ? "" : "Required"}
            value={values?.show}
            blur={() => debouncedHandleBlur("show")}
            focus={() => handleFocus("show")}
            error={errors["show"]}
            placeholder="Show Name"
            convert="capitalize"
            size={size}
            optionsOpen={!!open}
            type="autocomplete"
            theme="light"
            native={{
              cypress: "show-field",
            }}
            mobile={mobile}
            onInputChange={(show) => {
              handleChange("show", show || "");
            }}
            onSearch={(value?: string) => {
              const shows = ShowDataStatic.search(value);
              return shows;
            }}
          />
        </Col>
      );
    case "opening_night":
      return (
        <Col xs={24} sm={24} md={24} xl={span || 12}>
          <DesignedInput
            label="Opening Date"
            type="date"
            value={values.opening_night}
            change={(opening_night) => {
              clearFieldError("opening_night");
              handleChange("opening_night", opening_night);
            }}
            error={errors["opening_night"]}
            focus={() => handleFocus("opening_night")}
            blur={() => debouncedHandleBlur("opening_night")}
            convert="lowercase"
            size={size}
            theme="light"
            hint={errors["opening_night"] ? "" : "Required"}
            native={{
              cypress: "opening_date-field",
            }}
          />
        </Col>
      );
    case "closing_night":
      return (
        <Col xs={24} sm={24} md={24} xl={span || 12}>
          <DesignedInput
            label="Closing Date"
            type="date"
            value={values.closing_night}
            change={(closing_night) => {
              clearFieldError("closing_night");
              handleChange("closing_night", closing_night);
            }}
            blur={() => debouncedHandleBlur("closing_night")}
            error={errors["closing_night"]}
            focus={() => handleFocus("closing_night")}
            size={size}
            theme="light"
            native={{
              cypress: "closing_night-field",
            }}
          />
        </Col>
      );
    case "message":
      return (
        <Col xs={span || 24}>
          <DesignedInput
            label="How can we help?"
            placeholder="I just came from the StageGear Landing page..."
            value={values.message}
            change={(message) => handleChange("message", message)}
            textarea
            size={size}
            theme="light"
            native={{
              cypress: "message-field",
            }}
          />
        </Col>
      );
    case "products":
      return (
        <Col xs={span || 24}>
          <Rule display="block" rule="pm" color="var(--black-base)" bottom={12}>
            What products are you interested in?
          </Rule>
          <ReusableCheckboxSidebar
            minimal
            theme="light"
            size="xl"
            groups={{
              items: {
                expanded: true,
                label: "",
                items: [
                  {
                    label: "Digital Scenery",
                    value: "Digital Scenery",
                    active: values.products?.includes("Digital Scenery"),
                  },
                  {
                    label: "Projector Hardware",
                    value: "Projector Hardware",
                    active: values.products?.includes("Projector Hardware"),
                  },
                  {
                    label: "Wireless Microphone Systems",
                    value: "Wireless Microphone Systems",
                    active: values.products?.includes(
                      "Wireless Microphone Systems"
                    ),
                  },
                  {
                    label: "Choreography Guides",
                    value: "Choreography Guides",
                    active: values.products?.includes("Choreography Guides"),
                  },
                  {
                    label: "Other Resources",
                    value: "Other Resources",
                    active: values.products?.includes("Other Resources"),
                  },
                ],
              },
            }}
            change={(groups) => {
              const is = (groups["items"].items.map((item) => item.value) ||
                []) as string[];

              handleChange("products", is);
            }}
          />
        </Col>
      );

    default:
      return null;
  }
};
