/* eslint-disable @typescript-eslint/no-explicit-any */

import { useProducts } from "@with-nx/hooks-n-helpers";
import {
  DebounceContainer,
  DesignedCard,
  DesignedInput,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export const DropSearchInput = ({
  size = "large",
  properties = { border: "unset", color: "transparent" },
  reference,
  blur,
  press,
}: {
  size?: "large" | "medium" | "small";
  properties?: {
    [key: string]: unknown;
  };
  reference?: React.RefObject<HTMLInputElement>;
  blur?: () => void;
  focus?: () => void;
  press?: (product: unknown) => void;
}) => {
  const [search, _search] = useState("");
  const { products } = useProducts({
    currentPage: 1,
    searchText: search.trim(),
    pageSize: 4,
    productCategory: "digital-drops",
    searchRequired: true,
  });

  const [focused, _focused] = useState(false);

  return (
    <>
      <Box css="p:relative f:1 oa:visible p:relative i:99 z:99">
        <DebounceContainer
          value={search}
          change={(search) => _search(search || "")}
          delay={1000}
          render={({ value, set }) => (
            <DesignedInput
              bottom={0}
              size={size}
              icon="search/bold"
              placeholder="Search for your drop"
              container={{ flex: 1 }}
              properties={properties}
              value={value}
              change={(value) => {
                set(value);
              }}
              blur={() => {
                if (blur) {
                  blur();
                }
                _focused(false);
              }}
              focus={() => {
                if (focus) {
                  focus();
                }
                _focused(true);
              }}
              color={focused ? "var(--primary)" : undefined}
              reference={reference}
              native={{
                cypress: "global-search-input",
                name: "search",
              }}
            />
          )}
        />

        {search.length && products?.result?.length ? (
          <Box
            parse="p:absolute b:unset r:unset l:0 br:10 c:#2D3542 pa:16 w:100% h:auto i:999 z:999"
            mode="position"
            top="calc(100% + 12px)"
          >
            <Rule parse="!lt mb:4 c:?font4 d:block">Suggested</Rule>
            {products?.result?.map((product) => {
              const _image = (product as unknown as any)?.attachments?.find(
                (i: any) => i?.name === "preview_still"
              )?.blob?.signedUrl;

              return (
                <>
                  <Box
                    parse="d:flex a:center h:54 mb:2"
                    press={() => {
                      press?.(product);
                      _search("");
                    }}
                    key={product.id}
                  >
                    <Box
                      parse="br:8 h:32 w:57 c:#1E242D mr:12"
                      style={{ minWidth: 57 }}
                    >
                      <DesignedCard size="16x9" image={_image} mode="cover" />
                    </Box>
                    <Box>
                      <Rule parse="!ls d:block mb:3">
                        {(product as unknown as any)?.name}
                      </Rule>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
        ) : undefined}
      </Box>
    </>
  );
};

export default DropSearchInput;
