import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";
import dynamic from "next/dynamic";
import { DropdownMenuItemProps } from "./dropdown-menu-item";

export interface DropdownOption {
  hidden?: boolean;
  label?: string;
  url?: string;
  icon?: unknown;
  options?: {
    label: string;
    url?: string;
    icon?: unknown;
  }[];
}

const DropdownMenuItem = dynamic<DropdownMenuItemProps>(() =>
  import("./dropdown-menu-item").then((c) => c.DropdownMenuItem)
);

interface DropdownMenuProps {
  label: string;
  description?: string;
  options: DropdownOption[];
  mobile?: boolean;
  image?: string;
  chunks?: number;
}

export const DropdownMenu = ({
  label,
  options,
  description,
  mobile,
  image,
  chunks = 2,
}: DropdownMenuProps) => {
  const [open, _open] = useState(mobile ? true : false);

  return (
    <div
      onClick={!mobile ? undefined : () => _open(!open)}
      onMouseEnter={mobile ? undefined : () => _open(true)}
      onMouseLeave={mobile ? undefined : () => _open(false)}
    >
      <Box css="p:relative">
        <Box
          parse="d:inline-flex pt:8 pb:8 pl:16 pr:16 d:inline-flex a:center br:5"
          color="transparent"
          native={{
            cypress: `nav-${label}`,
            id: `nav-${label}`,
          }}
        >
          <Rule
            parse="!ls d:block"
            color="white"
            display="block"
            style={{ whiteSpace: "nowrap" }}
          >
            {label}
          </Rule>
          <Box parse="ml:10 w:16 h:16 d:inline-flex a:center j:center">
            <DesignedIcon
              size={16}
              color="#AFAFAF"
              name={`${open ? "up" : "down/regular"}`}
            />
          </Box>
        </Box>

        {open ? (
          <DropdownMenuItem
            mobile={mobile}
            label={label}
            options={options}
            image={image}
            description={description}
            chunks={chunks}
          />
        ) : undefined}
      </Box>
    </div>
  );
};

export default DropdownMenu;
