import { VideoSet } from "libs/editor-ui/types";
import { Box } from "simple-effing-primitive-layout";

import { Colors } from "../../../shared";
import CuePopupTextarea from "../CuePopupTextarea";

const DescriptionOptions = ({
  actIndex,
  sceneIndex,
  cueIndex,
  shouldPopupDisplayOnTop,
  setDescriptionOptionsAreOpen,
  descriptionOptionsAreOpen,
  onCueUpdate,
  cue,
}: {
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  shouldPopupDisplayOnTop: boolean;
  setDescriptionOptionsAreOpen: (is: boolean) => unknown;
  descriptionOptionsAreOpen: boolean;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  cue: VideoSet;
}) => {
  return (
    <div
      onMouseLeave={() => {
        setDescriptionOptionsAreOpen(false);
      }}
    >
      <Box
        display={descriptionOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          <CuePopupTextarea
            label="Cue Description"
            value={cue?.cueDescription || ""}
            change={(cueDescription) => {
              setDescriptionOptionsAreOpen(false);
              onCueUpdate(actIndex, sceneIndex, cueIndex, {
                ...cue,
                cueDescription: cueDescription as string,
              });
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default DescriptionOptions;
