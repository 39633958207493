import { DesignedButton, DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { FC, ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

export interface StepperActionsProps {
  current: number;
  previous?: () => void;
  next?: () => void;
  nextLabel?: string;
  nextHelperText?: ReactNode;
  mobile?: boolean;
  loading?: boolean;
  hideIcon?: boolean;
  hidePreviousButton?: boolean;
}

export const StepperActions: FC<StepperActionsProps> = ({
  current,
  previous,
  next,
  nextHelperText,
  nextLabel,
  mobile,
  loading,
  hideIcon,
  hidePreviousButton,
}) => {
  const first = current === 0;

  return (
    <Box parse={`w:100% d:flex j:space-between a:center mt:24`}>
      {!first && !mobile && !hidePreviousButton && (
        <DesignedButton
          size="large"
          icon={<DesignedIcon size={mobile ? 20 : 24} name="left" />}
          label="Previous"
          theme="white"
          press={previous}
          properties={{
            native: {
              cypress: "previous-button",
            },
          }}
        />
      )}

      <Box
        parse={`d:flex w:auto a:${
          mobile ? "flex-start" : "center"
        } j:flex-end ml:auto`}
      >
        {nextHelperText && (
          <Rule
            parse={`!pt d:block mr:24 c:#91969D ${
              mobile ? "ta:left" : "ta:right"
            }`}
          >
            {mobile ? (first ? nextHelperText : "") : nextHelperText}
          </Rule>
        )}

        <DesignedButton
          size="large"
          icon={
            hideIcon ? undefined : (
              <DesignedIcon size={mobile ? 20 : 24} name="right" />
            )
          }
          label={nextLabel || "Next"}
          theme="white"
          press={next}
          loading={loading}
          properties={{
            native: {
              cypress: "next-button",
            },
          }}
        />
      </Box>
    </Box>
  );
};
