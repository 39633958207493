import { CSSProperties, FC, Fragment, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Box } from "simple-effing-primitive-layout";

import { Swipeable } from "../swipeable/swipeable";
import { ModalBody } from "./modal-body";
import { ModalCloseButton } from "./modal-close-button";
import { ModalContent } from "./modal-content";
import { ModalFooter } from "./modal-footer";
import { ModalHeader } from "./modal-header";
import { ModalNavigationButton } from "./modal-navigation-button";
import { ModalOverlay } from "./modal-overlay";
import { ModalSeparator } from "./modal-separator";

interface ModalProps {
  open: boolean;
  onClose(): void;
  title?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  closeOnEsc?: boolean;
  centered?: boolean;
  hideCloseButton?: boolean;
  hideContentWrapper?: boolean;
  contentIsFocusable?: boolean;
  maxWidth?: number;
  navigation?: boolean;
  disablePrevious?: boolean;
  disableNext?: boolean;
  style?: CSSProperties;
  mobile?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

export const Modal: FC<ModalProps> = ({
  open,
  children,
  closeOnEsc = true,
  centered = false,
  hideCloseButton = false,
  contentIsFocusable = false,
  hideContentWrapper = false,
  title,
  footer,
  onClose,
  onClickNext,
  onClickPrevious,
  disablePrevious = false,
  disableNext = false,
  maxWidth,
  navigation = false,
  mobile = false,
  style,
  onSwipeLeft,
  onSwipeRight,
}) => {
  const Wrapper = mobile && navigation ? Swipeable : Fragment;

  return open
    ? createPortal(
        <>
          <ModalOverlay />

          <Wrapper onSwipeLeft={onSwipeLeft} onSwipeRight={onSwipeRight}>
            <ModalContent
              onClose={onClose}
              closeOnEsc={closeOnEsc}
              centered={centered}
              contentIsFocusable={contentIsFocusable}
              hideContentWrapper={hideContentWrapper}
              maxWidth={maxWidth}
              style={style}
            >
              {navigation && !mobile && (
                <ModalNavigationButton
                  mobile={mobile}
                  direction="left"
                  onClick={onClickPrevious}
                  disabled={disablePrevious}
                />
              )}

              {title && <ModalHeader>{title}</ModalHeader>}
              {!hideCloseButton && !hideContentWrapper && (
                <ModalCloseButton onClose={onClose} hasTitle={!!title} />
              )}

              {title && !hideContentWrapper && <ModalSeparator />}

              <ModalBody>{children}</ModalBody>
              {footer &&
                (mobile && navigation ? (
                  <Box parse="d:flex a:flex-end p:relative">
                    {navigation && mobile && (
                      <ModalNavigationButton
                        mobile={mobile}
                        direction="left"
                        onClick={onClickPrevious}
                        disabled={disablePrevious}
                      />
                    )}
                    <ModalFooter>{footer}</ModalFooter>
                    {navigation && mobile && (
                      <ModalNavigationButton
                        mobile={mobile}
                        direction="right"
                        onClick={onClickNext}
                        disabled={disableNext}
                      />
                    )}
                  </Box>
                ) : (
                  <ModalFooter>{footer}</ModalFooter>
                ))}

              {navigation && !mobile && (
                <ModalNavigationButton
                  mobile={mobile}
                  direction="right"
                  onClick={onClickNext}
                  disabled={disableNext}
                />
              )}
            </ModalContent>
          </Wrapper>
        </>,
        document.body
      )
    : null;
};

export default Modal;
