import moment from "moment";

import { Cookies } from "./Cookies";

export class Caching {
  private static version = "9.0.0";

  static set(key: string, data: unknown, duration: number) {
    try {
      const storage = this.storage(duration);

      storage.setItem(this.key(key), this.data(data));
    } catch (error) {
      console.log("🪵", error);
    } finally {
      /**
       * @comment
       * sessionStorage and localStorage has limit of 5mb, remove this cache upon logout.
       */
    }
  }

  static get(key: string, duration: number) {
    const storage = this.storage(duration);

    const value = storage.getItem(this.key(key));
    if (value) {
      const { timestamp, data } = JSON.parse(value);
      if (moment().diff(timestamp, "seconds") < duration) {
        return data;
      } else {
        storage.removeItem(this.key(key));
      }
    }
    return undefined;
  }

  static storage(duration: number) {
    if (duration > 21_600) {
      return localStorage;
    }
    return sessionStorage;
  }

  static key(name: string) {
    return `#cache/${name}`;
  }

  static data(data: unknown) {
    return JSON.stringify({
      timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
      data: data,
    });
  }

  static bootstrap() {
    const cversion = Cookies.get("_version");
    const cdate = Cookies.get("_date");

    if (cversion !== this.version || cdate !== moment().format("YYYY-MM-DD")) {
      console.log("🗑️", `Version: ${this.version}`, cversion);
      console.log("🗑️", `Date: ${moment().format("YYYY-MM-DD")}`, cdate);

      localStorage.clear();
      sessionStorage.clear();
      Cookies.set("_version", this.version);
      Cookies.set("_date", moment().format("YYYY-MM-DD"));
    }

    console.log("💯", "Caching");
  }
}

export default Caching;
