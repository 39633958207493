import { useRef, useState } from "react";

import UploadsUtils from "../uploads/UploadsUtils";

interface CustomActiveStorageBlob extends ActiveStorage.Blob {
  id: number;
}

export function useFileUpload() {
  const [file, setFile] = useState<File>();
  const [blob, setBlob] = useState<CustomActiveStorageBlob>();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickChoose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    inputRef?.current?.click();
  };

  const handleFileChosen = async (newFile: File | null) => {
    setUploading(!!newFile);
    setFile(newFile || undefined);
    if (!newFile) return;

    try {
      const newBlob = await UploadsUtils.createDirectUpload(
        newFile,
        setUploadProgress
      );
      setBlob(newBlob as CustomActiveStorageBlob);
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  return {
    file,
    blob,
    uploading,
    uploadProgress,
    inputRef,
    handleClickChoose,
    handleFileChosen,
  };
}
