import useSWR from "swr";

export interface RegionsData {
  page?: number;
  pageSize?: number;
  total: number;
  result: Region[];
}

export interface Region {
  id: number;
  name: string;
  enabled: boolean;
  currency: Currency;
}

export interface Currency {
  name: string;
  symbol: string;
}

const fetcher = (url: string) =>
  fetch(url).then((res) => {
    if (!res.ok) {
      throw new Error("Error fetching data");
    }
    return res.json();
  });

export const useRegions = () => {
  const page = 1;
  const pageSize = 10;

  const { data, error } = useSWR<RegionsData>(
    `${
      process.env["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"] ||
      "http://localhost:3000"
    }/ecommerce/regions?pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    fetcher
  );

  return {
    loading: !error && !data,
    error: error?.message,
    regionsData: data,
  };
};

export default useRegions;
