import { number, object, string } from "yup";

export const ProductionSchema = object({
  id: number().optional(),
  showTitle: string(),
  organizationName: string(),
  firstPerformance: string(),
  lastPerformance: string(),
  licensorCode: string().nullable(),
  additionalWeeks: number().nullable().default(0),
}).nullable();
