/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMobile } from "@with-nx/hooks-n-helpers";
import { Rule } from "@with-nx/simple-ui/atoms";
import { ReactNode, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import GenericContentHolder from "../generic-content-holder/generic-content-holder";

interface ReusableDoubleGradientCardInterface {
  gradients?: [string, string];
  image?: string;
  webp?: string;
  video?: string;
  pop?: boolean;
  properties?: {
    [key: string]: unknown;
  };
  press?: () => unknown;
  title?: string;
  minimal?: boolean;
  holder?: boolean;
  children?: ReactNode;
  bottomless?: boolean;
  bright?: boolean;
  subtitle?: ReactNode;
  arrowColor?: string;
  titleTag?: string;
}

export const ReusableDoubleGradientCard = ({
  gradients = ["#31B456", "#27C6BC"],
  image,
  webp,
  pop = false,
  video,
  properties,
  press,
  title,
  subtitle,
  minimal,
  children,
  holder = true,
  bottomless = false,
  bright = false,
  arrowColor = "#59687B",
  titleTag = "span",
}: ReusableDoubleGradientCardInterface) => {
  const [style, _style] = useState<string>(`rotate3d(0, 0, 0, 0deg)`);
  const [glow, _glow] = useState<string>(
    `radial-gradient(circle at 0 0, transparent, transparent)`
  );

  const mobile = useMobile();

  return (
    <Box
      parse="w:100% p:relative br:10 oa:hidden"
      height={properties?.["height"] || 400}
      press={press}
      {...properties}
      native={
        mobile
          ? undefined
          : {
              onMouseMove: (event: any) => {
                const bounds = (
                  event.target as HTMLDivElement
                ).getBoundingClientRect();

                const mouseX = event.clientX;
                const mouseY = event.clientY;
                const leftX = mouseX - bounds.x;
                const topY = mouseY - bounds.y;
                const center = {
                  x: leftX - bounds.width / 2,
                  y: topY - bounds.height / 2,
                };

                _style(`scale(1)`);

                _glow(
                  `radial-gradient(circle at ${
                    center.x * 2 + bounds.width / 2
                  }px ${
                    center.y * 2 + bounds.height / 2
                  }px, #ffffff25, #0000000f)`
                );
              },
              onMouseLeave: () => {
                _style(`scale(1)`);
                _glow(
                  `radial-gradient(circle at 0 0, transparent, transparent)`
                );
              },
            }
      }
      style={{
        ...((properties as unknown as any)?.style || {}),
        transform: style,
      }}
      css="--smooth"
    >
      <Box css="p:absolute t:0 l:0 w:100% h:100% oa:hidden br:10 z:2 i:2">
        <Box
          parse="p:absolute w:415 h:274 br:999 t:-90 l:-80 r:unset b:unset z:2 i:2"
          style={{
            filter: "blur(58.2979px)",
            backgroundImage: bright
              ? undefined
              : `linear-gradient(to right, ${gradients[0]}, transparent)`,
          }}
          color={bright ? gradients[0] : undefined}
        />
        <Box
          parse="p:absolute w:178 h:188 br:999 t:52 l:-112 r:unset b:unset z:2 i:2"
          style={{
            filter: "blur(58.2979px)",
          }}
          color={gradients[1]}
        />
      </Box>
      {minimal ? undefined : (
        <Box
          parse="p:absolute w:100% h:100% t:0 l:0 pa:20 i:3 z:3 d:flex fd:column j:flex-end"
          style={{
            backgroundImage:
              "linear-gradient(180deg, rgba(30, 36, 45, 0) 0%, #1E242D 100%)",
          }}
        >
          {children}
          {bottomless ? undefined : (
            <Box css="d:flex a:flex-end j:space-between">
              <Box>
                <Rule parse="!ht d:block" tag={titleTag}>
                  {title}
                </Rule>
                {subtitle && (
                  <Rule parse="!ls d:block mt:10" weight="300" lineHeight="22">
                    {subtitle}
                  </Rule>
                )}
              </Box>

              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                  d="M7 19C6.44772 19 6 19.4477 6 20C6 20.5523 6.44772 21 7 21V19ZM33.7071 20.7071C34.0976 20.3166 34.0976 19.6834 33.7071 19.2929L27.3431 12.9289C26.9526 12.5384 26.3195 12.5384 25.9289 12.9289C25.5384 13.3195 25.5384 13.9526 25.9289 14.3431L31.5858 20L25.9289 25.6569C25.5384 26.0474 25.5384 26.6805 25.9289 27.0711C26.3195 27.4616 26.9526 27.4616 27.3431 27.0711L33.7071 20.7071ZM7 21H33V19H7V21Z"
                  fill={arrowColor}
                />
              </svg>
            </Box>
          )}
        </Box>
      )}
      <Box
        css="t:0 l:0 r:0 b:0 i:2 z:2 w:100% h:100% p:absolute --smooth"
        style={{ backgroundImage: glow }}
      />
      {holder ? (
        <GenericContentHolder
          image={image}
          webp={webp}
          alt={title}
          video={video}
          pop={pop}
          width="auto"
          height="100%"
          style={{ width: "auto", height: "100%" }}
        />
      ) : undefined}
    </Box>
  );
};

export default ReusableDoubleGradientCard;
