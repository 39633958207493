import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Props {
  render: (
    onSceneMoveCallback: (dragIndex: number, hoverIndex: number) => unknown
  ) => React.ReactNode;
  mutate: (act: number, dragIndex: number, hoverIndex: number) => unknown;
  act: number;
}

const SceneMoverWrapper = ({ render, mutate, act }: Props) => {
  const onSceneMoveCallback = useCallback(
    (dragIndex: number, hoverIndex: number) =>
      mutate(act, dragIndex, hoverIndex),
    [act, mutate]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {render(onSceneMoveCallback)}
    </DndProvider>
  );
};

export default SceneMoverWrapper;
