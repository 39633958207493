import { useState } from "react";

export const useTimeout = (timeout?: number) => {
  const [done, _done] = useState(false);

  setTimeout(() => {
    _done(true);
  }, timeout || 2000);

  return done;
};
