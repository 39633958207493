import { Act, Scene, ShowData, VideoSet } from "libs/editor-ui/types";

const getActsNamesFromData = (data: ShowData) => {
  const options: Array<Array<string | number | undefined>> = [
    ["Choose", undefined],
  ];

  data.acts.map((act: Act, a: number) => {
    options.push([act.name, a]);
  });

  return options;
};

const getSceneNamesFromData = (
  data: ShowData,
  actIndex: number | undefined
) => {
  const options: Array<Array<string | number | undefined>> = [
    ["Choose", undefined],
  ];

  if (!actIndex) {
    return options;
  }

  data.acts[actIndex]?.scenes.map((scene: Scene, s: number) => {
    options.push([scene.name, s]);
  });

  return options;
};

const getCueNamesFromData = (
  data: ShowData,
  actIndex: number | undefined,
  sceneIndex: number | undefined
) => {
  const options: Array<Array<string | number | undefined>> = [
    ["Choose", undefined],
  ];

  if (!actIndex || !sceneIndex) {
    return options;
  }

  data.acts[actIndex]?.scenes[sceneIndex]?.sets.map(
    (cue: VideoSet, c: number) => {
      options.push([cue.name, c]);
    }
  );

  return options;
};

const anAdvancedChangeMadeToCue = (cue: VideoSet): string[] => {
  const changes: string[] = [];

  try {
    if (
      cue?.transitions?.avs ||
      cue?.transitions?.ave ||
      cue?.transitions?.lvs ||
      cue?.transitions?.lve ||
      cue?.transitions?.speed ||
      cue?.transitions?.transition
    ) {
      changes.push("transition");
    }

    if (
      cue?.effects?.background ||
      cue?.effects?.effect ||
      (cue?.mask && cue?.mask !== "none")
    ) {
      changes.push("effect");
    }

    if (
      cue?.filters?.grayscale ||
      cue?.filters?.brightness ||
      cue?.filters?.contrast ||
      cue?.filters?.opacity ||
      cue?.filters?.saturate ||
      cue?.filters?.["hue-rotate"]
    ) {
      changes.push("filter");
    }
  } catch (error) {
    console.error(error);
  }

  return changes;
};

const getCueNumber = (data: ShowData, cue: VideoSet): number => {
  let count = 0;
  let number = 0;

  data?.acts?.map((act) => {
    act?.scenes?.map((scene) => {
      scene?.sets?.map((set) => {
        count++;
        if (set.identifier && cue.identifier) {
          if (set?.identifier === cue?.identifier) {
            number = count;
          }
        }
      });
    });
  });

  return number;
};

const shouldPopupDisplayOnTop = (sceneIndex: number, sceneCount: number) => {
  return (sceneIndex * 100) / (sceneCount || 1) >= 75;
};

const CueUtils = {
  getActsNamesFromData,
  getSceneNamesFromData,
  getCueNamesFromData,
  getCueNumber,
  shouldPopupDisplayOnTop,
  anAdvancedChangeMadeToCue,
};

export default CueUtils;
