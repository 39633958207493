import { Rule } from "@with-nx/simple-ui/atoms";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

import { GridStepScroller } from "../grid-step-scroller/grid-step-scroller";
import ReusableDoubleGradientCard from "../reusable-double-gradient-card/reusable-double-gradient-card";

const BackdropLibraryImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/help-backdrop-library.webp";
const ChoreographyGuidesImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/help-choreography-guides.webp";
const ProjectionHardwareImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/help-projection-hardware.webp";
const ScenicProjectionsImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/help-scenic-projection.webp";
const UnlimitedSubscriptionImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/help-unlimited-subscription.jpg";
const ShopOverVideoImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/shop-over-video.png";
const SpeakWithSpecialistImageSource =
  "/assets/libs/simple-ui/solutions-components/assets/speak-with-specialist.png";

export type SolutionHelpTypes =
  | "backdrop-library"
  | "choreography-guides"
  | "projection-hardware"
  | "scenic-projections"
  | "unlimited-subscription"
  | "shop-over-video"
  | "speak-with-specialist";

const SolutionsHelpCard = ({
  type,
  arrowColor,
}: {
  type: SolutionHelpTypes;
  arrowColor?: string;
}) => {
  const router = useRouter();

  const image = (): string => {
    switch (type) {
      case "backdrop-library":
        return BackdropLibraryImageSource;
      case "choreography-guides":
        return ChoreographyGuidesImageSource;
      case "projection-hardware":
        return ProjectionHardwareImageSource;
      case "scenic-projections":
        return ScenicProjectionsImageSource;
      case "unlimited-subscription":
        return UnlimitedSubscriptionImageSource;
      case "shop-over-video":
        return ShopOverVideoImageSource;
      case "speak-with-specialist":
        return SpeakWithSpecialistImageSource;
      default:
        return "";
    }
  };

  const label = (): string => {
    switch (type) {
      case "backdrop-library":
        return "Backdrops Library";
      case "choreography-guides":
        return "Choreography Guides";
      case "projection-hardware":
        return "Find My Projector Now";
      case "scenic-projections":
        return "Scenic Projections";
      case "unlimited-subscription":
        return "Unlimited Subscription";
      case "speak-with-specialist":
        return "Speak with a Specialist";
      case "shop-over-video":
        return "Shop over Video";
    }
  };

  const subtitle = (): ReactNode => {
    switch (type) {
      case "choreography-guides":
        return (
          <>
            Spend $395 and get support for your show&apos;s
            <br />
            choreography
          </>
        );

      case "projection-hardware":
        return (
          <>
            Answer a set of questions to receive <br />
            your recommendation online, now
          </>
        );
      case "shop-over-video":
        return (
          <>
            Set up a video call for a one-on-one
            <br />
            personalized hardware consultation
          </>
        );
      case "speak-with-specialist":
        return (
          <>
            Schedule a phone call to shop with a<br /> Specialist
          </>
        );
      default:
        return "";
    }
  };

  const gradients = (): [string, string] => {
    switch (type) {
      case "backdrop-library":
        return ["#9231B4", "#5027C6"];

      case "choreography-guides":
        return ["#6C61E8", "#6D6BD4"];

      case "projection-hardware":
        return ["#314EB4", "#7627C6"];

      case "shop-over-video":
        return ["#9231B4", "#5027C6"];

      case "speak-with-specialist":
      case "scenic-projections":
        return ["#B43131", "#C67327"];

      case "unlimited-subscription":
        return ["#1C49E9", "#B"];
    }
  };

  const action = () => {
    switch (type) {
      case "backdrop-library":
        return router.push("/digital-backdrops");
      case "choreography-guides":
        return router.push("/choreography-guides");
      case "speak-with-specialist":
      case "shop-over-video":
        return router.push(
          `/contact/start?mode=get-started&referrer=${router.asPath}`
        );
      case "projection-hardware":
        return router.push("/projectors");

      case "scenic-projections":
        return router.push("/scenic-projections");
      case "unlimited-subscription":
        return router.push("/digital-backdrops");
    }
  };

  return (
    <ReusableDoubleGradientCard
      title={label()}
      gradients={gradients()}
      subtitle={subtitle()}
      press={action}
      image={image()}
      arrowColor={arrowColor}
    />
  );
};

export const SolutionsHelp = ({
  types,
  minimal,
  arrowColor,
  components,
}: {
  types: SolutionHelpTypes[];
  minimal?: boolean;
  arrowColor?: string;
  components?: JSX.Element[];
}) => {
  let items = [
    ...types.map((t) => (
      <SolutionsHelpCard type={t} key={t} arrowColor={arrowColor} />
    )),
  ];

  if (components?.length) {
    items = [...components, ...items];
  }

  return (
    <Box parse={minimal ? undefined : "pt:48 pb:48 z:1 i:1 p:relative"}>
      <div className="container">
        <Rule parse="!hl d:block mb:24">How Can We Help Today?</Rule>
      </div>
      <div className="container">
        <GridStepScroller
          xs={24}
          sm={24}
          md={12}
          lg={types.length === 4 ? 6 : 8}
          items={items}
        />
      </div>
    </Box>
  );
};

export default SolutionsHelp;
