import { FC } from "react";

export const ModalOverlay: FC = () => (
  <div
    style={{
      position: "fixed",
      left: "0",
      top: "0",
      width: "100vw",
      height: "100vh",
      backgroundColor: "var(--modal-black-alpha-600)",
      zIndex: "var(--modal-z-index)",
      animation: "fadeIn 0.3s forwards",
    }}
  />
);
