import { Formatter, useCMSPartners } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  AutoScroller,
  GridStepScroller,
  MobileViewSwitcher,
} from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { Box } from "simple-effing-primitive-layout";

import DesignedCard from "../designed-card/designed-card";
import ReusableSection from "../reusable-section/reusable-section";
import Rule from "../rule/rule";

export const ReusableSolutionPartners = ({
  top,
  filter,
  slugs,
  bottom,
  title,
  titleTag,
  description,
}: {
  bottom?: number;
  top?: number;
  title?: string;
  titleTag?: string;
  description?: string;
  filter?: {
    solutionTag?: string;
    partnerTypeTag?: string;
  };
  slugs?: string[];
}) => {
  const region = useServerRegion();
  const partners = useCMSPartners({});
  const _partners = partners.partners
    .filter((p) => {
      if (slugs && slugs.length > 0) {
        if (!slugs.includes(p?.slug || "")) {
          return false;
        }
      }

      if (filter?.solutionTag) {
        if (!p.solutionTags?.includes(filter.solutionTag)) {
          return false;
        }
      }

      if (filter?.partnerTypeTag) {
        if (!p.partnerTypeTags?.includes(filter.partnerTypeTag)) {
          return false;
        }
      }

      if (!p.regionTags?.includes(region?.id?.toString() || "")) {
        return false;
      }

      return true;
    })
    .reverse();

  if (!_partners.length) {
    return null;
  }

  return (
    <MobileViewSwitcher
      render={(mobile) => (
        <ReusableSection top={top || 96} bottom={bottom || 96}>
          <div className={mobile ? "container" : "container container-left"}>
            <Row
              gutter={[36, 18]}
              style={mobile ? undefined : { marginRight: 0, paddingRight: 0 }}
              align="middle"
            >
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                {title ? (
                  <Rule parse="!ht d:block mb:16" tag={titleTag || "h2"}>
                    {title}
                  </Rule>
                ) : undefined}
                {description ? (
                  <Rule parse="!ls d:block c:#E1E1E1 mb:24 c:?font3">
                    {description}
                  </Rule>
                ) : undefined}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={14}
                xl={14}
                style={mobile ? undefined : { paddingRight: 0 }}
              >
                {mobile ? (
                  <>
                    <GridStepScroller
                      xs={24}
                      items={_partners.map((item) => (
                        <DesignedCard
                          key={item._id}
                          size="16x9"
                          href={`/partners/${item.slug}`}
                          inside={{
                            image: Formatter.image(item.image, true),
                          }}
                          outside={{
                            title: item.name,
                            subtitle: item?.partnerTypeTags?.join(", ") || "",
                          }}
                        />
                      ))}
                    />
                  </>
                ) : (
                  <Box parse="p:relative oy:visible">
                    <Box
                      parse="p:absolute t:0 l:0 h:100% w:50 i:4 oy:visible"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, var(--background), transparent)",
                      }}
                    />
                    <AutoScroller
                      duration={!_partners?.length ? 10 : _partners?.length * 2}
                    >
                      <>
                        <Box parse="h:100% w:50" />
                        {_partners.map((item, i) => (
                          <Box
                            parse="w:256 mr:30"
                            style={{ minWidth: 256 }}
                            key={i}
                          >
                            <DesignedCard
                              size="16x9"
                              href={`/partners/${item.slug}`}
                              inside={{
                                image: Formatter.image(item.image, true),
                              }}
                              outside={{
                                title: item.name,
                                subtitle:
                                  item?.partnerTypeTags?.join(", ") || "",
                              }}
                            />
                          </Box>
                        ))}
                      </>
                    </AutoScroller>
                  </Box>
                )}
              </Col>
            </Row>
          </div>
        </ReusableSection>
      )}
    />
  );
};

export default ReusableSolutionPartners;
