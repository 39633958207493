/* eslint-disable no-useless-escape */
export class Formatter {
  static friendly(string = "") {
    const result = string.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  static gup(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const results =
      typeof window !== "undefined"
        ? new RegExp("[?&]" + name + "=?([^&#]*)").exec(window.location.href)
        : null;

    return results == null ? null : results[1];
  }

  static image(image?: string, print?: boolean) {
    return image?.includes("id:")
      ? `${
          process.env["NEXT_PUBLIC_CMS_API_ORIGIN"]
        }/api/v1/image/${image?.replace("id:", "")}/${
          print ? "print.png" : "base64.png"
        }`
      : image || "";
  }

  static compressed(image?: string, ideal?: number, webp?: boolean) {
    return image?.includes("id:")
      ? `${
          process.env["NEXT_PUBLIC_CMS_API_ORIGIN"]
        }/api/v1/image/${image?.replace("id:", "")}/base64/${ideal || 512}${
          webp ? ".webp" : ".png"
        }`
      : image || "";
  }

  static document(document?: string) {
    return document?.includes("id:")
      ? `${
          process.env["NEXT_PUBLIC_CMS_API_ORIGIN"]
        }/api/v1/file/${document?.replace("id:", "")}/base64`
      : document || "";
  }

  static print(document?: string) {
    return document?.includes("id:")
      ? `${
          process.env["NEXT_PUBLIC_CMS_API_ORIGIN"]
        }/api/v1/file/${document?.replace("id:", "")}/print`
      : document || "";
  }

  static bytes(bytes: number, decimals: number = 0) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  static mm(value: number, min: number, max: number) {
    if (value > max) {
      return max;
    } else if (value < min) {
      return min;
    } else {
      return value;
    }
  }

  static rgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  static chunks(items: unknown[], size = 2) {
    const t: unknown[][] = [];
    for (let i = 0; i < items.length; i += size) {
      const chunk = items.slice(i, i + size);
      t.push(chunk);
    }
    return t;
  }

  static uuid() {
    let d = new Date().getTime(),
      d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
    });
  }

  static hex(rgb: { r: number; g: number; b: number }) {
    const _ = (c: number) => {
      const hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    };

    return (
      "#" + _(Math.floor(rgb.r)) + _(Math.floor(rgb.g)) + _(Math.floor(rgb.b))
    );
  }

  static slugify(s: string) {
    return s
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }
}

export default Formatter;
