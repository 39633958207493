import {
  Formatter,
  UseCMSHomepage,
  useCMSPartners,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { DesignedCard, Rule } from "@with-nx/simple-ui/atoms";
import { CSSProperties } from "react";
import { Box } from "simple-effing-primitive-layout";

import { GridStepScroller } from "../grid-step-scroller/grid-step-scroller";
import MobileViewSwitcher from "../mobile-view-switcher/mobile-view-switcher";
import ReusableSlantedContainer from "../reusable-slanted-container/reusable-slanted-container";

export interface ReusableContainerPartnersProps {
  colored?: boolean;
  slanted?: boolean;
  title?: string;
  titleTag?: string;
  description?: string;
  align?: "left" | "center" | "right";
  filter?: {
    solutionTag?: string;
    partnerTypeTag?: string;
  };
  slugs?: string[];
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}

export const ReusableContainerPartners = ({
  slanted = true,
  colored = false,
  title = "Our Partners",
  titleTag = "h6",
  description,
  align = "left",
  filter,
  slugs,
  lighthouse,
  homepage,
}: ReusableContainerPartnersProps) => {
  const region = useServerRegion();

  const pp = "w:16% ml:10 mr:10";
  const ppp = `w:100%% br:999 p:relative pt:100% c:${
    colored ? "#171B20" : "transparent"
  }`;
  const is = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    height: "80%",
    objectFit: "contain",
    transform: "translate(-50%, -50%)",
  } as CSSProperties;

  const cp = useCMSPartners({}, !homepage?.partners);
  const cpf = cp.partners
    .filter((p) => {
      if (filter?.solutionTag) {
        if (!p.solutionTags?.includes(filter.solutionTag)) {
          return false;
        }
      }

      if (filter?.partnerTypeTag) {
        if (!p.partnerTypeTags?.includes(filter.partnerTypeTag)) {
          return false;
        }
      }

      if (!p.regionTags?.includes(region?.id?.toString() || "")) {
        return false;
      }

      return true;
    })
    .reverse();
  const loading = homepage?.partners ? false : cp.loading;
  const partners = homepage?.partners || cpf;

  const children = loading ? null : (
    <>
      {title ? (
        <div className="container">
          <Rule parse={`!hl d:block mb:32 ta:${align}`} tag={titleTag}>
            {title}
          </Rule>
        </div>
      ) : undefined}
      {description ? (
        <div className="container">
          <Rule
            parse={`!ll c:?font3 d:block mb:32 ta:${align} ml:auto mr:auto w:100%`}
          >
            {description}
          </Rule>
        </div>
      ) : undefined}
      <MobileViewSwitcher
        render={(mobile) => {
          const Container = ({ children }: { children: unknown }) => (
            <div
              className="container"
              style={{
                paddingLeft: mobile ? 0 : undefined,
                paddingRight: mobile ? 0 : undefined,
              }}
            >
              <Box
                parse={`d:flex a:center j:${
                  align === "center" ? "center" : "space-between"
                } pl:${mobile ? 0 : 10} pr:${mobile ? 0 : 10} p:relative`}
              >
                {children}
              </Box>
            </div>
          );

          return (
            <Container>
              {mobile ? (
                <GridStepScroller
                  per={2}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  items={partners.map((item) => (
                    <DesignedCard
                      size="16x9"
                      inside={{
                        image: lighthouse
                          ? undefined
                          : Formatter.image(item.image, true),
                        color: "none",
                      }}
                      href={`/partners/${item.slug}`}
                    />
                  ))}
                />
              ) : (
                partners.map((item) => {
                  if (slugs?.length && !slugs?.includes(item?.slug || "")) {
                    return null;
                  }

                  return (
                    <Box
                      parse={pp}
                      key={item._id}
                      native={{
                        href: `/partners/${item.slug}`,
                        rel: "noreferrer",
                      }}
                      element="a"
                    >
                      <Box parse={ppp} border="1px solid #2D3542">
                        {lighthouse ? undefined : (
                          <img
                            loading="lazy"
                            src={Formatter.image(item.image)}
                            alt={item.name || "Broadway Media"}
                            width="auto"
                            height="auto"
                            style={is}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })
              )}
            </Container>
          );
        }}
      />
    </>
  );

  return slanted ? (
    <ReusableSlantedContainer>{children}</ReusableSlantedContainer>
  ) : (
    <Box css="p:relative i:1 z:1 reusable-container-partners">{children}</Box>
  );
};

export default ReusableContainerPartners;
