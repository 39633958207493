import { Formatter, useCMSBlogs, useMobile } from "@with-nx/hooks-n-helpers";
import { Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import GridBlog from "../grid-blog/grid-blog";
import { GridStepScroller } from "../grid-step-scroller/grid-step-scroller";
import { SolutionPartner } from "./solution-partners";

export const SolutionsStories = ({
  solution,
  minimal,
  filter,
  type,
}: {
  solution?: SolutionPartner;
  minimal?: boolean;
  filter?: string;
  type?: string;
}) => {
  const mobile = useMobile();
  const _blogs = useCMSBlogs(
    type
      ? {
          filter: {
            storyTypeTags: [type],
          },
        }
      : filter
      ? {
          filter: {
            solutionTags: [filter],
          },
        }
      : {}
  );
  const all = useCMSBlogs({
    page: 0,
    per: 3,
  });

  const blogs = _blogs.blogs.length === 0 ? all : _blogs;

  return (
    <Box parse={minimal ? undefined : "pt:48 pb:48"}>
      <div className="container">
        <Box css="d:flex a:center" parse="mb:24">
          <Rule
            rule={mobile ? "ht" : "hl"}
            color="var(--font1)"
            display="block"
            right={24}
            tag="h3"
          >
            Blog Spotlight
          </Rule>
          {solution ? (
            <Box parse="w:176">
              <img
                loading="lazy"
                alt={solution.name}
                src={solution.logo}
                height={mobile ? 24 : 36}
                width="auto"
              />
            </Box>
          ) : undefined}
        </Box>
        <GridStepScroller
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          items={blogs.blogs.map((blog, b) => {
            if (b > 2) {
              return null;
            }

            return (
              <GridBlog
                key={blog._id}
                date={blog.date}
                description={blog.description}
                image={Formatter.compressed(blog.coverImage, 256, true)}
                title={blog.title}
                url={`/blog/${blog.slug}`}
                transparent={false}
                properties={{
                  height: "100%",
                }}
              />
            );
          })}
        />
      </div>
    </Box>
  );
};
