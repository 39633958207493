import { useAuth } from "@with-nx/auth";
import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

interface AuthenticationLockContainerProps {
  render: (authenticated: boolean) => JSX.Element;
  title?: string;
  bypass?: boolean;
}

export const AuthenticationLockContainer = ({
  render,
  title,
  bypass,
}: AuthenticationLockContainerProps) => {
  const auth = useAuth();
  const authenticated = auth?.authState?.status === "authenticated";

  if (authenticated || bypass) {
    return render(authenticated);
  } else {
    return (
      <Box parse="p:relative pt:24 pb:24">
        <Box
          parse="p:absolute t:0 l:0 w:100% h:100% i:99"
          style={{
            backgroundImage:
              "linear-gradient(270.3deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 100%)",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
          }}
        >
          <Box parse="w:100% d:flex a:center j:center pt:24 pb:24 i:2 p:sticky t:0 l:0 r:unset b:unset fd:column">
            <Rule top={16} align="center" parse="!ll d:block mb:16 ta:center">
              {title || "See Preview Videos"}
            </Rule>
            <Box parse="d:flex a:center">
              <DesignedButton
                size="small"
                theme="tetriary"
                label="Sign In"
                href="/auth?open=back"
                properties={{ left: 5, right: 5 }}
              />
              <DesignedButton
                size="small"
                theme="primary"
                label="Sign Up"
                href="/auth?form=register&open=back"
                properties={{ left: 5, right: 5 }}
              />
            </Box>
          </Box>
        </Box>

        {render(authenticated)}
      </Box>
    );
  }
};

export default AuthenticationLockContainer;
