type ThrottlerGlobal = {
  throttlerMap?: Map<string, number>;
};

export class ThrottlerController {
  private static map = new Map();

  static can(group: string, action: string, delay: number) {
    const global = globalThis as ThrottlerGlobal;
    if (!global.throttlerMap) {
      global.throttlerMap = new Map();
    }

    const key = `${group}:${action}`;
    const now = Date.now();
    const last = global.throttlerMap.get(key);
    if (last && last + delay > now) {
      return false;
    }
    global.throttlerMap.set(key, now);
    return true;
  }
}

export default ThrottlerController;
