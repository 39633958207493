import { FC, ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ModalBodyProps {
  children?: ReactNode;
}

export const ModalBody: FC<ModalBodyProps> = ({ children }) => (
  <Box parse="f:1 pt:var(--modal-space-2) pb:var(--modal-space-2) pl:var(--modal-space-6) pr:var(--modal-space-6)">
    {children}
  </Box>
);
