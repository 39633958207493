/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { useURL } from "./useService";

export async function createDirectUpload(
  file: File,
  progress?: (percentage: number) => void
) {
  const _url = useURL("accounts");
  const url = `${_url}/api/v2/direct_uploads`;

  const direct = (await import("@rails/activestorage")).DirectUpload;
  const upload = new direct(file, url.toString(), {
    directUploadWillStoreFileWithXHR: (xhr) => {
      xhr.upload.addEventListener(
        "progress",
        ({ loaded, total }) => progress && progress((loaded / total) * 100)
      );
    },
  });
  return new Promise((resolve, reject) => {
    upload.create((error, attributes) => {
      if (error) {
        reject(error);
      } else {
        resolve(attributes);
      }
    });
  });
}

const useUploads = () => {
  const [file, _file] = useState<File | null>(null);
  const [uploading, _uploading] = useState<boolean>(false);
  const [finished, _finished] = useState<boolean>(false);
  const [percentage, _precentage] = useState<number>(0);

  return {
    file,
    set: (f: File) => _file(f),
    clear: () => _file(null),
    create: async (progress?: (percentage: number) => void) => {
      if (!file || uploading) {
        throw new Error("No file selected");
      }

      _uploading(true);

      const data = (await createDirectUpload(file, (p) => {
        _precentage(p);

        if (p === 100) {
          _uploading(false);
        }

        progress?.(p);
      })) as any;

      _finished(true);

      return data["signed_id"] as string;
    },
    uploading,
    percentage,
    finished,
  };
};

export default useUploads;
