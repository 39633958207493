import Moment from "moment";
import React, { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Rule from "../../../../atoms/src/lib/rule/rule";

export interface CalendarInputProps {
  icon?: string;
  label?: string;
  hint?: string;
  value?: string;
  error?: string;
  positive?: string;
  size?: string;
  type?: string;
  placeholder?: string;
  bottom?: number;
  focus?: () => void;
  blur?: () => void;
  required?: boolean;
  change?: (value: string, event?: unknown) => void;
  properties?: {
    [key: string]: unknown;
  };
  container?: {
    [key: string]: unknown;
  };
  native?: {
    [key: string]: unknown;
  };
  allow?: number[];
}

type CalendarInputSizes = {
  [key: string]: {
    height?: number;
    padding?: number;
    size?: number;
    weight?: string;
  };
};

const Sizes: CalendarInputSizes = {
  large: {
    height: 48,
    padding: 16,
    size: 18,
    weight: "600",
  },
  medium: {
    height: 40,
    padding: 16,
    size: 16,
    weight: "600",
  },
  small: {
    height: 32,
    padding: 10,
    size: 14,
    weight: "600",
  },
};

export const CalendarInput = ({
  icon,
  label,
  hint,
  value,
  error,
  focus,
  blur,
  change,
  properties,
  container,
  required,
  size = "medium",
  placeholder,
  type,
  native,
  bottom = 10,
  positive,
  allow,
}: CalendarInputProps) => {
  const _size = size ? Sizes[size] || Sizes["medium"] : Sizes["medium"];
  const [focused, setFocused] = useState(false);

  return (
    <Box parse="w:auto" bottom={bottom} {...container}>
      {label ? (
        <Rule display="block" rule="lt" color="#AFAFAF" bottom={5}>
          {label}{" "}
          {required ? (
            <span
              style={{
                fontWeight: "bold",
                marginLeft: 4,
                color: "var(--primary)",
              }}
            >
              *
            </span>
          ) : undefined}
        </Rule>
      ) : undefined}
      <Box
        parse={`h:${_size.height} pr:${_size.padding} pl:${_size.padding} br:5 d:flex a:center j:flex-start`}
        color="#272E38"
        border="var(--border)"
        {...properties}
      >
        {icon ? null : undefined}
        <input
          type={type || "date"}
          value={value || ""}
          style={{
            background: "transparent",
            outline: "none",
            outlineColor: "none",
            border: "none",
            height: _size.height,
            flex: 1,
            fontSize: _size.size,
            fontWeight: _size.weight,
            fontFamily: "Figtree",
            color: "#FFFFFF",
            width: "100%",
            colorScheme: "dark",
          }}
          placeholder={placeholder}
          onChange={(event) => {
            const date = Moment(event.target.value);

            if (allow) {
              if (!allow.includes(date.isoWeekday())) {
                return;
              }

              if (Moment().isAfter(date)) {
                return;
              }
            }

            if (change) {
              if (date.isValid()) {
                change(date.format("YYYY-MM-DD"));
              } else {
                change(Moment().format("YYYY-MM-DD"));
              }
            }
          }}
          onFocus={() => {
            setFocused(true);
            if (focus) {
              focus();
            }
          }}
          onBlur={() => {
            setFocused(false);
            if (blur) {
              blur();
            }
          }}
          {...native}
        />
      </Box>
      {hint ? (
        <Rule display="block" rule="lt" color="#909090">
          {hint}
        </Rule>
      ) : undefined}
      {error && error.length > 0 && !focused ? (
        <Rule display="block" rule="lt" color="var(--negative)">
          {error}
        </Rule>
      ) : undefined}
      {positive && positive.length > 0 ? (
        <Rule display="block" rule="lt" color="var(--positive)">
          {positive}
        </Rule>
      ) : undefined}
    </Box>
  );
};

export default CalendarInput;
