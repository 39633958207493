import { Ecommerce } from "../types/types";

export class ShoppingCartUtils {
  static itemExists({
    cart,
    id,
    options,
    position,
  }: {
    cart: Ecommerce.Cart.State;
    id: number | string;
    options?: {
      proLicense?: boolean;
    };
    position?: "licensed" | "non-licensed";
  }) {
    const isInLicensedItems = cart.data?.licensedItems?.some((cartItem) =>
      options?.proLicense !== undefined
        ? String(cartItem?.product?.id) === String(id) &&
          !!cartItem?.proLicense === options.proLicense
        : String(cartItem?.product?.id) === String(id)
    );

    const isInNonLicensedItems = cart.data?.nonLicensedItems?.some((cartItem) =>
      options?.proLicense !== undefined
        ? String(cartItem?.product?.id) === String(id) &&
          Boolean(cartItem?.proLicense) === options.proLicense
        : String(cartItem?.product?.id) === String(id)
    );

    if (position) {
      return position === "licensed" ? isInLicensedItems : isInNonLicensedItems;
    }
    return isInLicensedItems || isInNonLicensedItems;
  }
}

export default ShoppingCartUtils;
