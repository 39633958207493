import { Filters, ShowData } from "libs/editor-ui/types";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors } from "../../../shared";
import Button from "../../../shared/Button";
import Range from "../../../shared/Range";
import CuePopupInput from "../../cue/CuePopupInput";

const Option = ({
  filters,
  option,
  setFilters,
  min = 0,
  max = 100,
  label,
  change,
}: {
  filters: Filters;
  option: keyof Filters;
  setFilters: (filters: Filters) => unknown;
  min?: number;
  max?: number;
  label: string;
  change: () => void;
}) => (
  <Box parse="d:flex a:flex-end">
    <Box parse="f:1">
      <Range
        label=""
        min={min}
        max={max}
        value={filters[option]}
        change={(value) => {
          const temporary = { ...filters };
          temporary[option] = value;
          setFilters(temporary);
        }}
        blur={change}
      />
    </Box>
    <Box parse="w:50 ml:10">
      <CuePopupInput
        label={label}
        value={filters[option].toString()}
        change={(value) => {
          const temporary = { ...filters };
          temporary[option] = value ? parseInt(value as string) : 0;
          setFilters(temporary);
          change();
        }}
        input={{ type: "number", min, max }}
        blur={change}
      />
    </Box>
    <Button size="small" theme="primary" label="Reset" />
  </Box>
);

const FilterOptions = ({
  shouldPopupDisplayOnTop,
  setFilterOptionsAreOpen,
  filterOptionsAreOpen,
  onDangerousDataUpdate,
  data,
  thumbnail,
  actIndex,
}: {
  shouldPopupDisplayOnTop: boolean;
  setFilterOptionsAreOpen: (is: boolean) => unknown;
  filterOptionsAreOpen: boolean;
  onDangerousDataUpdate: (data: ShowData) => unknown;
  data: ShowData;
  thumbnail?: string;
  actIndex: number;
}) => {
  const [
    filters = {
      grayscale: 0,
      brightness: 100,
      contrast: 100,
      opacity: 100,
      saturate: 100,
      "hue-rotate": 0,
    },
    setFilters,
  ] = useState<Filters>({
    grayscale: 0,
    brightness: 100,
    contrast: 100,
    opacity: 100,
    saturate: 100,
    "hue-rotate": 0,
  });

  useEffect(() => {
    const temporary = {
      grayscale: data?.acts[actIndex]?.filters?.grayscale || 0,
      brightness: data?.acts[actIndex]?.filters?.brightness || 100,
      contrast: data?.acts[actIndex]?.filters?.contrast || 100,
      opacity: data?.acts[actIndex]?.filters?.opacity || 100,
      saturate: data?.acts[actIndex]?.filters?.saturate || 100,
      "hue-rotate": data?.acts[actIndex]?.filters?.["hue-rotate"] || 0,
    };
    setFilters(temporary);
  }, [filterOptionsAreOpen]);

  const update = (override?: Filters) => {
    const temporary = cloneDeep(data);

    const act = temporary.acts[actIndex];

    if (!act) {
      return;
    }

    act.filters = override ? override : filters;

    act.scenes.forEach((scene) => {
      scene.filters = override ? override : filters;

      scene.sets.forEach((cue) => {
        cue.filters = override ? override : filters;
      });
    });

    onDangerousDataUpdate(temporary);
  };

  const reset = () => {
    const temporary = cloneDeep(data);

    const act = temporary.acts[actIndex];

    if (!act) {
      return;
    }

    act.filters = undefined;

    act.scenes.forEach((scene) => {
      scene.filters = undefined;

      scene.sets.forEach((cue) => {
        cue.filters = undefined;
      });
    });

    onDangerousDataUpdate(temporary);
  };

  const css = `grayscale(${filters.grayscale}%) brightness(${filters.brightness}%) contrast(${filters.contrast}%) opacity(${filters.opacity}%) saturate(${filters.saturate}%) hue-rotate(${filters["hue-rotate"]}deg)`;

  return (
    <div onMouseLeave={() => setFilterOptionsAreOpen(false)}>
      <Box
        display={filterOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          {typeof thumbnail === "string" ? (
            <img
              src={thumbnail}
              style={{
                display: "block",
                width: "100%",
                height: 168.75,
                borderRadius: 4,
                border: "1px solid rgba(255,255,255,0.1)",
                marginBottom: 15,
                filter: css,
              }}
              loading="lazy"
            />
          ) : undefined}
          <Option
            label="Grayscale"
            option="grayscale"
            filters={filters}
            setFilters={setFilters}
            change={() => {
              /* */
            }}
          />
          <Option
            label="Brightness"
            option="brightness"
            filters={filters}
            setFilters={setFilters}
            change={() => {
              /* */
            }}
            max={200}
          />
          <Option
            label="Contrast"
            option="contrast"
            filters={filters}
            setFilters={setFilters}
            change={() => {
              /* */
            }}
            max={200}
          />
          <Option
            label="Opacity"
            option="opacity"
            filters={filters}
            setFilters={setFilters}
            change={() => {
              /* */
            }}
          />
          <Option
            label="Saturate"
            option="saturate"
            filters={filters}
            setFilters={setFilters}
            change={() => {
              /* */
            }}
            max={200}
          />
          <Option
            label="Hue"
            option="hue-rotate"
            filters={filters}
            setFilters={setFilters}
            change={() => {
              /* */
            }}
            min={0}
            max={360}
          />
          <Box parse="d:flex a:center mt:10">
            <Button
              label="Save & Override"
              press={() => update()}
              properties={{ right: 5 }}
              size="small"
            />
            <Button
              size="small"
              label="Reset"
              press={() => {
                setFilters({
                  grayscale: 0,
                  brightness: 100,
                  contrast: 100,
                  opacity: 100,
                  saturate: 100,
                  "hue-rotate": 0,
                });
                reset();
              }}
              properties={{ right: 5 }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default FilterOptions;
