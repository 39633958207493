import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { ReactNode, useEffect } from "react";
import { Box } from "simple-effing-primitive-layout";

import Area from "./area";
import { UploadedFile, useFile } from "./useFile";

interface FileCardProps {
  accept?: string;
  multiple?: boolean;
  base64?: boolean;
  upload?: (files?: UploadedFile[]) => void;
  height?: number;
  uploaded?: UploadedFile;
  tag?: ReactNode;
  bottom?: number;
}

export const FileCard = ({
  accept = ".png, .jpg, .pdf",
  multiple = false,
  base64 = false,
  upload,
  height = 40,
  uploaded,
  tag,
  bottom = 24,
}: FileCardProps) => {
  const { change, file, open } = useFile({
    accept,
    multiple,
    base64,
  });

  useEffect(() => {
    if (uploaded) {
      change([uploaded]);
    }
  }, []);

  useEffect(() => {
    if (upload) {
      upload(file);
    }
  }, [file]);

  return !file ? (
    <Area
      upload={(_) => change(_)}
      height={height}
      accept={accept}
      multiple={multiple}
      base64={base64}
    />
  ) : (
    <Box bottom={bottom}>
      <Box parse="d:flex a:flex-start">
        <Box
          parse="mnw:40 w:40 h:40 br:10 c:#1E242D d:inline-flex a:center j:center mr:12"
          style={{ minWidth: 40 }}
        >
          <DesignedIcon name="@paperclip" />
        </Box>
        <Box>
          <Box parse="d:flex a:center mb:6">
            {file.length > 1 ? (
              <Rule rule="ls" color="#AFAFAF" right={12}>
                {`${file.length} files`}
              </Rule>
            ) : file.length === 1 ? (
              <Rule rule="ls" color="#AFAFAF" right={12}>
                {file[0]?.name && file[0].name.length > 32
                  ? file[0].name.substring(0, 32) + "..."
                  : file[0].name}
                {file[0].extension ? `.${file[0].extension}` : ""}
              </Rule>
            ) : undefined}
            {tag ? (
              <Rule rule="ls" color="#6F6F6F" right={12}>
                {tag}
              </Rule>
            ) : undefined}
          </Box>
          <Box parse="d:flex a:center">
            <Box
              parse="d:inline-flex a:center mr:10"
              press={() => change(undefined)}
            >
              <DesignedIcon name="delete/bold" color="#6F6F6F" size={16} />
              <Rule rule="ls" color="#6F6F6F">
                Delete File
              </Rule>
            </Box>
            <Box parse="d:inline-flex a:center mr:10" press={open}>
              <DesignedIcon name="picture/filled" color="#E2B576" size={16} />
              <Rule rule="ls" color="#E2B576">
                Replace File
              </Rule>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FileCard;
