/**
 * @todo Wait for stage player web implementation and connect to it.
 */
import { useAuth } from "@with-nx/auth";
import {
  Currency,
  useMobile,
  usePricing,
  useSubscription,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import {
  DesignedButton,
  DesignedIcon,
  LoadingShimmer,
  ReusableGlossBox,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { useRouter } from "next/router";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

export const DigitalBackdropPricing = ({
  press,
  cta,
}: {
  press?: () => unknown;
  cta?: boolean;
}) => {
  const [tab] = useState<"monthly" | "yearly">("monthly");
  const router = useRouter();
  const auth = useAuth();
  const subscription = useSubscription();
  const region = useServerRegion();
  const digitalDropPricing = usePricing(
    {
      type: "DIGITAL_DROP",
      region: region?.id,
    },
    !!region?.id
  );

  const mobile = useMobile();

  const formattedSubscriptionPricing = Currency.formatInteger(
    tab === "monthly"
      ? subscription.pricing.monthly?.price || 0
      : subscription.pricing.yearly?.price || 0,
    subscription.pricing.monthly?.currency || "USD"
  );

  const { pricing } = subscription;

  const oneTimePurchaseFormattedPrice = Currency.formatInteger(
    pricing.oneTimePurchase?.price || 0,
    pricing.oneTimePurchase?.currency || "USD"
  );

  const subscribeLabel = `Subscribe for ${Currency.formatInteger(
    tab === "monthly"
      ? pricing.monthly?.price || 0
      : pricing.yearly?.price || 0,
    pricing.monthly?.currency || "USD"
  )} a ${tab === "monthly" ? "Month" : "Year"}`;

  const SubscriptionContent = ({
    cta,
    otp,
  }: {
    cta?: boolean;
    otp?: boolean;
  }) =>
    !subscription.pricing.monthly ? (
      <LoadingShimmer parse="br:10 pa:24 pt:75%" />
    ) : (
      <ReusableGlossBox
        parse={`pa:24 br:10 oa:hidden h:100%`}
        mode={otp ? "top-left-purple" : "top-left-yellow"}
      >
        <Box parse="d:flex fd:column j:space-between h:100%">
          <Box>
            <Box parse="mb:24 d:flex a:flex-start j:space-between">
              <Rule parse="!hs d:block">Unlimited Backdrops</Rule>
              {otp ? undefined : (
                <img
                  style={{ width: "auto", display: "block", height: 26 }}
                  src="/assets/libs/simple-ui/reusable-gloss-box/assets/best-value.svg"
                  alt="Best Value"
                  loading="lazy"
                />
              )}
            </Box>

            {cta ? (
              <Box
                parse={
                  mobile
                    ? "d:flex fd:column"
                    : "d:flex a:center j:space-between"
                }
              >
                <Box parse="d:flex a:baseline">
                  <Rule rule="ll" size={48} line={56}>
                    {Currency.formatInteger(
                      tab === "monthly"
                        ? subscription.pricing.monthly?.price || 0
                        : subscription.pricing.yearly?.price || 0,
                      subscription.pricing.monthly?.currency || "USD"
                    )}
                  </Rule>
                  <Rule rule="lm" parse="ml:5">
                    /unlimited/per month
                  </Rule>
                </Box>
                <DesignedButton
                  press={() => {
                    if (auth.authState.status === "authenticated") {
                      subscription.helpers.create("standard", tab, true);
                    } else {
                      router.push("/auth?open=subscription");
                    }
                  }}
                  label="Purchase Unlimited Subscription"
                  size="medium"
                  theme="primary"
                  properties={{ top: 12 }}
                />
              </Box>
            ) : (
              <Box parse="d:flex a:baseline mt:16 mb:24">
                <Rule rule="ll" size={48} line={56}>
                  {otp
                    ? oneTimePurchaseFormattedPrice
                    : formattedSubscriptionPricing}
                </Rule>
                <Rule rule="lm" parse="ml:5">
                  {otp
                    ? "/unlimited/one month license"
                    : "/unlimited/per month"}
                </Rule>
              </Box>
            )}

            {cta ? undefined : (
              <>
                <Rule rule="lm" parse="d:block mt:24 mb:12 c:?font3">
                  Features:
                </Rule>

                <Row gutter={[10, 10]}>
                  {[
                    "Unlimited Selection of Backdrops",
                    "StagePlayer Software",
                    "Organize Backdrops in “My Collections”",
                    "Custom Uploads",
                    "Vignette and Layers",
                    ...(otp ? [] : ["Subscription Access"]),
                  ].map((item) => (
                    <Col key={item} xs={24}>
                      <Box parse="d:flex a:center" key={item}>
                        <DesignedIcon
                          name="check/bold"
                          color={otp ? "var(--warning)" : "var(--positive)"}
                          size={24}
                        />
                        <Rule rule="lm" parse="d:block ml:10 c:?font1">
                          {item}
                        </Rule>
                      </Box>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </Box>

          {cta ? undefined : (
            <>
              {otp ? (
                <DesignedButton
                  press={() => {
                    if (auth.authState.status === "authenticated") {
                      subscription.helpers.create("standard", "otp", true);
                    } else {
                      router.push("/auth?open=subscription&interval=otp");
                    }
                  }}
                  label={`Try for 1 Month Only for ${oneTimePurchaseFormattedPrice}`}
                  properties={{
                    style: {
                      width: "100%",
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                    },
                  }}
                  container={{
                    style: {
                      width: "100%",
                      display: "flex",
                      marginTop: 24,
                    },
                  }}
                  theme="tetriary"
                />
              ) : (
                <DesignedButton
                  press={() => {
                    if (auth.authState.status === "authenticated") {
                      subscription.helpers.create("standard", tab, true);
                    } else {
                      router.push("/auth?open=subscription&interval=monthly");
                    }
                  }}
                  label={subscribeLabel}
                  properties={{
                    style: {
                      width: "100%",
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                    },
                  }}
                  container={{
                    style: {
                      width: "100%",
                      display: "flex",
                      marginTop: 24,
                    },
                  }}
                  theme="primary"
                />
              )}
            </>
          )}
        </Box>

        {cta ? (
          <Rule parse="!pm d:block mt:24 c:?font2">
            Take your show to new heights! Let your imagination run wild and
            start your creative journey today! Step into a world where
            imagination knows no limits and let your productions shine like
            never before.
          </Rule>
        ) : undefined}
      </ReusableGlossBox>
    );

  const individualPricing = digitalDropPricing
    ? Currency.formatInteger(
        Number(digitalDropPricing?.price) || 0,
        region?.currency?.code || "USD"
      )
    : undefined;

  if (cta) {
    return (
      <Box>
        <Rule parse="!_t d:block mb:24" tag="h2">
          Craft Your Own Show Package
        </Rule>
        <Rule parse="!pl d:block mb:24 c:?font3">
          Each of these captivating settings can be licensed individually for
          just {individualPricing || ""}. For those seeking the full experience,
          we offer an unlimited option, granting access to this drop collection
          and our entire library of over 3,000 backdrops for only{" "}
          {formattedSubscriptionPricing} per month!
        </Rule>
        <SubscriptionContent cta={!mobile} />;
      </Box>
    );
  }

  return (
    <Row gutter={[36, 18]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        {!individualPricing ? (
          <LoadingShimmer parse="br:10 pa:24 h:100%" />
        ) : (
          <ReusableGlossBox
            parse="pa:24 br:10 oa:hidden h:100%"
            mode="top-left-blue"
          >
            <Box parse="d:flex fd:column j:space-between h:100%">
              <Box>
                <Rule parse="!hs d:block mb:24">Individual Backdrops</Rule>

                <Box parse="d:flex a:baseline mt:16 mb:24">
                  <Rule rule="ll" size={48} line={56}>
                    {individualPricing}
                  </Rule>
                  <Rule rule="lm" parse="ml:5">
                    /each/per month
                  </Rule>
                </Box>

                <Rule rule="lm" parse="d:block mt:24 mb:12 c:?font3">
                  Features:
                </Rule>

                <Row gutter={[10, 10]}>
                  {[
                    "Pay per Backdrop",
                    "StagePlayer Software",
                    "Online Show Setup",
                    "Vignette and Layers",
                  ].map((item) => (
                    <Col key={item} xs={24}>
                      <Box parse="d:flex a:center" key={item}>
                        <DesignedIcon
                          name="check/bold"
                          color="var(--positive)"
                          size={24}
                        />
                        <Rule rule="lm" parse="d:block ml:10 c:?font1">
                          {item}
                        </Rule>
                      </Box>
                    </Col>
                  ))}
                </Row>
              </Box>

              <DesignedButton
                press={press}
                label="Search All Backdrops"
                properties={{
                  style: {
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  },
                }}
                container={{
                  style: {
                    width: "100%",
                    display: "flex",
                    marginTop: 24,
                  },
                }}
                theme="tetriary"
              />
            </Box>
          </ReusableGlossBox>
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        <SubscriptionContent otp />
      </Col>
    </Row>
  );
};

export default DigitalBackdropPricing;
