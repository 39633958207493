/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";

import useService from "./useService";

type UseRepresentativeRepresentative = {
  id: string;
  name?: string | null;
  title?: string | null;
  biography?: string | null;
  externalEmail?: string | null;
  calendlyLink?: string | null;
  customerServiceTeam: boolean;
  profilePicture?: string | null;
  repRegions: {
    id: number;
    name: string;
    fallback: boolean;
    regions: unknown[];
  }[];
  staff?: {
    attachments?: {
      blob?: {
        signedUrl?: string;
      };
    }[];
  };
};

export const useRepresentative = () => {
  const makeRequestToMicroservice = useService("microservice", {
    cache: 1_800_000,
  });
  const [representative, _representative] = useState<
    UseRepresentativeRepresentative | undefined
  >(undefined);
  const [loading, _loading] = useState<boolean>(true);
  const auth = useAuth();

  const getCurrentRepresentative = async (token?: string) => {
    return await makeRequestToMicroservice(
      "GET",
      "/localization/representatives/representatives/current",
      {
        "includes[repRegions]": "true",
        "includes[staff]": "true",
        "includes[staff_attachments]": "true",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
  };

  const getRepresentativeByIpLocation = async () => {
    return await makeRequestToMicroservice(
      "GET",
      "/localization/representatives/representatives/ip-location",
      {
        "includes[repRegions]": "true",
        "includes[staff]": "true",
        "includes[staff_attachments]": "true",
      }
    );
  };

  useEffect(() => {
    (async () => {
      try {
        let request;

        if (auth.authState.status === "authenticated") {
          request = (await getCurrentRepresentative(
            auth.authState?.session?.token
          )) as any;
        } else {
          request = (await getRepresentativeByIpLocation()) as any;
        }

        if (request?.name) {
          _representative(request);
          _loading(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return { representative, loading };
};

export default useRepresentative;
