import { useAuth } from "@with-nx/auth";
import { useEffect, useState } from "react";

import useService from "./useService";

export type UseLibraryChoreoGuide = {
  id: string;
  slug: string;
  "marketing-description": string;
  licensor: string;
  producer: string;
  "other-credits": string;
  "has-access": boolean;
  songs: {
    id: number;
    name: string;
    description: string;
    preview_video: string;
    preview_thumbnail: string;
    videos: {
      id: number;
      name: string;
      description: string;
      video_file: string;
      video_thumbnail: string;
    }[];
  }[];
  "marketing-image": string;
  choreographer: string;
  "choreographer-biography": string;
  "choreographer-thumbnail"?: string;
  "commercial-video-url"?: string;
  show?: {
    id: string;
    title: string;
    slug: string;
  };
};

export async function sendAccessEmails(
  token: string,
  slug: string,
  emails: string[]
) {
  const service = useService("accounts");

  const request = await service(
    "POST",
    "/api/v2/session/send_access_emails",
    {
      slug,
      emails,
    },
    {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  );

  return request;
}

export async function getChoreographyGuideBySlug(token: string, slug: string) {
  const service = useService("accounts", {
    cache: 10_000,
  });

  const request = await service(
    "GET",
    `/api/v2/choreo-guides`,
    {
      "filter[slug]": slug,
      include: "show",
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (request?.["data"]?.[0]) {
    return {
      id: request?.["data"]?.[0]?.["id"],
      ...request?.["data"]?.[0]?.["attributes"],
      show: {
        id: request?.["included"]?.[0]?.["id"],
        ...request?.["included"]?.[0]?.["attributes"],
      },
    };
  }

  return undefined;
}

export const useLibrary = (options?: { slug: string }) => {
  const [data, _data] = useState<UseLibraryChoreoGuide | undefined>(undefined);
  const auth = useAuth();
  const token = auth.authState.session?.token || "";

  const helpers = {
    access: {
      send: async (slug: string, emails: string[]) => {
        const response = await sendAccessEmails(token, slug, emails);

        return response;
      },
    },
    get: {
      slug: async (slug: string) => {
        const response = await getChoreographyGuideBySlug(token, slug);

        return response;
      },
    },
  };

  useEffect(() => {
    if (options?.slug && options?.slug?.length > 0) {
      helpers.get.slug(options.slug).then((response) => {
        _data(response);
      });
    }
  }, [auth.authState.status, options?.slug]);

  return { helpers, data, slug: options?.slug };
};

export default useLibrary;
