import { VideoSet } from "libs/editor-ui/types";
import { useEffect, useRef, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../../../shared";
import Button from "../../../shared/Button";
import CuePopupInput from "../CuePopupInput";
import CuePopupSelect from "../CuePopupSelect";

const ControlledVideo = ({
  playbackRate = 1,
  src,
  setDuration,
  videoStart,
  videoEnd,
}: {
  playbackRate: number;
  src: string;
  setDuration: (value: number) => void;
  videoStart: number;
  videoEnd: number;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentPosition, setCurrentPosition] = useState<number>(videoStart);
  const [videoDuration, setVideoDuration] = useState<number>(NaN);

  const [_src, __src] = useState(src);
  const [_videoStart, __videoStart] = useState(videoStart);
  const [_videoEnd, __videoEnd] = useState(videoEnd);

  const [loading, _loading] = useState(false);

  useEffect(() => {
    __src(src);

    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [src, playbackRate]);

  useEffect(() => {
    (async () => {
      if (videoStart === _videoStart && videoEnd === _videoEnd) {
        return;
      }

      _loading(true);
      __videoEnd(videoEnd);
      __videoStart(videoStart);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      _loading(false);
    })();
  }, [videoStart, videoEnd]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadeddata", () => {
        setDuration(videoRef.current?.duration || NaN);
        setVideoDuration(videoRef.current?.duration || NaN);
        if (_videoEnd === 0 || !_videoEnd || isNaN(_videoEnd)) {
          __videoEnd(videoRef.current?.duration || NaN);
        }
      });
      videoRef.current.addEventListener("timeupdate", () => {
        const currentTime = videoRef.current?.currentTime || 0;

        setCurrentPosition(currentTime);
        const endPosition = _videoEnd || videoDuration || Infinity;

        if (
          !isNaN(currentPosition) &&
          !isNaN(_videoStart) &&
          !isNaN(endPosition) &&
          !isNaN(currentTime)
        ) {
          if (currentTime < _videoStart || currentTime > endPosition) {
            if (videoRef.current) {
              console.log(
                "currentTime",
                currentTime,
                "_videoEnd",
                _videoEnd,
                currentTime > endPosition + 1
              );

              videoRef.current.currentTime = _videoStart;
            }
          }
        }
      });
    }
  }, []);

  return (
    <Box parse="w:100% h:auto">
      {loading ? (
        <Box
          parse="w:100% br:4"
          color={Colors.foreground}
          mode="padding"
          top="56.25%"
        />
      ) : (
        <video
          ref={videoRef}
          src={_src}
          muted
          autoPlay
          loop
          style={{
            display: "block",
            width: "100%",
            height: "auto",
            borderRadius: 4,
            border: "1px solid rgba(255,255,255,0.1)",
          }}
        />
      )}
      <Box parse="br:999 mt:5 mb:5 h:5 w:100%" color={Colors.foreground}>
        <Box
          parse="h:5 br:999"
          color={Colors.entity}
          width={`${(currentPosition * 100) / videoDuration}%`}
        ></Box>
      </Box>
    </Box>
  );
};

const TransitionOptions = ({
  actIndex,
  sceneIndex,
  cueIndex,
  shouldPopupDisplayOnTop,
  setTransitionOptionsAreOpen,
  transitionOptionsAreOpen,
  onCueUpdate,
  cue,
  loop,
  action,
}: {
  actIndex: number;
  sceneIndex: number;
  cueIndex: number;
  shouldPopupDisplayOnTop: boolean;
  setTransitionOptionsAreOpen: (is: boolean) => unknown;
  transitionOptionsAreOpen: boolean;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  cue: VideoSet;
  loop: string | boolean;
  action: string | boolean;
}) => {
  const [speed, _speed] = useState<number>(1);
  const __speed = [
    ["0.1", 0.1],
    ["0.5", 0.5],
    ["Default", 1],
    ["1.5", 1.5],
    ["2", 2],
    ["4", 4],
  ];
  const [transition, _transition] = useState<string>("default");
  const __transition = [
    ["Default", "default"],
    ["Hard Cut", "hard-cut"],
    ["Fast Crossfade", "fast-crossfade"],
    ["Fade Trough", "fade-trough"],
  ];

  const [avs, _avs] = useState(0);
  const [ave, _ave] = useState(0);
  const [lvs, _lvs] = useState(0);
  const [lve, _lve] = useState(0);
  const [olve, _olve] = useState(0);

  useEffect(() => {
    _speed(cue?.transitions?.speed || 1);
    _transition(cue?.transitions?.transition || "default");
  }, []);

  const update = ({ s, t }: { s?: number; t?: string }) => {
    onCueUpdate(actIndex, sceneIndex, cueIndex, {
      ...cue,
      transitions: {
        speed: s || speed,
        transition: t || transition,
        avs,
        ave,
        lvs,
        lve,
      },
    });
  };

  return (
    <div onMouseLeave={() => setTransitionOptionsAreOpen(false)}>
      <Box
        display={transitionOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          {transitionOptionsAreOpen ? (
            <Box parse="d:flex mb:10">
              <Box parse="f:1 mr:5">
                <Text rule="Label Tiny" color={Colors.font4}>
                  Action Video
                </Text>
                {typeof action === "string" ? (
                  <ControlledVideo
                    playbackRate={1}
                    videoStart={avs}
                    videoEnd={ave}
                    src={action}
                    setDuration={(value) => {
                      const _save = cue?.transitions?.ave;
                      const _savs = cue?.transitions?.avs;
                      const __save =
                        _save && !isNaN(_save) && _save > 0 ? _save : value;
                      const __savs =
                        _savs && !isNaN(_savs) && _savs > 0 ? _savs : 0;

                      _ave(__save);
                      _avs(__savs);
                    }}
                  />
                ) : (
                  <Box
                    parse="w:100% br:4"
                    color={Colors.foreground}
                    mode="padding"
                    top="56.25%"
                  />
                )}
              </Box>
              <Box parse="f:1 ml:5">
                <Text rule="Label Tiny" color={Colors.font4}>
                  Loop Video
                </Text>
                {typeof loop === "string" ? (
                  <ControlledVideo
                    playbackRate={speed}
                    videoStart={lvs}
                    videoEnd={lve}
                    src={loop}
                    setDuration={(value) => {
                      const _slve = cue?.transitions?.lve;
                      const _slvs = cue?.transitions?.lvs;
                      const __slve =
                        _slve && !isNaN(_slve) && _slve > 0 ? _slve : value;
                      const __slvs =
                        _slvs && !isNaN(_slvs) && _slvs > 0 ? _slvs : 0;

                      _lve(__slve);
                      _olve(__slve);
                      _lvs(__slvs);
                    }}
                  />
                ) : (
                  <Box
                    parse="w:100% br:4"
                    color={Colors.foreground}
                    mode="padding"
                    top="56.25%"
                  />
                )}
              </Box>
            </Box>
          ) : undefined}
          {cue ? (
            typeof cue.loop === "string" ? (
              olve !== lve ? (
                <CuePopupSelect
                  label="Loop Speed"
                  value="custom"
                  change={() => {
                    //Bye 👋
                  }}
                  options={[["Custom", "custom"]]}
                />
              ) : (
                <CuePopupSelect
                  label="Loop Speed"
                  value={speed}
                  change={(value) => {
                    _speed(value as number);
                    update({ s: value as number });
                  }}
                  options={__speed}
                />
              )
            ) : undefined
          ) : undefined}
          <CuePopupSelect
            label="Transition Options"
            value={transition}
            change={(value) => {
              _transition(value as string);
              update({ t: value as string });
            }}
            options={__transition}
          />
          <Box parse="mt:10">
            <Box parse="mb:5 f:1">
              <CuePopupInput
                label="Loop Video Retiming (Seconds)"
                value={lve.toString()}
                change={(value) => {
                  const _value = parseFloat(value as string);

                  if (isNaN(_value) || _value <= 0) {
                    return;
                  }

                  _lve(_value);

                  if (_value !== olve) {
                    const __value = (_value * 1) / olve;

                    if (__value <= 0) {
                      return;
                    }

                    const ___value = parseFloat((1 / __value).toFixed(2));
                    _speed(___value);
                  }
                }}
                input={{ type: "number", min: 0 }}
                blur={() => update({})}
              />
            </Box>
            <Text rule="Label Tiny" color={Colors.font4} size={9} line={11}>
              Speed: <span style={{ color: Colors.branding }}>{speed}x</span>
            </Text>
          </Box>
          <Button
            properties={{ top: 10 }}
            size="small"
            theme="primary"
            label="Reset"
            press={() =>
              onCueUpdate(actIndex, sceneIndex, cueIndex, {
                ...cue,
                transitions: undefined,
              })
            }
          />
        </Box>
      </Box>
    </div>
  );
};

export default TransitionOptions;
