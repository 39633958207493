import { Box } from "simple-effing-primitive-layout";
import { passwordStrength, useHazel } from "@with-nx/hooks-n-helpers";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { views } from "../contact-form/types";
import { getServerRegionByCountry } from "../contact-form/helpers";
import { useRegions } from "@with-nx/ecommerce";
import { DesignedButton, DesignedInput, Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { GetStartedDetails } from "../contact-form/contact-form";
import { useAuthForm } from "apps/catalog/components/w/auth/useAuthForm";
import RegionMeta from "libs/region/src/meta";

interface SelfOnboardProps {
  view?: string;
  _view: Dispatch<SetStateAction<views>>;
  values: GetStartedDetails;
}

export const SelfOnboard: FC<SelfOnboardProps> = ({ view, values, _view }) => {
  const hazel = useHazel();
  const regions = useRegions();

  const {
    name,
    email,
    organization,
    region,
    organization_type,
    phone_number,
    state,
  } = values;

  const location =
    regions?.regionsData?.result?.find(
      (r) => getServerRegionByCountry(region || "") === r.name.toUpperCase()
    )?.id || 0;

  const initialValues = {
    name,
    email,
    organization,
    location,
    type: Number(organization_type),
    phone: phone_number,
    country: region,
    state,
  };

  const {
    loading,
    password,
    _password,
    repeat,
    _repeat,
    error,
    _error,
    positive,
    register,
  } = useAuthForm({ initialValues });
  const strength = password.length > 0 ? passwordStrength(password)?.value : "";

  const [submitting, _submitting] = useState(true);

  useEffect(() => {
    if (!submitting && !error) {
      _view("onboard");
    }
  }, [submitting]);

  const handleRegister = async () => {
    _submitting(true);

    await register();

    _submitting(false);
  };

  if (view === "auth") {
    return (
      <Row gutter={[30, 20]}>
        <Col xs={24} lg={12}>
          <Rule parse="!_h2 d:block mb:24 c:var(--black-base)">
            Complete Your Account
          </Rule>

          <Rule parse="d:block c:var(--grey-60) mb:24">
            Creating an account is free, quick and easy to do, and gives you
            access to the world's largest selection of Broadway-quality,
            licensor-approved theatrical resources.
          </Rule>

          <Rule parse="d:block c:var(--grey-60)">
            Completing your profile allows us to serve you better, and gives you
            the tools to begin building your production.
          </Rule>
        </Col>

        <Col xs={24} lg={12}>
          <Box parse="d:flex fd:column a:flex-end w:100%">
            <DesignedInput
              type="password"
              label="Password"
              theme="light"
              size="large"
              value={password}
              change={(password) => _password(password)}
              hint={strength && strength?.length > 0 ? strength : ""}
              focus={() => _error("")}
              native={{
                name: "password",
                cypress: "field-password",
              }}
              container={{
                width: "100%",
              }}
            />

            <DesignedInput
              type="password"
              label="Repeat Password"
              theme="light"
              size="large"
              value={repeat}
              change={(repeat) => _repeat(repeat)}
              error={error}
              positive={positive}
              bottom={0}
              native={{
                name: "repeat-password",
                cypress: "field-repeat-password",
              }}
              container={{
                width: "100%",
              }}
            />

            <Box parse="mt:24">
              <DesignedButton
                label="Complete Profile"
                loading={loading}
                press={handleRegister}
              />
            </Box>
          </Box>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[30, 50]}>
      <Col xs={24}>
        <Rule parse="!_h2 d:block mb:10 c:var(--black-base)">
          Welcome to Broadway Media
        </Rule>

        <Rule parse="d:block c:var(--grey-60) mb:10">
          We are thrilled to partner with you in making your next production,
          your BEST production!
        </Rule>

        <DesignedButton label="View Account" href="/profile/account" />
      </Col>

      <Col xs={24} xl={12}>
        <Box
          parse="br:10 pa:20 c:var(--grey-10)"
          border="1px solid var(--grey-40)"
        >
          <div
            style={{
              color: "var(--grey-60)",
            }}
          >
            <img
              width={88}
              height={59}
              src="/assets/logo/sp-icon.svg"
              alt="StagePlayer"
              loading="lazy"
              style={{
                marginBottom: "10px",
              }}
            />
          </div>

          <Rule parse="!_h3 d:block c:var(--black-base) mb:10">
            Download StagePlayer
          </Rule>

          <Rule parse="!_bl d:block c:var(--grey-80) mb:10">
            Access your backdrops via our simple and intuitive, StagePlayer
            software and start building your production
          </Rule>

          <DesignedButton
            label="Download StagePlayer"
            theme="white"
            press={() => {
              if (window) {
                window.open(hazel.download, "_blank");
              }
            }}
            size="small"
            properties={{
              border: "1px solid var(--grey-20)",
            }}
          />
        </Box>
      </Col>

      <Col xs={24} xl={12}>
        <Box
          parse="br:10 pa:20 c:var(--grey-10)"
          border="1px solid var(--grey-40)"
        >
          <img
            width={60}
            height={60}
            src="/assets/logo/workshops.svg"
            alt="Workshops"
            loading="lazy"
            style={{ marginBottom: "10px" }}
          />
          <Rule parse="!_h3 d:block c:var(--black-base) mb:10">
            Join a Workshop
          </Rule>
          <Rule parse="!_bl d:block c:var(--grey-80) mb:10">
            Broadway Media is proud to offer educational workshops to enhance
            your unique artistic vision at every turn.
          </Rule>
          <DesignedButton
            label="See Workshops"
            href="/workshops"
            size="small"
            theme="white"
            properties={{
              border: "1px solid var(--grey-20)",
            }}
          />
        </Box>
      </Col>
    </Row>
  );
};
