import Moment from "moment";
import { useEffect, useState } from "react";
import Database from "./Database";

interface HazelDownload {
  ready: boolean;
  download: string;
  version: string;
  date: string;
}
export const useHazel = (options?: { beta?: boolean }) => {
  const [data, _data] = useState<HazelDownload>({
    ready: false,
    download: "",
    version: "",
    date: "",
  });

  const base = options?.beta
    ? "https://betadownloads.broadwaymedia.com"
    : "https://downloads.broadwaymedia.com";
  const path = `${base}/update/win32/1.0.0`;

  useEffect(() => {
    (async () => {
      const cache = await Database.get(path);

      if (cache) {
        _data(cache as HazelDownload);
      }

      fetch(`${base}/update/win32/1.0.0`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((request) => request.json())
        .then((json) => {
          if (json.name !== undefined) {
            const newData = {
              ready: true,
              download: `${base}/download`,
              version: json.name as string,
              date: Moment(new Date(json.pub_date)).format("MMMM DD, YYYY"),
            };

            Database.set(path, newData);

            if (JSON.stringify(cache) !== JSON.stringify(newData)) {
              _data(newData);
            }
          }
        })
        .catch(() => {
          _data({
            ready: true,
            download: `${base}/download`,
            version: "v1.6.0",
            date: Moment(new Date("2023-04-05T09:20:12Z")).format(
              "MMMM DD, YYYY"
            ),
          });
        });
    })();
  }, []);

  return data;
};

export const useHazelDowngrade = (options: { major: string }) => {
  const [data, _data] = useState<{
    ready?: boolean;
    url?: string;
    version?: string;
  }>({
    ready: false,
  });

  useEffect(() => {
    fetch("https://downloads.broadwaymedia.com/major/v1.0.0", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((request) => request.json())
      .then((json) => {
        if (json.version) {
          _data({
            ready: true,
            url: `https://downloads.broadwaymedia.com/version/${json.version}`,
            version: json.version,
          });
        }
      });
  }, [options.major]);

  return data;
};

export default useHazel;
