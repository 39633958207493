/* eslint-disable @typescript-eslint/no-explicit-any */
import { Act, Scene, ShowData, VideoSet } from "libs/editor-ui/types";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Icon, Text, Tooltip } from "../shared";
import CueOptions from "./cue/CueOptions";
import Mover from "./Mover";

interface Props {
  data: ShowData;
  children: React.ReactNode;
  enabled: boolean;
  act: Act;
  actEnabled?: boolean;
  actIndex: number;
  scene: Scene;
  sceneIndex: number;
  sceneEnabled?: boolean;
  cue: VideoSet;
  cueIndex: number;
  onCueEnable: (actIndex: number, sceneIndex: number, cueIndex: number) => void;
  onCueDisable: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onCueDuplicate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onCueUpdate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cue: VideoSet
  ) => void;
  onCueTeleport: (
    teleportMode: string,
    oldActIndex: number,
    oldSceneIndex: number,
    oldCueIndex: number,
    newActIndex: number,
    newSceneIndex: number,
    newCueIndex: number
  ) => void;
  thumbnail: boolean | string;
  video: boolean | string;
  press: (props: {
    cue: VideoSet;
    actIndex: number;
    sceneIndex: number;
    cueIndex: number;
  }) => unknown;
  actCount?: number;
  sceneCount?: number;
  cueCount?: number;
}

const CueWrapper = (props: Props) => {
  const {
    data,
    cue,
    onCueEnable,
    onCueDisable,
    onCueDuplicate,
    onCueTeleport,
    enabled,
    sceneEnabled,
    actEnabled,
    actIndex,
    sceneIndex,
    cueIndex,
    onCueUpdate,
    thumbnail,
    video,
    press,
  } = props;

  const [displayThumbnailPopover, setDisplayThumbnailPopover] = useState(false);

  const getCueNumber = (): number => {
    let count = 0;
    let number = 0;

    data?.acts?.map((act) => {
      act?.scenes?.map((scene) => {
        scene?.sets?.map((set) => {
          count++;
          if (set.identifier && cue.identifier) {
            if (set?.identifier === cue?.identifier) {
              number = count;
            }
          }
        });
      });
    });

    return number;
  };

  const isAdvanced = Boolean(cue.camera);

  const shouldActionBeDisabled: false | string = (() => {
    if (cueIndex === 0) {
      return false;
    }

    if (!cue.action) {
      return false;
    }

    const previousSet = data?.acts?.[actIndex]?.scenes?.[
      sceneIndex
    ]?.sets?.find((set: any) => set?.nextSet === cue?.identifier);

    const previousCue =
      data?.acts?.[actIndex]?.scenes?.[sceneIndex]?.sets?.[cueIndex - 1];

    /* If previous is not correct. */
    if (
      previousSet &&
      previousCue &&
      previousSet.identifier !== previousCue.identifier
    ) {
      return `This video expects previous set to be "${
        previousSet?.cueDescription || previousSet?.name
      }". Thus will not contain any action videos. Only the loop video will be playing.`;
    }

    return false;
  })();

  return (
    <Box parse="w:100%">
      <Box
        css="--parent"
        parse="w:100% pa:15 d:flex a:center h:55"
        color={
          enabled && sceneEnabled && actEnabled
            ? Colors.foreground
            : Colors.background
        }
        style={{
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          boxSizing: "border-box",
        }}
      >
        {shouldActionBeDisabled ? (
          <Tooltip position="top-left" label={shouldActionBeDisabled}>
            <Box right={0}>
              <Icon name="warning" size={16} color={Colors.font4} />
            </Box>
          </Tooltip>
        ) : (
          <Mover right={0} css="--children" />
        )}
        <Mover right={0} opacity={0} />
        <Mover right={15} opacity={0} />

        {typeof thumbnail === "string" ? (
          <>
            <Box parse="p:relative w:47 mr:15 h:55 o:visible oa:visible d:inline-flex a:center j:center">
              <img
                onMouseEnter={() => setDisplayThumbnailPopover(true)}
                onMouseLeave={() => setDisplayThumbnailPopover(false)}
                src={thumbnail}
                style={{
                  display: "block",
                  width: 47,
                  maxWidth: 47,
                  minWidth: 47,
                  height: "auto",
                  maxHeight: 40,
                  borderRadius: 4,
                  border: "1px solid rgba(255,255,255,0.1)",
                  marginRight: 15,
                  cursor: "pointer",
                }}
                loading="lazy"
              />
              {displayThumbnailPopover ? (
                typeof video === "string" ? (
                  <video
                    src={video}
                    muted
                    autoPlay
                    loop
                    style={{
                      display: "block",
                      width: 200,
                      height: "auto",
                      borderRadius: 4,
                      border: "1px solid rgba(255,255,255,0.1)",
                      zIndex: 2,
                      position: "absolute",
                      left: 62,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                ) : (
                  <img
                    src={thumbnail}
                    style={{
                      display: "block",
                      width: 200,
                      height: "auto",
                      borderRadius: 4,
                      border: "1px solid rgba(255,255,255,0.1)",
                      zIndex: 2,
                      position: "absolute",
                      left: 62,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    loading="lazy"
                  />
                )
              ) : undefined}
            </Box>
          </>
        ) : (
          <Box parse="p:relative w:47 mr:15 h:55 o:visible oa:visible d:inline-flex a:center j:center">
            <Box
              parse="w:100% h:26.25 mr:15 oa:invisible br:5 mr:15 d:flex a:center j:center"
              color={
                isAdvanced
                  ? Colors.branding
                  : cue.blackout
                  ? Colors.background
                  : Colors.accent
              }
              style={{ minWidth: 47 }}
              border="1px solid rgba(255,255,255,0.1)"
            >
              {isAdvanced ? (
                <Icon color={Colors.background} name="stageplayer" size={14} />
              ) : cue.blackout ? (
                <Icon color={Colors.font1} name="on" size={14} />
              ) : cue.curtainWarmer ? (
                <Icon color={Colors.font1} name="picture" size={14} />
              ) : undefined}
            </Box>
          </Box>
        )}

        <Box parse="w:40 mw:40" right={15}>
          <Text
            rule="Label Tiny"
            color={
              enabled && sceneEnabled && actEnabled
                ? Colors.font3
                : Colors.font4
            }
            opacity={enabled && sceneEnabled && actEnabled ? 1 : 0.5}
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {getCueNumber()}
          </Text>
        </Box>

        <Box
          flex={1}
          style={{ flex: "1 0 auto", maxWidth: "calc((100% - 345px) / 2)" }}
          right={15}
          parse="d:flex a:center"
        >
          <Text
            rule="Label Tiny"
            color={
              enabled && sceneEnabled && actEnabled
                ? Colors.font3
                : Colors.font4
            }
            opacity={enabled && sceneEnabled && actEnabled ? 1 : 0.5}
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {cue?.cueDescription}{" "}
            {isAdvanced ? (
              <Box
                parse="d:inline-flex a:center j:center h:16 pl:5 pr:5 br:5 ml:10"
                color={Colors.branding}
              >
                <Icon color={Colors.background} name="stageplayer" size={9} />
                <Text size={9} left={2} color={Colors.background} weight="800">
                  ADVANCED
                </Text>
              </Box>
            ) : undefined}
          </Text>
        </Box>

        <Box parse="w:90 mw:90" right={15}>
          <Text
            rule="Label Tiny"
            color={
              enabled && sceneEnabled && actEnabled
                ? Colors.font3
                : Colors.font4
            }
            opacity={enabled && sceneEnabled && actEnabled ? 1 : 0.5}
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {cue?.cuePage}
          </Text>
        </Box>
        <Box
          flex={1}
          style={{ flex: "1 0 auto", maxWidth: "calc((100% - 345px) / 2)" }}
          right={15}
        >
          <Text
            rule="Label Tiny"
            color={
              enabled && sceneEnabled && actEnabled
                ? Colors.font3
                : Colors.font4
            }
            opacity={enabled && sceneEnabled && actEnabled ? 1 : 0.5}
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              display: "block",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {cue?.cueLine}
          </Text>
        </Box>

        <CueOptions
          sceneIndex={sceneIndex}
          cue={cue}
          actIndex={actIndex}
          cueIndex={cueIndex}
          data={data}
          press={press}
          onCueDisable={onCueDisable}
          onCueEnable={onCueEnable}
          onCueDuplicate={onCueDuplicate}
          onCueTeleport={onCueTeleport}
          onCueUpdate={onCueUpdate}
          loop={cue.loop || false}
          action={cue.action || false}
          enabled={enabled}
          thumbnail={typeof thumbnail === "string" ? thumbnail : undefined}
        />
      </Box>
    </Box>
  );
};

export default CueWrapper;
