import { DesignedInput, Rule } from "@with-nx/simple-ui/atoms";
import { useCallback, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

interface TypeaheadProps {
  loading?: boolean;
  options: string[];
  change?: (value: string, free?: boolean) => unknown;
  blur?: () => unknown;
  search?: (value: string) => unknown;
  value?: string;
  input?: {
    [key: string]: unknown;
  };
  disable?: boolean;
  free?: boolean;
}

export const Typeahead = ({
  loading,
  options,
  change,
  search,
  value,
  input,
  disable,
  blur,
  free,
}: TypeaheadProps) => {
  const [v, _v] = useState(value || "");
  const [o, _o] = useState(options);
  const [f, _f] = useState(false);
  const [h, _h] = useState(false);

  useEffect(() => {
    if (value?.length === 0 || !value) {
      _v("");
    }
    _o(options);

    /*
    @todo Improve this check upon change from library.
    if (!options.includes(value)) {
      _v("");
    }
    */
  }, [value, options]);

  const check = useCallback(
    (override?: string) => {
      _f(false);
      _h(false);
      if (!o.includes(override || v) && !free) {
        _v("");
      } else if (override) {
        _v(override);
      }

      change?.(override || v, free);
    },
    [change, o, v, free]
  );

  const onblur = useCallback(() => {
    if (h) {
      return;
    }
    check();
  }, [check, h]);

  const focus = () => {
    _f(true);
  };

  const label = v ? (v?.includes("-") ? v.split("-")[0].trim() : v) : "";

  return (
    <Box css="p:relative" opacity={disable ? 0.75 : 1}>
      <DesignedInput
        {...input}
        focus={focus}
        blur={() => {
          onblur();
          blur?.();
        }}
        value={label}
        change={(v) => {
          _v(v);
          search?.(v);
        }}
        loading={loading}
        disable={disable}
      />
      {f && !free ? (
        <Box
          parse="p:absolute b:unset r:unset l:0 br:10 c:#2D3542 pa:16 w:100% i:99 z:99 oy:auto pb:0"
          mode="position"
          top="calc(100% + 12px)"
          style={{ maxHeight: 200 }}
          css="--no-scrollbar"
          native={{
            onMouseEnter: () => _h(true),
            onMouseLeave: () => _h(false),
          }}
        >
          {o.map((option) => {
            if (v.length) {
              if (!option.toLocaleLowerCase().includes(v.toLocaleLowerCase())) {
                return;
              }
            }

            const label = option.includes("-")
              ? option.split("-")[0].trim()
              : option;

            return (
              <Box
                key={option}
                parse="d:flex a:center"
                press={() => check(option)}
                bottom={16}
                native={{
                  cypress: `option-${label}`,
                }}
              >
                <Rule parse="!ls d:block">{label}</Rule>
              </Box>
            );
          })}
          {o.length === 0 ? (
            <Rule parse="!ls d:block c:?font4 mb:16">
              Start typing to search
            </Rule>
          ) : o.filter((option) => {
              if (v.length) {
                if (
                  !option.toLocaleLowerCase().includes(v.toLocaleLowerCase())
                ) {
                  return false;
                }
              }
              return true;
            }).length === 0 ? (
            <Rule parse="!ls d:block c:?font4 mb:16">No option available</Rule>
          ) : undefined}
        </Box>
      ) : undefined}
    </Box>
  );
};

export default Typeahead;
