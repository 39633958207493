/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";

import useService from "./useService";

type UseAddressesCountry = {
  code: string;
  name: string;
};

type UseAddressesState = {
  code: string;
  name: string;
  countryCode: string;
};

type UseAddressesCity = {
  id: number;
  name: string;
  stateCode: string;
};

export class UseAddresses {
  static async states(
    country: string,
    search: string
  ): Promise<UseAddressesState[]> {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 600_000,
    });

    const request = (await makeRequestToMicroservice(
      "GET",
      `/localization/geography/countries/${country}/states`,
      {
        "pagination[page]": "1",
        "filter[search]": search,
      }
    )) as any;

    return request.result || ([] as UseAddressesState[]);
  }

  static async cities(
    country: string,
    state: string,
    search: string
  ): Promise<UseAddressesCity[]> {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 600_000,
    });

    const request = (await makeRequestToMicroservice(
      "GET",
      `/localization/geography/countries/${country}/states/${state}/cities`,
      {
        "pagination[page]": "1",
        "filter[search]": search,
      }
    )) as any;

    return request.result || ([] as UseAddressesCity[]);
  }
}

export const useAddresses = ({
  initial,
  free,
}: {
  initial?: {
    country: string;
    state: string;
    city: string;
  };
  free?: {
    country: string[];
  };
}) => {
  const [countries, _countries] = useState<UseAddressesCountry[]>([]);
  const [country, _country] = useState(initial?.country || "");
  const [state, _state] = useState(initial?.state || "");
  const [city, _city] = useState(initial?.city || "");
  const [timeout, _timeout] = useState<
    | {
        timeout: NodeJS.Timeout;
        type: "country" | "state" | "city";
        search: string;
      }
    | undefined
  >(undefined);
  const [loading, _loading] = useState(initial !== undefined);
  const [initialized, _initialized] = useState(false);

  const [stateOptions, setStateOptions] = useState<UseAddressesState[]>([]);
  const [cityOptions, setCityOptions] = useState<UseAddressesCity[]>([]);

  const isFreeByCountry =
    free?.country?.includes(country?.split("-")?.[0]?.trim()) ||
    free?.country?.[0] === "*";

  const country_ = useCallback(
    (v: string) => {
      _country(v);

      if (v !== country) {
        _state("");
        _city("");
        setCityOptions([]);
        setStateOptions([]);
      }
    },
    [country, state, city]
  );
  const state_ = useCallback(
    (v: string) => {
      if (isFreeByCountry) {
        _state(v);
        return;
      }

      _state(v);
      if (v !== state) {
        _city("");
        setCityOptions([]);
      }

      if (timeout?.timeout) {
        clearTimeout(timeout.timeout);
      }

      _timeout({
        timeout: setTimeout(action, 500),
        type: "state",
        search: v,
      });
    },
    [state, city, timeout]
  );
  const city_ = (v: string) => {
    if (isFreeByCountry) {
      _city(v);
      return;
    }

    _city(v);

    if (timeout?.timeout) {
      clearTimeout(timeout.timeout);
    }

    _timeout({
      timeout: setTimeout(action, 500),
      type: "city",
      search: v,
    });
  };

  const action = useCallback(() => {
    (async () => {
      if (timeout?.type === "state") {
        const c = country.includes("-")
          ? country.split("-")[1].trim()
          : country;
        const states = await UseAddresses.states(c, timeout.search);
        setStateOptions(states);
      }

      if (timeout?.type === "city") {
        const c = country.includes("-")
          ? country.split("-")[1].trim()
          : country;
        const s = state.includes("-") ? state.split("-")[1].trim() : state;

        const cities = await UseAddresses.cities(c, s, timeout.search);
        setCityOptions(cities);
      }

      _timeout(undefined);
    })();
  }, [timeout, country, state, city]);

  const unload = () => {
    setTimeout(() => _loading(false), 100);
  };

  useEffect(() => {
    if (initialized) {
      return;
    }

    _initialized(true);

    (async () => {
      try {
        if (initial !== undefined) {
          const list = await (async () => {
            try {
              if (countries?.length) {
                return countries;
              }

              const c = await fetch("/json/countries.json").then((r) =>
                r.json()
              );
              _countries(c);

              return c as UseAddressesCountry[];
            } catch (error) {
              console.error(error);
              return [];
            }
          })();

          const c = list.find((i) =>
            i.code
              .toLocaleLowerCase()
              .includes(initial.country.toLocaleLowerCase())
          );
          if (c) {
            _country(`${c.name} - ${c.code}`);

            const ss = await UseAddresses.states(c.code, initial.state);
            const s = ss.find((i) =>
              i.name
                .toLocaleLowerCase()
                .includes(initial.state.toLocaleLowerCase())
            );

            if (s) {
              _state(`${s.name} - ${s.code}`);

              const cc = await UseAddresses.cities(
                c.code,
                s.code,
                initial.city
              );
              const ci = cc.find((i) =>
                i.name
                  .toLocaleLowerCase()
                  .includes(initial.city.toLocaleLowerCase())
              );

              if (ci) {
                _city(`${ci.name} - ${ci.stateCode}`);
              }

              unload();
            } else {
              unload();
            }
          } else {
            unload();
          }
        }
      } catch (error) {
        console.error(false);
        unload();
      }
    })();
  }, [initial]);

  return {
    country: {
      value: country,
      options: countries,
      set: country_,
      loading: false,
      valid: country.length > 0 && (isFreeByCountry || country.includes("-")),
    },
    state: {
      value: state,
      options: stateOptions,
      set: state_,
      loading: timeout?.type === "state",
      valid: state.length > 0 && (isFreeByCountry || state.includes("-")),
    },
    city: {
      value: city,
      options: cityOptions,
      set: city_,
      loading: timeout?.type === "city",
      valid:
        country.length > 0 &&
        (isFreeByCountry || country.includes("-")) &&
        state.length > 0 &&
        (isFreeByCountry || state.includes("-")),
    },
    loading: loading,
  };
};

export default useAddresses;
