import ThrottlerController from "../ThrottlerController";
import useService from "../useService";

export class GatedContentInteractions {
  static save(
    data: {
      page?: string;
      content: string;
      access?: string;
      metadata?: unknown;
    },
    token?: string
  ) {
    if (!ThrottlerController.can("interactions", "save", 10000)) {
      return;
    }

    const makeRequestToCMS = useService("cms");
    const path = (() => {
      if (typeof window !== "undefined") {
        return window.location.pathname;
      } else {
        return data.page;
      }
    })();

    makeRequestToCMS(
      "POST",
      "/api/v1/gated-content-interaction/save",
      {
        page: data.page || path,
        content: data.content,
        access: data.access || "View",
        metadata: data.metadata,
      },
      token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined
    );
  }

  static createWaitList(
    token: string,
    show_id: string,
    product_type: string = "scenic-projections"
  ) {
    const makeRequestToBackstage = useService("accounts");

    return makeRequestToBackstage(
      "POST",
      "/api/v2/waitlists",
      {
        waitlist: {
          show_id,
          product_type:
            {
              "choreography-guides": "Choreo::Guide",
              "scenic-projections": "ScenicProjections::Package",
              "stage-guides": "Guide::Package",
            }[product_type] || "ScenicProjections::Package",
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
  }
}
