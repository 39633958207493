import { Text as TextPrimitive } from "simple-effing-primitive-layout";

const Text = (props: {
  rule?: string;
  children?: React.ReactNode;
  [key: string]: unknown;
}) => {
  const { rule } = props;

  switch (rule) {
    case "Header Large":
    case "HL":
    case "hl":
      return (
        <TextPrimitive
          family="Figtree"
          size={32}
          line={40}
          weight="600"
          {...props}
        />
      );
      break;

    case "Header Medium":
    case "HM":
    case "hm":
      return (
        <TextPrimitive
          family="Figtree"
          size={28}
          line={36}
          weight="600"
          {...props}
        />
      );
      break;

    case "Header Small":
    case "HS":
    case "hs":
      return (
        <TextPrimitive
          family="Figtree"
          size={24}
          line={32}
          weight="600"
          {...props}
        />
      );
      break;

    case "Header Tiny":
    case "HT":
    case "ht":
      return (
        <TextPrimitive
          family="Figtree"
          size={20}
          line={28}
          weight="600"
          {...props}
        />
      );
      break;

    case "Label Large":
    case "LL":
    case "ll":
      return (
        <TextPrimitive
          family="Figtree"
          size={18}
          line={32}
          weight="600"
          {...props}
        />
      );
      break;

    case "Label Medium":
    case "LM":
    case "lm":
      return (
        <TextPrimitive
          family="Figtree"
          size={16}
          line={20}
          weight="600"
          {...props}
        />
      );
      break;

    case "Label Small":
    case "LS":
    case "ls":
      return (
        <TextPrimitive
          family="Figtree"
          size={14}
          line={16}
          weight="600"
          {...props}
        />
      );
      break;

    case "Label Tiny":
    case "LT":
    case "lt":
      return (
        <TextPrimitive
          family="Figtree"
          size={12}
          line={16}
          weight="600"
          {...props}
        />
      );
      break;

    case "Paragraph Large":
    case "PL":
    case "pl":
      return (
        <TextPrimitive
          family="Figtree"
          size={18}
          line={28}
          weight="400"
          {...props}
        />
      );
      break;

    case "Paragraph Medium":
    case "PM":
    case "pm":
      return (
        <TextPrimitive
          family="Figtree"
          size={16}
          line={24}
          weight="400"
          {...props}
        />
      );
      break;

    case "Paragraph Small":
    case "PS":
    case "ps":
      return (
        <TextPrimitive
          family="Figtree"
          size={14}
          line={20}
          weight="400"
          {...props}
        />
      );
      break;

    case "Paragraph Tiny":
    case "PT":
    case "pt":
      return (
        <TextPrimitive
          family="Figtree"
          size={12}
          line={20}
          weight="400"
          {...props}
        />
      );
      break;

    default:
      return <TextPrimitive family="Figtree" {...props} />;
      break;
  }
};

export default Text;
