import { ShowData, VideoSet } from "libs/editor-ui/types";
import clone from "lodash/clone";
import { Box } from "simple-effing-primitive-layout";
import { v4 as uuidv4 } from "uuid";

import { Colors, Icon, Text } from "../../../shared";

const NewCueOptions = ({
  shouldPopupDisplayOnTop,
  setNewCueOptionsAreOpen,
  newCueOptionsAreOpen,
  onDangerousDataUpdate,
  data,
  actIndex,
  sceneIndex,
}: {
  shouldPopupDisplayOnTop: boolean;
  setNewCueOptionsAreOpen: (is: boolean) => unknown;
  newCueOptionsAreOpen: boolean;
  onDangerousDataUpdate: (data: ShowData) => unknown;
  data: ShowData;
  thumbnail?: string;
  actIndex: number;
  sceneIndex: number;
}) => {
  const OPTIONS = [
    {
      label: "Blackout",
      press: () => create("blackout"),
    },
    {
      label: "Curtain Warmer",
      press: () => create("curtainWarmer"),
    },
    {
      label: "Camera",
      press: () => create("camera"),
      advanced: true,
    },
  ];

  const create = (type: "camera" | "blackout" | "curtainWarmer") => {
    const add = (set: VideoSet) => {
      const temporary = clone(data);

      temporary.acts[actIndex]?.scenes[sceneIndex]?.sets?.push(set);

      onDangerousDataUpdate(temporary);
    };

    switch (type) {
      case "camera":
        return add({
          identifier: uuidv4(),
          name: "Camera",
          cueDescription: "Camera",
          enabled: true,
          camera: true,
        });
      case "blackout":
        return add({
          identifier: uuidv4(),
          name: "Blackout",
          cueDescription: "Blackout",
          enabled: true,
          blackout: true,
        });
      case "curtainWarmer":
        return add({
          identifier: uuidv4(),
          name: "Curtain Warmer",
          cueDescription: "Curtain Warmer",
          enabled: true,
          curtainWarmer: true,
        });
    }
  };

  return (
    <div onMouseLeave={() => setNewCueOptionsAreOpen(false)}>
      <Box
        display={newCueOptionsAreOpen ? "block" : "none"}
        parse={
          "w:305 pr:5 r:240 p:absolute z:3 i:3 " +
          (shouldPopupDisplayOnTop ? "b:25" : "t:25")
        }
      >
        <Box parse="w:300 pa:15 br:5" color={Colors.background}>
          {OPTIONS.map((option, o) => (
            <Box
              key={o}
              parse="w:100% d:flex a:center"
              css="--more"
              press={option.press}
              top={o === 0 ? 0 : 10}
            >
              <Text left={10} color={Colors.font1} rule="Label Tiny">
                {option.label}
              </Text>
              {option.advanced === true ? (
                <Box
                  parse="d:inline-flex a:center j:center h:16 pl:5 pr:5 br:5 ml:10"
                  color={Colors.branding}
                >
                  <Icon color={Colors.background} name="stageplayer" size={9} />
                  <Text
                    size={9}
                    left={2}
                    color={Colors.background}
                    weight="800"
                  >
                    ADVANCED
                  </Text>
                </Box>
              ) : undefined}
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default NewCueOptions;
