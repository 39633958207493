import { useMobile } from "@with-nx/hooks-n-helpers";
import { Rule } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

import { GridStepScroller } from "../grid-step-scroller/grid-step-scroller";

type FeaturesContainerItem = {
  icon?: React.ReactNode;
  title: string;
  description: string;
};

interface FeaturesContainerProps {
  items: FeaturesContainerItem[];
  gutter?: [number, number];
  md?: number;
  lg?: number;
  xl?: number;
}

export const FeaturesContainer = (props: FeaturesContainerProps) => {
  const { md = 8, lg = 8, xl = 8 } = props;

  const mobile = useMobile();

  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor="#46C2F2" offset="0%" />
          <stop stopColor="#1875FF" offset="100%" />
        </linearGradient>
      </svg>
      <GridStepScroller
        gutter={props.gutter}
        xs={24}
        sm={12}
        md={md}
        lg={lg}
        xl={xl}
        items={props.items.map((item) => (
          <Box parse="br:10 pa:24 c:#1E242D h:100%">
            <Box parse="d:flex mb:16 a:center">
              {item.icon ? (
                <Box
                  parse={`w:${mobile ? 48 : 64} h:${
                    mobile ? 48 : 64
                  } br:999 d:inline-flex a:center j:center mr:10`}
                  style={{
                    backgroundImage:
                      "linear-gradient(224.12deg, #202833 0%, #223045 101.59%)",
                  }}
                >
                  {item.icon}
                </Box>
              ) : undefined}
              <Box css="f:1">
                <Rule
                  parse="!ht d:block"
                  style={{
                    background:
                      "linear-gradient(90deg, #46D3F2 0%, #1875FF 106.85%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    color: "transparent",
                    textFillColor: "transparent",
                  }}
                  tag="p"
                >
                  {item.title}
                </Rule>
              </Box>
            </Box>
            <Rule parse="!ps d:block mt:8">{item.description}</Rule>
          </Box>
        ))}
      />
    </>
  );
};

export default FeaturesContainer;
