import { useService } from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { useEffect, useState } from "react";

import { Ecommerce } from "../types/types";

export function useLicensors() {
  const region = useServerRegion();
  const [licensors, _licensors] = useState<
    Ecommerce.Schemas.LicensorSchemaType[]
  >([]);
  const service = useService("microservice", {
    cache: 1_800_000,
  });
  const [error] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const data = await service(
        "GET",
        `/ecommerce/products/${region?.id}/licensors`
      );

      _licensors(data as Ecommerce.Schemas.LicensorSchemaType[]);
    })();
  }, []);

  return {
    licensors: licensors,
    isLoading: !error && !licensors?.length,
    isError: Boolean(error),
  };
}
