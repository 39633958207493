import { useState } from "react";

import { base64 as BASE64, open as OPEN } from "./utils";

export type UploadedFile = {
  name?: string;
  extension?: string;
  base64?: string;
  file?: File;
};

export function useFile({
  accept = "*",
  multiple = false,
  base64 = false,
}: {
  accept?: string;
  multiple?: boolean;
  base64?: boolean;
}) {
  const [file, _file] = useState<UploadedFile[] | undefined>();
  const [loading, _loading] = useState<boolean>(false);
  const [error] = useState<string | null>();

  const _open = () => {
    _file(undefined);
    _loading(true);

    OPEN(accept, multiple, (event) => {
      const input = event.target as HTMLInputElement;
      const files = input.files ? input.files[0] : undefined;

      if (files) {
        if (base64) {
          BASE64(files, (value) => {
            _file(
              multiple
                ? [
                    ...(file || []),
                    {
                      name: files.name.split(".").shift() as string,
                      extension: files.name.split(".").pop() as string,
                      file: files,
                      base64: value,
                    },
                  ]
                : [
                    {
                      name: files.name.split(".").shift() as string,
                      extension: files.name.split(".").pop() as string,
                      file: files,
                      base64: value,
                    },
                  ]
            );
            _loading(false);
          });
        } else {
          _file(
            multiple
              ? [
                  ...(file || []),
                  {
                    name: files.name.split(".").shift() as string,
                    extension: files.name.split(".").pop() as string,
                    file: files,
                    base64: "",
                  },
                ]
              : [
                  {
                    name: files.name.split(".").shift() as string,
                    extension: files.name.split(".").pop() as string,
                    file: files,
                    base64: "",
                  },
                ]
          );
          _loading(false);
        }
      } else {
        _loading(false);
      }
    });
  };
  const _change = (files?: UploadedFile[] | undefined) => {
    _file(files);
  };

  return { file, loading, error, open: _open, change: _change };
}

export default useFile;
