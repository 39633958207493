import { useContext, useState } from "react";

import { RegionMeta } from "./meta";
import { RegionContext, UseServerRegionType } from "./useServerRegion";

export type Regions =
  | "🇺🇸 United States"
  | "🇨🇦 Canada"
  | "🇦🇺 Australia"
  | "🇳🇿 New Zealand"
  | "🇬🇧 United Kingdom"
  | "🇪🇺 Europe"
  | "🌍 Global";
export type RegionGroups =
  | "🇺🇸 United States / 🇨🇦 Canada"
  | "🇦🇺 Australia / 🇳🇿 New Zealand"
  | "🇬🇧 United Kingdom / 🇪🇺 Europe"
  | "🌍 Global";

const decideLocalRegionByServerRegion = (
  server: UseServerRegionType
): Regions => {
  let region: Regions = "🌍 Global";

  server?.countries?.reverse()?.map((country) => {
    if (country?.code === "US") {
      region = "🇺🇸 United States";
    }

    if (country?.code === "CA") {
      region = "🇨🇦 Canada";
    }

    if (country?.code === "AU") {
      region = "🇦🇺 Australia";
    }

    if (country?.code === "NZ") {
      region = "🇳🇿 New Zealand";
    }

    if (country?.code === "GB" || country?.code === "UK") {
      region = "🇬🇧 United Kingdom";
    }

    if (country?.code === "TR" || server?.currency?.code === "EUR") {
      region = "🇪🇺 Europe";
    }
  });

  return region;
};

export function useRegion() {
  const context = useContext(RegionContext);

  if (!context) {
    throw new Error("useRegion must be used within an `RegionProvider`");
  }

  const region = decideLocalRegionByServerRegion(context);

  const [error] = useState<string | null>(null);

  const regions: Regions[] = [
    "🇺🇸 United States",
    "🇨🇦 Canada",
    "🇦🇺 Australia",
    "🇳🇿 New Zealand",
    "🇬🇧 United Kingdom",
    "🇪🇺 Europe",
    "🌍 Global",
  ];

  const groups: RegionGroups[] = [
    "🇺🇸 United States / 🇨🇦 Canada",
    "🇬🇧 United Kingdom / 🇪🇺 Europe",
    "🇦🇺 Australia / 🇳🇿 New Zealand",
    "🌍 Global",
  ];

  const options: [string, string][] = [];
  groups.map((group) => options.push([group, group]));

  return {
    region: RegionMeta[region].group,
    r: region,
    groups,
    regions,
    loading: context?.meta?.loading,
    error,
    options,
    email: RegionMeta[region].email,
    calendly: RegionMeta[region].calendly,
    instagram: RegionMeta[region].instagram,
    facebook: RegionMeta[region].facebook,
    emoji: RegionMeta[region].emoji,
    currency: RegionMeta[region].currency,
    symbol: RegionMeta[region].symbol,
    meta: RegionMeta[region] || RegionMeta["🌍 Global"],
    metas: RegionMeta,
  };
}

export function useStatesCodes(country: "US" | "CA" = "US") {
  const options =
    country === "CA"
      ? Object.entries({
          AB: "Alberta",
          BC: "British Columbia",
          MB: "Manitoba",
          NB: "New Brunswick",
          NL: "Newfoundland and Labrador",
          NS: "Nova Scotia",
          NT: "Northwest Territories",
          NU: "Nunavut",
          ON: "Ontario",
          PE: "Prince Edward Island",
          QC: "Québec",
          SK: "Saskatchewan",
          YT: "Yukon",
        }).map(([key, value]) => [key, value])
      : Object.entries({
          AL: "Alabama",
          AK: "Alaska",
          AS: "American Samoa",
          AZ: "Arizona",
          AR: "Arkansas",
          CA: "California",
          CO: "Colorado",
          CT: "Connecticut",
          DE: "Delaware",
          DC: "District Of Columbia",
          FM: "Federated States Of Micronesia",
          FL: "Florida",
          GA: "Georgia",
          GU: "Guam",
          HI: "Hawaii",
          ID: "Idaho",
          IL: "Illinois",
          IN: "Indiana",
          IA: "Iowa",
          KS: "Kansas",
          KY: "Kentucky",
          LA: "Louisiana",
          ME: "Maine",
          MH: "Marshall Islands",
          MD: "Maryland",
          MA: "Massachusetts",
          MI: "Michigan",
          MN: "Minnesota",
          MS: "Mississippi",
          MO: "Missouri",
          MT: "Montana",
          NE: "Nebraska",
          NV: "Nevada",
          NH: "New Hampshire",
          NJ: "New Jersey",
          NM: "New Mexico",
          NY: "New York",
          NC: "North Carolina",
          ND: "North Dakota",
          MP: "Northern Mariana Islands",
          OH: "Ohio",
          OK: "Oklahoma",
          OR: "Oregon",
          PW: "Palau",
          PA: "Pennsylvania",
          PR: "Puerto Rico",
          RI: "Rhode Island",
          SC: "South Carolina",
          SD: "South Dakota",
          TN: "Tennessee",
          TX: "Texas",
          UT: "Utah",
          VT: "Vermont",
          VI: "Virgin Islands",
          VA: "Virginia",
          WA: "Washington",
          WV: "West Virginia",
          WI: "Wisconsin",
          WY: "Wyoming",
        }).map(([key, value]) => [key, value]);

  return options as [string, string][];
}

export function getStateCodesObject(country: "US" | "CA" = "US") {
  const options: {
    [key: string]: string;
  } =
    country === "CA"
      ? {
          AB: "Alberta",
          BC: "British Columbia",
          MB: "Manitoba",
          NB: "New Brunswick",
          NL: "Newfoundland and Labrador",
          NS: "Nova Scotia",
          NT: "Northwest Territories",
          NU: "Nunavut",
          ON: "Ontario",
          PE: "Prince Edward Island",
          QC: "Québec",
          SK: "Saskatchewan",
          YT: "Yukon",
        }
      : {
          AL: "Alabama",
          AK: "Alaska",
          AS: "American Samoa",
          AZ: "Arizona",
          AR: "Arkansas",
          CA: "California",
          CO: "Colorado",
          CT: "Connecticut",
          DE: "Delaware",
          DC: "District Of Columbia",
          FM: "Federated States Of Micronesia",
          FL: "Florida",
          GA: "Georgia",
          GU: "Guam",
          HI: "Hawaii",
          ID: "Idaho",
          IL: "Illinois",
          IN: "Indiana",
          IA: "Iowa",
          KS: "Kansas",
          KY: "Kentucky",
          LA: "Louisiana",
          ME: "Maine",
          MH: "Marshall Islands",
          MD: "Maryland",
          MA: "Massachusetts",
          MI: "Michigan",
          MN: "Minnesota",
          MS: "Mississippi",
          MO: "Missouri",
          MT: "Montana",
          NE: "Nebraska",
          NV: "Nevada",
          NH: "New Hampshire",
          NJ: "New Jersey",
          NM: "New Mexico",
          NY: "New York",
          NC: "North Carolina",
          ND: "North Dakota",
          MP: "Northern Mariana Islands",
          OH: "Ohio",
          OK: "Oklahoma",
          OR: "Oregon",
          PW: "Palau",
          PA: "Pennsylvania",
          PR: "Puerto Rico",
          RI: "Rhode Island",
          SC: "South Carolina",
          SD: "South Dakota",
          TN: "Tennessee",
          TX: "Texas",
          UT: "Utah",
          VT: "Vermont",
          VI: "Virgin Islands",
          VA: "Virginia",
          WA: "Washington",
          WV: "West Virginia",
          WI: "Wisconsin",
          WY: "Wyoming",
        };

  return options;
}

export default useRegion;
