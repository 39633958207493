import { Act, Scene, ShowData } from "libs/editor-ui/types";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { Colors, Text } from "../shared";
import Mover from "./Mover";
import SceneOptions from "./scene/SceneOptions";

interface Props {
  children: React.ReactNode;
  enabled: boolean;
  act: Act;
  actIndex: number;
  actEnabled?: boolean;
  scene: Scene;
  sceneIndex: number;
  onSceneDuplicate: (act: number, scene: number) => void;
  onSceneDisable: (act: number, scene: number) => void;
  onSceneEnable: (act: number, scene: number) => void;
  thumbnail?: string;
  onDangerousDataUpdate: (data: ShowData) => void;
  data: ShowData;
}

const SceneWrapper = (props: Props) => {
  const {
    scene,
    children,
    enabled,
    actEnabled,
    actIndex,
    sceneIndex,
    onSceneDisable,
    onSceneEnable,
    onSceneDuplicate,
    thumbnail,
    onDangerousDataUpdate,
    data,
  } = props;
  const [expanded, setExpanded] = useState(true);

  return (
    <Box parse="w:100%">
      <div>
        <Box
          css="--parent"
          parse="w:100% pa:15 d:flex a:center h:47"
          color={enabled && actEnabled ? Colors.foreground : Colors.background}
          style={{
            borderBottom: "1px solid rgba(255,255,255,0.1)",
            boxSizing: "border-box",
          }}
        >
          <Mover
            vertical={!expanded}
            press={() => setExpanded(!expanded)}
            right={0}
            css={expanded ? "--children" : undefined}
          />
          <Mover right={15} opacity={0} />
          <Text
            rule="Label Tiny"
            color={Colors.font4}
            opacity={enabled && actEnabled ? 1 : 0.5}
          >
            {scene.name}
          </Text>
          <Box flex={1} right={15} />
          {actEnabled ? (
            <SceneOptions
              thumbnail={thumbnail}
              enabled={enabled}
              actIndex={actIndex}
              sceneIndex={sceneIndex}
              data={data}
              onSceneDisable={onSceneDisable}
              onSceneEnable={onSceneEnable}
              onSceneDuplicate={onSceneDuplicate}
              onDangerousDataUpdate={onDangerousDataUpdate}
            />
          ) : undefined}
        </Box>
      </div>
      <Box
        parse="w:100% o:visible oa:visible"
        display={expanded ? "block" : "none"}
        style={{ transition: "height 0.2s ease-in-out" }}
      >
        <Box
          parse="w:100% pa:15 d:flex a:center h:32"
          color={enabled && actEnabled ? Colors.accent : Colors.background}
          style={{
            borderBottom: "1px solid rgba(255,255,255,0.1)",
            boxSizing: "border-box",
          }}
        >
          <Mover right={0} opacity={0} />
          <Mover right={0} opacity={0} />
          <Mover right={15} opacity={0} />
          <Box parse="mr:15 w:47" />
          <Box parse="w:40" right={15}>
            <Text rule="Label Tiny" color={Colors.font4} opacity={0.5}>
              Cue
            </Text>
          </Box>
          <Box
            flex={1}
            right={15}
            style={{ flex: "1 0 auto", maxWidth: "calc((100% - 345px) / 2)" }}
          >
            <Text rule="Label Tiny" color={Colors.font4} opacity={0.5}>
              Description
            </Text>
          </Box>
          <Box parse="w:90 mw:90" right={15}>
            <Text rule="Label Tiny" color={Colors.font4} opacity={0.5}>
              Page Number
            </Text>
          </Box>
          <Box
            flex={1}
            right={15}
            style={{ flex: "1 0 auto", maxWidth: "calc((100% - 345px) / 2)" }}
          >
            <Text rule="Label Tiny" color={Colors.font4} opacity={0.5}>
              Cue Line
            </Text>
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default SceneWrapper;
